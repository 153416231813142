export default function getItems(parent) {
  return [
    {
      template: 'groove_end',
      item: [],
    },
    {
      template: 'groove',
      item: [],
    },
    {
      template: 'groove_edge',
      item: [],
    },
    {
      template: 'groove_side',
      item: [],
    },
    {
      template: 'arc_side_inner',
      item: [],
    },
    {
      template: 'radius_inner',
      item: [],
    },
    {
      template: 'radius_outer',
      item: [],
    },
    {
      template: 'arc_corner',
      item: [],
    },
    {
      template: 'arc_side',
      item: [],
    },
    {
      template: 'line_corner',
      item: [],
    },
    {
      template: 'carve_p',
      item: [],
    },
    {
      template: 'carve_g',
      item: [],
    },
    {
      template: 'carve_gcnc',
      item: [],
    },
    {
      template: 'ellipse_in',
      item: [],
    },
    {
      template: 'square_in',
      item: [],
    },
    {
      template: 'hdl_smile',
      item: [],
    },
    {
      template: 'carve_groove',
      item: [],
    },
    {
      template: 'carve_led_direct',
      item: [],
    },
    {
      template: 'carve_led_corner',
      item: [],
    },
    {
      template: 'edge_hole',
      item: [],
    },
    {
      template: 'side_hole',
      item: [],
    },
    {
      template: 'minifix',
      item: [],
    },
    {
      template: 'minifix_shkant',
      item: [],
    },
    {
      template: 'rafix',
      item: [],
    },
    {
      template: 'hinge35',
      item: [],
    },
    {
      template: 'tt_lock_socket',
      item: [],
    },
    {
      template: 'tt_lock_plug',
      item: [],
    },
    {
      template: 'tt_radius_flange',
      item: [],
    },
    {
      template: 'tt_corner_cut',
      item: [],
    },
    {
      template: 'tt_rect_inside',
      item: [],
    },
    {
      template: 'tt_2corner_radius',
      item: [],
    },
    {
      template: 'tt_4corner_radius',
      item: [],
    },
    {
      template: 'background',
      item: [],
    },
  ]
}

export function findItemByIdCarve(items, idCarve) {
  for (let item of items) {
    const foundItem = item.item.find(i => i.idCarve == idCarve)
    if (foundItem) return foundItem
  }
  return null
}
