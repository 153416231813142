import paper, { Project, Group, Item } from 'paper'

export function toggleClickEvents(activate = true, coordinateGrid) {
  const { drawings } = coordinateGrid.drawings
  Object.values(drawings || {}).forEach((item: any) => {
    const { op, areas, groups, selections } = item
    if (!op) return
    new Array(...areas, ...selections).forEach(target => {
      // При нажатии на операцию, мы должны проваливаться в неё
      // и выделять жирным шрифтом, или другим цветом, в добавленных
      // для быстрого поиска (в случае, если нам нужно её к примеру, удалить)
      if (activate) {
        target.on('click', function(evt) {
          // Cancels the event if it is cancelable,
          // and stops stopping further propagation of the event.
          // This is has the same effect as calling both stopPropagation() and preventDefault().
          evt.stop()
          coordinateGrid.onSelect(op)
        })
      } else {
        target.off('click')
      }
    })
  })
}
