<template>
  <div class="constructor-form-group">
    <div class="constructor-sides-field">
      <span>{{ label }}</span>
      <div class="sides" :class="{ 'is-invalid': error }">
        <div v-for="item in items" :key="item">
          <div class="side" :class="'side--' + item">
            <label class="side__label"
              ><input
                class="side__checkbox"
                type="radio"
                :name="_uid"
                :value="true"
                @change="toggle(item)"
                v-model="value[item]"/><span class="side__value"></span
            ></label>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SideInput',
  props: {
    value: {
      type: Object,
      default: function() {
        return {
          top: false,
          bottom: false,
          right: false,
          left: false,
        }
      },
    },
    label: String,
    error: Boolean,
  },
  data() {
    return {}
  },
  computed: {
    items() {
      return ['top', 'right', 'bottom', 'left']
    },
  },
  methods: {
    toggle(item) {
      const value = {
        top: false,
        bottom: false,
        right: false,
        left: false,
      }
      value[item] = true
      this.$emit('input', value)
    },
  },
}
</script>

<style scoped lang="scss"></style>
