export const groups = {
  1: [],
  2: [
    'carve_led_direct',
    'carve_led_corner',
    'groove_edge',
    // 'groove_end',
    'groove',
    'groove_side',
  ],
  3: [
    'hinge35',
    'side_hole',
    'edge_hole',
    'minifix',
    'minifix_shkant',
    'rafix',
  ],
  4: [
    'arc_side_inner',
    'radius_inner',
    'radius_outer',
    'arc_corner',
    'arc_side',
    'line_corner',
    'carve_p',
    'carve_g',
    'carve_gcnc',
    'ellipse_in',
    'square_in',
    'hdl_smile',
    'carve_groove',
  ],
  5: [
    'tt_lock_socket',
    'tt_lock_plug',
    'tt_radius_flange',
    'tt_corner_cut',
    'tt_rect_inside',
  ],
}

export function getGroupOfTemplate(template: string) {
  return Object.keys(groups)
    .map(Number)
    .find(group => groups[group].includes(template))
}
