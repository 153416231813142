import { COLORS } from './variables'

const priorities = {
  groove_edge: 1000,
  groove: 100,
  groove_side: 98,
  arc_side_inner: 91,
  radius_inner: 95,
  radius_outer: 95,
  arc_corner: 90,
  arc_side: 90,
  line_corner: 90,
  carve_p: 90,
  carve_g: 90,
  carve_gcnc: 90,
  ellipse_in: 50,
  square_in: 50,
  hdl_smile: 50,
  carve_groove: 50,
  carve_led_direct: 97,
  carve_led_corner: 97,
  edge_hole: 10,
  side_hole: 10,
  minifix: 10,
  minifix_shkant: 10,
  rafix: 10,
  hinge35: 10,
  tt_lock_socket: 70,
  tt_lock_plug: 70,
  tt_radius_flange: 95,
  tt_corner_cut: 70,
  tt_rect_inside: 70,
}

function isEqualPairs(
  a: string,
  b: string,
  values: Array<string>,
  others: Array<string>
): boolean {
  return (
    (values.includes(a) && others.includes(b)) ||
    (values.includes(b) && others.includes(a))
  )
}
const drillings = ['edge_hole', 'side_hole', 'minifix', 'rafix', 'hinge35'],
  radiuses = ['radius_inner', 'radius_outer', 'radius_flange'],
  leds = ['carve_led_direct', 'carve_led_corner']

function getMessage(t: string, t1: string) {
  const p = priorities[t],
    p1 = priorities[t1],
    type = p > p1 ? t : t1,
    type1 = p > p1 ? t1 : t

  if (type == 'groove_edge') {
    switch (type1) {
      case 'radius_inner':
      case 'radius_outer':
      case 'radius_flange':
      case 'arc_side':
      case 'arc_corner':
        return 'Выполнение радиуса на стороне "Срез торца" невозможно'
      case 'groove_side':
        return 'Операции "Четверть" и "Срез торца" невозможно выполнить на одном торце'
      case 'edge_hole':
        return 'Услуга "Произвольное отверстие в торце" со стороны "Срез торца" невозможна'
      case 'side_hole':
        return 'Услуга "Произвольное отверстие в плоскости" со стороны "Срез торца" невозможна'
      case 'minifix':
        return 'Услуга "Узел минификс" со стороны "Срез торца" невозможна'
      case 'minifix_shkant':
        return 'Услуга "Узел минификс + шкант" со стороны "Срез торца" невозможна'
      case 'rafix':
        return 'Услуга "Рафикс" со стороны "Срез торца" невозможна'
      case 'hinge35':
        return 'Услуга "Петли" со стороны "Срез торца" невозможна'
    }
  } else if (type == 'groove') {
    switch (type1) {
      case 'radius_inner':
      case 'radius_outer':
      case 'radius_flange':
      case 'arc_side':
      case 'arc_corner':
        return 'Выполнение радиуса на стороне паза невозможно'
      case 'edge_hole':
        return 'Услуга "Произвольное отверстие в торце" со стороны паза невозможна'
      case 'side_hole':
        return 'Услуга "Произвольное отверстие в плоскости" со стороны паза невозможна'
      case 'minifix':
        return 'Услуга "Узел минификс" со стороны паза невозможна'
      case 'minifix_shkant':
        return 'Услуга "Узел минификс + шкант" со стороны паза невозможна'
      case 'rafix':
        return 'Услуга "Рафикс" со стороны паза невозможна'
      case 'hinge35':
        return 'Услуга "Петли" со стороны паза невозможна'
    }
  } else if (type == 'groove_side') {
    switch (type1) {
      case 'radius_inner':
      case 'radius_outer':
      case 'radius_flange':
      case 'arc_side':
      case 'arc_corner':
        return 'Выполнение радиуса на стороне четверти невозможно'
      case 'edge_hole':
        return 'Услуга "Произвольное отверстие в торце" со стороны четверти невозможна'
      case 'side_hole':
        return 'Услуга "Произвольное отверстие в плоскости" со стороны четверти невозможна'
      case 'minifix':
        return 'Услуга "Узел минификс" со стороны четверти невозможна'
      case 'minifix_shkant':
        return 'Услуга "Узел минификс + шкант" со стороны четверти невозможна'
      case 'rafix':
        return 'Услуга "Рафикс" со стороны четверти невозможна'
      case 'hinge35':
        return 'Услуга "Петли" со стороны четверти невозможна'
    }
  } else if (leds.includes(type)) {
    switch (type1) {
      case 'radius_inner':
      case 'radius_outer':
      case 'radius_flange':
      case 'arc_side':
      case 'arc_corner':
        return 'Выполнение радиуса на стороне лед подсветки невозможно'
    }
  } else if (radiuses.includes(type)) {
    switch (type1) {
      case 'edge_hole':
        return 'Операцию "Произвольное отверстие в торце" невозможно выполнить на остатке'
      case 'side_hole':
        return 'Операцию "Произвольное отверстие в плоскости" невозможно выполнить на остатке'
      case 'minifix':
        return 'Операцию "Узел минификс" невозможно выполнить на остатке'
      case 'minifix_shkant':
        return 'Операцию "Узел минификс + шкант" невозможно выполнить на остатке'
      case 'rafix':
        return 'Операцию "Рафикс" невозможно выполнить на остатке'
      case 'hinge35':
        return 'Операцию "Петли" невозможно выполнить на остатке'
      default:
        return 'Недопустимое значение - пересечение границ радиуса'
    }
  } else if (drillings.includes(type)) {
    if (drillings.includes(type1)) {
      return 'Недопустимое значение - отверствия слишком близко друг к другу'
    }
  }
  return 'Пересечение границ недопустимо!!'
}

function getType(op): string {
  return op.type || op.template
}

function isViborka(op): boolean {
  return [
    'groove',
    'groove_side',
    'carve_led_direct',
    'carve_led_corner',
  ].includes(getType(op))
}

export default class Drawings {
  drawings: Object
  warnings: Array<Object>
  selections: Array<any>

  isValid(): boolean {
    return !this.warnings.length
  }

  constructor() {
    this.drawings = {}
    this.warnings = []
    this.selections = []
  }

  select({ idCarve }) {
    this.deselect()
    const drawingsItem = this.drawings[idCarve]
    if (!drawingsItem) return
    const { selections } = drawingsItem
    this.selections = selections || []
    this.selections.forEach(g => (g.selected = true))
  }
  deselect() {
    if (!this.selections) return
    this.selections.forEach(g => (g.selected = false))
    this.selections = []
  }

  add(
    id: string,
    op: Object,
    areas: Object | Array<Object>,
    groups: Object,
    selections: Object | Array<Object>
  ) {
    if (!this.drawings[id]) {
      this.drawings[id] = { id, op, areas: [], groups: [], selections: [] }
    }
    const data = this.drawings[id]
    if (op) {
      data.op = op
    }
    if (areas)
      if (Array.isArray(areas)) {
        data.areas.push(...areas)
      } else {
        data.areas.push(areas)
      }
    if (groups)
      if (Array.isArray(groups)) {
        data.groups.push(...groups)
      } else {
        data.groups.push(groups)
      }
    if (selections)
      if (Array.isArray(selections)) {
        data.selections.push(...selections)
      } else {
        data.selections.push(selections)
      }
  }

  clear() {
    this.drawings = {}
    this.warnings = []
  }

  intersects(areas, areas1): boolean {
    for (let path of areas) {
      for (let path1 of areas1) {
        if (
          // path.intersects(path1)
          path.getIntersections(path1).length > 1 ||
          path.contains(path1.bounds.center) ||
          path1.contains(path.bounds.center)
        )
          return true
      }
    }
    return false
  }

  isWhitelisted(drawing, drawing1): boolean {
    const { op, areas, groups } = drawing,
      { op: op1, areas: areas1, groups: groups1 } = drawing1,
      type = getType(op),
      type1 = getType(op1)
    // пересечение паз/паз, паз/четверть, четверть/четверть, паз/лед подсветка, четверть/лед подсветка, лед подсветка/лед подсветка - допустимо
    // if (
    //   isEqualPairs(
    //     type,
    //     type1,
    //     ['groove', 'groove_side', 'carve_led_direct', 'carve_led_corner'],
    //     ['groove', 'groove_side', 'carve_led_direct', 'carve_led_corner']
    //   )
    // )
    //   return true
    if (
      isEqualPairs(
        type,
        type1,
        ['groove_edge'],
        [
          'groove_edge',
          'groove',
          'groove_side',
          'carve_led_direct',
          'carve_led_corner',
          'line_corner',
          'carve_p',
          'carve_g',
          'carve_gcnc',
        ]
      )
    )
      return op.side != op1.side
    else if (
      isEqualPairs(
        type,
        type1,
        ['groove', 'groove_side', 'carve_led_direct', 'carve_led_corner'],
        ['line_corner', 'carve_p', 'carve_g', 'carve_gcnc']
      )
    )
      return true
    else if (
      isEqualPairs(
        type,
        type1,
        ['carve_led_direct', 'carve_led_corner'],
        ['ellipse_in', 'square_in']
      )
    )
      return true //Эллипс/круг, прямоугольник - пересечение с Лед подсветками допустимо

    return false
  }

  isViborkaIntersectsOk(drawing, drawing1) {
    // пересечение под прямым углом паз/паз, паз/четверть, четверть/четверть, паз/лед подсветка, четверть/лед подсветка, лед подсветка/лед подсветка - допустимо
    // а наложение (когда выборки идут в одном направлении и заходят друг на друга) - запрещено
    const { op, areas } = drawing
    const { op: op1, areas: areas1 } = drawing1
    for (let path of areas) {
      for (let path1 of areas1) {
        const intersection = path.intersect(path1)
        if (intersection) {
          // intersection.selected = true
          const { width, height } = intersection.bounds
          const { width: w } = op,
            { width: w1 } = op1
          const isOk = Math.max(width, height) <= Math.max(w, w1)
          if (!isOk) {
            return false
          }
        }
      }
    }
    return true
  }

  validate() {
    const { drawings } = this,
      ids = Object.keys(drawings)
        .map(Number)
        .sort((a, b) => (a <= b ? -1 : 1))
    this.warnings = []

    for (let id of ids) {
      const { op, areas, groups } = drawings[id]
      for (let id1 of ids) {
        if (id >= id1) continue
        const { op: op1, areas: areas1, groups: groups1 } = drawings[id1]
        if (this.intersects(areas, areas1)) {
          if (this.isWhitelisted(drawings[id], drawings[id1])) {
            continue
          }
          if (
            isViborka(op) &&
            isViborka(op1) &&
            this.isViborkaIntersectsOk(drawings[id], drawings[id1])
          ) {
            continue
          }
          const type = getType(op),
            type1 = getType(op1),
            pr = priorities[type],
            pr1 = priorities[type1]
          let invalid, primary
          if (pr > pr1) {
            invalid = drawings[id1]
            primary = drawings[id]
          } else if (pr < pr1) {
            invalid = drawings[id]
            primary = drawings[id1]
          } else {
            invalid = op.idCarve > op1.idCarve ? drawings[id1] : drawings[id]
            primary = op.idCarve > op1.idCarve ? drawings[id] : drawings[id1]
          }
          this.mark(invalid)
          this.warnings.push({
            message: getMessage(type, type1),
            op,
            groups,
            op1,
            groups1,
            invalid,
            primary,
          })
        }
      }
    }
  }

  mark(invalid) {
    if (invalid.groups) {
      invalid.groups.forEach(g => {
        g.style.strokeColor = COLORS.danger
        g.style.selectedColor = COLORS.danger
        // g.selected = true
        if (!isViborka(invalid.op)) {
          // перемечаем наверх невалидные рисунки - если это не выборка
          g.data.invalid = true
        }
      })
    }
  }
}
