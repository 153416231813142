export const operationIcons = {
  groove_edge: 'ico_raz_5',
  groove_end: 'ico_raz_3',
  groove: 'ico_raz_4',
  groove_side: 'ico_raz_6',
  radius_inner: 'ico_kriv_5',
  radius_outer: 'ico_kriv_4',
  arc_side_inner: 'ico_kriv_1',
  arc_corner: 'ico_kriv_3',
  arc_side: 'ico_kriv_2',
  line_corner: 'ico_kriv_7',
  carve_p: 'ico_kriv_11',
  carve_g: 'ico_kriv_9',
  carve_gcnc: 'ico_kriv_10',
  ellipse_in: 'ico_kriv_12',
  square_in: 'ico_kriv_13',
  hdl_smile: 'ico_kriv_6',
  carve_groove: 'ico_kriv_8',
  carve_led_direct: 'ico_raz_1',
  carve_led_corner: 'ico_raz_2',
  edge_hole: 'ico_sverl_3',
  side_hole: 'ico_sverl_2',
  minifix: 'ico_sverl_5',
  minifix_shkant: 'ico_sverl_6',
  rafix: 'ico_sverl_4',
  hinge35: 'ico_sverl_1',
  tt_lock_socket: 'ico_post_2',
  tt_lock_plug: 'ico_post_3',
  tt_radius_flange: 'ico_post_4',
  tt_corner_cut: 'ico_post_5',
  tt_rect_inside: 'ico_post_1',
}
