<template>
  <InCutMap
    :orderId="Number($route.params.orderId)"
    :materialId="Number($route.params.materialId)"
  />
</template>

<script>
import InCutMap from '@/components/order/InCutMap.vue'

export default {
  name: 'OrderInCutMap',
  components: {
    InCutMap,
  },
  data: () => ({}),
  computed: {},
  created() {},
}
</script>

<style lang="scss"></style>
