import { Color } from 'paper'

export const STROKE_WIDTH1 = 0.2
export const STROKE_WIDTH2 = 0.4
export const DASH_ARRAY = [STROKE_WIDTH1 * 2, STROKE_WIDTH1 * 2]
export const DASH_ARRAY2 = [STROKE_WIDTH2 * 8, STROKE_WIDTH2 * 8]

export const STYLES = {
  fontSize: 14,
  fontColor: '#000000',
  pathColor: '#000',
}

export const COLORS = {
  transparent: new Color(1, 1, 1, 0.1),
  transparentWhite: new Color(1, 1, 1, 0.55),
  danger: 'red',
  white: '#fff',
}
