import api from '@/gateway/api'

export default {
  namespaced: true,
  state: {
    user: null,
    userChangeInfoRequest: null,
    users: [],
    cities: [],
    companyBranches: [],
    roles: ['admin', 'manager', 'furniture_maker', 'customer']
  },
  getters: {
  },
  mutations: {
    updateUser (state, user) {
      state.user = user
    },
    updateUserChangeInfoRequest (state, user) {
      state.userChangeInfoRequest = user
    },
    updateUsers (state, users) {
      state.users = users
    },
    updateCities (state, cities) {
      state.cities = cities
    },
    updateCompanyBranches (state, companyBranches) {
      state.companyBranches = companyBranches
    },
  },
  actions: {
    getUsers ({ commit }, { type = null, isActive = true, page = 1,
      limit = null, search = null }) {
      return new Promise((resolve, reject) => {
        api.get('user', {
          params: {
            type: type,
            is_active: isActive,
            page: page,
            limit: limit,
            search: search
          }
        }).then(result => {
          commit('updateUsers', result.data.users)
          resolve(result)
        }).catch(error => {
          reject(error)
        });
      })
    },
    getUser ({ commit }, id) {
      return new Promise((resolve, reject) => {
        api.get('user/' + id).then(result => {
          commit('updateUserChangeInfoRequest', result.data.user_change_info_request || null)
          commit('updateUser', result.data.user)
          resolve(result)
        }).catch(error => {
          reject(error)
        })
      })
    },
    updateUser ({ commit }, { user }) {
      return new Promise((resolve, reject) => {
        api.put('user/' + user.id, user).then(result => {
          commit('updateUser', result.data.user)
          commit('updateUserChangeInfoRequest', null)
          resolve(result)
        }).catch(error => {
          reject(error)
        })
      })
    },
    deleteUser ({ commit }, id) {
      return new Promise((resolve, reject) => {
        api.delete('user/' + id).then(result => {
          commit('updateUser', null)
          commit('updateUserChangeInfoRequest', null)
          resolve(result)
        }).catch(error => {
          reject(error)
        })
      })
    },
    restoreUser ({ commit }, id) {
      return new Promise((resolve, reject) => {
        api.post('user/' + id + '/restore').then(result => {
          resolve(result)
        }).catch(error => {
          reject(error)
        })
      })
    },
    addManagerToUser ({ commit }, {id, managerId = null}) {
      return new Promise((resolve, reject) => {
        api.post('user/' + id + '/manager/add',{
          manager_id: managerId
        }).then(result => {
          resolve(result)
        }).catch(error => {
          reject(error)
        })
      })
    },
    removeManagerFromUser ({ commit }, id) {
      return new Promise((resolve, reject) => {
        api.post('user/' + id + '/manager/remove').then(result => {
          resolve(result)
        }).catch(error => {
          reject(error)
        })
      })
    },
    getCities ({ commit }) {
      return new Promise((resolve, reject) => {
        api.get('auth/city').then(result => {
          commit('updateCities', result.data.cities)
          resolve(result)
        }).catch(error => {
          reject(error)
        });
      })
    },
    getCompanyBranches ({ commit }) {
      return new Promise((resolve, reject) => {
        api.get('company-branch').then(result => {
          commit('updateCompanyBranches', result.data.company_branches)
          resolve(result)
        }).catch(error => {
          reject(error)
        });
      })
    },
  }
}
