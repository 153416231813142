<template>
  <div class="constructor__btns">
    <div class="constructor__btns-steps" v-if="showPrevNext">
      <router-link
        class="constructor__btn ico_back"
        type="button"
        v-tooltip
        data-bs-toggle="tooltip"
        data-bs-trigger="hover"
        data-bs-placement="top"
        :data-bs-original-title="$t('toolbar.prev')"
        :aria-label="$t('toolbar.prev')"
        :to="{
          name: 'Dateylor',
          params: {
            orderId,
            materialId,
            detailId: prevDetail && prevDetail.id,
          },
        }"
        replace
        :class="{ disabled: !prevDetail || detailIndex <= 0 }"
      ></router-link
      ><router-link
        class="constructor__btn ico_return"
        type="button"
        v-tooltip
        data-bs-toggle="tooltip"
        data-bs-trigger="hover"
        data-bs-placement="top"
        :data-bs-original-title="$t('toolbar.next')"
        :aria-label="$t('toolbar.next')"
        :to="{
          name: 'Dateylor',
          params: {
            orderId,
            materialId,
            detailId: nextDetail && nextDetail.id,
          },
        }"
        replace
        :class="{
          disabled: !nextDetail || detailIndex >= allDetails.length - 1,
        }"
      ></router-link>
    </div>
    <div class="constructor__btns-group">
      <!-- <button
        class="constructor__btn ico_reload"
        type="button"
        v-tooltip
        data-bs-toggle="tooltip"
        data-bs-trigger="hover"
        data-bs-placement="top"
        :data-bs-original-title="$t('toolbar.turn')"
        :aria-label="$t('toolbar.turn')"
        @click="$emit('rotate')"
      ></button> -->
      <button
        class="constructor__btn ico_copy"
        type="button"
        v-tooltip
        data-bs-toggle="tooltip"
        data-bs-trigger="hover"
        data-bs-placement="top"
        :data-bs-original-title="$t('toolbar.copy')"
        :aria-label="$t('toolbar.copy')"
        @click="$emit('copy')"
      ></button
      ><button
        class="constructor__btn ico_print"
        type="button"
        v-tooltip
        data-bs-toggle="tooltip"
        data-bs-trigger="hover"
        data-bs-placement="top"
        :data-bs-original-title="$t('toolbar.print')"
        :aria-label="$t('toolbar.print')"
        @click="$emit('print')"
      ></button
      ><button
        class="constructor__btn ico_trash"
        type="button"
        v-tooltip
        data-bs-toggle="tooltip"
        data-bs-trigger="hover"
        data-bs-placement="top"
        :data-bs-original-title="$t('toolbar.remove')"
        :aria-label="$t('toolbar.remove')"
        @click="$emit('delete')"
      ></button
      ><button
        class="constructor__btn ico_size"
        type="button"
        v-tooltip
        data-bs-toggle="tooltip"
        data-bs-trigger="hover"
        data-bs-placement="top"
        :data-bs-original-title="$t('toolbar.scale')"
        :aria-label="$t('toolbar.scale')"
        @click="$emit('resetZoom')"
      >
        100%
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Toolbar',
  props: ['orderId', 'detailId', 'materialId'],
  data() {
    return {}
  },
  computed: {
    showPrevNext() {
      return this.allDetails.length > 1
    },
    allDetails() {
      return (this.$store.state.order.details || [])
        .filter(({ width, length }) => width > 0 && length > 0)
        .sort((a, b) => (a.order_no > b.order_no ? 1 : -1))
    },
    detailIndex() {
      const { allDetails, detailId } = this
      if (!detailId || !allDetails || !allDetails.length) return null
      return allDetails.findIndex(({ id }) => detailId == id)
    },
    prevDetail() {
      const { detailIndex, allDetails } = this
      if (!allDetails || !allDetails.length) return null
      return detailIndex <= 0
        ? allDetails[allDetails.length - 1]
        : allDetails[detailIndex - 1]
    },
    nextDetail() {
      const { detailIndex, allDetails } = this
      if (!allDetails || !allDetails.length) return null
      return detailIndex >= allDetails.length - 1
        ? allDetails[0]
        : allDetails[detailIndex + 1]
    },
  },
  methods: {},
}
</script>

<style scoped lang="scss">
.constructor__btn {
  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }
}
</style>
