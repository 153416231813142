<template>
  <component
    :is="tagName"
    :class="{ [activeClass]: isOpen }"
    v-click-outside="
      () => {
        if (closeOnClickOutside) isOpen = false
      }
    "
  >
    <slot
      v-bind:show="show"
      v-bind:hide="hide"
      v-bind:toggle="toggle"
      v-bind:isOpen="isOpen"
    >
    </slot>
  </component>
</template>

<script>
export default {
  name: 'Dropdown',
  components: {},
  props: {
    activeClass: {
      type: String,
      default: '_active',
    },
    tagName: {
      type: String,
      default: 'div',
    },
    closeOnClickOutside: {
      type: Boolean,
      default: true,
    },
  },

  data: () => ({
    isOpen: false,
  }),

  methods: {
    show() {
      this.isOpen = true
    },
    hide() {
      this.isOpen = false
    },
    toggle() {
      this.isOpen = !this.isOpen
    },
  },
}
</script>

<style lang="scss"></style>
