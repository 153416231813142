import paper, { Project, Group, Item } from 'paper'

export function buildEmptyHoverDrawings(coordinateGrid) {
  if (coordinateGrid.operations.length) {
    // make mapping of hover drawings {id1: {target: null, items: []}, ...}
    return coordinateGrid.operations
      .reduce((result, { item }) => [...result, ...item], [])
      .reduce(
        (result, { idCarve }) => ({
          [idCarve]: { target: null, items: [], options: {} },
          ...result,
        }),
        {}
      )
  }
}

export function toggleHoverEvents(activate = true, coordinateGrid) {
  Object.values(coordinateGrid.hoverDrawings || {}).forEach((item: any) => {
    const {
      target,
      items,
      options: { hideOther = false },
    } = item
    if (!target || !items) return
    if (activate) {
      items.forEach(item => (item.visible = false))
      // When the mouse enters the item, set its fill color to red:
      target.on('mouseenter', function() {
        if (hideOther) {
          // @ts-ignore:
          const { project } = paper.paperScope
          project
            .getItems({
              data: {
                is: 'metric',
              },
            })
            .forEach(p => {
              p.opacity = 0.1
            })
        }
        items.forEach(item => {
          item.opacity = 1
          item.visible = true
        })
      })

      // When the mouse leaves the item, set its fill color to black:
      target.on('mouseleave', function() {
        // @ts-ignore:
        const { project } = paper.paperScope
        project
          .getItems({
            data: {
              is: 'metric',
            },
          })
          .forEach(p => {
            p.opacity = 1
          })
        items.forEach(item => (item.visible = false))
      })
    } else {
      target.off('mouseenter').off('mouseleave')
    }
  })
}
