<template>
  <div class="constructor-form-group" :class="{ 'is-invalid': error }">
    <div class="constructor-radio-fields">
      <span>{{ label }}</span>
      <div class="constructor-radio-group" :class="'--value-' + options.length">
        <label v-for="(option, index) in options" :key="index"
          ><input
            type="radio"
            :name="_uid"
            :value="option[itemValue]"
            v-model="value"
            @change="$emit('input', option[itemValue])"
          />
          <span>{{ option[itemText] }}</span></label
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BtnToggleInput',
  props: {
    value: {},
    label: String,
    options: { type: Array, default: () => [] },
    itemText: { type: String, default: 'text' },
    itemValue: { type: String, default: 'value' },
    error: Boolean,
  },
  data() {
    return {}
  },
  computed: {},
  methods: {},
}
</script>

<style scoped lang="scss"></style>
