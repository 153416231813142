function faceLabel(face): string {
  if (face && face.id == 1) return 't'
  else if (face && face.id == 2) return 'f'
  return ''
}

function sideLabel(sides): string {
  const side = Object.keys(sides).find(side => sides[side])
  if (side) return side[0]
  else return ''
}

function cornerLabel(corners: any): string {
  const corner = Object.keys(corners).find(corner => corners[corner])
  const map = {
    top_left: 'lt',
    top_right: 'rt',
    bottom_left: 'lb',
    bottom_right: 'rb',
  }
  if (corner) return map[corner] || ''
  else return ''
}

export function getOperationLabel(op: any, p: any): string {
  switch (op.template) {
    case 'groove_end':
      return `${sideLabel(op.place_second)} ${op.deep}x${op.width}`
    case 'groove':
      return (
        `${p.$t('active_op.side')} ${sideLabel(op.place_second)}(${op.margin}х${
          op.deep
        }x${op.width})` + (op.is_limited ? `, y-${op.groove_length || ''}` : '')
      )
    case 'groove_edge':
      return `${p.$t('active_op.side')} ${op.place &&
        sideLabel(op.place.checkboxes)}, ${op.place && op.place.value}\u00B0`
    case 'groove_side':
      return (
        `${p.$t('active_op.side')} ${sideLabel(op.place_second)}, ${op.deep}х${
          op.width
        }` + (op.is_limited ? `, L-${op.groove_length || ''}` : '')
      )
    case 'radius_inner':
      return [cornerLabel(op.rad_outside), 'R-' + (op.rad_simple || '')].join(
        ', '
      )
    case 'radius_outer':
      return (
        cornerLabel(op.rad_inside) +
        ', R-' +
        (op.rad_simple || '') +
        (op.round ? ' + R-' + (op.rad_round || '') : '')
      )
    case 'arc_corner':
      return cornerLabel(op.angle) + ', R-' + (op.rad || '')
    case 'arc_side':
      return `${p.$t('active_op.side')} ${sideLabel(
        op.place_second
      )}, H-${op.remaining_size_1 || ''}`
    case 'arc_side_inner':
      return `${p.$t('active_op.side')} ${sideLabel(
        op.place_second
      )}, H-${op.arc_height || ''}`
    case 'line_corner':
      return `${cornerLabel(op.angle)}, (x-${op.width || ''}, y-${op.height ||
        ''})`
    case 'carve_p':
      return `${p.$t('active_op.side')} ${sideLabel(
        op.place_second
      )}, x-${op.cutout_size_width || ''}, y-${op.cutout_size_deep || ''}`
    case 'carve_g':
      return `${cornerLabel(op.angle)}, x-${op.width || ''}, y-${op.height ||
        ''}`
    case 'carve_gcnc':
      return `${cornerLabel(op.angle)}, x-${op.width || ''}, y-${op.height ||
        ''}, R1-${op.radius1 || 0}, R2-${op.radius2 || 0}, R3-${op.radius3 ||
        0}`
    case 'ellipse_in':
      return `${cornerLabel(op.angle)}, x-${op.centerwidth ||
        ''}, y-${op.centerheight || ''}`
    case 'square_in':
      return `${cornerLabel(op.angle)}, x-${op.centerwidth ||
        ''}, y-${op.centerheight || ''}`
    case 'hdl_smile':
      return `${p.$t('active_op.side')} ${sideLabel(
        op.place_second
      )}, x-${op.width || ''}, y-${op.deep || ''}`
    case 'carve_groove':
      return `x-${op.width || ''}, y-${op.height || ''} , z-${op.deep || ''}`
    case 'carve_led_direct':
      return (
        `x-${op.width || ''}, z-${op.deep || ''}` +
        (op.is_limited ? `, y-${op.groove_length || ''}` : '')
      )
    case 'carve_led_corner':
      return `x-${op.offsetwidth || ''}, y-${op.offsetheight || ''}`
    case 'edge_hole':
      return `d${op.tool_param_f || ''}, ${cornerLabel(
        op.angle
      )}, x-${op.width || ''}, y-${op.deep || ''}`
    case 'side_hole':
      return `d${op.tool_param_f || ''}, ${cornerLabel(
        op.angle
      )}, x-${op.distance_x_mm || ''}, y-${op.distance_y_mm || ''}`
    case 'minifix':
      return `${cornerLabel(op.angle)}, y-${op.indent_mm || ''}`
    case 'minifix_shkant':
      return `${cornerLabel(op.angle)}, y-${op.minifix_knot_distance_mm || ''}`
    case 'rafix':
      return `${cornerLabel(op.angle)}, y-${op.distance_to_rafix_mm || ''}`
    case 'hinge35':
      const ys = [
        op.off_start,
        op.off_end,
        op.off_3,
        op.off_4,
        op.off_5,
        op.off_6,
      ]
        .slice(0, op.number_of_loops)
        .map(y => 'y-' + y)
        .join(', ')
      return `d${op.diameter || ''}, ${cornerLabel(op.angle)}, ${ys}`
    case 'tt_lock_socket':
      return `${cornerLabel(
        op.tt_lock_socket_angle
      )}, L-${op.lock_length_mm_input || ''}`
    case 'tt_lock_plug':
      return `${cornerLabel(op.tt_lock_plug_angle)}`
    case 'tt_radius_flange':
      return `${cornerLabel(
        op.tt_radius_flange_angle
      )}, R-${op.tt_radius_flange_radius_mm || ''}`
    case 'tt_corner_cut':
      return `${cornerLabel(op.angle)}, x-${op.slice_width_parts_mm ||
        ''}, y-${op.cut_height_parts_mm || ''}`
    case 'tt_rect_inside':
      return `${cornerLabel(
        op.angle
      )}, x-${op.width_to_the_center_of_the_cutout_mm ||
        ''}, y-${op.height_to_center_of_cutout_mm || ''}`
    case 'tt_2corner_radius':
      return ``
    case 'tt_4corner_radius':
      return ``
  }
  return ''
}
