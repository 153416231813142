var render = function render(){var _vm=this,_c=_vm._self._c;return _c('fragment',[_c('MatchMedia',{attrs:{"wrapperTag":"tr"},scopedSlots:_vm._u([{key:"default",fn:function({ xs, sm, md, lg }){return [_c('td',{class:{ 'footable-last-visible': xs || sm },attrs:{"colspan":xs || sm ? 2 : 1}},[(xs || sm)?_c('span',{staticClass:"footable-toggle fooicon last-column",class:_vm.isOpen ? 'fooicon-minus' : 'fooicon-plus',on:{"click":function($event){_vm.isOpen = !_vm.isOpen}}}):_vm._e(),_c('span',[_vm._v(_vm._s(_vm.item.name))])]),_c('td',{staticClass:"d-none d-md-table-cell"},[_c('span',[_vm._v(_vm._s(_vm.item.article))])]),_c('td',{staticClass:"d-none d-md-table-cell"},[_c('span',[_vm._v(_vm._s(parseInt(_vm.item.width))+"x"+_vm._s(parseInt(_vm.item.height)))])]),_c('td',{staticClass:"d-none d-md-table-cell"},[_c('span',[_vm._v(_vm._s(_vm.item.details_count))])]),_c('td',{staticClass:"d-none d-md-table-cell",class:{ 'footable-last-visible': md || lg },attrs:{"colspan":md || lg ? 2 : 1}},[(md || lg)?_c('span',{staticClass:"footable-toggle fooicon last-column",class:_vm.isOpen ? 'fooicon-minus' : 'fooicon-plus',on:{"click":function($event){_vm.isOpen = !_vm.isOpen}}}):_vm._e(),_c('span',{staticClass:"text-nowrap"},[_vm._v(_vm._s(_vm.item.price.toFixed(2) || 0)+" ₴")])]),_c('td',{staticClass:"d-none d-xl-table-cell"},[_c('span',[_vm._v(_vm._s(_vm.item.quantity))])]),_c('td',{staticClass:"d-none d-xl-table-cell"},[_c('span',{staticClass:"text-nowrap"},[_vm._v(_vm._s((_vm.item.price * _vm.item.quantity).toFixed(2))+" ₴")])]),_c('td',{staticClass:"d-none d-xl-table-cell"},[_c('div',{staticClass:"btn-status",class:_vm.item.stock !== 0 && _vm.item.stock >= _vm.item.quantity
            ? 'confirmed'
            : 'new'},[_c('span',{staticClass:"circle"}),_c('span',[_vm._v(" "+_vm._s(_vm.item.stock !== 0 && _vm.item.stock >= _vm.item.quantity ? _vm.$i18n.t('product.available') : _vm.$i18n.t('product.not_available'))+" ")])])]),_c('td',{staticClass:"d-none d-xl-table-cell"},[_c('div',{staticClass:"d-flex justify-content-end"},[_c('button',{staticClass:"btn btn-icon ico_plus",attrs:{"type":"button"},on:{"click":function($event){return _vm.$root.$emit('openDialog', 'FileImportDialog', {
              title: 'Выгрузить детали для данного материала',
            })}}}),_c('router-link',{staticClass:"btn btn-primary",attrs:{"to":{
            name: 'OrderDetails',
            params: { orderId: _vm.orderId, materialId: _vm.item.id },
          }}},[_vm._v("Обробка")]),_c('button',{staticClass:"btn btn-icon ico_trash",attrs:{"type":"button"},on:{"click":function($event){return _vm.$emit('remove')}}})],1)])]}}])}),_c('MatchMedia',{directives:[{name:"show",rawName:"v-show",value:(_vm.isOpen),expression:"isOpen"}],attrs:{"wrapperTag":null},scopedSlots:_vm._u([{key:"default",fn:function({ xs, sm, md, lg }){return [_c('tr',{staticClass:"footable-detail-row"},[_c('td',{attrs:{"colspan":xs || sm ? 2 : 6}},[_c('table',{staticClass:"footable-details"},[_c('tbody',[_c('tr',{staticClass:"d-table-row d-md-none"},[_c('th',[_vm._v("Артикул")]),_c('td',[_c('span',[_vm._v(_vm._s(_vm.item.article))])])]),_c('tr',{staticClass:"d-table-row d-md-none"},[_c('th',[_vm._v("Розмір плити")]),_c('td',[_c('span',[_vm._v(_vm._s(_vm.item.width)+"x"+_vm._s(_vm.item.height))])])]),_c('tr',{staticClass:"d-table-row d-md-none"},[_c('th',[_vm._v("Кількість деталей")]),_c('td',[_c('span',[_vm._v(_vm._s(_vm.item.details_count))])])]),_c('tr',[_c('th',[_vm._v("Кількість панелей")]),_c('td',[_c('span',[_vm._v(_vm._s(_vm.item.quantity))])])]),_c('tr',[_c('th',[_vm._v("Сума")]),_c('td',[_c('span',[_vm._v(_vm._s((_vm.item.price * _vm.item.quantity).toFixed(2))+" ₴")])])]),_c('tr',[_c('th',[_vm._v("Наявність")]),_c('td',[_c('div',{staticClass:"btn-status",class:_vm.item.stock !== 0 && _vm.item.stock >= _vm.item.quantity
                      ? 'confirmed'
                      : 'new'},[_c('span',{staticClass:"circle"}),_c('span',[_vm._v(" "+_vm._s(_vm.item.stock !== 0 && _vm.item.stock >= _vm.item.quantity ? _vm.$i18n.t('product.available') : _vm.$i18n.t('product.not_available'))+" ")])])])]),_c('tr',[_c('th'),_c('td',[_c('div',{staticClass:"d-flex justify-content-end"},[_c('button',{staticClass:"btn btn-icon ico_plus",attrs:{"type":"button"},on:{"click":function($event){return _vm.$root.$emit('openDialog', 'FileImportDialog', {
                        title: 'Выгрузить детали для данного материала',
                      })}}}),_c('router-link',{staticClass:"btn btn-primary",attrs:{"to":{
                      name: 'OrderDetails',
                      params: { orderId: _vm.orderId, materialId: _vm.item.id },
                    }}},[_vm._v("Обробка")]),_c('button',{staticClass:"btn btn-icon ico_trash",attrs:{"type":"button"},on:{"click":function($event){return _vm.$emit('remove')}}})],1)])])])])])])]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }