<template>
  <div class="constructor-form-group">
    <div class="constructor-angles-field">
      <span>{{ label }}</span>
      <div class="angles" :class="{ 'is-invalid': error }">
        <div v-for="item in items" :key="item">
          <div class="angle" :class="'angle--' + item.replace('_', '-')">
            <label class="angle__label"
              ><input
                class="angle__checkbox"
                type="radio"
                name="angle-1"
                :value="true"
                @change="toggle(item)"
                v-model="value[item]"/><span class="angle__value"></span
            ></label>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CornerInput',
  props: {
    value: {
      type: Object,
      default: function() {
        return {
          top_left: false,
          top_right: false,
          bottom_right: false,
          bottom_left: false,
        }
      },
    },
    label: String,
    error: Boolean,
  },
  data() {
    return {}
  },
  computed: {
    items() {
      return ['top_left', 'bottom_right', 'top_right', 'bottom_left']
    },
  },
  methods: {
    toggle(item) {
      const value = {
        top_left: false,
        top_right: false,
        bottom_right: false,
        bottom_left: false,
      }
      value[item] = true
      this.$emit('input', value)
    },
  },
}
</script>

<style scoped lang="scss"></style>
