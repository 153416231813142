import { Group, Rectangle, Point, PointText } from 'paper'
import { drawArrow } from '../arrows'
import { isEqual } from '../math'
import { querySide } from '../sides'

export function testMeasurementDeep({ sides }) {
  const id = 1,
    idCarve = 11,
    width1 = 46,
    width2 = 30,
    width3 = 38
  sides.bottom.measurements.push({
    id,
    idCarve,
    margin: 0,
    deep: width1,
    width: width1,
    side: 'left',
  })
  sides.bottom.measurements.push({
    id,
    idCarve,
    margin: 550,
    deep: width2,
    width: width2,
  })
  // sides.bottom.measurements.push({
  //   id,
  //   idCarve,
  //   margin: width1 + width2,
  //   deep: width3,
  //   width: width3,
  //   side: 'left',
  // })
}

export function justifyMeasurementDeepTextPosition({ side, sides, property }) {
  const nextSide = querySide(property, 'next'),
    { hasRadiusMetric } = sides[nextSide].markers
  if (!hasRadiusMetric) return
  const metrics = side.getItems({
    data: { is: 'metric', metric: 'deep' },
  })
  const items = metrics.filter(m => {
    return m.lastChild.matches({
      // находим метрики, которые выходят за край детали
      data: { is: 'arrow', textFitsInsideLine: false },
      bounds: function(bounds) {
        return bounds.right > sides[property].size
      },
    })
  })
  items.forEach(item => {
    const arrow = item.lastChild,
      text = arrow.getItem({ class: PointText })
    if (arrow && arrow.children[4]) {
      text.position.x = arrow.children[4].position.x
      // text.selected = true
    }
  })
}

export function drawMeasurementDeep({
  measurement,
  side,
  sides,
  heightScale,
  EDGE_OFFSET,
  EDGE_HEIGHT,
  property,
  levels,
  hoverDrawings,
}) {
  // вычисляем параметры
  const { hover } = measurement,
    _margin = measurement.side
      ? sides[property].size - measurement.deep
      : measurement.margin,
    _width = measurement.side ? measurement.deep : measurement.width

  // строим и добавляем рисунок для первого уровня,
  // потом поднимем при необходимости
  const innerBounds = new Rectangle(
      _margin,
      -(EDGE_HEIGHT + EDGE_OFFSET * 2 + heightScale * Math.max(1, 1)),
      _width,
      heightScale * Math.max(1, 1)
    ),
    gLine1 = drawArrow(
      {
        start: innerBounds.topLeft,
        end: innerBounds.bottomLeft,
        size: heightScale,
        angle: 0,
      },
      {
        start: false,
        end: false,
      }
    ),
    gLine2 = drawArrow(
      {
        start: innerBounds.topRight,
        end: innerBounds.bottomRight,
        size: heightScale,
        angle: 0,
      },
      {
        start: false,
        end: false,
      }
    ),
    gTextArrow = drawArrow(
      {
        start: innerBounds.topLeft.add(new Point(0, (heightScale * 15) / 100)), // 1.5mm , heightScale is 10mm
        end: innerBounds.topRight.add(new Point(0, (heightScale * 15) / 100)), // 1.5mm , heightScale is 10mm
        size: heightScale,
        angle: 0,
      },
      {
        start: true,
        end: true,
      },
      measurement.side ? measurement.deep : measurement.width,
      sides[property].angle
    ),
    line1 = gLine1.firstChild,
    line2 = gLine2.firstChild,
    measurementGroup = new Group({
      children: [gLine1, gLine2, gTextArrow],
      data: { is: 'metric', metric: 'deep' },
    })
  side.addChild(measurementGroup)

  // проходимся по уровням с поисках клонов и пересечений
  // hover-метрики размещаются выше постоянных метрик.
  // При построении уровней hover-метрик учитываются только
  // постоянные метрики и метрики той же фигуры (тот же idCarve).
  let i = 0,
    currentLevel,
    measurementBounds
  for (i = 0; i <= levels.length; i++) {
    // проверяем существование уровня, добавляем его при необходимости
    if (!levels[i]) {
      levels.push([])
    }
    currentLevel = levels[i] // текущий уровень
    measurementBounds = measurementGroup.bounds // определяем реальные границы рисунка
    // определяем пересечения и дубликаты
    let intersection = false,
      clone = false
    currentLevel
      // При определении пересечения учытываем видимость метрики -
      // hover-метрики отображаються поверх постоянных.
      // Поэтому здесь надо отфильтровать лишь постоянные метрики
      // плюс метрики текущей фигуры
      .filter(
        ({ measurement: m }) => !m.hover || m.idCarve == measurement.idCarve
      )
      .forEach(({ bounds }) => {
        if (isEqual(measurementBounds, bounds)) {
          intersection = false
          clone = true
        } else if (measurementBounds.intersects(bounds)) {
          intersection = true
        }
      })
    // решаем что делать дальше
    if (intersection || (clone && hover)) {
      // при наличии пересечений выносим рисунок на следующий уровень
      // запоминаем конечные точки вертикальных линий
      const seg1 = line1.lastSegment.clone(),
        seg2 = line2.lastSegment.clone()
      // переносим рисунок на уровень вверх
      measurementGroup.translate(new Point(0, -1).multiply(heightScale))
      // применяем конечные точки
      line1.add(seg1)
      line1.removeSegment(1)
      line2.add(seg2)
      line2.removeSegment(1)
    } else if (clone && !hover) {
      // если такой рисунок уже есть, удаляем наш новый рисунок и выходим
      measurementGroup.remove()
      return
    } else {
      // если нету пересечений, тогда нужный уровень найден
      break
    }
  }

  // добавляем границы рисунка в текущий уровень
  currentLevel.push({ measurement, bounds: measurementGroup.bounds })

  // добавляем в hoverDrawings при необходимости
  if (measurement.hover) {
    if (hoverDrawings[measurement.idCarve]) {
      hoverDrawings[measurement.idCarve].items.push(measurementGroup)
    }
  }
}
