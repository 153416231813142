<template>
  <fragment>
    <component :is="wrappedTag || 'td'" :class="firstClass">
      <Dropdown
        class="action-group --side"
        v-slot:default="{ show, hide }"
        activeClass="is-show"
      >
        <div class="action-group-btn" @click="show()">
          <span class="ico_submenu"></span>
        </div>
        <div class="action-group-drop">
          <ul class="action-group-list">
            <li>
              <div class="side side--all">
                <label class="side__label"
                  ><input
                    class="js-all-side side__checkbox"
                    type="checkbox"
                    :checked="
                      !!ends.end_top &&
                        !!ends.end_left &&
                        !!ends.end_bottom &&
                        !!ends.end_right
                    "
                    @click.stop.prevent="
                      toggleDefaultKromka($event.target.checked)
                    "/><span class="side__value"></span
                ></label>
              </div>
            </li>
            <li v-for="side in [...sides].reverse()" :key="side">
              <div
                class="side"
                :class="{
                  'side--top': side == 'end_top',
                  'side--bottom': side == 'end_bottom',
                  'side--left': side == 'end_right',
                  'side--right': side == 'end_left',
                }"
              >
                <label class="side__label"
                  ><input
                    class="side__checkbox"
                    type="checkbox"
                    :checked="ends[side]"
                    @click.stop.prevent="
                      toggleDefaultKromka($event.target.checked, side)
                    "/><span class="side__value"></span
                ></label>
              </div>
            </li>
            <li>
              <button class="js-hide-drop" type="button" @click="hide()">
                <span class="ico_close"></span>
              </button>
            </li>
          </ul>
        </div>
      </Dropdown>
    </component>
    <component :is="wrappedTag || 'td'" :class="secondClass">
      <slot></slot>
      <div class="checkboxs">
        <div v-for="side in sides" :key="side">
          <Dropdown
            class="dropdown dropdown-edge"
            v-slot:default="{ show, hide, isOpen }"
          >
            <div
              class="dropdown-edge__tooltip"
              :class="{ 'is-active': ends[side] }"
              :style="{
                color: ends[side] ? 'rgb(255, 255, 0)' : null,
              }"
            >
              <span>{{
                ends[side] && ends[side].kromka && ends[side].kromka.name
              }}</span>
            </div>
            <button
              class="dropdown-toggle check-edge"
              :class="{
                '--top': side == 'end_top',
                '--bottom': side == 'end_bottom',
                '--left': side == 'end_right',
                '--right': side == 'end_left',
                'is-checked': ends[side],
              }"
              :style="{
                color: ends[side] ? 'rgb(255, 255, 0)' : null,
              }"
              type="button"
              tabindex="-1"
              @click="show()"
            ></button>
            <ul
              class="dropdown-menu dropdown-menu-end"
              :class="{ show: isOpen }"
            >
              <li>
                <div
                  class="dropdown-item"
                  @click="
                    hide()
                    remove(side).then(() => getDetails())
                  "
                >
                  Без крайки
                </div>
              </li>
              <li v-for="kromka in kromkas" :key="kromka.id">
                <div
                  class="dropdown-item"
                  :class="{
                    'text-primary disabled':
                      ends[side] && ends[side].material_id == kromka.id,
                  }"
                  @click="
                    hide()
                    update(side, kromka).then(() => getDetails())
                  "
                >
                  {{ kromka.name }}
                </div>
              </li>
            </ul>
          </Dropdown>
        </div>
      </div>
    </component>
  </fragment>
</template>

<script>
import { Fragment } from 'vue-frag'
import api from '@/gateway/api'

export default {
  name: 'DetailEnds',
  components: { Fragment },
  data: () => ({
    end_top: {},
    end_right: {},
    end_bottom: {},
    end_left: {},
  }),

  props: [
    'material_id',
    'detail',
    'orderId',
    'defaultKromka',
    'secondClass',
    'firstClass',
    'wrappedTag',
  ],

  computed: {
    sides() {
      return ['end_top', 'end_left', 'end_bottom', 'end_right']
    },
    kromkas() {
      return this.$store.getters['order/kromkas'] || []
    },
    endsData() {
      return this.detail && this.detail.edges
    },
    ends() {
      const arr = this.endsData || []
      return arr.reduce((result, item) => {
        result[item.type_edge] = {
          ...item,
          kromka: this.kromkas.find(({ id }) => id == item.material_id),
        }
        return result
      }, {})
    },
  },

  methods: {
    getDetails() {
      return this.$store.dispatch('order/getMaterialDetails', {
        material_id: this.material_id,
      })
    },
    async toggleDefaultKromka(checked, side) {
      const { defaultKromka } = this
      if (!defaultKromka) {
        return this.$emit('no-default-kromka')
      }
      const sides = side ? [side] : this.sides
      for (var i = 0; i < sides.length; i++) {
        if (checked) {
          await this.update(sides[i], defaultKromka)
        } else {
          await this.remove(sides[i])
        }
      }
      await this.getDetails()
    },
    update(typeEdge, kromka) {
      // this.$root.$emit("playPreloader")
      return this.$store
        .dispatch('order/updateDetailEdge', {
          type_edge: typeEdge,
          detail_id: this.detail.id,
          material_id: kromka.id,
        })
        .catch(e => {
          if (e && e.response && e.response.data && e.response.data.errors) {
            for (let field in e.response.data.errors) {
              let messages = e.response.data.errors[field]
              if (Array.isArray(messages))
                messages.forEach(message => this.$toast.error(message))
            }
          }
          // this.$root.$emit("stopPreloader")
        })
    },
    remove(typeEdge) {
      return this.$store
        .dispatch('order/removeDetailEdge', {
          type_edge: typeEdge,
          detail_id: this.detail.id,
        })
        .catch(e => {
          if (e && e.response && e.response.data && e.response.data.errors) {
            for (let field in e.response.data.errors) {
              let messages = e.response.data.errors[field]
              if (Array.isArray(messages))
                messages.forEach(message => this.$toast.error(message))
            }
          }
          // this.$root.$emit("stopPreloader")
        })
    },
  },

  mounted() {},
}
</script>

<style lang="scss">
.footable .dropdown-menu {
  @media (max-width: 1200px) {
    left: auto;
    right: 0;
  }
}
.action-group.--side .action-group-drop {
  @media (max-width: 767px) {
    left: -50px;
  }
}
</style>
