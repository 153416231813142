<template>
  <header class="page-header">
    <div class="container container-xxl">
      <div class="page-header__inner">
        <div class="page-header__logo">
          <a class="logo" href="/"
            ><img src="/assets/img/logo.svg" alt="logo"
          /></a>
        </div>
        <div class="page-header__group">
          <div class="page-header__phones">
            <div class="dropdown">
              <button
                class="dropdown-toggle"
                type="button"
                data-bs-toggle="dropdown"
              >
                +38 (096) 278 4997
              </button>
              <ul class="dropdown-menu">
                <li>
                  <a class="dropdown-item" href="tel:380962784997"
                    >+38 (096) 278 4997</a
                  >
                </li>
                <li>
                  <a class="dropdown-item" href="tel:380962784997"
                    >+38 (096) 278 4997</a
                  >
                </li>
                <li>
                  <a class="dropdown-item" href="tel:380962784997"
                    >+38 (096) 278 4997</a
                  >
                </li>
              </ul>
            </div>
          </div>
          <div class="page-header__lang">
            <div class="dropdown">
              <button
                class="dropdown-toggle"
                type="button"
                data-bs-toggle="dropdown"
              >
                Ua
              </button>
              <ul class="dropdown-menu">
                <li><a class="dropdown-item" href="#!">Ua</a></li>
                <li><a class="dropdown-item" href="#!">Ru</a></li>
                <li><a class="dropdown-item" href="#!">En</a></li>
              </ul>
            </div>
          </div>
        </div>
        <div class="page-header__menu">
          <ul class="navbar-nav">
            <li class="nav-item">
              <a class="nav-link active" href="page-order-index.html"
                ><span>Замовлення в роботі</span><sup>4</sup></a
              >
            </li>
            <li class="nav-item">
              <a class="nav-link" href="user-receivables.html"
                ><span>Дебіторка</span><sup>3</sup></a
              >
            </li>
            <li class="nav-item">
              <a class="nav-link" href="user-messages.html"
                ><span>Повідомлень</span><sup>4</sup></a
              >
            </li>
          </ul>
        </div>
        <div class="page-header__user">
          <div class="user dropdown">
            <button
              class="dropdown-toggle user__button"
              type="button"
              data-bs-toggle="dropdown"
              data-bs-auto-close="outside"
            >
              <div class="user__avatar">
                <img src="/assets/img/avatar.svg" alt="image" />
              </div>
              <div class="user__info">
                <div class="user__name">Максим</div>
                <div class="user__label">мій профіль</div>
              </div>
            </button>
            <div class="dropdown-menu dropdown-menu-end user__dropdown">
              <div class="user-info">
                <div class="user-info__title">Особисті данні</div>
                <div class="user-info__body">
                  <ul class="user-info__list">
                    <li>П.І.Б.</li>
                    <li>Номер телефону</li>
                    <li>E-mail</li>
                    <li>Місто</li>
                    <li>
                      Пароль<a href="#m-change-password" data-bs-toggle="modal"
                        >змінити</a
                      >
                    </li>
                    <li>
                      <select
                        class="js-select"
                        name="payment"
                        style="display: none;"
                      >
                        <option value="Тип оплати за замовчуванням"
                          >Тип оплати за замовчуванням</option
                        >
                        <option value="Тип оплати за замовчуванням"
                          >Тип оплати за замовчуванням</option
                        >
                        <option value="Тип оплати за замовчуванням"
                          >Тип оплати за замовчуванням</option
                        >
                        <option value="Тип оплати за замовчуванням"
                          >Тип оплати за замовчуванням</option
                        >
                        <option value="Тип оплати за замовчуванням"
                          >Тип оплати за замовчуванням</option
                        >
                      </select>
                      <div class="nice-select js-select" tabindex="0">
                        <span class="current">Тип оплати за замовчуванням</span>
                        <ul class="list">
                          <li
                            data-value="Тип оплати за замовчуванням"
                            class="option selected"
                          >
                            Тип оплати за замовчуванням
                          </li>
                          <li
                            data-value="Тип оплати за замовчуванням"
                            class="option"
                          >
                            Тип оплати за замовчуванням
                          </li>
                          <li
                            data-value="Тип оплати за замовчуванням"
                            class="option"
                          >
                            Тип оплати за замовчуванням
                          </li>
                          <li
                            data-value="Тип оплати за замовчуванням"
                            class="option"
                          >
                            Тип оплати за замовчуванням
                          </li>
                          <li
                            data-value="Тип оплати за замовчуванням"
                            class="option"
                          >
                            Тип оплати за замовчуванням
                          </li>
                        </ul>
                      </div>
                    </li>
                    <li>Код ЕДРПОУ</li>
                    <li>Назва компанії</li>
                    <li>Посада</li>
                    <li>Платник ПДВ</li>
                  </ul>
                  <div class="user-info__avatar">
                    <img src="/assets/img/avatar-user.svg" alt="avatar" />
                  </div>
                </div>
                <div class="user-info__bottom">
                  <div class="user-info__link">
                    <a href="#m-edit-user" data-bs-toggle="modal">Редагувати</a>
                  </div>
                  <div class="user-info__exit">
                    <a href="/page-login.html">Вийти</a>
                  </div>
                </div>
              </div>
              <div class="user-info">
                <div class="user-info__title">Мій менеджер</div>
                <div class="user-info__body">
                  <ul class="user-info__list">
                    <li>П.І.Б.</li>
                    <li>Номер телефону</li>
                    <li>E-mail</li>
                    <li>Місто</li>
                  </ul>
                  <div class="user-info__avatar">
                    <img src="/assets/img/avatar-manager.svg" alt="avatar" />
                  </div>
                </div>
                <div class="user-info__bottom">
                  <div class="user-info__link">
                    <a href="user-messages-chat.html">Написати</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="page-header__action">
          <a
            class="action-link --youtube"
            href="http://www.youtube.com"
            target="_blank"
            ><i class="ico_youtube"></i></a
          ><a class="action-link --cart" href="#!"
            ><i class="ico_cart"></i><sup>4</sup></a
          ><a
            class="action-link"
            href="#offcanvas"
            data-bs-toggle="offcanvas"
            role="button"
            ><i class="ico_menu"></i
          ></a>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  name: 'Header',
  components: {},
  data: () => ({}),
  computed: {},
  methods: {},
}
</script>

<style lang="scss"></style>
