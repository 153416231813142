const sides = ['left', 'top', 'right', 'bottom']

function getSideKey(item) {
  return Object.keys(item).find(key => {
    const value = item[key]
    return (
      value && typeof value == 'object' && sides.every(side => side in value)
    )
  })
}

function isSideSet(value) {
  return sides.some(side => value[side])
}

export default function readyToSave(item) {
  // if (item.template == 'groove_edge') {
  //   return isSideSet(item.place.checkboxes)
  // }
  // const sideKey = getSideKey(item)
  // if (sideKey) {
  //   return isSideSet(item[sideKey])
  // }
  return true
}
