<template>
  <div>
    <div class="table-caption">
      <h3>Мої замовлення</h3>
    </div>
    <div class="table-body">
      <table
        class="footable footable-1 footable-paging footable-paging-right breakpoint breakpoint-md"
      >
        <thead>
          <tr class="footable-header">
            <th
              data-sortable="false"
              class="footable-first-visible"
              style="display: table-cell;"
            >
              №
            </th>
            <th style="display: table-cell;">
              Назва
            </th>
            <th style="display: table-cell;">
              Дата замовлення
            </th>
            <th style="display: table-cell;"></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in items" :key="item.id">
            <td style="display: table-cell;">
              <span>{{ item.id }}</span>
            </td>
            <td style="display: table-cell;">
              <span>{{ item.name }}</span>
            </td>
            <td style="display: table-cell;">
              <span>{{ new Date(item.updated_at) }}</span>
            </td>
            <td style="display: table-cell; text-align: right;">
              <div class="d-flex justify-content-end">
                <router-link
                  class="btn btn-primary"
                  :to="{ name: 'OrderMaterials', params: { orderId: item.id } }"
                  >Переглянути</router-link
                >
              </div>
            </td>
          </tr>
        </tbody>
        <tfoot></tfoot>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Orders',
  components: {},
  props: [],

  data: () => ({
    routeName: 'Orders',
    type: 'default',
  }),

  computed: {
    items() {
      return this.$store.state.order.orders
    },
  },

  methods: {},

  created() {
    this.routeName = this.$route.name
    this.$store.dispatch('order/getOrders', {
      type: this.type,
      page: 1,
      limit: 20,
      userId: this.$store.state.order.userOrderID | '',
    })
  },
}
</script>

<style lang="scss"></style>
