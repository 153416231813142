<template>
  <General :orderId="Number($route.params.orderId)" />
</template>

<script>
import General from '@/components/order/General.vue'

export default {
  name: 'OrderGeneral',
  components: {
    General,
  },
  data: () => ({}),
  computed: {},
  created() {},
}
</script>

<style lang="scss"></style>
