<template>
  <ul :class="'constructor-form is-active'">
    <li class="constructor-form__item" v-for="item in items" :key="item">
      <a
        class="constructor-form__link"
        :class="{ active: item == value }"
        data-bs-toggle="tooltip"
        data-bs-trigger="hover"
        data-bs-placement="right"
        v-tooltip
        href=""
        title=""
        :data-bs-original-title="$t('operations.' + item)"
        :aria-label="$t('operations.' + item)"
        @click.prevent="$emit('input', item)"
      >
        <span :class="icons[item]"></span>
      </a>
    </li>
  </ul>
</template>

<script>
import { operationIcons } from '../icons'
import { groups } from './groups'

export default {
  name: 'InnerTabs',
  props: { group: Number, value: String },
  data() {
    return {
      icons: operationIcons,
      groups,
    }
  },
  computed: {
    items() {
      return this.groups[this.group]
    },
  },
  methods: {},
}
</script>

<style scoped lang="scss"></style>
