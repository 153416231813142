import { paper, Point } from 'paper'

export default class Zoom {
  constructor({ zoomFactor = 1.1, minZoom = 0.1, maxZoom = 10 }, el) {
    this.zoomFactor = zoomFactor
    this.minZoom = minZoom
    this.maxZoom = maxZoom
    this.el = el
    this.init()
  }
  init() {
    const { minZoom, maxZoom } = this
    let onPointerDown = {
      zoomDistanceStart: null,
      zoomDistanceEnd: null,
    }
    if (!this.el) throw new Error('No parent element')
    this.el.addEventListener('wheel', event => {
      event.preventDefault()
      event.stopImmediatePropagation()
      let oldZoom = paper.paperScope.view.zoom
      let oldCenter = paper.paperScope.view.center
      let mousePosition = paper.paperScope.view.viewToProject(
        new Point(event.offsetX, event.offsetY)
      )
      let step =
        Math.abs(event.deltaY) > 30
          ? event.deltaY < 0
            ? oldZoom - oldZoom * this.zoomFactor
            : oldZoom - oldZoom / this.zoomFactor
          : event.deltaY * 0.01
      let zoomValue = paper.paperScope.view.zoom - step
      paper.paperScope.view.zoom = Math.max(
        minZoom,
        Math.min(zoomValue, maxZoom)
      )
      paper.paperScope.view.center = paper.paperScope.view.center.add(
        mousePosition
          .subtract(oldCenter)
          .multiply(1 - oldZoom / paper.paperScope.view.zoom)
      )
    })
    this.el.addEventListener('touchstart', event => {
      if (event.touches.length == 2) {
        let dx = event.touches[0].pageX - event.touches[1].pageX
        let dy = event.touches[0].pageY - event.touches[1].pageY
        onPointerDown.zoomDistanceEnd = onPointerDown.zoomDistanceStart = Math.sqrt(
          dx * dx + dy * dy
        )
      }
    })
    this.el.addEventListener('touchmove', event => {
      if (event.touches.length == 2) {
        let dx = event.touches[0].pageX - event.touches[1].pageX
        let dy = event.touches[0].pageY - event.touches[1].pageY
        onPointerDown.zoomDistanceEnd = Math.sqrt(dx * dx + dy * dy)
        let factor =
          onPointerDown.zoomDistanceStart / onPointerDown.zoomDistanceEnd
        onPointerDown.zoomDistanceStart = onPointerDown.zoomDistanceEnd
        paper.paperScope.view.zoom = Math.max(
          minZoom,
          Math.min(paper.paperScope.view.zoom / factor, maxZoom)
        )
        paper.paperScope.view._needsUpdate = true
        paper.paperScope.view.update()
      }
    })
    this.el.addEventListener('touchend', () => {
      onPointerDown.zoomDistanceStart = onPointerDown.zoomDistanceEnd = 0
    })
    const tool = new paper.Tool()
    tool.name = 'toolPath'
    tool.activate()
    tool.onMouseDrag = function(event) {
      paper.paperScope.view.center = paper.paperScope.view.center.add(
        event.downPoint.subtract(event.point)
      )
    }
  }
}
