<template>
  <div class="constructor__info">
    <ul class="info-list" v-if="detail && material">
      <li class="info-list__item">
        {{ $t('dateylor.porition') }}{{ detail.position }}
      </li>
      <li class="info-list__item">
        {{ parseInt(material.thickness) }} {{ $t('dateylor.mm') }}
      </li>
      <li class="info-list__item">{{ material.name }}</li>
      <li class="info-list__item">
        {{ $t('dateylor.detail_size') }} {{ detail.length }}x{{
          detail.width
        }}x{{ parseInt(detail.thickness) * parseInt(material.thickness) }}
        {{ $t('dateylor.mm') }}
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'Info',
  props: { detail: Object, material: Object },
  data() {
    return {}
  },
  computed: {},
  methods: {},
}
</script>

<style scoped lang="scss"></style>
