<template>
  <div class="constructor__type-processing">
    <div class="constructor__menu-title">Операції</div>
    <ul class="type-processing">
      <li
        class="type-processing__item --type-processing-1"
        :class="'--type-processing-' + item"
        :data-type-processing="item"
        v-for="item in items"
        :key="item"
      >
        <a
          class="type-processing__link"
          :class="{ active: item == value }"
          href="#!"
          title=""
          :data-bs-original-title="$t('operation-groups.group-' + item)"
          :aria-label="$t('operation-groups.group-' + item)"
          data-bs-toggle="tooltip"
          data-bs-trigger="hover"
          data-bs-placement="right"
          v-tooltip
          @click.prevent="$emit('input', item)"
        ></a>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'Tabs',
  props: { value: Number },
  data() {
    return {
      items: [1, 2, 3, 4, 5],
    }
  },
  computed: {},
  methods: {},
}
</script>

<style scoped lang="scss"></style>
