import Vue from 'vue'
import VueToast from 'vue-toast-notification'

import App from './App.vue'
import i18n from '@/plugins/i18n'

import router from './router'
import store from './store'

import '@/assets/scss/global.scss'

Vue.use(VueToast, {
  duration: 5000,
  position: 'bottom-right',
})

Vue.config.productionTip = false

import PortalVue from 'portal-vue'
Vue.use(PortalVue)

import VClickOutside from 'v-click-outside'
Vue.use(VClickOutside)
// fix TS complain on bootstrap
declare const bootstrap: any
Vue.directive('tooltip', {
  // Когда привязанный элемент вставлен в DOM...
  inserted(el) {
    // Enable tooltip
    new bootstrap.Tooltip(el)
  },
  unbind(el) {
    // Destroy tooltip
    const tooltip = bootstrap.Tooltip.getInstance(el)
    if (tooltip) tooltip.dispose()
  },
})

import Dropdown from '@/components/Dropdown.vue'
Vue.component('Dropdown', Dropdown)

import Multiselect from 'vue-multiselect'
Vue.component('multiselect', {
  ...Multiselect,
  props: {
    // @ts-ignore
    ...Multiselect.props,
    openDirection: {
      type: String,
      default: 'bottom',
    },
  },
})

// get token form url
function getToken() {
  const matches = window.location.search.match(/token=([^=]+)/)
  return matches && matches[1]
}
const token = getToken()
if (token) {
  store.commit('auth/updateToken', token)
  store.commit('auth/updateUser', {})
}

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app')
