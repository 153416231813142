var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"table-head --page-new-order"},[_c('div',[_c('Submenu',{attrs:{"orderId":_vm.orderId,"materialId":_vm.materialId}})],1),_vm._m(0)]),_c('div',{staticClass:"table-body"},[_c('div',{staticClass:"table-body-overflow"},[_c('table',{staticClass:"footable breakpoint"},[_c('tbody',_vm._l((_vm.materials),function(item,index){return _c('ProductsTableRow',{key:index,attrs:{"item":item,"index":index,"selected":_vm.material && _vm.material.id == item.id},on:{"edit":function($event){return _vm.$router.replace({
                name: 'OrderDetails',
                params: { orderId: _vm.orderId, materialId: item.id },
              })},"remove":function($event){return _vm.deleteProduct(item)}}})}),1)])])]),_c('div',{staticClass:"details-list"},[_c('div',{staticClass:"details-list__box"},[_c('div',{staticClass:"details-list__left"},[(_vm.material)?_c('div',{staticClass:"details-list__material"},[_c('h4',[_vm._v("Матеріал")]),_c('div',{staticClass:"material-card"},[_c('div',{staticClass:"material-card__img"},[_c('a',{ref:"pic",attrs:{"rel":"popover","data-img":_vm.material.image || _vm.defaultPicture,"data-cls":"popover-img"}},[_c('img',{attrs:{"src":_vm.material.image || _vm.defaultPicture,"alt":_vm.material.name},on:{"error":function($event){$event.target.src = require('@/assets/img/no_plita.svg')}}})])]),_c('div',{staticClass:"material-card__info"},[_c('h3',[_vm._v(_vm._s(_vm.material.name))]),_c('ul',[_c('li',[_c('span',[_vm._v("Формат")]),_c('strong',[_vm._v(_vm._s(_vm.material.width)+"x"+_vm._s(_vm.material.height))])]),_c('li',[_c('span',[_vm._v("Товщина")]),_c('strong',[_vm._v(_vm._s(parseInt(_vm.material.thickness))+" мм")])]),_vm._m(1)])]),_c('div',{staticClass:"material-card__action"},[_c('label',{staticClass:"check"},[_c('input',{staticClass:"check__input",attrs:{"type":"checkbox"},domProps:{"checked":!!_vm.material.with_waste},on:{"click":function($event){$event.preventDefault();return _vm.changeProduct(_vm.material.id, {
                      with_waste: !_vm.material.with_waste,
                    })}}}),_c('span',{staticClass:"check__box"}),_vm._v("Забрати залишок")]),_c('div',{},[_c('Dropdown',{staticClass:"drop --select",scopedSlots:_vm._u([{key:"default",fn:function({ show, hide, isOpen }){return [_c('button',{ref:"defaultKromka",staticClass:"form-control drop-button",attrs:{"type":"button"},on:{"click":function($event){return show()}}},[_vm._v(" "+_vm._s(_vm.defaultKromka ? _vm.defaultKromka.name : 'Вибір кромки по замовченю')+" ")]),_c('div',{staticClass:"drop-box",style:({ display: isOpen ? 'block' : 'none' })},[_c('div',{staticClass:"drop-overflow"},[_c('ul',{staticClass:"drop-list"},[(!_vm.kromkas.length)?_c('li',{staticClass:"drop-list-item text-muted"},[_vm._v(" Кромка вiдсутня ")]):_vm._e(),_vm._l((_vm.kromkas),function(item,index){return _c('li',{key:index,staticClass:"drop-list-item",class:{
                            'text-muted pe-none':
                              _vm.defaultKromka && _vm.defaultKromka.id == item.id,
                          },on:{"click":function($event){hide()
                            _vm.onDefaultKromkaChange(item)}}},[_vm._v(" "+_vm._s(item.name)+" ")])})],2)])])]}}],null,false,1209279650)})],1),_c('router-link',{staticClass:"btn btn-primary mt-4",attrs:{"to":{
                  name: 'OrderAddMaterial',
                  params: { orderId: _vm.orderId },
                }}},[_vm._v("Додати матеріал")])],1)])]):_vm._e()]),(_vm.material)?_c('div',{staticClass:"details-list__right"},[_c('div',{staticClass:"details-list__table"},[_c('label',{staticClass:"input-file"},[_c('button',{staticClass:"btn btn-primary",on:{"click":function($event){return _vm.$root.$emit('openDialog', 'FileImportDialog', {
                  title: 'Выгрузить детали для данного материала',
                })}}},[_c('i',{staticClass:"ico_arrow-down"}),_c('span',[_vm._v("Завантажити файл")])])]),_c('div',{staticClass:"table-body"},[_c('table',{staticClass:"footable breakpoint"},[_c('thead',[_c('tr',{staticClass:"footable-header"},[_c('th',{staticClass:"w-1"},[_c('div',{staticClass:"btn-icon ico_plus",on:{"click":function($event){return _vm.addDetail(_vm.material)}}})]),_c('th',[_vm._v("№")]),_c('th',{staticClass:"w-1 d-none d-md-table-cell",attrs:{"data-breakpoints":"xs"}},[_vm._v(" Розмір ")]),_c('th',{staticClass:"w-1 d-none d-md-table-cell",attrs:{"data-breakpoints":"xs"}}),_c('th',{staticClass:"w-1 d-none d-md-table-cell",attrs:{"data-breakpoints":"xs"}},[_vm._v(" Кромки ")]),_c('th',{staticClass:"w-1 d-none d-xl-table-cell",attrs:{"data-breakpoints":"xs sm md"}},[_c('div',{staticClass:"d-flex align-items-center flex-column"},[_c('span',[_vm._v("Структура")]),_c('label',{staticClass:"check mt-1"},[_c('input',{staticClass:"check__input",attrs:{"type":"checkbox","tabindex":"-1"},domProps:{"checked":_vm.isTextureSetForAll},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.updateAllDetails({
                              is_texture_set: !_vm.isTextureSetForAll,
                            })}}}),_c('span',{staticClass:"check__box me-0"})])])]),_c('th',{staticClass:"w-1 d-none d-xl-table-cell",attrs:{"data-breakpoints":"xs sm md"}},[_vm._v(" Зшивка ")]),_c('th',{staticClass:"w-1 d-none d-xl-table-cell",attrs:{"data-breakpoints":"xs sm md"}},[_vm._v(" Назва ")]),_c('th',{staticClass:"w-1 d-none d-xl-table-cell",attrs:{"data-breakpoints":"xs sm md"}})])]),_c('tbody',{ref:"tbody"},_vm._l((_vm.details),function(item,index){return _c('DetailsTableRow',{key:index,ref:"detailRows",refInFor:true,attrs:{"item":item,"material":_vm.material,"order":_vm.order,"defaultKromka":_vm.defaultKromka,"anyDetailHasDefaultKromka":_vm.anyDetailHasDefaultKromka},on:{"no-default-kromka":function($event){_vm.$toast.warning('Виберіть крайку по замовчуванню')
                    _vm.$refs.defaultKromka.click()}}})}),1)])])])]):_vm._e()])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('button',{staticClass:"btn btn-primary",attrs:{"type":"button"}},[_vm._v("Зберегти")]),_c('button',{staticClass:"btn btn-icon ico_trash",attrs:{"type":"button"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('span',[_vm._v("Напрямок")]),_c('strong',[_vm._v("Горизонтальний")])])
}]

export { render, staticRenderFns }