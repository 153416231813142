<template>
  <div>
    <!-- Submenu -->
    <div class="table-head --page-new-order">
      <div>
        <Submenu
          :orderId="orderId"
          :materialId="orderMaterials[0] && orderMaterials[0].id"
        />
      </div>
      <div>
        <button class="btn btn-primary" type="button">Зберегти</button>
        <a class="btn btn-primary" href="#" @click.prevent="">В роботу</a>
        <button class="btn btn-icon ico_trash" type="button"></button>
      </div>
    </div>
    <div
      class="row justify-content-center align-items-center py-3"
      v-if="isLoading"
    >
      Завантаження...
    </div>
    <div v-else>
      <!-- Materials -->
      <div
        class="table-body"
        v-for="(table, tableIndex) in tables"
        :key="tableIndex"
        v-if="table.items.length"
      >
        <table class="footable breakpoint">
          <thead>
            <tr class="footable-header">
              <th>{{ table.title }}</th>
              <th
                data-breakpoints="xs"
                class="d-none d-md-table-cell text-end fixed-width-1x"
              >
                Вартість
              </th>
              <th
                data-breakpoints="xs"
                class="d-none d-md-table-cell text-center fixed-width-1x"
              >
                Товарів
              </th>
              <th
                data-breakpoints="xs"
                class="d-none d-md-table-cell text-center fixed-width-1x"
                v-if="tableIndex == 0"
              >
                Деталей
              </th>
              <th
                data-breakpoints="xs"
                class="d-none d-md-table-cell text-end"
                :class="tableIndex == 0 ? 'fixed-width-1x' : 'fixed-width-2x'"
              >
                Сума
              </th>
            </tr>
          </thead>
          <tbody>
            <GeneralMaterialsTableRow
              :item="item"
              v-for="(item, index) in table.items"
              :showDetails="tableIndex == 0"
              :key="index"
            />
          </tbody>
          <tfoot>
            <tr>
              <td colspan="5">
                <div class="total">
                  <span>Разом</span
                  ><strong>{{ table.total.toFixed(2) }} грн</strong>
                </div>
              </td>
            </tr>
          </tfoot>
        </table>
      </div>

      <!-- Operations -->
      <div class="table-body">
        <table class="footable breakpoint">
          <thead>
            <tr class="footable-header">
              <th>Роботи</th>
              <th
                data-breakpoints="xs"
                class="d-none d-md-table-cell text-end fixed-width-1x"
              >
                Вартість
              </th>
              <th
                data-breakpoints="xs"
                class="d-none d-md-table-cell text-end fixed-width-1x"
              >
                Одиниць
              </th>
              <th
                data-breakpoints="xs"
                class="d-none d-md-table-cell text-end fixed-width-2x"
              >
                Сума
              </th>
            </tr>
          </thead>
          <tbody>
            <GeneralOperationsTableRow
              :item="item"
              v-for="(item, index) in operations"
              :key="index"
            />
          </tbody>
          <tfoot>
            <tr>
              <td colspan="5">
                <div class="total">
                  <span>Разом</span
                  ><strong
                    >{{
                      calculations.total_operations > 0
                        ? parseFloat(calculations.total_operations).toFixed(2)
                        : 0 || 0
                    }}
                    грн</strong
                  >
                </div>
              </td>
            </tr>
          </tfoot>
        </table>
      </div>

      <!-- Delivery and Total -->
      <div class="table-footer align-items-md-start">
        <div>
          <Delivery :orderId="orderId" class="row gx-2 gy-2" />
        </div>
        <div>
          <div class="total-box mb-3">
            <div class="total">
              <span>Разом</span
              ><strong
                >{{
                  calculations.total > 0
                    ? parseFloat(calculations.total).toFixed(2)
                    : 0 || 0
                }}
                грн</strong
              >
            </div>
          </div>
          <div class="table-head --page-new-order">
            <div class="">
              <button class="btn btn-primary" type="button">Зберегти</button>
              <a class="btn btn-primary ml-2" href="#" @click.prevent=""
                >В роботу</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- End -->
  </div>
</template>

<script>
import Submenu from '@/components/order/Submenu.vue'
import GeneralMaterialsTableRow from '@/components/order/general/GeneralMaterialsTableRow.vue'
import GeneralOperationsTableRow from '@/components/order/general/GeneralOperationsTableRow.vue'
import Delivery from '@/components/order/general/Delivery.vue'

export default {
  name: 'General',
  props: ['orderId'],
  components: {
    Submenu,
    GeneralOperationsTableRow,
    GeneralMaterialsTableRow,
    Delivery,
  },

  data: () => ({
    isLoading: false,
  }),
  computed: {
    order() {
      return this.$store.state.order.order || {}
    },
    calculations() {
      return this.$store.state.order.calculations
    },
    tables() {
      const { calculations } = this
      const materials = (
        (calculations && calculations.materials) ||
        []
      ).filter(({ type }) => ['tabletop', 'plate'].includes(type))
      const kromkas = ((calculations && calculations.materials) || []).filter(
        ({ type }) => type == 'edge'
      )
      const other = ((calculations && calculations.materials) || []).filter(
        ({ type }) => !['tabletop', 'plate', 'edge'].includes(type)
      )
      return [
        {
          title: 'Матеріал',
          items: materials,
          total: materials.reduce((sum, { total }) => sum + total, 0),
        },
        {
          title: 'Крайка',
          items: kromkas,
          total: kromkas.reduce((sum, { total }) => sum + total, 0),
        },
        {
          title: 'Фурнітура/інше',
          items: other,
          total: other.reduce((sum, { total }) => sum + total, 0),
        },
      ]
    },
    operations() {
      const { calculations } = this.$store.state.order
      return (calculations && calculations.operations) || []
    },
    orderMaterials() {
      return this.$store.getters['order/materials']
    },
  },

  methods: {},

  async mounted() {
    // this.$Progress.start()
    this.$root.$emit('playPreloader')
    this.isLoading = true
    try {
      if (!this.order || this.order.id != this.orderId) {
        await this.$store.dispatch('order/getOrder', {
          id: this.orderId,
        })
      }
      await this.$store.dispatch('order/getOrderProducts', {
        orderId: this.orderId,
      })
      await this.$store.dispatch('order/getCalculations', this.orderId)
    } catch (e) {
      // do nothing
    }
    this.isLoading = false
    this.$root.$emit('stopPreloader')
  },
}
</script>

<style lang="scss">
.fixed-width {
  $width: 130px;
  &-1x {
    width: $width;
  }
  &-2x {
    width: 2 * $width;
  }
}
table.footable > tbody > tr > td,
table.footable > tbody > tr > th {
  display: table-cell;
}
</style>
