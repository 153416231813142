import api from '@/gateway/api'

export default {
  namespaced: true,
  state: {
    order: null,
    orders: [],
    userOrderID: '',
    novaPochtaRegions: [],
    novaPochtaCities: [],
    novaPochtaAddress: [],
    deliveryCities: [],
    deliveryWarehouses: [],
    deliveryWarehousesInfo: [],
    satCity: [],
    satWarehouses: [],
    pickupStores: [],
    pickupStoreInfo: [],
    details: [],
    detailsUpdate: [],
    materialCutMap: [],
    materialCuttingEdges: {},
    carve: [],
    tools: [],
    products: {},
    calculations: {},
    furnitureOperations: [],
  },
  getters: {
    areaTools({ tools }) {
      return tools.filter(item => item.is_target_side)
    },
    edgeTools({ tools }) {
      return tools.filter(item => item.is_target_edge)
    },
    kromkas({ products }) {
      return products && products.edges
    },
    furniture({ products }) {
      return products && products.furniture
    },
    materials({ products }) {
      if (!products) return []
      return [
        ...(products.plates || []),
        ...(products.tabletops || []),
        ...(products.facade || []),
      ]
    },
  },
  mutations: {
    updateOrderID(state, orderID) {
      state.userOrderID = orderID
    },
    updateOrder(state, order) {
      state.order = order
    },
    updateOrders(state, orders) {
      state.orders = orders
    },
    getNovaPochtaRegions(state, novaPochtaRegions) {
      state.novaPochtaRegions = novaPochtaRegions
    },

    getNovaPochtaCities(state, novaPochtaCities) {
      state.novaPochtaCities = novaPochtaCities
    },

    getDeliveryCities(state, deliveryCities) {
      state.deliveryCities = deliveryCities
    },

    getDeliveryWarehouses(state, deliveryWarehouses) {
      state.deliveryWarehouses = deliveryWarehouses
    },

    getDeliveryWarehousesInfo(state, deliveryWarehousesInfo) {
      state.deliveryWarehousesInfo = deliveryWarehousesInfo
    },

    getNovaPochtaAddress(state, novaPochtaAddress) {
      state.novaPochtaAddress = novaPochtaAddress
    },

    getSatWarehouses(state, satWarehouses) {
      state.satWarehouses = satWarehouses
    },
    getPickupStores(state, pickupStores) {
      state.pickupStores = pickupStores
    },
    getPickupStoreInfo(state, pickupStoreInfo) {
      state.pickupStoreInfo = pickupStoreInfo
    },
    addDetails(state, addDetails) {
      state.addDetails = addDetails
    },
    getDetails(state, details) {
      state.details = details
    },
    clearDetails(state) {
      state.details = []
    },
    detailsUpdate(state, detailsUpdate) {
      state.detailsUpdate = detailsUpdate
    },

    getMaterialCutMap(state, materialCutMap) {
      state.materialCutMap = materialCutMap
    },
    getMaterialCuttingEdges(state, materialCuttingEdges) {
      state.materialCuttingEdges = materialCuttingEdges
    },

    updateCarve(state, carve) {
      // const result : string[] = [];
      // carve.forEach(function (valueItem) {
      //
      //       let idCarve = valueItem.id
      //       let params = JSON.parse(valueItem.params)
      //       params.idCarve = idCarve
      //       result.push(params)
      //
      // });
      // debugger

      // state.carve = result
      state.carve = carve
    },
    getTools(state, tools) {
      state.tools = tools
    },
    getProducts(state, products) {
      state.products = products
    },
    getCalculations(state, calculations) {
      state.calculations = calculations
    },
    getFurnitureOperations(state, furnitureOperations) {
      state.furnitureOperations = furnitureOperations
    },
  },
  actions: {
    getOrders(
      { commit },
      { type = null, page = 1, limit = null, search = null, userId = null }
    ) {
      return new Promise((resolve, reject) => {
        api
          .get('order', {
            params: {
              type: type,
              page: page,
              limit: limit,
              search: search,
              userId: userId,
            },
          })
          .then(result => {
            commit('updateOrders', result.data.orders)
            resolve(result)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    getOrder({ commit }, data) {
      // getOrder({commit}, id) {

      return new Promise((resolve, reject) => {
        // api.get('order/' + id ).then(result => {

        // api.get('order/' + data.id + '?' + data.value).then(result => {
        api
          .get('order/' + data.id, {
            params: {
              search: data.value,
            },
          })
          .then(result => {
            commit('updateOrder', result.data.order)
            resolve(result)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    createOrder({ commit }, status) {
      return new Promise((resolve, reject) => {
        api
          .post('order', {
            status,
          })
          .then(result => {
            commit('updateOrder', result.data.order)
            resolve(result.data.order)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    updateOrder({ commit }, { order, status, production_id = null }) {
      return new Promise((resolve, reject) => {
        api
          .put('order/' + order.id, order)
          .then(result => {
            commit('updateOrder', result.data.order)
            resolve(result)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    deleteOrder({ commit }, id) {
      return new Promise((resolve, reject) => {
        api
          .delete('order/' + id)
          .then(result => {
            commit('updateOrder', null)
            resolve(result)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    restoreOrder({ commit }, id) {
      return new Promise((resolve, reject) => {
        api
          .post('order/' + id + '/restore')
          .then(result => {
            commit('updateOrder', result.data.order)
            resolve(result)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    copyOrder({ commit }, id) {
      return new Promise((resolve, reject) => {
        api
          .post('order/' + id + '/copy')
          .then(result => {
            commit('updateOrder', result.data.order)
            resolve(result.data.order)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    getOrderProducts({ commit }, { orderId }) {
      return new Promise((resolve, reject) => {
        api
          .get(`/order/${orderId}/material`)
          .then(result => {
            commit('getProducts', result.data)
            resolve(result)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    addOrderProduct({ commit }, { orderId, product, data = {} }) {
      return new Promise((resolve, reject) => {
        api
          .post('order/' + orderId + '/material/' + product.id, data)
          .then(result => {
            resolve(result)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    changeOrderProduct(
      { commit, dispatch, state },
      { orderId, productId, data }
    ) {
      return new Promise((resolve, reject) => {
        api
          .put('order/' + orderId + '/material/' + productId, data)
          .then(result => {
            if (state.products) {
              // обновляем сосояние списка материалов в памяти
              // чтобы избежать лишнего запроса к серверу
              const { material } = result.data
              const key = ['facade', 'furniture'].includes(material.type)
                ? material.type
                : material.type + 's'
              const items = state.products[key]
              const index = items.findIndex(({ id }) => productId == id)
              const product = items[index]
              if (index != -1) {
                const products = {
                  ...state.products,
                  [key]: [
                    ...items.slice(0, index),
                    { ...product, ...data },
                    ...items.slice(index + 1),
                  ],
                }
                commit('getProducts', products)
              }
            }
            resolve(result.data && result.data.material)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    removeOrderProduct({ commit }, { orderId, product }) {
      return new Promise((resolve, reject) => {
        api
          .delete('order/' + orderId + '/material/' + product.id)
          .then(result => {
            resolve(result)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    createOrderProduct({ commit }, { order_id, product, material_id = null }) {
      return new Promise((resolve, reject) => {
        api
          .post('order/' + order_id + '/product', {
            product_id: product.id,
            quantity: product.count || 1,
            material_id: material_id,
          })
          .then(result => {
            commit('updateOrder', result.data.order)
            resolve(result)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    updateOrderProduct({ commit }, { order_id, product }) {
      return new Promise((resolve, reject) => {
        api
          .put('order/' + order_id + '/product/' + product.id, product)
          .then(result => {
            commit('updateOrder', result.data.order)
            resolve(result)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    deleteOrderProduct({ commit }, { order_id, product_id }) {
      return new Promise((resolve, reject) => {
        api
          .delete('order/' + order_id + '/product/' + product_id)
          .then(result => {
            commit('updateOrder', result.data.order)
            resolve(result)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    getNovaPochtaRegions({ commit }, { delivery_name = null, lng = null }) {
      // debugger
      return new Promise((resolve, reject) => {
        api
          .post('order/delivery/regions', {
            delivery_name: delivery_name,
            lng: lng,
          })
          .then(result => {
            // console.log(result.data)
            // debugger
            // commit('getDeliveryRegions', result.data.order)
            commit('getNovaPochtaRegions', result.data)
            // resolve(result.data.order)
            resolve(result.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    getNovaPochtaCities(
      { commit },
      { delivery_name = null, lng = null, region_cod = null }
    ) {
      return new Promise((resolve, reject) => {
        api
          .post('order/delivery/cities', {
            delivery_name: delivery_name,
            lng: lng,
            region_cod: region_cod,
          })
          .then(result => {
            commit('getNovaPochtaCities', result.data)
            // resolve(result.data.order)
            resolve(result.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    getAllDeliveryCities({ commit }) {
      return new Promise((resolve, reject) => {
        api
          .get('delivery/deliveryAuto')
          .then(result => {
            commit('getDeliveryCities', result.data)
            resolve(result.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    getDeliveryWarehousesByCity({ commit }, { cityId }) {
      return new Promise((resolve, reject) => {
        api
          .get('delivery/deliveryAuto', {
            params: {
              CityId: cityId,
            },
          })
          .then(result => {
            commit('getDeliveryWarehouses', result.data)
            resolve(result.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    getDeliveryWarehousesInfoById({ commit }, { warehousesId }) {
      return new Promise((resolve, reject) => {
        api
          .get('delivery/deliveryAuto', {
            params: {
              WarehousesId: warehousesId,
            },
          })
          .then(result => {
            commit('getDeliveryWarehousesInfo', result.data)
            resolve(result.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    getNovaPochtaAddress(
      { commit },
      { delivery_name = null, lng = null, cityRef = null }
    ) {
      return new Promise((resolve, reject) => {
        api
          .post('order/delivery/address', {
            delivery_name: delivery_name,
            lng: lng,
            cityRef: cityRef,
          })
          .then(result => {
            commit('getNovaPochtaAddress', result.data)
            // resolve(result.data.order)
            resolve(result.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    getSatWarehouses({ commit }) {
      return new Promise((resolve, reject) => {
        api
          .get('delivery/cat')
          .then(result => {
            commit('getSatWarehouses', result.data)
            resolve(result.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    /*getPickupStores({commit}) {
            return new Promise((resolve, reject) => {
                api.get('delivery/self-pickup')
                    .then( result => {
                        commit('getPickupStores', result.data)
                        resolve(result.data)
                    })
                    .catch( error => {
                        reject(error)
                    })
            })
        },*/

    getPickupStores({ commit }) {
      return api
        .get('delivery/self-pickup')
        .then(result => {
          commit('getPickupStores', result.data)
        })
        .catch()
    },

    getPickupStoresInfo({ commit }, { id }) {
      return new Promise((resolve, reject) => {
        api
          .get('delivery/self-pickup?id=' + id)
          .then(result => {
            commit('getPickupStoreInfo', result.data)
            resolve(result.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    // DETAILS ACTIONS

    getMaterialDetails({ commit }, { material_id }) {
      return new Promise((resolve, reject) => {
        api
          .get(`/material/${material_id}/detail`)
          .then(result => {
            commit(
              'getDetails',
              (result.data.data || []).sort((a, b) =>
                a.order_no > b.order_no ? 1 : -1
              )
            )
            resolve(result)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    addMaterialDetail({ dispatch }, { material_id, details }) {
      return new Promise((resolve, reject) => {
        // api.put('order/' + order.id, order ).then(result => {
        api
          .post(`/material/${material_id}/detail`, {})
          .then(result => {
            dispatch('getMaterialDetails', { material_id })
            resolve(result)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    updateAllDetailsOfMaterial({ commit, dispatch }, { material_id, data }) {
      return new Promise((resolve, reject) => {
        api
          .post(`/material/${material_id}/detail/bulk`, data)
          .then(result => {
            resolve(result.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    getDetail({ commit }, { detail_id }) {
      return new Promise((resolve, reject) => {
        api
          .get(`/detail/${detail_id}`)
          .then(result => {
            resolve(result)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    updateDetail({ commit, dispatch }, { detail_id, data }) {
      return new Promise((resolve, reject) => {
        api
          .put(`/detail/${detail_id}`, data)
          .then(result => {
            resolve(result.data && result.data.material)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    deleteDetail({ dispatch }, { detail_id, material_id }) {
      return new Promise((resolve, reject) => {
        api
          .delete(`/detail/${detail_id}`)
          .then(result => {
            // commit('getDetails', null)
            resolve(result)
            dispatch('getMaterialDetails', { material_id })
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    copyMaterialDetail({ dispatch }, { detail_id, material_id }) {
      return new Promise((resolve, reject) => {
        api
          .post(`/detail/${detail_id}/replicate`)
          .then(result => {
            dispatch('getMaterialDetails', { material_id })
            resolve(result)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    // DETAIL EDGES

    getDetailEdges({ commit }, { detail_id }) {
      return new Promise((resolve, reject) => {
        api
          .get(`/detail/${detail_id}/edge`)
          .then(result => {
            resolve(result)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    updateDetailEdge({ commit }, { type_edge, detail_id, material_id }) {
      return new Promise((resolve, reject) => {
        api
          .post(`/detail/${detail_id}/edge`, {
            type_edge,
            material_id,
          })
          .then(result => {
            resolve(result)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    removeDetailEdge({ commit }, { type_edge, detail_id }) {
      return new Promise((resolve, reject) => {
        api
          .delete(`/detail/${detail_id}/edge`, {
            data: {
              type_edge,
            },
          })
          .then(result => {
            resolve(result)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    // CUT MAP

    getMaterialCuttingEdges({ commit }, { material_id }) {
      return new Promise((resolve, reject) => {
        api
          .get(`/material/${material_id}/cutting`)
          .then(result => {
            commit('getMaterialCuttingEdges', result.data)
            resolve(result)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    getMaterialCutMap({ commit }, { order }) {
      return new Promise((resolve, reject) => {
        api
          .get('order/cutting/' + order)
          .then(result => {
            commit('getMaterialCutMap', result.data)
            resolve(result)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    updateMaterialCutMap({ commit }, { order, productIdCutMap }) {
      return new Promise((resolve, reject) => {
        api
          .put('order/' + order.id, {
            product_id_cut_map: productIdCutMap,
          })
          .then(result => {
            commit('updateOrder', result.data.order)
            resolve(result)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    // CARVES

    getCarves({ commit }, { orderDetail }) {
      return new Promise((resolve, reject) => {
        api
          .get('order/detail/' + orderDetail + '/carve')
          .then(result => {
            commit('updateCarve', result.data.data)
            resolve(result)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    updateCarves({ commit }, { orderDetail, item }) {
      return new Promise((resolve, reject) => {
        api
          .put('order/detail/' + orderDetail + '/carve/' + item.idCarve, item)
          .then(result => {
            commit('updateCarve', result.data.data)
            resolve(result)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    deleteCarve({ dispatch }, { orderDetail, idCarve }) {
      return new Promise((resolve, reject) => {
        api
          .delete('order/detail/' + orderDetail + '/carve/' + idCarve)
          .then(result => {
            // commit('getDetails', null)
            resolve(result)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    saveDeliveryInfo({ dispatch }, { orderId, data }) {
      return new Promise((resolve, reject) => {
        api
          .post('order/' + orderId + '/shipping', JSON.stringify(data))
          .then(result => {
            resolve(result)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    getTools({ commit }) {
      return new Promise((resolve, reject) => {
        api
          .get('tools')
          .then(result => {
            commit('getTools', result.data.data)
            resolve(result)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    // CALCULATIONS
    getCalculations({ commit }, orderId) {
      return new Promise((resolve, reject) => {
        api
          .get('order/' + orderId + '/calculations')
          .then(result => {
            commit('getCalculations', result.data.data)
            resolve(result)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    // Furniture
    getFurnitureOperations({ commit }, orderId) {
      return new Promise((resolve, reject) => {
        api
          .get('order/' + orderId + '/cutting/furnitureOperations')
          .then(result => {
            commit(
              'getFurnitureOperations',
              result.data.data && result.data.data.operations
            )
            resolve(result)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
  },
}
