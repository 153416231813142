<template>
  <div id="app" data-app class="page-wrapper">
    <Header />
    <main class="page-main">
      <div class="page-head">
        <div class="container container-xxl">
          <div class="page-head__inner">
            <div class="page-head__link"><a href="#!" @click.prevent="$router.go(-1)">/ повернутися</a></div>
            <div class="page-head__title">Нове замовлення</div>
            <div class="page-head__empty"></div>
          </div>
        </div>
      </div>
      <div class="page-content">
        <MediaQueryProvider :queries="$options.queries" wrapperTag="div">
          <div class="container container-xxl">
            <router-view />
          </div>
        </MediaQueryProvider>
      </div>
    </main>
    <Footer />
    <DialogManager />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { MediaQueryProvider } from 'vue-component-media-queries'
import DialogManager from '@/components/dialogs/DialogManager.vue'
import Header from '@/components/Header'
import Footer from '@/components/Footer'

export default {
  name: 'App',
  queries: {
    xs: '(max-width: 575px)',
    sm: '(min-width: 576px) and (max-width: 767px)',
    md: '(min-width: 768px) and (max-width: 991px)',
    lg: '(min-width: 992px) and (max-width: 1199px)',
    xl: '(min-width: 1200px) and (max-width: 1399px)',
    xxl: '(min-width: 1400px)',
  },
  components: {
    MediaQueryProvider,
    DialogManager,
    Header,
    Footer,
  },
  data: () => ({
    status: 'loading',
  }),
  computed: {
    token() {
      const matches = window.location.search.match(/token=([^=]+)/)
      return matches && matches[1]
    },
    ...mapGetters({
      isAuthenticated: 'auth/isAuthenticated',
    }),
  },
  async created() {
    if (this.$store.getters['auth/isAuthenticated']) {
      await this.$store.dispatch('auth/getProfile') // load user data on page reload
    }
    this.status = 'success'
  },
}
</script>

<style lang="scss"></style>
