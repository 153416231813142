<template>
  <Materials :orderId="Number($route.params.orderId)" />
</template>

<script>
import Materials from '@/components/order/Materials.vue'

export default {
  name: 'OrderMaterials',
  components: {
    Materials,
  },
  data: () => ({}),
  computed: {},
  created() {},
}
</script>

<style lang="scss"></style>
