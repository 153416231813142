<template>
  <div class="text-error mx-3">
    <h4>404</h4>
    <h6>{{ $t('404.page_not_found') }}</h6>
    <p>
      {{ $t('404.can_be_wrong_link') }} <br class="d-block d-md-none" />
      <a href="/"> {{ $t('404.to_main_page') }}. </a>
    </p>
  </div>
</template>

<script>
export default {
  name: 'NotFound',
  components: {},
  created() {
    // this.$Progress.finish()
    this.$root.$emit('stopPreloader')
  },
}
</script>
<style lang="scss" scoped></style>
