<template>
  <div class="table-nav">
    <div
      class="drop --arrow js-page-size"
      :class="{ _active: isPerPageOpen }"
      v-click-outside="
        () => {
          isPerPageOpen = false
        }
      "
    >
      <button
        class="form-control drop-button"
        type="button"
        @click="isPerPageOpen = !isPerPageOpen"
      >
        {{ perPage }}
      </button>
      <div
        class="drop-box"
        :style="{ display: isPerPageOpen ? 'block' : 'none' }"
      >
        <div class="drop-overflow">
          <ul class="drop-list">
            <li
              class="drop-list-item"
              v-for="item in perPageOptions"
              :key="item"
              @click="
                $emit('perPageChanged', item)
                isPerPageOpen = false
              "
            >
              {{ item }}
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div id="table-nav" class="footable-paging-external footable-paging-right">
      <div class="footable-pagination-wrapper">
        <ul class="pagination">
          <li class="footable-page-nav disabled" data-page="prev">
            <a
              class="footable-page-link"
              href="#"
              @click.prevent="$emit('input', Math.max(1, value - 1))"
              >‹</a
            >
          </li>
          <li class="footable-page-nav" data-page="next">
            <a
              class="footable-page-link"
              href="#"
              @click.prevent="$emit('input', Math.min(length, value + 1))"
              >›</a
            >
          </li>
        </ul>
        <div class="divider"></div>
        <span class="label label-default">{{ value }} / {{ length }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Pagination',
  components: {},
  props: ['value', 'length', 'perPage'],

  data: () => ({
    perPageOptions: [10, 20, 30, 40],
    isPerPageOpen: false,
  }),

  methods: {
    toggle() {},
  },
}
</script>

<style lang="scss"></style>
