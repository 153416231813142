import paper, { Project, Group, Item, Point, Size } from 'paper'

export default class Shift {
  center: InstanceType<typeof Point> | null
  size: InstanceType<typeof Size> | null

  constructor() {
    this.center = null
    this.size = null
  }

  isEmpty(): boolean {
    return !this.size || !this.center
  }

  updateState({ project }) {
    const textureGroup = project.getItem({
      data: {
        is: 'texture_direction',
      },
    })
    if (!textureGroup) {
      return
    }
    const { center, size } = textureGroup.bounds
    this.center = center
    this.size = size
  }

  fixCenter({ view, project }) {
    // Размер изображения меняется, если меняются метрики, а точка зума остается той же,
    // потому и прыгает. Если просто поменять размер, например сверла в отверствиях,
    // то прыгать не будет. Здесь м делаем коррекцию значения paper.paperScope.view.center,
    // чтобы центр детали оставался на месте при перерисовке
    const textureGroup = project.getItem({
      data: {
        is: 'texture_direction',
      },
    })
    if (!textureGroup) {
      return
    }
    const { center, size } = textureGroup.bounds
    if (this.center && !this.center.equals(center)) {
      // поправляем центр
      // console.log('поправляем центр', this.center, center)
      const vector = this.center.subtract(center)
      view.translate(vector)
    }
    if (this.size && !this.size.equals(size)) {
      // поправляем зум
      // console.log('поправляем зум', this.size, size)
      const scaleFactor = this.size.divide(size).width
      view.scale(scaleFactor, center)
    }
    this.center = center
    this.size = size
  }
}
