import { Point } from 'paper'

export function querySide(side: string, query: string): string {
  const queries = {
    top: {
      prev: 'left',
      next: 'right',
      opposite: 'bottom',
    },
    right: {
      prev: 'top',
      next: 'bottom',
      opposite: 'left',
    },
    bottom: {
      prev: 'right',
      next: 'left',
      opposite: 'top',
    },
    left: {
      prev: 'bottom',
      next: 'top',
      opposite: 'right',
    },
  }
  return queries[side][query]
}
export function getOpposite(side: string): string {
  return querySide(side, 'prev')
}
export function getOpposites(side: string): Array<string> {
  return [querySide(side, 'prev'), querySide(side, 'next')]
}
export function getSideOfCorner(corner: string): string {
  const cornersMap = {
    top_left: 'top',
    top_right: 'right',
    bottom_right: 'bottom',
    bottom_left: 'left',
  }
  return cornersMap[corner]
}
