<template>
  <div class="dialog-wrap" :class="{ open: activeDialog }" id="dialogs">
    <div class="overllay" @click="close"></div>
    <div
      class="modal fade"
      :class="{ show: activeDialog }"
      :style="{ display: activeDialog ? 'block' : 'none' }"
    >
      <div class="modal-dialog modal-dialog-centered">
        <component :is="activeDialog" :options="options" v-bind="props" />
      </div>
    </div>
  </div>
</template>

<script>
import AskDialog from '@/components/dialogs/AskDialog'
import FileImportDialog from '@/components/dialogs/FileImportDialog'

export default {
  name: 'DialogsWrap',
  components: {
    AskDialog,
    FileImportDialog,
  },
  data: () => ({
    activeDialog: '',
    // props: ['material_id'],
    props: [],
    options: {},
  }),
  created() {
    this.$root.$on('openDialog', (name, props = {}, options = {}) => {
      // debugger
      this.activeDialog = name
      this.props = props
      this.options = options
    })
    this.$root.$on('closeDialog', (isCloseForce = false) => {
      if (isCloseForce) {
        this.activeDialog = ''
        this.props = {}
      } else {
        this.close()
      }
    })
  },
  methods: {
    close() {
      if (this.options.redirect) {
        this.activeDialog = this.options.redirect
      } else {
        this.activeDialog = ''
        this.props = {}
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.dialog-wrap {
  position: fixed;
  display: none;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 100;
  justify-content: center;
  align-items: center;
  &.open {
    display: flex;
  }
  .overllay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
    background: rgba(0, 0, 0, 0.6);
    cursor: pointer;
  }

  .modal-wrap {
    position: relative;
    z-index: 101;
    @media (max-width: 767px) {
      width: 100%;
    }
  }
}
</style>
