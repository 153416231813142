<template>
  <fragment>
    <MatchMedia v-slot="{ xs, sm, md, lg }" wrapperTag="tr">
      <td :class="{ 'footable-last-visible': xs || sm }">
        <span
          class="footable-toggle fooicon last-column"
          :class="isOpen ? 'fooicon-minus' : 'fooicon-plus'"
          @click="isOpen = !isOpen"
          v-if="xs || sm"
        ></span>
        <span>{{ item.name }}</span>
      </td>
      <td class="d-none d-md-table-cell">
        <span>{{ item.article }}</span>
      </td>
      <td class="d-none d-md-table-cell">
        <span>{{ Number(item.height) }}/{{ Number(item.thickness) }}</span>
      </td>
      <td
        class="d-none d-md-table-cell"
        :class="{ 'footable-last-visible': md || lg }"
      >
        <span
          class="footable-toggle fooicon last-column"
          :class="isOpen ? 'fooicon-minus' : 'fooicon-plus'"
          @click="isOpen = !isOpen"
          v-if="md || lg"
        ></span>
        <span class="text-nowrap">{{ item.price.toFixed(2) || 0 }} ₴</span>
      </td>
      <td class="d-none d-xl-table-cell">
        <span
          ><span class="text-nowrap"
            >{{ (item.price * item.quantity).toFixed(2) }} ₴</span
          ></span
        >
      </td>
      <td class="d-none d-xl-table-cell">
        <div
          class="btn-status"
          :class="
            item.stock !== 0 && item.stock >= item.quantity
              ? 'confirmed'
              : 'new'
          "
        >
          <span class="circle"></span
          ><span>
            {{
              item.stock !== 0 && item.stock >= item.quantity
                ? $i18n.t('product.available')
                : $i18n.t('product.not_available')
            }}
          </span>
        </div>
      </td>
      <td class="w-1 d-none d-xl-table-cell">
        <button
          class="btn btn-icon ico_trash"
          type="button"
          @click="$emit('remove')"
        ></button>
      </td>
    </MatchMedia>
    <MatchMedia v-slot="{ xs, sm, md, lg }" :wrapperTag="null" v-show="isOpen">
      <tr class="footable-detail-row">
        <td :colspan="xs || sm ? 1 : 5">
          <table class="footable-details">
            <tbody>
              <tr class="d-table-row d-md-none">
                <th>Артикул</th>
                <td>
                  <span>{{ item.article }}</span>
                </td>
              </tr>
              <tr class="d-table-row d-md-none">
                <th>Розмір</th>
                <td>
                  <span
                    >{{ Number(item.height) }}/{{
                      Number(item.thickness)
                    }}</span
                  >
                </td>
              </tr>
              <tr class="d-table-row d-md-none">
                <th>Ціна</th>
                <td>
                  <span>{{ item.price.toFixed(2) || 0 }} ₴</span>
                </td>
              </tr>
              <tr>
                <th>Сума</th>
                <td>
                  <span>{{ (item.price * item.quantity).toFixed(2) }} ₴</span>
                </td>
              </tr>
              <tr>
                <th>Наявність</th>
                <td>
                  <div
                    class="btn-status"
                    :class="
                      item.stock !== 0 && item.stock >= item.quantity
                        ? 'confirmed'
                        : 'new'
                    "
                  >
                    <span class="circle"></span
                    ><span>
                      {{
                        item.stock !== 0 && item.stock >= item.quantity
                          ? $i18n.t('product.available')
                          : $i18n.t('product.not_available')
                      }}
                    </span>
                  </div>
                </td>
              </tr>
              <tr>
                <th></th>
                <td>
                  <button
                    class="btn btn-icon ico_trash"
                    type="button"
                    @click="$emit('remove')"
                  ></button>
                </td>
              </tr>
            </tbody>
          </table>
        </td>
      </tr>
    </MatchMedia>
  </fragment>
</template>

<script>
import { Fragment } from 'vue-frag'
import { MatchMedia } from 'vue-component-media-queries'

export default {
  name: 'GoodsTableRow',
  components: { Fragment, MatchMedia },
  props: ['item', 'index', 'orderId'],
  data: () => ({
    isOpen: false,
  }),

  methods: {
    toggle() {
      this.isOpen = !this.isOpen
    },
  },
}
</script>

<style lang="scss"></style>
