<template>
  <div class="modal-content">
    <div class="modal-header">
      <h5 class="modal-title">
        {{ title }}
      </h5>
      <button class="btn-close" type="button" @click="deny"></button>
    </div>
    <div class="modal-body">
      <form action="#!">
        <div v-if="subtitle" class="form-group">
          <p>{{ subtitle }}</p>
        </div>
        <div class="row">
          <div class="col-6" v-if="showAccept">
            <button class="btn btn-primary w-100" type="button" @click="accept">
              {{ acceptTxt }}
            </button>
          </div>
          <div class="col-6" v-if="showOther">
            <button class="btn btn-primary w-100" type="button" @click="other">
              {{ otherText }}
            </button>
          </div>
          <div class="col-6" v-if="showDeny">
            <button class="btn btn-secondary w-100" type="button" @click="deny">
              {{ denyTxt }}
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AskDialog',
  props: {
    acceptAction: Function,
    otherAction: Function,
    cancelAction: Function,
    title: String,
    subtitle: String,
    acceptText: {
      type: String,
    },
    otherText: {
      type: String,
    },
    denyText: {
      type: String,
    },
    showAccept: {
      type: Boolean,
      default: true,
    },
    showOther: {
      type: Boolean,
      default: false,
    },
    showDeny: {
      type: Boolean,
      default: true,
    },
  },
  components: {},
  computed: {
    acceptTxt() {
      return this.acceptText || this.$i18n.t('yes')
    },
    denyTxt() {
      return this.denyText || this.$i18n.t('no')
    },
  },
  methods: {
    accept() {
      this.acceptAction && this.acceptAction()
      this.$root.$emit('closeDialog')
    },
    other() {
      this.otherAction && this.otherAction()
      this.$root.$emit('closeDialog')
    },
    deny() {
      this.cancelAction && this.cancelAction()
      this.$root.$emit('closeDialog')
    },
  },
}
</script>

<style lang="scss"></style>
