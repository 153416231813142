<template>
  <div class="constructor-form-group">
    <label class="constructor-checkbox-field"
      ><span>{{ label }}</span>
      <div class="check">
        <input
          class="check__input"
          type="checkbox"
          v-model="value"
          @change="$emit('input', $event.target.checked)"
          ref="checkbox"
        />
        <div class="check__box" :class="{ 'is-invalid': error }"></div>
      </div>
      <div @click.stop.prevent=""></div>
    </label>
  </div>
</template>

<style scoped lang="scss">
.check ~ div {
  flex: 1;
  min-height: 25px;
  height: 100%;
}
</style>

<script>
export default {
  name: 'CheckInput',
  props: ['value', 'label', 'error'],
  methods: {},
}
</script>
