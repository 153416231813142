<template>
  <div>
    <BtnToggleInput
      v-model="value.diameter"
      :label="$t('fields.diameter')"
      :options="[
        { value: 26, text: '26 mm' },
        { value: 35, text: '35 mm' },
      ]"
      @input="handleDiameterInput"
    />
    <SelectInput
      v-model="value.number_of_loops"
      :label="$t('fields.hinge35.number_of_loops')"
      :options="[1, 2, 3, 4, 5, 6].map(i => ({ value: i, text: i }))"
      @input="$emit('input', value)"
    />
    <div class="constructor-form-group">
      <label class="constructor-text-field"
        ><span>{{
          $t('fields.hinge35.distance_from_corner_to_center_of_bowl_mm')
        }}</span
        ><input
          type="number"
          placeholder="0"
          onclick="this.select();"
          v-model.number="value.distance_from_corner_to_center_of_bowl_mm"
          @input="$emit('input', value)"
      /></label>
    </div>
    <CheckInput
      :label="$t('fields.hinge35.distances_are_equal')"
      v-model="value.distances_are_equal"
      @input="$emit('input', value)"
    />
    <template v-if="!value.distances_are_equal">
      <div
        v-for="(field, index) in fields.slice(0, value.number_of_loops)"
        :key="field"
        class="constructor-form-group"
      >
        <label class="constructor-text-field"
          ><span>{{ $t('fields.offsets.off_' + (index + 1)) }}</span
          ><input
            type="number"
            placeholder="0"
            onclick="this.select();"
            v-model.number="value[field]"
            @input="$emit('input', value)"
        /></label>
      </div>
    </template>
  </div>
</template>

<script>
import CheckInput from '@/components/dateylor/construction/inputs/CheckInput'
import SelectInput from '@/components/dateylor/construction/inputs/SelectInput'
import BtnToggleInput from '@/components/dateylor/construction/inputs/BtnToggleInput'

export default {
  name: 'Hinge35Input',
  components: {
    CheckInput,
    SelectInput,
    BtnToggleInput,
  },
  props: {
    value: {
      type: Object,
      default: function() {
        return {}
      },
    },
  },
  data() {
    return {
      fields: ['off_start', 'off_end', 'off_3', 'off_4', 'off_5', 'off_6'],
    }
  },
  computed: {},
  methods: {
    handleDiameterInput(diameter) {
      const diameterDistances = {
        26: 17,
        35: 22,
      }
      this.value.distance_from_corner_to_center_of_bowl_mm =
        diameterDistances[diameter]
      this.$emit('input', this.value)
    },
  },
}
</script>

<style scoped lang="scss"></style>
