<template>
  <div class="constructor-form-group" :class="{ wide: isWide }">
    <label class="constructor-select-field" tabindex="0"
      ><span>{{ label }}</span>
      <div class="dropdown" style="position: static" ref="drop">
        <button
          class="dropdown-toggle"
          :class="{ 'is-invalid': error, show: isOpen }"
          type="button"
          :aria-expanded="String(isOpen)"
          @click="isOpen = true"
          v-click-outside="
            () => {
              isOpen = false
            }
          "
          :data-bs-original-title="isWide ? text : null"
          v-tooltip
        >
          {{ text }}
        </button>
        <ul class="dropdown-menu" :class="{ show: isOpen }">
          <li
            v-for="(option, index) in options"
            :key="index"
            @click.prevent="handleSelect(option[itemValue])"
          >
            {{ option[itemText] }}
          </li>
        </ul>
      </div>
    </label>
  </div>
</template>

<style scoped lang="scss">
.wide {
  .dropdown-menu {
    right: 15px;
    min-width: 208px;
  }
}
</style>

<script>
export default {
  name: 'SelectInput',
  props: {
    value: {},
    label: String,
    options: { type: Array, default: () => [] },
    itemText: { type: String, default: 'text' },
    itemValue: { type: String, default: 'value' },
    returnObject: { type: Boolean, default: false },
    error: Boolean,
    isWide: Boolean,
  },
  data() {
    return {
      isOpen: false,
    }
  },
  computed: {
    text() {
      return this.selectedOption && this.selectedOption[this.itemText]
    },
    selectedOption() {
      return this.options.find(option => option[this.itemValue] == this.value)
    },
  },
  methods: {
    handleSelect(val) {
      this.$emit('input', val)
      this.isOpen = false
    },
  },
}
</script>
