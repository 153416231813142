<template>
  <MatchMedia v-slot="{ xs, sm, md, lg }">
    <fragment>
      <tr :data-id="item.id">
        <td>
          <div class="btn btn-icon ico_move"></div>
        </td>
        <td :class="{ 'footable-last-visible': xs || sm }">
          <span
            class="footable-toggle fooicon last-column"
            :class="isOpen ? 'fooicon-minus' : 'fooicon-plus'"
            @click="toggle()"
            v-if="xs || sm"
          ></span>
          <span> {{ materialIndex + 1 }}-{{ item.position }}</span>
        </td>
        <td class="d-none d-md-table-cell">
          <div class="inputs">
            <div>
              <div
                class="text-length text-validate"
                :class="{
                  error: touched.l && !isLengthValid,
                }"
              >
                <input
                  placeholder="Довжина"
                  type="text"
                  name="length"
                  class="input-length form-control"
                  v-model="item.length"
                  ref="widthInp"
                  @focus="$event.target.select()"
                  @blur="touched.l = true"
                  @change="
                    item.width
                      ? updateDetails(
                          item,
                          item.is_grooves || item.is_curves || item.is_milling
                        )
                      : planUpdateDetails(item)
                  "
                />
                <div class="text-error">
                  Число должно находиться в диапазоне от
                  {{ minLength }} до {{ maxLength }}
                </div>
              </div>
            </div>
            <div>
              <div
                class="text-width text-validate"
                :class="{
                  error: touched.w && !isWidthValid,
                }"
              >
                <input
                  placeholder="Ширина"
                  type="text"
                  name="width"
                  class="input-width form-control"
                  v-model="item.width"
                  @focus="
                    $event.target.select()
                    cancelUpdateDetails()
                  "
                  @blur="touched.w = true"
                  @change="
                    updateDetails(
                      item,
                      item.is_grooves || item.is_curves || item.is_milling
                    )
                  "
                />
                <div class="text-error">
                  Число должно находиться в диапазоне от
                  {{ minWidth }} до {{ maxWidth }}.
                </div>
              </div>
            </div>
            <div>
              <div
                class="text-amount text-validate"
                :class="{
                  error: touched.q && !isQuantityValid,
                }"
              >
                <input
                  placeholder="Кількість"
                  type="text"
                  name="quantity"
                  class="input-amount form-control"
                  v-model="item.quantity"
                  @focus="$event.target.select()"
                  @blur="touched.q = true"
                  @change="updateDetails(item)"
                />
                <div class="text-error">
                  Число должно находится в диапазоне от
                  {{ minQuantity }} до {{ maxQuantity }}
                </div>
              </div>
            </div>
          </div>
        </td>

        <DetailEnds
          :detail="item"
          :orderId="order.id"
          :material_id="material.id"
          :defaultKromka="defaultKromka"
          @no-default-kromka="
            $toast.warning('Виберіть крайку по замовчуванню')
            $refs.defaultKromka.click()
          "
          firstClass="d-none d-md-table-cell"
          :secondClass="{
            'd-none d-md-table-cell': true,
            'footable-last-visible': md || lg,
          }"
        >
          <span
            class="footable-toggle fooicon last-column"
            :class="isOpen ? 'fooicon-minus' : 'fooicon-plus'"
            @click="toggle()"
            v-if="md || lg"
          ></span>
        </DetailEnds>

        <td class="text-center d-none d-xl-table-cell">
          <label class="check"
            ><input
              v-model="item.is_texture_set"
              class="check__input"
              type="checkbox"
              tabindex="-1"
              @change="
                updateDetails(
                  item,
                  item.is_grooves || item.is_curves || item.is_milling,
                  $event.target.checked
                )
              "/><span class="check__box me-0"></span
          ></label>
        </td>
        <td class="d-none d-xl-table-cell">
          <Dropdown
            class="drop --select --arrow"
            v-slot:default="{ show, hide, isOpen }"
          >
            <button
              class="form-control drop-button"
              :class="{
                'opacity-50': !material.thickness_changeable,
              }"
              type="button"
              tabindex="-1"
              @click="show()"
              :disabled="!material.thickness_changeable"
            >
              {{ item.thickness }}
            </button>
            <div
              class="drop-box"
              :style="{ display: isOpen ? 'block' : 'none' }"
            >
              <div class="drop-overflow">
                <ul class="drop-list">
                  <li
                    class="drop-list-item"
                    v-for="(thicknessItem,
                    thicknessIndex) in material.thickness_changeable
                      ? [1 + 'x', 2 + 'x', 3 + 'x']
                      : [1 + 'x']"
                    :key="thicknessIndex"
                    @click="
                      changeThickness(item, thicknessItem)
                      hide()
                    "
                  >
                    {{ thicknessItem }}
                  </li>
                </ul>
              </div>
            </div>
          </Dropdown>
        </td>
        <td class="d-none d-xl-table-cell">
          <div
            class="text-name text-validate"
            :class="{
              error: item.name !== null && item.name.length > 15,
            }"
          >
            <input
              placeholder="Назва"
              type="text"
              @focus="$event.target.select()"
              name="name"
              class="input-name form-control"
              v-model="item.name"
              :id="`${item.id}-1`"
              tabindex="-1"
              @change="updateDetails(item)"
            />
            <div class="text-error">
              Количество вводимых знаков не должно превышать 15
            </div>
          </div>
        </td>
        <td class="d-none d-xl-table-cell">
          <div class="btns">
            <div>
              <button
                class="btn btn-icon ico_setting"
                tabindex="-1"
                @click="toDateylor(item)"
              ></button>
            </div>
            <div>
              <button
                class="js-add-row btn btn-icon ico_copy"
                type="button"
                tabindex="-1"
                @click="copyItem(item)"
              ></button>
            </div>
            <div>
              <button
                class="btn btn-icon ico_trash"
                type="button"
                tabindex="-1"
                @click="deleteItem(item)"
              ></button>
            </div>
          </div>
        </td>
      </tr>
      <tr class="footable-detail-row" v-if="isOpen">
        <td :colspan="xs || sm ? 2 : 5">
          <table class="footable-details">
            <tbody>
              <tr class="d-table-row d-md-none">
                <th>Розмір</th>
                <td>
                  <div class="inputs">
                    <div>
                      <div
                        class="text-length text-validate"
                        :class="{
                          error: touched.l && !isLengthValid,
                        }"
                      >
                        <input
                          placeholder="Довжина"
                          type="text"
                          name="length"
                          class="input-length form-control"
                          v-model="item.length"
                          ref="widthInp"
                          @focus="$event.target.select()"
                          @blur="touched.l = true"
                          @change="
                            item.width
                              ? updateDetails(
                                  item,
                                  item.is_grooves ||
                                    item.is_curves ||
                                    item.is_milling
                                )
                              : planUpdateDetails(item)
                          "
                        />
                        <div class="text-error">
                          Число должно находиться в диапазоне от
                          {{ minLength }} до {{ maxLength }}
                        </div>
                      </div>
                    </div>
                    <div>
                      <div
                        class="text-width text-validate"
                        :class="{
                          error: touched.w && !isWidthValid,
                        }"
                      >
                        <input
                          placeholder="Ширина"
                          type="text"
                          name="width"
                          class="input-width form-control"
                          v-model="item.width"
                          @focus="
                            $event.target.select()
                            cancelUpdateDetails()
                          "
                          @blur="touched.w = true"
                          @change="
                            updateDetails(
                              item,
                              item.is_grooves ||
                                item.is_curves ||
                                item.is_milling
                            )
                          "
                        />
                        <div class="text-error">
                          Число должно находиться в диапазоне от
                          {{ minWidth }} до {{ maxWidth }}.
                        </div>
                      </div>
                    </div>
                    <div>
                      <div
                        class="text-amount text-validate"
                        :class="{
                          error: touched.q && !isQuantityValid,
                        }"
                      >
                        <input
                          placeholder="Кількість"
                          type="text"
                          name="quantity"
                          class="input-amount form-control"
                          v-model="item.quantity"
                          data-id="4"
                          @focus="$event.target.select()"
                          @blur="touched.q = true"
                          @change="updateDetails(item)"
                        />
                        <div class="text-error">
                          Число должно находится в диапазоне от
                          {{ minQuantity }} до {{ maxQuantity }}
                        </div>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
              <tr class="d-table-row d-md-none">
                <th>Кромки</th>
                <td>
                  <div class="d-flex justify-content-end">
                    <DetailEnds
                      :detail="item"
                      :orderId="order.id"
                      :material_id="material.id"
                      :detailId="item.id"
                      :defaultKromka="defaultKromka"
                      wrappedTag="div"
                      firstClass="me-2"
                      secondClass="ms-1"
                      @no-default-kromka="
                        $toast.warning('Виберіть крайку по замовчуванню')
                        $refs.defaultKromka.click()
                      "
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <th>Структура</th>
                <td>
                  <label class="check"
                    ><input
                      v-model="item.is_texture_set"
                      class="check__input"
                      type="checkbox"
                      tabindex="-1"
                      @change="
                        updateDetails(
                          item,
                          item.is_grooves || item.is_curves || item.is_milling,
                          $event.target.checked
                        )
                      "/><span class="check__box me-0"></span
                  ></label>
                </td>
              </tr>
              <tr>
                <th>Зшивка</th>
                <td>
                  <Dropdown
                    class="drop --select --arrow"
                    v-slot:default="{ show, hide, isOpen }"
                  >
                    <button
                      class="form-control drop-button"
                      :class="{
                        'opacity-50': !material.thickness_changeable,
                      }"
                      type="button"
                      tabindex="-1"
                      @click="show()"
                      :disabled="!material.thickness_changeable"
                    >
                      {{ item.thickness }}
                    </button>
                    <div
                      class="drop-box"
                      :style="{
                        display: isOpen ? 'block' : 'none',
                      }"
                    >
                      <div class="drop-overflow">
                        <ul class="drop-list">
                          <li
                            class="drop-list-item"
                            v-for="(thicknessItem,
                            thicknessIndex) in material.thickness_changeable
                              ? [1 + 'x', 2 + 'x', 3 + 'x']
                              : [1 + 'x']"
                            :key="thicknessIndex"
                            @click="
                              changeThickness(item, thicknessItem)
                              hide()
                            "
                          >
                            {{ thicknessItem }}
                          </li>
                        </ul>
                      </div>
                    </div>
                  </Dropdown>
                </td>
              </tr>
              <tr>
                <th>Назва</th>
                <td>
                  <div
                    class="text-name text-validate"
                    :class="{
                      error: item.name !== null && item.name.length > 15,
                    }"
                  >
                    <input
                      placeholder="Назва"
                      type="text"
                      @focus="$event.target.select()"
                      name="name"
                      class="input-name form-control"
                      v-model="item.name"
                      :id="`${item.id}-1`"
                      data-id="1"
                      tabindex="-1"
                      autocomplete
                      @change="updateDetails(item)"
                    />
                    <div class="text-error">
                      Количество вводимых знаков не должно превышать 15
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <th></th>
                <td>
                  <div class="btns">
                    <div>
                      <button
                        class="btn btn-icon ico_setting"
                        tabindex="-1"
                        @click="toDateylor(item)"
                      ></button>
                    </div>
                    <div>
                      <button
                        class="js-add-row btn btn-icon ico_copy"
                        type="button"
                        tabindex="-1"
                        @click="copyItem(item)"
                      ></button>
                    </div>
                    <div>
                      <button
                        class="btn btn-icon ico_trash"
                        type="button"
                        tabindex="-1"
                        @click="deleteItem(item)"
                      ></button>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </td>
      </tr>
    </fragment>
  </MatchMedia>
</template>

<script>
import { Fragment } from 'vue-frag'
import { MatchMedia } from 'vue-component-media-queries'
import DetailEnds from '@/components/order/DetailEnds.vue'

export default {
  name: 'DetailsTableRow.vue',
  components: { Fragment, MatchMedia, DetailEnds },
  props: {
    activeClass: {
      type: String,
      default: '_active',
    },
    item: Object,
    material: Object,
    order: Object,
    defaultKromka: Object,
    anyDetailHasDefaultKromka: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    isOpen: false,
    // planned detail update tomeout id
    timeoutId: null,
    touched: {
      l: false,
      w: false,
      q: false,
    },
  }),

  computed: {
    materialIndex() {
      return (this.$store.getters['order/materials'] || []).findIndex(
        ({ id }) => id == this.material.id
      )
    },
    delta() {
      return 15
    },
    minLength() {
      return 50
    },
    minWidth() {
      return 50
    },
    maxLength() {
      return Number(this.material.width) - this.delta
    },
    maxWidth() {
      return Number(this.material.height) - this.delta
    },
    minQuantity() {
      return 0
    },
    maxQuantity() {
      return 300
    },
    isLengthValid() {
      const val = Number(this.item.length)
      return val && val >= this.minLength && val < this.maxLength
    },
    isWidthValid() {
      const val = Number(this.item.width)
      return (
        val &&
        val >= this.minWidth &&
        val < Number(this.material.width) - this.delta
      )
    },
    isQuantityValid() {
      const val = Number(this.item.quantity)
      return val >= this.minQuantity && val <= this.maxQuantity
    },
  },

  methods: {
    show() {
      this.isOpen = true
    },
    hide() {
      this.isOpen = false
    },
    toggle() {
      this.isOpen = !this.isOpen
    },
    toDateylor(item) {
      const { isLengthValid, isWidthValid } = this
      if (!isLengthValid || !isWidthValid) {
        this.touched = {
          l: true,
          w: true,
          q: true,
        }
      } else {
        this.$router.push({
          name: 'Dateylor',
          params: {
            orderId: this.order.id,
            materialId: this.material.id,
            detailId: item.id,
          },
        })
      }
    },
    deleteItem(item) {
      this.$root.$emit('openDialog', 'AskDialog', {
        title: 'Ви дійсно хочете видалити деталь?',
        acceptAction: () => {
          // this.$Progress.start()
          // this.$root.$emit("playPreloader")
          this.$store
            .dispatch('order/deleteDetail', {
              detail_id: item.id,
              order_id: this.order.id,
              material_id: this.material.id,
            })
            .then(() => {
              // this.$Progress.finish()
              // this.$root.$emit("stopPreloader")
            })
            .catch(() => {
              // this.$Progress.fail()
              // this.$root.$emit("stopPreloader")
            })
        },
      })
    },
    copyItem(item) {
      // this.items.push({...item, id: this.items.length + 1})
      // this.$Progress.start()
      // this.$root.$emit("playPreloader")
      this.$store
        .dispatch('order/copyMaterialDetail', {
          detail_id: item.id,
          material_id: this.material.id,
        })
        .then(() => {
          // this.selectMaterial(item)
        })
        .then(() => {
          // this.$Progress.finish()
          // this.$root.$emit("stopPreloader")
        })
        .catch(() => {
          // this.$Progress.fail()
          // this.$root.$emit("stopPreloader")
        })
    },
    focusInput() {
      this.$refs.widthInp.focus()
    },
    changeThickness(detail, thickness) {
      const { edges } = this.item
      if (
        edges &&
        edges.length &&
        parseInt(detail.thickness) < parseInt(thickness)
      ) {
        const acceptAction = () => {
          detail.thickness = thickness
          this.updateDetails(detail, false, thickness)
        }
        const cancelAction = () => {}
        this.$root.$emit('openDialog', 'AskDialog', {
          acceptAction,
          cancelAction,
          title: this.$i18n.t('alert.note.attention_change_kromka_deleted'),
          acceptText: this.$i18n.t('yes'),
          denyText: this.$i18n.t('no'),
        })
      } else {
        detail.thickness = thickness
        this.updateDetails(detail, false, true)
      }
    },
    planUpdateDetails(item) {
      this.timeoutId = window.setTimeout(
        () =>
          this.updateDetails(
            item,
            item.is_grooves || item.is_curves || item.is_milling
          ),
        100
      )
    },
    cancelUpdateDetails() {
      if (this.timeoutId) {
        window.clearTimeout(this.timeoutId)
        this.timeoutId = null
      }
    },
    updateDetails(detail, warning = false, $event) {
      // if ($event.checked){
      //   this.detail.texture = $event
      // }
      const acceptAction = () => {
        this.updateDetails(detail, false, $event)
      }
      const cancelAction = () => {
        this.loadData()
      }
      if (warning) {
        this.$root.$emit('openDialog', 'AskDialog', {
          acceptAction,
          cancelAction,
          title: this.$i18n.t('alert.note.attention_change_processing_deleted'),
          acceptText: this.$i18n.t('yes'),
          denyText: this.$i18n.t('no'),
        })
      } else {
        // this.$Progress.start()
        // this.$root.$emit("playPreloader")

        this.$store
          .dispatch('order/updateDetail', {
            detail_id: detail.id,
            data: { ...detail, is_texture_set: $event },
          })
          .then(() => {
            // this.$Progress.finish()
            // this.$root.$emit("stopPreloader")
            this.loadData(false)
          })
          .catch(e => {
            if (e && e.response && e.response.data && e.response.data.errors) {
              for (let field in e.response.data.errors) {
                let messages = e.response.data.errors[field]
                if (Array.isArray(messages))
                  messages.forEach(message => this.$toast.error(message))
              }
            }
            // this.$Progress.fail()
            // this.$root.$emit("stopPreloader")
          })
      }
    },
    loadData() {
      return this.$store.dispatch('order/getMaterialDetails', {
        material_id: this.material.id,
      })
    },
  },
}
</script>

<style lang="scss"></style>
