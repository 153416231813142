<template>
  <transition-group
    name="list-transition"
    tag="div"
    class="constructor-operation-box position-relative"
  >
    <div
      class="operation-item"
      :class="{
        active: item && item.idCarve == itm.idCarve,
        error: !!itemsErrors[itm.idCarve],
      }"
      v-for="(itm, index) in activeItems"
      :key="itm.idCarve"
      tabindex="1"
      @click="$emit('select', itm)"
    >
      <div class="operation-item__numb">{{ index + 1 }}</div>
      <div class="operation-item__ico">
        <span :class="icons[itm.template]"></span>
      </div>
      <div class="operation-item__desc">
        {{ $t('operations.' + itm.template) }},
        {{ getOpLabel(itm) }}
      </div>
      <a
        class="operation-item__del"
        @click.stop="$emit('delete-item', itm)"
      ></a>
    </div>
  </transition-group>
</template>

<script>
import { operationIcons } from '../icons'
import { getOperationLabel } from './operationLabel'

export default {
  name: 'ActiveOperations',
  props: { items: Array, item: Object, itemsErrors: Object },
  data() {
    return {
      icons: operationIcons,
    }
  },
  computed: {
    activeItems() {
      return this.items
        .reduce((result, { item }) => [...result, ...item], [])
        .sort(({ idCarve: id1 }, { idCarve: id2 }) => (id1 < id2 ? 1 : -1))
    },
  },
  methods: {
    getOpLabel(op) {
      return getOperationLabel(op, this)
    },
  },
}
</script>

<style scoped lang="scss">
.operation-item {
  &.active {
    border: 1px solid #628eff;
    .operation-item__desc {
      color: #628eff;
    }
  }
  &.error {
    border: 1px solid #dc3545;
    background: #fdedef;
  }
}
.list-transition-item {
  transition: all 1s;
  display: inline-block;
  margin-right: 10px;
}
.list-transition-enter, .list-transition-leave-to
/* .list-transition-leave-active below version 2.1.8 */ {
  opacity: 0.5;
  transform: translateY(-50px);
}
.list-transition-leave-active {
  position: absolute;
  width: auto;
  left: 0;
  right: 20px;
}
</style>
