<template>
  <footer class="page-footer">
    <div class="container container-xxl">
      <div class="page-footer__inner">
        <div
          data-da=".page-footer__center-top, 1199, 0"
          class="page-footer__left d-none d-xl-block"
        >
          <div class="page-footer__logo">
            <a href="/" class="logo"
              ><img src="/assets/img/logo-footer.svg" alt="logo"
            /></a>
          </div>
          <div class="page-footer__dev">
            <a href="fairtech.group"
              ><span>Developed by</span
              ><img src="/assets/img/logo-fairtech.svg" alt="FairTech"
            /></a>
          </div>
        </div>
        <div class="page-footer__center">
          <div class="page-footer__center-top">
            <div class="page-footer__left d-xl-none">
              <div class="page-footer__logo">
                <a href="/" class="logo"
                  ><img src="/assets/img/logo-footer.svg" alt="logo"
                /></a>
              </div>
              <div class="page-footer__dev">
                <a href="fairtech.group"
                  ><span>Developed by</span
                  ><img src="/assets/img/logo-fairtech.svg" alt="FairTech"
                /></a>
              </div>
            </div>
            <div class="page-footer__group">
              <div class="page-footer__menu">
                <ul>
                  <li><a href="#!">Договір публічної оферти</a></li>
                  <li>
                    <a href="page-privacy-policy.html"
                      >Використання та обробка інформації</a
                    >
                  </li>
                </ul>
              </div>
              <div class="page-footer__menu">
                <ul>
                  <li>
                    <a href="page-payment-delivery.html">Оплата та доставка</a>
                  </li>
                  <li><a href="#!">Залишити скаргу</a></li>
                </ul>
              </div>
            </div>
            <div class="page-footer__group">
              <div class="page-footer__download">
                <a href="/assets/img/exsamle.pdf" target="_blank"
                  ><i class="ico_arrow-down"></i
                  ><span>Презентація послуг</span></a
                >
              </div>
              <div class="page-footer__social">
                <ul class="social">
                  <li class="social__item">
                    <a
                      href="http://www.facebook.com"
                      target="_blank"
                      class="social__link"
                      ><i class="ico_facebook"></i
                    ></a>
                  </li>
                  <li class="social__item">
                    <a
                      href="http://www.instagram.com"
                      target="_blank"
                      class="social__link"
                      ><i class="ico_instagram"></i
                    ></a>
                  </li>
                  <li class="social__item">
                    <a
                      href="http://www.youtube.com"
                      target="_blank"
                      class="social__link"
                      ><i class="ico_youtube"></i
                    ></a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="page-footer__center-bottom">
            <span class="copy">© 2021 Detailor. Всі права захищені</span>
          </div>
        </div>
        <div class="page-footer__right">
          <div class="page-footer__contacts">
            <p>м. Чернівці</p>
            <p class="accent">
              Відділ обслуговування клієнтів з продажу фурнітури і послуг
            </p>
            <p>
              <a href="tel:380962784997">+38 (096) 278-4997</a
              ><a href="mailto:deksinfo@gmail.com">deksinfo@gmail.com</a>
            </p>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'Footer',
  computed: {},
  methods: {},
}
</script>

<style lang="scss"></style>
