import { Group, Path } from 'paper'
import {
  STROKE_WIDTH1,
  STROKE_WIDTH2,
  STYLES,
  DASH_ARRAY,
  DASH_ARRAY2,
  COLORS,
} from '../variables'

export function drawEdgeLine({
  operation,
  side,
  sides,
  property,
  EDGE_HEIGHT,
  EDGE_OFFSET,
  drawings,
}) {
  const {
      offset = 0.75 * EDGE_HEIGHT,
      dashed = true,
      isFace = true,
      validate,
      from = 0,
      to,
    } = operation,
    group = new Group(),
    y = 0 - EDGE_OFFSET - (isFace ? offset : EDGE_HEIGHT - offset)
  const edgeItems = side.getItems({
    data: { isEdge: true },
  })
  if ([-EDGE_OFFSET, -EDGE_OFFSET - EDGE_HEIGHT].includes(y)) {
    // не надо рисовать линию если она на перекрывает границы рисунка торца
    return
  }
  edgeItems.forEach(edge => {
    // edge.selected = true
    if (edge.bounds.width < 1) return
    const g = new Group(
      edge.clone(),
      new Path.Line({
        from: [from, y],
        to: [to || edge.bounds.topRight.x, y],
        strokeWidth: STROKE_WIDTH2,
        strokeColor: STYLES.pathColor,
        // selected: true,
      })
    )
    // g.selected = true
    g.clipped = true
    group.addChild(g)
  })
  group.style = {
    strokeWidth: STROKE_WIDTH2,
    strokeColor: STYLES.pathColor,
  }
  if (dashed) {
    group.style.dashArray = DASH_ARRAY2
    group.strokeWidth = STROKE_WIDTH1
  }
  side.addChild(group)
  if (validate) {
    drawings.add(operation.idCarve, null, null, group)
  }
}
