import {
  paper,
  Point,
  Path,
  PointText,
  Group,
  Size,
  Rectangle,
  CompoundPath,
  Segment,
  Color,
} from 'paper'
import Zoom from './Zoom'
import { drawArrow } from './arrows'
import { buildEmptyHoverDrawings, toggleHoverEvents } from './hoverEvents'
import { toggleClickEvents } from './clickEvents'
import {
  radToDeg,
  degToRad,
  calculateRadiusOuter,
  calculateLineCorner,
  calculateArcSide,
  calculateArcSideInner,
} from './math'
import {
  STROKE_WIDTH1,
  STROKE_WIDTH2,
  STYLES,
  DASH_ARRAY,
  DASH_ARRAY2,
  COLORS,
} from './variables'
import { querySide, getOpposite, getOpposites, getSideOfCorner } from './sides'
import Drawings from './Drawings'
import Shift from './Shift'
import zIndexing from './z-indexing'
import { drawEdgeRect } from './drawings/edgeRect'
import { drawEdgeLine } from './drawings/edgeLine'
import { renderTextureDirection } from './drawings/textureDirection'
import {
  testMeasurementDeep,
  drawMeasurementDeep,
  justifyMeasurementDeepTextPosition,
} from './measurements/deep'

export default class CoordinateGrid {
  operations = null
  hoverDrawings = null
  background = null

  constructor({
    el,
    size,
    operations,
    thickness = 16,
    onValidationWarnings = () => {},
    onSelect,
  }) {
    this.parent = el
    this.size = size || [400, 200]
    this.thickness = thickness
    this.operations = operations || []
    this.padding = 10
    this.styles = STYLES
    this.hoverDrawings = {}
    this.drawings = new Drawings()
    this.shift = new Shift()
    this.onValidationWarnings = onValidationWarnings
    this.onSelect = onSelect
    let canvas = document.createElement('CANVAS')
    canvas.oncontextmenu = event => {
      event.preventDefault()
    }
    this.parent.appendChild(canvas)
    paper.paperScope = new paper.PaperScope()
    paper.paperScope.setup(canvas)
    this.zoom = new Zoom(
      {
        zoomFactor: 1.1,
        maxZoom: (10 * Math.max(this.size[0], this.size[1])) / 400,
      },
      canvas
    )
    let setSceneSize = () => {
      let size = this.parent.getBoundingClientRect()
      paper.paperScope.view.setViewSize(
        new Size(size.width, size.height).subtract(30)
      )
      this.draw()
      // обновляем данные о размере и позиции центра детали
      // чтобы избежать "улетания" чертежа при первом изменении размера
      this.shift.updateState(paper.paperScope)
      // console.log('setSceneSize', size)
    }
    new ResizeObserver(setSceneSize).observe(this.parent)
    this.draw()
  }
  update({ thickness, size, operations }) {
    if (size) this.size = size
    if (thickness) this.thickness = thickness
    this.operations = operations
    this.draw()
  }
  draw() {
    toggleHoverEvents(false, this)
    toggleClickEvents(false, this)
    if (paper.paperScope.project && paper.paperScope.project.activeLayer)
      paper.paperScope.project.activeLayer.removeChildren()
    this.drawGraphLines()
    this.drawOperations()
  }
  drawDiagonalLines(clip, parent, offset, firstChild, angle = 45) {
    if (!clip || !parent) return
    const clipGroup = new Group(),
      { bounds } = clip
    clipGroup.addChild(
      firstChild ||
        new Path.Rectangle({
          from: bounds.topLeft,
          to: bounds.bottomRight,
        })
    )

    const { left, top, width, height } = bounds,
      isLandscape = width >= height,
      start = isLandscape ? left - width / 2 : top - height / 2,
      end = isLandscape ? left + 1.5 * width : top + 1.5 * height
    for (let i = start + offset / 2; i < end - offset / 4; i += offset) {
      let line = new Path.Line({
        from: isLandscape ? [i, top] : [left, i],
        to: isLandscape ? [i, top + height] : [left + width, i],
        strokeWidth: STROKE_WIDTH1,
        strokeColor: '#628EFF',
      })
      // make line long enough and rotate
      line.scale(1.42)
      line.rotate(angle)
      clipGroup.addChild(line)
    }

    clipGroup.clipped = true
    parent.addChild(clipGroup)
    return clipGroup
  }
  drawGraphLines() {
    let bounds = paper.paperScope.view.viewSize
    let arrowSize = 5
    this.graphLines = new Group()
    this.graphLines.addChild(
      new Path({
        segments: [
          [this.padding, bounds.height - this.padding],
          [bounds.width - this.padding, bounds.height - this.padding],
        ],
        strokeColor: '#000000',
        strokeWidth: STROKE_WIDTH2,
      })
    )
    this.graphLines.addChild(
      new Path({
        segments: [
          [this.padding, this.padding],
          [this.padding, bounds.height - this.padding],
        ],
        strokeColor: '#000000',
        strokeWidth: STROKE_WIDTH2,
      })
    )
    this.graphLines.addChild(
      new Path({
        segments: [
          [
            bounds.width - this.padding - arrowSize,
            bounds.height - this.padding - arrowSize,
          ],
          [bounds.width - this.padding, bounds.height - this.padding],
          [
            bounds.width - this.padding - arrowSize,
            bounds.height - this.padding + arrowSize,
          ],
        ],
        strokeColor: '#000000',
        strokeWidth: STROKE_WIDTH2,
      })
    )
    this.graphLines.addChild(
      new Path({
        segments: [
          [this.padding - arrowSize, this.padding + arrowSize],
          [this.padding, this.padding],
          [this.padding + arrowSize, this.padding + arrowSize],
        ],
        strokeColor: '#000000',
        strokeWidth: STROKE_WIDTH2,
      })
    )
    this.graphLines.addChild(
      new PointText({
        content: 'x',
        position: new Point(
          bounds.width - this.padding / 1.5,
          bounds.height - this.padding - this.styles.fontSize
        ),
        fontSize: 12,
        justification: 'center',
        fillColor: '#000000',
      })
    )
    this.graphLines.addChild(
      new PointText({
        content: 'y',
        position: new Point(
          this.padding / 1.5,
          this.padding - this.styles.fontSize / 2
        ),
        fontSize: 12,
        justification: 'center',
        fillColor: '#000000',
      })
    )
    this.graphLines.addChild(
      new PointText({
        content: 'lt',
        position: new Point(
          this.padding + 2 * this.styles.fontSize,
          this.padding + this.styles.fontSize
        ),
        fontSize: 12,
        justification: 'center',
        fillColor: '#000000',
      })
    )
    this.graphLines.addChild(
      new PointText({
        content: 'rt',
        position: new Point(
          bounds.width - this.padding,
          this.padding + this.styles.fontSize
        ),
        fontSize: 12,
        justification: 'center',
        fillColor: '#000000',
      })
    )
    this.graphLines.addChild(
      new PointText({
        content: 'rb',
        position: new Point(
          bounds.width - this.padding,
          bounds.height - this.padding - 3 * this.styles.fontSize
        ),
        fontSize: 12,
        justification: 'center',
        fillColor: '#000000',
      })
    )
    this.graphLines.addChild(
      new PointText({
        content: 'lb',
        position: new Point(
          this.padding + 2 * this.styles.fontSize,
          bounds.height - this.padding - 3 * this.styles.fontSize
        ),
        fontSize: 12,
        justification: 'center',
        fillColor: '#000000',
      })
    )
  }

  operateCarveP({ item, sides }) {
    for (const currentSide in item.place_second) {
      if (item.place_second[currentSide]) {
        let margin = item.by_side_center
            ? sides[currentSide].size / 2 - Number(item.cutout_size_width) / 2
            : Number(item.cutout_center_side),
          deep = Number(item.cutout_size_deep),
          width = Number(item.cutout_size_width),
          R = Math.max(Number(item.rad), 4),
          prevSide = querySide(currentSide, 'prev')

        if (
          width < 0 ||
          margin < 0 ||
          deep < 0 ||
          deep > sides[prevSide].size ||
          margin + width > sides[currentSide].size ||
          R > width / 2 ||
          R > deep
        ) {
          return
        }
        this.pushEndFace(sides[currentSide].endFace, {
          end: margin,
          start: margin + Number(item.cutout_size_width),
        })
        sides[currentSide].measurements.push({
          id: item.id,
          idCarve: item.idCarve,
          margin: 0,
          deep: margin,
          width: margin,
        })
        sides[currentSide].measurements.push({
          id: item.id,
          idCarve: item.idCarve,
          margin: margin,
          deep,
          width,
        })
        sides[getOpposite(currentSide)].measurements.push({
          id: item.id,
          idCarve: item.idCarve,
          deep,
          width: deep,
          side: currentSide,
        })
        const p = new Point(margin, deep - R)
          .add([R, 0])
          .add(new Point(R, 0).rotate(135))
        this.planMeasurementDiameter({
          sides,
          side: currentSide,
          params: {
            id: item.id,
            idCarve: item.idCarve,
            x: p.x,
            y: p.y,
            diameter: R * 2,
            text: `R${R}`,
            hover: true,
          },
        })
        sides[currentSide].operations.push({
          type: 'carve_p',
          id: item.id,
          idCarve: item.idCarve,
          margin: margin,
          deep,
          width,
          R,
          hover: true,
        })
      }
    }
  }
  drawCarveP({ operation, sidePathGroup, side, sides, property }) {
    const { width, deep, margin, idCarve, hover, R = 4 } = operation,
      delta = 8,
      R1 = Math.max(R - delta, 0),
      R2 = Math.max(R - 2 * delta, 0)

    const getCarveP = (r, d = 0) => {
      const line = new Path({
        segments: [
          [margin + d, 0],
          [margin + d, deep - r - d],
        ],
        strokeWidth: STROKE_WIDTH2,
        strokeColor: this.styles.pathColor,
        // selected: true,
      })
      line.arcTo(
        line.lastSegment.point.add([r, 0]).add(new Point(r, 0).rotate(135)),
        [margin + d + r, deep - d]
      )
      line.lineTo([margin + width - r - d, deep - d])
      line.arcTo(
        line.lastSegment.point.add([0, -r]).add(new Point(r, 0).rotate(45)),
        [margin + width - d, deep - r - d]
      )
      line.lineTo([margin + width - d, 0])
      return line
    }
    const line = getCarveP(R),
      line1 = getCarveP(R1, delta),
      line2 = getCarveP(R2, 2 * delta)

    line1.style.dashArray = [delta / 5, delta / 5]

    const bg = this.cutOut(
      [
        [margin, 0],
        [margin, deep],
        [margin + width, deep],
        [margin + width, 0],
      ],
      side
    )
    const group = new Group([line, line1, line2])
    side.addChild(group)
    // Рисуем вход пилы
    side.addChild(this.getDotCircle(line1.firstSegment.point))
    if (hover) {
      this.hoverDrawings[idCarve].target = bg
    }
    this.drawings.add(operation.idCarve, operation, bg, group, [line])
  }

  operateGrooveEdge({ item, sides, EDGE_OFFSET, EDGE_HEIGHT, heightScale }) {
    for (const selectedSide in item.place.checkboxes) {
      if (item.place.checkboxes[selectedSide]) {
        const { id, idCarve } = item,
          isFace = item.face && item.face.id == 2
        let angle = Number(item.place.value)
        // Угол наклона должен быть больше 0 и не более 45 градусов
        if (angle < 0) {
          angle = 0
        }
        if (angle > 45) {
          angle = 45
        }
        Object.assign(sides[selectedSide].edge, {
          selected: true,
          op: { ...item, side: selectedSide },
          angle,
          isFace: item.face && item.face.id == 2,
        })
        if (angle) {
          const deep = EDGE_HEIGHT * Math.tan(degToRad(angle))
          sides[selectedSide].measurements.push({
            id,
            idCarve,
            tiltAngle: angle,
            startAngle: isFace ? 180 + angle : 180 - angle,
            point: new Point(
              isFace ? -EDGE_HEIGHT - EDGE_HEIGHT : -EDGE_OFFSET,
              0
            ),
            height: EDGE_HEIGHT + 0.3 * heightScale,
          })
          sides[selectedSide].operations.push({
            type: 'groove_edge',
            id: item.id,
            idCarve: item.idCarve,
            height: deep,
            isFace,
            hover: true,
          })
        }
      }
    }
  }
  drawGrooveEdge({ operation, side, sides, property }) {
    const { height, isFace } = operation
    const bg = new Path.Rectangle({
      from: [0, 0],
      to: [sides[property].size, height],
      // selected: true,
    })
    bg.style.fillColor = new Color({
      stops: [isFace ? '#AFAFAF55' : '#AFAFAF33', COLORS.transparent],
      origin: bg.bounds.topCenter,
      destination: bg.bounds.bottomCenter,
    })
    this.drawings.add(operation.idCarve, operation, null, [bg], [bg])
    side.addChild(bg)
  }
  drawEdge({ side, sides, property, EDGE_HEIGHT, EDGE_OFFSET, radius }) {
    const propSide = sides[property]
    let edgePieces = [
      propSide.points[0],
      propSide.points[propSide.points.length - 1].add(
        new Point(0, EDGE_HEIGHT)
      ),
    ]
    propSide.endFace.forEach((endFace, index) => {
      edgePieces.splice(
        2 * index + 1,
        0,
        new Point(endFace.end, EDGE_HEIGHT),
        new Point(endFace.start, 0)
      )
    })
    if (propSide.edge.selected) {
      const { op } = propSide.edge
      const area = new Path.Rectangle({
        point: [0, 0],
        size: [propSide.size, 1],
        // selected: true
      })
      side.addChild(area)
      this.drawings.add(op.idCarve, op, area)
    }
    for (let i = 0; i < edgePieces.length; i += 2) {
      let edge = new Path.Rectangle({
        from: edgePieces[i],
        to: edgePieces[i + 1],
        strokeColor: this.styles.pathColor,
        strokeWidth: STROKE_WIDTH2,
        data: { isEdge: true, edge: property },
      })
      edge.visible = edge.bounds.width > 2 ? true : false
      edge.position = edge.position.subtract(
        new Point(0, EDGE_HEIGHT + EDGE_OFFSET)
      )
      // draw opposite edge angle
      let opposites = getOpposites(property)
      opposites.forEach((opposite, index) => {
        // we need to inverse position for index === 1
        if (sides[opposite] && sides[opposite].edge.selected) {
          const { isFace, angle } = sides[opposite].edge
          const dH = EDGE_HEIGHT * Math.tan(degToRad(angle))
          if (index === 0 && i === 0) {
            const seg = edge.segments[isFace ? 0 : 1]
            seg.point = seg.point.add(new Point(dH, 0))
          }
          if (index === 1 && i === edgePieces.length - 2) {
            const seg =
              edge.segments[
                isFace ? edge.segments.length - 1 : edge.segments.length - 2
              ]
            seg.point = seg.point.subtract(new Point(dH, 0))
          }
        } else if (sides[opposite] && sides[opposite].edge.isPostformed) {
          const { pfHeight, pfIsReversed } = sides[opposite].edge,
            isNext = index === 0 && i === 0,
            isPrev = index === 1 && i === edgePieces.length - 2
          if ((isNext && !pfIsReversed) || (isPrev && pfIsReversed)) {
            // edge.selected = true
            const seg1 =
                edge.segments[pfIsReversed ? edge.segments.length - 1 : 0],
              seg2 = edge.segments[pfIsReversed ? edge.segments.length - 2 : 1],
              delta = new Point(((pfIsReversed ? -1 : 1) * EDGE_HEIGHT) / 2, 0),
              handle = new Point(0, EDGE_HEIGHT / 2)
            seg1.point = seg1.point.add(delta)
            seg2.point = seg2.point.add(delta)
            edge.insert(
              pfIsReversed ? edge.segments.length - 1 : 1,
              new Segment({
                point: seg1.point.add([-delta.x, -EDGE_HEIGHT / 2]),
                handleIn: handle.rotate(pfIsReversed ? 180 : 0),
                handleOut: handle.rotate(pfIsReversed ? 0 : 180),
              })
            )
          }
          if ((isPrev && !pfIsReversed) || (isNext && pfIsReversed)) {
            if (!pfHeight) {
              // edge.selected = true
              // debugger
              const seg1 =
                  edge.segments[pfIsReversed ? 0 : edge.segments.length - 1],
                seg2 =
                  edge.segments[pfIsReversed ? 1 : edge.segments.length - 2],
                delta = new Point(
                  ((pfIsReversed ? 1 : -1) * EDGE_HEIGHT) / 2,
                  0
                ),
                handle = new Point(0, EDGE_HEIGHT / 2)
              seg1.point = seg1.point.add(delta)
              seg2.point = seg2.point.add(delta)
              edge.insert(
                pfIsReversed ? 1 : edge.segments.length - 1,
                new Segment({
                  point: seg1.point.add([-delta.x, -EDGE_HEIGHT / 2]),
                  handleIn: handle.rotate(pfIsReversed ? 0 : 180),
                  handleOut: handle.rotate(pfIsReversed ? 180 : 0),
                })
              )
            } else {
              const rounding = new Path.Line({
                from: [0, -EDGE_HEIGHT / 2],
                to: [pfHeight - EDGE_HEIGHT / 2, -EDGE_HEIGHT / 2],
                strokeWidth: STROKE_WIDTH2,
                strokeColor: this.styles.pathColor,
              })
              rounding.arcTo(
                [pfHeight, 0],
                [pfHeight - EDGE_HEIGHT / 2, EDGE_HEIGHT / 2]
              )
              rounding.lineTo([0, EDGE_HEIGHT / 2])
              if (pfIsReversed) {
                rounding.rotate(180)
                rounding.translate(0, -EDGE_HEIGHT / 2 - EDGE_OFFSET)
              } else {
                rounding.translate(
                  propSide.size - pfHeight,
                  -EDGE_HEIGHT / 2 - EDGE_OFFSET
                )
              }
              rounding.style.dashArray = DASH_ARRAY2
              // rounding.selected = true
              side.addChild(rounding)
            }
          }
        }
      })
      if (propSide.edge.selected || propSide.edge.isPostformed) {
        this.drawDiagonalLines(edge, side, radius / 2, edge.clone())
      } else if (propSide.edge.isPartiallyDashed) {
        const { deepFrom = 0, deepTo = 0, from = 0, to = 0 } = propSide.edge
        if (
          from < to &&
          ((from >= edge.bounds.left && from <= edge.bounds.right) ||
            (to >= edge.bounds.left && to <= edge.bounds.right))
        ) {
          const rect = new Path.Rectangle({
            from: [
              Math.max(from, edge.bounds.left),
              -EDGE_OFFSET - Math.min(deepFrom, deepTo, EDGE_HEIGHT),
            ],
            to: [
              Math.min(to, edge.bounds.right),
              -EDGE_OFFSET - Math.min(Math.max(deepFrom, deepTo), EDGE_HEIGHT),
            ],
          })
          this.drawDiagonalLines(edge, side, radius / 2, rect)
        }
      }
      side.addChild(edge)
    }
  }

  operateGrooveEnd({ item, sides, EDGE_HEIGHT, EDGE_OFFSET }) {
    const {
        face,
        idCarve,
        id,
        place_second,
        mid_by_z_center: zCenter,
        z_offset,
      } = item,
      deep = Number(item.deep),
      width = Number(item.width),
      zOffset = Number(z_offset)

    const selectedSide = Object.keys(place_second).find(
      k => item.place_second[k]
    )
    if (!selectedSide || !deep || !width) return
    const prevSide = querySide(selectedSide, 'prev'),
      nextSide = querySide(selectedSide, 'next'),
      oppositeSide = querySide(selectedSide, 'opposite')
    let x0 = 0,
      x1 = sides[selectedSide].size,
      y1 = zCenter ? EDGE_HEIGHT / 2 + width / 2 : zOffset + width,
      y0 = y1 - width
    if (
      x0 < 0 ||
      x1 > sides[selectedSide].size ||
      y0 <= 0 ||
      y1 >= EDGE_HEIGHT
    ) {
      return
    }
    // метрики: ширина паза, глубина выреза
    sides[prevSide].measurements.push({
      id,
      idCarve,
      margin: sides[prevSide].size - deep,
      deep: true,
      width: deep,
      hover: true,
    })
    sides[selectedSide].measurements.push({
      id: item.id,
      idCarve: item.idCarve,
      margin: -EDGE_OFFSET - Math.max(y0, y1),
      deep: true,
      width: Number(item.width),
      hover: true,
    })
    // помечаем штриховку торца
    Object.assign(sides[selectedSide].edge, {
      isPartiallyDashed: true,
      deepFrom: y1,
      deepTo: y0,
      from: x0,
      to: x1,
    })
    // прорисовка паза
    sides[selectedSide].operations.push({
      type: 'groove_end',
      deep,
      width,
      from: x0,
      to: x1,
      id,
      idCarve,
      hover: true,
      side: selectedSide,
    })
    const isFace = true
    new Array(y0, y1).forEach(y =>
      sides[selectedSide].operations.push({
        type: 'edge_line',
        offset: y,
        from: x0,
        to: x1,
        isFace,
        dashed: false,
        id,
        idCarve,
      })
    )

    sides[prevSide].operations.push({
      type: 'edge_rect',
      offset: sides[prevSide].size - deep / 2,
      zOffset: zCenter ? EDGE_HEIGHT / 2 - width / 2 : zOffset,
      width: deep,
      height: width,
      isFace,
      id,
      idCarve,
      dashed: false,
      through: true,
      // selected: true,
    })
    sides[nextSide].operations.push({
      type: 'edge_rect',
      offset: deep / 2,
      zOffset: zCenter ? EDGE_HEIGHT / 2 - width / 2 : zOffset,
      width: deep,
      height: width,
      isFace,
      id,
      idCarve,
      dashed: false,
      through: true,
    })
  }
  drawGrooveEnd({ operation, side, EDGE_HEIGHT }) {
    const { idCarve, hover, deep, from, to } = operation
    const line = new Path.Line({
        from: [from, deep],
        to: [to, deep],
        strokeWidth: STROKE_WIDTH2,
        strokeColor: this.styles.pathColor,
        dashArray: DASH_ARRAY2,
      }),
      bg = new Path.Rectangle({
        from: [from, 0],
        to: line.bounds.bottomRight,
        fillColor: COLORS.transparent,
        // selected: true
      })
    const group = new Group({
      children: [bg, line],
      data: { is: 'template', template: operation.type },
    })
    side.addChild(group)
    if (hover) {
      this.hoverDrawings[idCarve].target = group
    }
    this.drawings.add(operation.idCarve, operation, bg, line, [line])
  }

  operateGroove({ item, sides, EDGE_HEIGHT, EDGE_OFFSET }) {
    const {
        face,
        idCarve,
        id,
        place_second,
        is_limited: isLimited,
        is_opposite_start: isOppositeStart,
      } = item,
      deep = Number(item.deep),
      margin = Number(item.margin),
      width = Number(item.width),
      startOffset = Number(item.start_offset),
      grooveLength = Number(item.groove_length),
      isFace = face && face.id == 2

    const selectedSide = Object.keys(place_second).find(
      k => item.place_second[k]
    )
    if (!selectedSide || !face || !width) return
    const prevSide = querySide(selectedSide, 'prev'),
      nextSide = querySide(selectedSide, 'next'),
      oppositeSide = querySide(selectedSide, 'opposite')
    let x0 = 0,
      x1 = sides[selectedSide].size
    if (isLimited) {
      x0 = startOffset
      x1 = startOffset + grooveLength
      if (isOppositeStart) {
        x1 = sides[selectedSide].size - startOffset
        x0 = x1 - grooveLength
      }
    }
    if (x0 < 0 || x1 > sides[selectedSide].size) {
      return
    }
    // метрики: отступ и ширина паза, глубины выреза
    sides[prevSide].measurements.push({
      id,
      idCarve,
      margin: sides[prevSide].size - margin,
      deep: margin,
      width: margin,
      hover: true,
    })
    sides[prevSide].measurements.push({
      id,
      idCarve,
      margin: sides[prevSide].size - margin - width,
      deep: width,
      width: width,
      hover: true,
    })
    sides[selectedSide].measurements.push({
      id: item.id,
      idCarve: item.idCarve,
      margin: -EDGE_OFFSET - (isFace ? deep : EDGE_HEIGHT),
      deep: Number(item.deep),
      width: Number(item.deep),
      hover: true,
    })
    if (isLimited) {
      sides[selectedSide].measurements.push({
        id,
        idCarve,
        margin: x0,
        deep: true,
        width: x1 - x0,
        hover: true,
      })
      if (startOffset) {
        sides[selectedSide].measurements.push({
          id,
          idCarve,
          margin: isOppositeStart ? x1 : 0,
          deep: true,
          width: startOffset,
          hover: true,
        })
      }
    }
    // прорисовка паза
    sides[selectedSide].operations.push({
      type: 'groove',
      deep,
      width,
      from: x0,
      to: x1,
      isFace,
      margin,
      id,
      idCarve,
      hover: true,
    })
    // линии выреза на торцах
    if (isLimited) {
      sides[selectedSide].operations.push({
        type: 'edge_rect',
        offset: (x1 + x0) / 2,
        width: Math.abs(x1 - x0),
        height: deep,
        isFace,
        id,
        idCarve,
        dashed: true,
      })
      sides[oppositeSide].operations.push({
        type: 'edge_rect',
        offset: sides[oppositeSide].size - (x1 + x0) / 2,
        width: Math.abs(x1 - x0),
        height: deep,
        isFace,
        id,
        idCarve,
        dashed: true,
      })
    } else {
      sides[selectedSide].operations.push({
        type: 'edge_line',
        offset: deep,
        from: x0,
        to: x1,
        isFace,
        id,
        idCarve,
      })
      sides[oppositeSide].operations.push({
        type: 'edge_line',
        offset: deep,
        from: sides[oppositeSide].size - x1,
        to: sides[oppositeSide].size - x0,
        isFace,
        id,
        idCarve,
      })
    }
    // прямоугольныики выреза на торцах
    sides[prevSide].operations.push({
      type: 'edge_rect',
      offset: sides[prevSide].size - margin - width / 2,
      width,
      height: deep,
      isFace,
      id,
      idCarve,
      dashed: false,
      through: true,
    })
    sides[nextSide].operations.push({
      type: 'edge_rect',
      offset: margin + width / 2,
      width,
      height: deep,
      isFace,
      id,
      idCarve,
      dashed: false,
      through: true,
    })
  }
  drawGroove({ operation, side, EDGE_HEIGHT }) {
    const { width, isFace, margin, idCarve, hover, deep, from, to } = operation
    const bg = new Path.Rectangle({
      from: [from, margin],
      to: [to, margin + width],
      fillColor: COLORS.transparent,
    })
    const line = new Path.Line({
        from: bg.bounds.topLeft,
        to: bg.bounds.topRight,
      }),
      line1 = new Path.Line({
        from: bg.bounds.bottomLeft,
        to: bg.bounds.bottomRight,
      }),
      lineGroup = new Group([line, line1]),
      group = new Group({
        children: [bg, lineGroup],
        data: { is: 'template', template: operation.type },
      })
    lineGroup.style = {
      strokeWidth: STROKE_WIDTH2,
      strokeColor: this.styles.pathColor,
    }
    if (!isFace) {
      lineGroup.style.dashArray = DASH_ARRAY2
    }
    side.addChild(group)
    if (hover) {
      this.hoverDrawings[idCarve].target = group
    }
    this.drawings.add(idCarve, operation, bg, lineGroup, [line, line1])
    if (deep > EDGE_HEIGHT) {
      lineGroup.style = {
        strokeColor: COLORS.danger,
        selectedColor: COLORS.danger,
      }
    }
  }

  operateGrooveSide({ item, sides, EDGE_HEIGHT, EDGE_OFFSET }) {
    const {
        face,
        idCarve,
        id,
        place_second,
        is_limited: isLimited,
        is_opposite_start: isOppositeStart,
      } = item,
      deep = Number(item.deep),
      width = Number(item.width),
      startOffset = Number(item.start_offset),
      grooveLength = Number(item.groove_length),
      isFace = face && face.id == 2

    const selectedSide = Object.keys(place_second).find(
      k => item.place_second[k]
    )
    if (!selectedSide || !face || !width) return
    const prevSide = querySide(selectedSide, 'prev'),
      nextSide = querySide(selectedSide, 'next'),
      oppositeSide = querySide(selectedSide, 'opposite')
    let x0 = 0,
      x1 = sides[selectedSide].size
    if (isLimited) {
      x0 = startOffset
      x1 = startOffset + grooveLength
      if (isOppositeStart) {
        x1 = sides[selectedSide].size - startOffset
        x0 = x1 - grooveLength
      }
    }
    if (x0 < 0 || x1 > sides[selectedSide].size) {
      return
    }
    // метрики: ширина четверти, глубина выреза
    sides[prevSide].measurements.push({
      id,
      idCarve,
      margin: sides[prevSide].size - width,
      deep: width,
      width: width,
      hover: true,
    })
    sides[selectedSide].measurements.push({
      id: item.id,
      idCarve: item.idCarve,
      margin: -EDGE_OFFSET - (isFace ? deep : EDGE_HEIGHT),
      deep: Number(item.deep),
      width: Number(item.deep),
      hover: true,
    })
    if (isLimited) {
      sides[selectedSide].measurements.push({
        id,
        idCarve,
        margin: x0,
        deep: true,
        width: x1 - x0,
        hover: true,
      })
      if (startOffset) {
        sides[selectedSide].measurements.push({
          id,
          idCarve,
          margin: isOppositeStart ? x1 : 0,
          deep: true,
          width: startOffset,
          hover: true,
        })
      }
    }
    // помечаем штриховку торца
    Object.assign(sides[selectedSide].edge, {
      isPartiallyDashed: true,
      deepFrom: isFace ? deep : EDGE_HEIGHT,
      deepTo: isFace ? 0 : EDGE_HEIGHT - deep,
      from: x0,
      to: x1,
    })
    // прорисовка четверти
    sides[selectedSide].operations.push({
      type: 'groove_side',
      deep,
      width,
      from: x0,
      to: x1,
      isFace,
      id,
      idCarve,
      hover: true,
      side: selectedSide,
    })
    sides[selectedSide].operations.push({
      type: 'edge_line',
      offset: deep,
      from: x0,
      to: x1,
      isFace,
      dashed: false,
      id,
      idCarve,
      validate: true,
    })
    sides[prevSide].operations.push({
      type: 'edge_rect',
      offset: sides[prevSide].size - width / 2,
      width,
      height: deep,
      isFace,
      id,
      idCarve,
      dashed: false,
      through: true,
    })
    sides[nextSide].operations.push({
      type: 'edge_rect',
      offset: width / 2,
      width,
      height: deep,
      isFace,
      id,
      idCarve,
      dashed: false,
      through: true,
    })
  }
  drawGrooveSide({ operation, side, EDGE_HEIGHT }) {
    const { width, isFace, idCarve, hover, deep, from, to } = operation
    const line = new Path.Line({
        from: [from, width],
        to: [to, width],
        strokeWidth: STROKE_WIDTH2,
        strokeColor: this.styles.pathColor,
      }),
      bg = new Path.Rectangle({
        from: [from, 0],
        to: line.bounds.bottomRight,
        fillColor: COLORS.transparent,
        // selected: true
      })
    const group = new Group({
      children: [bg, line],
      data: { is: 'template', template: operation.type },
    })

    if (!isFace) {
      line.style.dashArray = DASH_ARRAY2
      line.strokeWidth = STROKE_WIDTH2
    }
    side.addChild(group)
    if (hover) {
      this.hoverDrawings[idCarve].target = group
    }
    this.drawings.add(operation.idCarve, operation, bg, line, [line])
    if (deep > EDGE_HEIGHT) {
      line.style = {
        strokeColor: COLORS.danger,
      }
    }
  }

  operateGrooveSideOld({ item, sides }) {
    for (const property in item.place_second) {
      if (item.place_second[property]) {
        this.pushEndFace(sides[property].endFace, {
          end: Number(item.margin),
          start: Number(item.margin) + Number(item.width),
        })
        sides[property].measurements.push({
          id: item.id,
          idCarve: item.idCarve,
          margin: Number(item.margin),
          deep: Number(item.deep),
          width: Number(item.width),
          width_saw: Number(item.width_saw),
        })
        sides[getOpposite(property)].measurements.push({
          id: item.id,
          idCarve: item.idCarve,
          deep: Number(item.deep),
          width: Number(item.deep),
          offset: Number(item.deep),
          side: property,
        })
        sides[property].operations.push({
          type: 'groove_side',
          id: item.id,
          idCarve: item.idCarve,
          margin: Number(item.margin),
          deep: Number(item.deep),
          width: Number(item.width),
        })
      }
    }
  }
  drawGrooveSideOld({ operation, sidePathGroup, sides, property }) {
    sides[property].points[0] = new Point(Number(0), Number(operation.deep))
    sides[property].points.splice(
      1,
      0,
      new Point(Number(operation.width), Number(operation.deep)),
      new Point(Number(operation.width), Number(0))
    )
    sides[getOpposite(property)].points[
      sides[getOpposite(property)].points.length - 1
    ] = sides[getOpposite(property)].points[
      sides[getOpposite(property)].points.length - 1
    ].subtract(new Point(Number(operation.deep), 0))
    sidePathGroup.removeSegments()
    sidePathGroup.add(...sides[property].points)
  }

  operateArcSideInner({ item, sides }) {
    const { id, idCarve, round } = item,
      h = Number(item.arc_height),
      currentSide = Object.keys(item.place_second).find(
        s => item.place_second[s]
      )
    // debugger
    if (!currentSide || !h || h <= 0) return
    const prevSide = querySide(currentSide, 'prev'),
      nextSide = querySide(currentSide, 'next'),
      r1 = Number(round ? item.rad_left : 0),
      r2 = Number(round ? item.rad_right : 0)
    if (h <= 0 || h > sides[prevSide].size || h > sides[currentSide].size / 2) {
      this.onValidationWarnings([
        { message: 'Операция с указанным радиусом невозможна' },
      ])
      return
    }
    const points = calculateArcSideInner({
        h,
        r1,
        r2,
        sizeX: sides[currentSide].size,
        sizeY: sides[prevSide].size,
      }),
      { start, middle, end, L, R, alpha, arcMiddle, m1, m2 } = points

    if (
      round &&
      (r1 >= sides[currentSide].size / 2 ||
        r2 >= sides[currentSide].size / 2 ||
        (r1 && start.equals(m1)) ||
        (r2 && end.equals(m2)))
    ) {
      this.onValidationWarnings([
        { message: 'Операция с указанным радиусом невозможна' },
      ])
      return
    }

    // cut end face
    this.pushEndFace(sides[currentSide].endFace, {
      end: 0,
      start: sides[currentSide].size,
    })

    // measurements
    sides[prevSide].measurements.push({
      id,
      idCarve,
      margin: sides[prevSide].size - h,
      deep: true,
      width: h,
    })
    if (round && start.y != m1.y) {
      sides[currentSide].measurements.push({
        id,
        idCarve,
        margin: 0,
        deep: true,
        width: Math.round(m1.x),
      })
      sides[prevSide].measurements.push({
        id,
        idCarve,
        margin: sides[prevSide].size - Math.round(start.y),
        deep: true,
        width: Math.round(start.y),
      })
    }
    if (round && end.y != m2.y) {
      sides[currentSide].measurements.push({
        id,
        idCarve,
        margin: Math.round(m2.x),
        deep: true,
        width: sides[currentSide].size - Math.round(m2.x),
      })
      sides[nextSide].measurements.push({
        id,
        idCarve,
        margin: 0,
        deep: true,
        width: Math.round(end.y),
      })
    }
    // метрика радиуса
    sides[currentSide].measurements.push({
      innerRadius: R,
      translate: arcMiddle.subtract(
        new Point(R, R).subtract(new Point(R, 0).rotate(45))
      ),
      id,
      idCarve,
    })
    if (round) {
      const { m0, m3 } = points
      const metrics = []
      if (r1 && !start.equals(m0)) {
        metrics.push({ m: m0, r: r1, force: 'opposite', index: 1 })
      }
      if (r2 && !end.equals(m3)) {
        metrics.push({ m: m3, r: r2, force: 'prev', index: 2 })
      }
      metrics.forEach(({ m, r, force, index }) =>
        this.planMeasurementDiameter({
          sides,
          side: currentSide,
          force,
          params: {
            id,
            idCarve,
            x: m.x,
            y: m.y,
            diameter: 2 * r,
            text: `R${index}-${r}`,
            hover: true,
          },
        })
      )
    }

    sides[currentSide].operations.push({
      type: 'arc_side_inner',
      points,
      id,
      idCarve,
      hover: true,
    })
  }
  drawArcSideInner({ operation, side, sides, property, EDGE_OFFSET }) {
    const { points, hover, idCarve } = operation,
      { o1, o2, m0, m1, m2, m3, start, R, middle, end, center } = points,
      delta = 10

    const arc = new Path({
      segments: [start],
      strokeWidth: STROKE_WIDTH2,
      strokeColor: this.styles.pathColor,
    })
    arc.arcTo(m0, m1)
    arc.arcTo(middle, m2)
    arc.arcTo(m3, end)

    const normal = new Point(0, -1),
      arc1 = arc.clone(),
      arc2 = arc.clone()

    arc1.style.dashArray = [delta / 5, delta / 5]
    arc1.translate(0, -delta)
    arc2.translate(0, -(2 * delta))

    const bg = this.cutOut(
      [...arc.segments, [sides[property].size, 0], [0, 0]],
      side
    )

    const clipRect = new Path.Rectangle(
      new Point(0, -2 * delta),
      new Point(end.x, Math.max(start.y, end.y, middle.y))
    )
    // clipRect.selected = true
    const group = new Group([clipRect, arc, arc1, arc2])
    group.clipped = true
    side.addChild(group)
    side.addChild(this.getDotCircle(end.add(delta / 2, -delta)))
    if (hover) {
      this.hoverDrawings[idCarve].target = bg
    }
    this.drawings.add(
      operation.idCarve,
      operation,
      bg,
      [arc, arc1, arc2],
      [arc]
    )
  }

  operateRadiusInner({ item, sides }) {
    const { rad_outside: angle, rad_simple, id, idCarve } = item,
      corner = Object.keys(angle).find(k => angle[k])
    if (!corner || !rad_simple) return
    const cornerSide = getSideOfCorner(corner),
      selectedSide = cornerSide,
      prevSide = querySide(selectedSide, 'prev'),
      R = Number(rad_simple)

    sides[selectedSide].markers.hasRadiusMetric = true
    sides[selectedSide].measurements.push({
      innerRadius: R,
      id,
      idCarve,
    })
    sides[selectedSide].operations.push({
      type: 'radius_inner',
      radius: R,
      id,
      idCarve,
    })
    // cut end face
    this.pushEndFace(sides[selectedSide].endFace, {
      end: 0,
      start: R,
    })
    this.pushEndFace(sides[prevSide].endFace, {
      end: sides[prevSide].size - R,
      start: sides[prevSide].size,
    })
  }
  drawRadiusInner({ operation, side, radius }) {
    let rotation = 180
    let len = 0.5 * Math.PI
    let offsetRad = 10
    let arc = Path.Arc({
      from: [operation.radius + offsetRad, 0],
      through: [
        (operation.radius + offsetRad) * Math.cos(len / 2),
        (operation.radius + offsetRad) * Math.sin(len / 2),
      ],
      to: [
        (operation.radius + offsetRad) * Math.cos(len),
        (operation.radius + offsetRad) * Math.sin(len),
      ],
      strokeWidth: STROKE_WIDTH2,
      dashArray: [radius / 10, radius / 10],
      strokeColor: this.styles.pathColor,
    })
    let arc1 = Path.Arc({
      from: [operation.radius, 0],
      through: [
        operation.radius * Math.cos(len / 2),
        operation.radius * Math.sin(len / 2),
      ],
      to: [operation.radius * Math.cos(len), operation.radius * Math.sin(len)],
      strokeWidth: STROKE_WIDTH2,
      strokeColor: this.styles.pathColor,
    })
    let arc2 = Path.Arc({
      from: [operation.radius + offsetRad * 2, 0],
      through: [
        (operation.radius + offsetRad * 2) * Math.cos(len / 2),
        (operation.radius + offsetRad * 2) * Math.sin(len / 2),
      ],
      to: [
        (operation.radius + offsetRad * 2) * Math.cos(len),
        (operation.radius + offsetRad * 2) * Math.sin(len),
      ],
      strokeWidth: STROKE_WIDTH2,
      strokeColor: this.styles.pathColor,
    })
    let coolRect = new Path.Rectangle(
      new Point(0, 0),
      new Size(operation.radius, operation.radius)
    )
    let coolGroup = new Group([coolRect, arc, arc1, arc2])
    coolGroup.clipped = true
    coolGroup.rotate(rotation)
    const bg = this.cutOut([...arc1.segments, [0, 0]], side)
    side.addChild(coolGroup)
    side.addChild(this.getDotCircle(arc.firstSegment.point))
    this.drawings.add(
      operation.idCarve,
      operation,
      bg,
      [arc1, arc2, arc],
      [arc1]
    )
  }

  operateRadiusOuter({ item, sides }) {
    const { round, id, idCarve } = item,
      radius = Number(item.rad_simple),
      r1 = Number(item.rad_round),
      r2 = Number(item.rad_round1),
      corner = Object.keys(item.rad_inside).find(k => item.rad_inside[k]),
      currentSide = getSideOfCorner(corner),
      prevSide = querySide(currentSide, 'prev')

    if (!corner || !currentSide || !radius || (round && r1 < 10)) return
    if (r1 >= radius || r2 >= radius) {
      this.onValidationWarnings([
        { message: 'Операция с указанным радиусом невозможна' },
      ])
      return
    }
    const points = calculateRadiusOuter(radius, r1, r2),
      { k2, k4 } = points
    if (
      radius > this.size[0] ||
      radius > this.size[1] ||
      (round && (k2.y > sides[prevSide].size || k4.x > sides[currentSide].size))
    ) {
      this.onValidationWarnings([
        { message: 'Операция с указанным радиусом невозможна' },
      ])
      return
    }

    sides[currentSide].markers.hasRadiusMetric = true
    sides[currentSide].measurements.push({
      outerRadius: radius,
      id,
      idCarve,
    })
    sides[currentSide].operations.push({
      type: 'radius_outer',
      radius,
      round,
      r1,
      r2,
      points,
      id,
      idCarve,
      hover: true,
    })
    let width = radius,
      height = radius
    if (round) {
      const { m1, m2, k2, k4 } = points
      new Array({ m: m1, r: r1 }, { m: m2, r: r2 }).forEach(({ m, r }, index) =>
        this.planMeasurementDiameter({
          sides,
          side: currentSide,
          force: 'opposite',
          params: {
            id,
            idCarve,
            x: m.x,
            y: m.y,
            diameter: 2 * r,
            text: `R${index + 1}-${r}`,
            hover: true,
          },
        })
      )
      height = k2.y
      width = k4.x
    }
    // cut end face
    this.pushEndFace(sides[currentSide].endFace, {
      end: 0,
      start: width,
    })
    this.pushEndFace(sides[prevSide].endFace, {
      end: sides[prevSide].size - height,
      start: sides[prevSide].size,
    })
  }
  drawRadiusOuter({ operation, side }) {
    const len = 0.5 * Math.PI,
      { radius: R, round, points, hover, idCarve } = operation,
      { dotCircleRadius } = this,
      offsetRad = 10
    let arc, arc1, arc2, group

    if (round) {
      let { k1, k2, k3, k4, m0, m1, m2 } = points
      arc = new Path.Arc({
        from: k2,
        through: m1,
        to: k1,
        // selected: true,
      })
      arc.arcTo(m0, k3)
      arc.arcTo(m2, k4)

      arc.style = {
        strokeWidth: STROKE_WIDTH2,
        strokeColor: this.styles.pathColor,
      }
      const deltaVector = new Point(offsetRad, 0).rotate(225)
      arc1 = arc.clone()
      arc2 = arc.clone()

      arc1.style.dashArray = [dotCircleRadius / 10, dotCircleRadius / 10]
      arc1.translate(deltaVector)
      arc2.translate(deltaVector.multiply(2))
      const clipRect = new Path.Rectangle(k2, k4)
      // clipRect.selected = true
      group = new Group([clipRect, arc, arc1, arc2])
      group.clipped = true
      side.addChild(group)
      side.addChild(this.getDotCircle(arc1.firstSegment.point))
    } else {
      arc = Path.Arc({
        from: [0, R],
        through: [R * Math.cos(len / 2), R * Math.sin(len / 2)],
        to: [R, 0],
        strokeWidth: STROKE_WIDTH2,
        strokeColor: this.styles.pathColor,
      })
      arc1 = Path.Arc({
        from: [0, R - offsetRad],
        through: [
          (R - offsetRad) * Math.cos(len / 2),
          (R - offsetRad) * Math.sin(len / 2),
        ],
        to: [R - offsetRad, 0],
        strokeWidth: STROKE_WIDTH2,
        dashArray: [dotCircleRadius / 10, dotCircleRadius / 10],
        strokeColor: this.styles.pathColor,
      })
      arc2 = Path.Arc({
        from: [0, R - offsetRad * 2],
        through: [
          (R - offsetRad * 2) * Math.cos(len / 2),
          (R - offsetRad * 2) * Math.sin(len / 2),
        ],
        to: [R - offsetRad * 2, 0],
        strokeWidth: STROKE_WIDTH2,
        strokeColor: this.styles.pathColor,
      })
      group = new Group([arc, arc1, arc2])
      side.addChild(group)
      side.addChild(this.getDotCircle(arc1.firstSegment.point))
    }
    const bg = this.cutOut([...arc.segments, [0, 0]], side)
    bg.insertBelow(group)
    if (hover) {
      this.hoverDrawings[idCarve].target = bg
    }
    this.drawings.add(
      operation.idCarve,
      operation,
      bg,
      [arc, arc1, arc2],
      [arc]
    )
  }

  operateArcCorner({ item, sides, heightScale }) {
    const { byrest, id, idCarve } = item,
      R = Number(item.rad),
      corner = Object.keys(item.angle).find(k => item.angle[k])
    if (!corner || !item.width || !item.height) return
    let currentSide = getSideOfCorner(corner),
      isRL = ['left', 'right'].includes(currentSide),
      w = Number(isRL ? item.height : item.width),
      h = Number(isRL ? item.width : item.height),
      prevSide = querySide(currentSide, 'prev'),
      width = byrest ? sides[currentSide].size - w : w,
      height = byrest ? sides[prevSide].size - h : h,
      start = new Point(0, height),
      end = new Point(width, 0),
      L = start.getDistance(end),
      invalid = false
    if (
      width <= 0 ||
      height <= 0 ||
      width > sides[currentSide].size ||
      height > sides[prevSide].size ||
      L > 2 * R
    ) {
      this.onValidationWarnings([
        { message: 'Операция с указанным радиусом невозможна' },
      ])
      return
    }

    // подгоняем точки пересечения детали с дугой,
    // чтобы дуга не выходила за деталь
    // при этом значения отступов height/width и контрольных точек могут изменится
    const middle = new Point(end.x / 2, start.y / 2),
      dR = R - Math.sqrt(Math.pow(R, 2) - Math.pow(L, 2) / 4),
      alpha = Math.atan(start.y / end.x),
      center = new Point(
        middle.x + (R - dR) * Math.sin(alpha),
        middle.y + (R - dR) * Math.cos(alpha)
      ),
      dx = Math.sqrt(Math.pow(R, 2) - Math.pow(center.y, 2)),
      dy = Math.sqrt(Math.pow(R, 2) - Math.pow(center.x, 2)),
      calcWidth = center.x - dx,
      calcHeight = center.y - dy
    if (width !== Math.round(calcWidth) || height !== Math.round(calcHeight)) {
      // сообщаем если дуга не вписывается в деталь
      this.onValidationWarnings([{ message: 'Дуга выходит за деталь!' }])
      invalid = true
    }
    end.x = width = calcWidth
    start.y = height = calcHeight
    L = start.getDistance(end)

    // console.table({
    //   alpha: (alpha * 180) / Math.PI,
    //   dR,
    //   middle,
    //   center,
    //   width,
    //   height,
    //   start: new Point(0, height),
    //   end: new Point(width, 0),
    // })

    // cut end face
    this.pushEndFace(sides[currentSide].endFace, {
      end: 0,
      start: width,
    })
    this.pushEndFace(sides[prevSide].endFace, {
      end: sides[prevSide].size - height,
      start: sides[prevSide].size,
    })

    // measurements
    sides[currentSide].measurements.push({
      id,
      idCarve,
      margin: byrest ? end.x : 0,
      deep: byrest ? sides[currentSide].size - end.x : end.x,
      width: Math.round(byrest ? sides[currentSide].size - end.x : end.x),
    })
    sides[prevSide].measurements.push({
      id,
      idCarve,
      margin: byrest ? 0 : sides[prevSide].size - start.y,
      deep: byrest ? sides[prevSide].size - start.y : start.y,
      width: Math.round(byrest ? sides[prevSide].size - start.y : start.y),
    })

    // метрика радиуса
    const arcH = R - Math.sqrt(Math.pow(R, 2) - Math.pow(L, 2) / 4),
      chord = new Path.Line({
        from: start,
        to: end,
        strokeWidth: STROKE_WIDTH2,
        strokeColor: this.styles.pathColor,
      }),
      normal = chord.getNormalAt(chord.length / 2),
      arcMiddle = chord.getPointAt(chord.length / 2).add(normal.multiply(arcH))
    sides[currentSide].measurements.push({
      innerRadius: R,
      translate: arcMiddle.subtract(
        new Point(R, R).subtract(new Point(R, 0).rotate(45))
      ),
      id,
      idCarve,
    })
    chord.remove() // убираем промежуточные обьекты

    // draw line corner
    sides[currentSide].operations.push({
      type: 'arc_corner',
      start,
      end,
      R,
      L,
      id,
      idCarve,
      invalid,
    })
  }
  drawArcCorner({ operation, side, EDGE_HEIGHT }) {
    const { start, end, R, L, hover, idCarve, invalid } = operation,
      delta = 10,
      h = R - Math.sqrt(Math.pow(R, 2) - Math.pow(L, 2) / 4),
      chord = new Path.Line({
        from: start,
        to: end,
        strokeWidth: STROKE_WIDTH2,
        strokeColor: this.styles.pathColor,
      }),
      normal = chord.getNormalAt(chord.length / 2),
      middle = chord.getPointAt(chord.length / 2).add(normal.multiply(h))

    const arc = Path.Arc({
      from: start,
      through: middle,
      to: end,
      strokeWidth: STROKE_WIDTH2,
      strokeColor: this.styles.pathColor,
    })
    const arc1 = Path.Arc({
      from: start.add(arc.getNormalAt(0).multiply(delta)),
      through: middle.add(arc.getNormalAt(arc.length / 2).multiply(delta)),
      to: end.add(arc.getNormalAt(arc.length - 1).multiply(delta)),
      dashArray: [delta / 5, delta / 5],
      strokeWidth: STROKE_WIDTH2,
      strokeColor: this.styles.pathColor,
    })
    const arc2 = Path.Arc({
      from: start.add(arc.getNormalAt(0).multiply(2 * delta)),
      through: middle.add(arc.getNormalAt(arc.length / 2).multiply(2 * delta)),
      to: end.add(arc.getNormalAt(arc.length - 1).multiply(2 * delta)),
      strokeWidth: STROKE_WIDTH2,
      strokeColor: this.styles.pathColor,
    })
    // обрезаем плоскость
    const bg = this.cutOut([...arc.segments, [0, 0]], side)

    const clipRect = new Path.Rectangle(
      new Point(0, 0),
      new Size(end.x, start.y)
    )
    const group = new Group([clipRect, arc, arc1, arc2, chord])
    group.clipped = true
    chord.remove()
    side.addChild(group)
    side.addChild(this.getDotCircle(start))
    if (hover) {
      this.hoverDrawings[idCarve].target = group
    }
    this.drawings.add(
      operation.idCarve,
      operation,
      bg,
      [arc, arc1, arc2],
      [arc]
    )
    if (invalid) {
      new Array(arc, arc1, arc2).forEach(p => (p.strokeColor = COLORS.danger))
    }
  }

  operateArcSide({ item, sides }) {
    const { byrest, id, idCarve, round } = item,
      h = Number(item.remaining_size_1),
      currentSide = Object.keys(item.place_second).find(
        s => item.place_second[s]
      )
    if (!currentSide || !h || h <= 0) return
    const prevSide = querySide(currentSide, 'prev'),
      nextSide = querySide(currentSide, 'next'),
      height = byrest ? sides[prevSide].size - h : h,
      r1 = Number(round ? item.rad_round : 0),
      r2 = Number(round ? item.rad_round1 : 0)
    if (
      height <= 0 ||
      height > sides[prevSide].size ||
      height > sides[currentSide].size / 2
    ) {
      this.onValidationWarnings([
        { message: 'Операция с указанным радиусом невозможна' },
      ])
      return
    }
    const points = calculateArcSide({
        height,
        r1,
        r2,
        sizeX: sides[currentSide].size,
        sizeY: sides[prevSide].size,
      }),
      { start, middle, end, L, R, alpha, arcMiddle, m1, m2 } = points

    if (
      round &&
      (r1 >= sides[currentSide].size / 2 ||
        r2 >= sides[currentSide].size / 2 ||
        (r1 && start.equals(m1)) ||
        (r2 && end.equals(m2)))
    ) {
      this.onValidationWarnings([
        { message: 'Операция с указанным радиусом невозможна' },
      ])
      return
    }

    // cut end face
    this.pushEndFace(sides[currentSide].endFace, {
      end: 0,
      start: sides[currentSide].size,
    })
    this.pushEndFace(sides[prevSide].endFace, {
      end: sides[prevSide].size - start.y,
      start: sides[prevSide].size,
    })
    this.pushEndFace(sides[nextSide].endFace, {
      end: 0,
      start: end.y,
    })

    // measurements
    sides[prevSide].measurements.push({
      id,
      idCarve,
      margin: byrest ? 0 : sides[prevSide].size - Math.round(start.y),
      deep: true,
      width: byrest
        ? sides[prevSide].size - Math.round(start.y)
        : Math.round(start.y),
    })
    if (round && end.y != m2.y) {
      sides[nextSide].measurements.push({
        id,
        idCarve,
        margin: byrest ? Math.round(end.y) : 0,
        deep: true,
        width: byrest
          ? sides[nextSide].size - Math.round(end.y)
          : Math.round(end.y),
      })
    }
    // метрика радиуса
    sides[currentSide].measurements.push({
      customRadius: R,
      point: arcMiddle,
      level: 2,
      id,
      idCarve,
    })
    if (round) {
      const { m0, m3 } = points
      const metrics = []
      if (r1 && !start.equals(m0)) {
        metrics.push({ m: m0, r: r1, force: 'opposite', index: 1 })
      }
      if (r2 && !end.equals(m3)) {
        metrics.push({ m: m3, r: r2, force: 'prev', index: 2 })
      }
      metrics.forEach(({ m, r, force, index }) =>
        this.planMeasurementDiameter({
          sides,
          side: currentSide,
          force,
          params: {
            id,
            idCarve,
            x: m.x,
            y: m.y,
            diameter: 2 * r,
            text: `R${index}-${r}`,
            hover: true,
          },
        })
      )
    }

    sides[currentSide].operations.push({
      type: 'arc_side',
      points,
      id,
      idCarve,
    })
  }
  drawArcSide({ operation, side, sides, property, EDGE_OFFSET }) {
    const { points, hover, idCarve } = operation,
      { o1, o2, m0, m1, m2, m3, start, R, middle, end, center } = points,
      delta = 10

    const arc = new Path({
      segments: [start],
      strokeWidth: STROKE_WIDTH2,
      strokeColor: this.styles.pathColor,
    })
    arc.arcTo(m0, m1)
    arc.arcTo(middle, m2)
    arc.arcTo(m3, end)

    const normal = new Point(0, -1),
      arc1 = arc.clone(),
      arc2 = arc.clone()

    arc1.style.dashArray = [delta / 5, delta / 5]
    arc1.scale(1 + delta / R, center)
    arc2.scale(1 + (2 * delta) / R, center)

    const bg = this.cutOut(
      [...arc.segments, [sides[property].size, 0], [0, 0]],
      side
    )

    const clipRect = new Path.Rectangle(
      new Point(0, -2 * delta - STROKE_WIDTH2),
      new Point(end.x, Math.max(start.y, end.y) + STROKE_WIDTH2)
    )
    // clipRect.selected = true
    const group = new Group([clipRect, arc, arc1, arc2])
    group.clipped = true
    side.addChild(group)
    side.addChild(this.getDotCircle(end))
    if (hover) {
      this.hoverDrawings[idCarve].target = group
    }
    this.drawings.add(
      operation.idCarve,
      operation,
      bg,
      [arc, arc1, arc2],
      [arc]
    )
  }

  operateLineCorner({ item, sides }) {
    const { byrest, id, idCarve, round } = item,
      corner = Object.keys(item.angle).find(k => item.angle[k])
    if (!corner || !item.width || !item.height) return
    const currentSide = getSideOfCorner(corner),
      isRL = ['left', 'right'].includes(currentSide),
      w = Number(isRL ? item.height : item.width),
      h = Number(isRL ? item.width : item.height),
      r1 = Number(round ? item.rad_round : 0),
      r2 = Number(round ? item.rad_round1 : 0),
      prevSide = querySide(currentSide, 'prev')

    const width = byrest ? sides[currentSide].size - w : w,
      height = byrest ? sides[prevSide].size - h : h
    if (
      width <= 0 ||
      height <= 0 ||
      width > sides[currentSide].size ||
      height > sides[prevSide].size
    )
      return
    const points = calculateLineCorner({
        width,
        height,
        r1,
        r2,
        sizeX: sides[currentSide].size,
        sizeY: sides[prevSide].size,
      }),
      { start, end, m1, m2 } = points

    // cut end face
    this.pushEndFace(sides[currentSide].endFace, {
      end: 0,
      start: end.x,
    })
    this.pushEndFace(sides[prevSide].endFace, {
      end: sides[prevSide].size - start.y,
      start: sides[prevSide].size,
    })

    // measurements
    sides[currentSide].measurements.push({
      id,
      idCarve,
      margin: 0,
      deep: true,
      width: Math.round(m2.x),
    })
    if (round && end.x != m2.x) {
      sides[currentSide].measurements.push({
        id,
        idCarve,
        margin: Math.round(m2.x),
        deep: true,
        width: Math.round(end.x) - Math.round(m2.x),
      })
    }
    sides[currentSide].measurements.push({
      id,
      idCarve,
      margin: Math.round(end.x),
      deep: true,
      width: sides[currentSide].size - Math.round(end.x),
    })
    sides[prevSide].measurements.push({
      id,
      idCarve,
      margin: sides[prevSide].size - Math.round(m1.y),
      deep: true,
      width: Math.round(m1.y),
    })
    if (round && start.y != m1.y) {
      sides[prevSide].measurements.push({
        id,
        idCarve,
        margin: sides[prevSide].size - Math.round(start.y),
        deep: true,
        width: Math.round(start.y) - Math.round(m1.y),
      })
    }
    sides[prevSide].measurements.push({
      id,
      idCarve,
      margin: 0,
      deep: true,
      width: sides[prevSide].size - Math.round(start.y),
    })
    if (round) {
      const { m0, m3 } = points
      new Array({ m: m0, r: r1, index: 1 }, { m: m3, r: r2, index: 2 }).forEach(
        ({ m, r, index }) =>
          this.planMeasurementDiameter({
            sides,
            side: currentSide,
            force: 'opposite',
            params: {
              id,
              idCarve,
              x: m.x,
              y: m.y,
              diameter: 2 * r,
              text: `R${index}-${r}`,
              hover: true,
            },
          })
      )
    }

    // draw line corner
    sides[currentSide].operations.push({
      type: 'line_corner',
      points,
      width,
      height,
      r1,
      r2,
      round,
      id,
      idCarve,
      hover: true,
    })
  }
  drawLineCorner({
    operation,
    side,
    sides,
    property,
    EDGE_HEIGHT,
    heightScale,
  }) {
    const { width, height, points, hover, idCarve, round } = operation,
      delta = 10
    const { o1, o2, m0, m1, m2, m3, start, end } = points

    const line = new Path({
      segments: [start],
      strokeWidth: STROKE_WIDTH2,
      strokeColor: this.styles.pathColor,
    })
    line.arcTo(m0, m1)
    line.lineTo(m2)
    line.arcTo(m3, end)
    line.lineTo(end)

    const normal = line.getNormalAt(line.length / 2),
      line1 = line.clone(),
      line2 = line.clone()

    line1.style.dashArray = [delta / 5, delta / 5]
    line1.translate(normal.multiply(delta))
    line2.translate(normal.multiply(2 * delta))

    // обрезаем плоскость
    const bg = this.cutOut([...line.segments, [0, 0]], side)

    const clipRect = new Path.Rectangle(
      new Point(0, 0),
      new Size(end.x, start.y)
    )
    // clipRect.selected = true
    const group = new Group([clipRect, line, line1, line2])
    group.clipped = true
    side.addChild(group)
    // показываем точку входа
    side.addChild(this.getDotCircle(line1.firstSegment.point))
    this.drawings.add(
      operation.idCarve,
      operation,
      bg,
      [line, line1, line2],
      [line]
    )
    if (hover) {
      Object.assign(this.hoverDrawings[idCarve], {
        target: bg,
      })
    }

    // показываем размер
    const measurementNormal = new Array('bottom', 'left').includes(property)
      ? normal.multiply(-1)
      : normal
    const sideAngle = sides[property].angle
    const mH = heightScale / 2,
      angleMap = { 0: 0, 90: 0, 180: 180, 270: 180 },
      measurementGroup = new Group([
        drawArrow(
          {
            start: m1,
            end: m1.add(measurementNormal.multiply(mH)),
            size: heightScale,
            angle: 0,
          },
          {
            start: false,
            end: false,
          }
        ),
        drawArrow(
          {
            start: m2,
            end: m2.add(measurementNormal.multiply(mH)),
            size: heightScale,
            angle: 0,
          },
          {
            start: false,
            end: false,
          }
        ),
        drawArrow(
          {
            start: m1.add(measurementNormal.multiply(0.7 * mH)),
            end: m2.add(measurementNormal.multiply(0.7 * mH)),
            size: heightScale,
            angle: 0,
          },
          {
            start: true,
            end: true,
          },
          Math.round(m1.getDistance(m2)),
          angleMap[sideAngle]
        ),
      ])
    side.addChild(measurementGroup)
    if (hover) {
      Object.assign(this.hoverDrawings[idCarve], {
        target: bg,
        items: [measurementGroup],
      })
    }

    // показываем углы
    if (!round) {
      const dAngle = Math.min(heightScale / 2, width, height) / 2,
        textSize = 0.35 * heightScale,
        angleArc1 = new Path.Arc({
          from: [start.x, start.y + dAngle],
          through: [start.x + dAngle, start.y],
          to: line.getPointAt(dAngle),
          // selected: true
        }),
        angle1 = Math.round(90 - line.getTangentAt(dAngle).angle),
        angleLabel1 = new PointText({
          content: `${angle1}°`,
          fontSize: textSize,
          justification: 'center',
          fillColor: '#000000',
          point: start.add(
            new Point(0, 1).rotate(-angle1 / 4).multiply(2 * textSize)
          ),
          // selected: true
        }),
        angleArc2 = new Path.Arc({
          from: [end.x + dAngle, end.y],
          through: [end.x, end.y + dAngle],
          to: line.getPointAt(line.length - dAngle),
          // selected: true
        }),
        angle2 = 270 - angle1,
        angleLabel2 = new PointText({
          content: `${angle2}°`,
          fontSize: textSize,
          justification: 'center',
          fillColor: '#000000',
          point: end.add(
            new Point(1, 0).rotate(angle2 / 4).multiply(2 * textSize)
          ),
          // selected: true
        })
      angleLabel1.rotate(-sideAngle)
      angleLabel2.rotate(-sideAngle)
      const angleGroup = new Group({
        children: [angleArc1, angleLabel1, angleArc2, angleLabel2],
        data: { is: 'metric', metric: 'angle' },
      })
      if (angleLabel1.intersects(angleLabel2)) {
        angleLabel1.position.y += heightScale / 3
        angleLabel2.position.x += heightScale / 3
      }
      angleGroup.style = {
        strokeWidth: STROKE_WIDTH2,
        strokeColor: this.styles.pathColor,
      }
      side.addChild(angleGroup)
      if (hover) {
        this.hoverDrawings[idCarve].items.push(angleGroup)
      }
    }
  }
  operateCarveG({ item, sides }) {
    const { byrest, face, id, idCarve } = item,
      corner = Object.keys(item.angle).find(k => item.angle[k]),
      isFace = face && face.id == 2
    if (!corner || !item.width || !item.height) return
    const currentSide = getSideOfCorner(corner),
      isRL = ['left', 'right'].includes(currentSide),
      w = Number(isRL ? item.height : item.width),
      h = Number(isRL ? item.width : item.height),
      prevSide = querySide(currentSide, 'prev'),
      width = byrest ? sides[currentSide].size - w : w,
      height = byrest ? sides[prevSide].size - h : h,
      start = new Point(0, height),
      end = new Point(width, 0)
    if (
      width <= 0 ||
      height <= 0 ||
      width > sides[currentSide].size ||
      height > sides[prevSide].size
    )
      return

    // cut end face
    this.pushEndFace(sides[currentSide].endFace, {
      end: 0,
      start: width,
    })
    this.pushEndFace(sides[prevSide].endFace, {
      end: sides[prevSide].size - height,
      start: sides[prevSide].size,
    })

    // measurements
    sides[currentSide].measurements.push({
      id,
      idCarve,
      margin: 0,
      deep: true,
      width: end.x,
    })
    sides[currentSide].measurements.push({
      id,
      idCarve,
      margin: end.x,
      deep: true,
      width: sides[currentSide].size - end.x,
    })
    sides[prevSide].measurements.push({
      id,
      idCarve,
      margin: 0,
      deep: true,
      width: sides[prevSide].size - start.y,
    })
    sides[prevSide].measurements.push({
      id,
      idCarve,
      margin: sides[prevSide].size - start.y,
      deep: true,
      width: start.y,
    })

    // draw line corner
    sides[currentSide].operations.push({
      type: 'carve_g',
      start,
      end,
      isFace,
      id,
      idCarve,
    })
  }
  drawCarveG({ operation, side, EDGE_HEIGHT }) {
    const { start, end, isFace } = operation,
      delta = Math.min(EDGE_HEIGHT, start.y, end.x) / 8,
      // dashArray = [delta / 5, delta / 5]
      dashArray = DASH_ARRAY2.map(n => n / 4),
      tile = 5 * delta

    // обрезаем плоскость
    const bg = this.cutOut([start, [end.x, start.y], end, [0, 0]], side)
    const group = new Group([
      new Path({
        segments: [start, [end.x - 2 * delta, start.y]],
      }),
      new Path({
        segments: [
          [end.x + tile, start.y],
          [end.x, start.y],
        ],
      }),
      new Path({
        segments: [end, [end.x, start.y - 2 * delta]],
      }),
      new Path({
        segments: [
          [end.x, start.y + tile],
          [end.x, start.y],
        ],
      }),
      new Path({
        segments: [
          [end.x - 2 * delta, start.y + tile],
          [end.x - 2 * delta, start.y],
        ],
      }),
      new Path({
        segments: [
          [start.x, start.y - delta],
          [end.x + tile, start.y - delta],
        ],
        dashArray,
      }),
      new Path({
        segments: [
          [end.x - delta, start.y + tile],
          [end.x - delta, end.y],
        ],
        dashArray,
      }),
      new Path({
        segments: [
          [start.x, start.y - 2 * delta],
          [end.x - 2 * delta, start.y - 2 * delta],
        ],
      }),
      new Path({
        segments: [
          [end.x - 2 * delta, end.y],
          [end.x - 2 * delta, start.y - 2 * delta],
        ],
      }),
      new Path({
        segments: [
          [end.x + tile, start.y - 2 * delta],
          [end.x, start.y - 2 * delta],
        ],
      }),
    ])
    group.style = {
      strokeWidth: STROKE_WIDTH2,
      strokeColor: this.styles.pathColor,
    }
    if (!isFace) {
      const ch = group.children
      new Array(ch[1], ch[3], ch[4], ch[9]).forEach(
        p => (p.dashArray = dashArray)
      )
    }
    side.addChild(group)
    this.drawings.add(operation.idCarve, operation, bg, group, group)
  }
  operateCarveGcnc({ item, sides, heightScale }) {
    const { byrest, id, idCarve } = item,
      corner = Object.keys(item.angle).find(k => item.angle[k])
    if (!corner || !item.width || !item.height) return
    const currentSide = getSideOfCorner(corner),
      isRL = ['left', 'right'].includes(currentSide),
      w = Number(isRL ? item.height : item.width),
      h = Number(isRL ? item.width : item.height),
      r1 = Number(isRL ? item.radius3 : item.radius1),
      r2 = Number(item.radius2),
      r3 = Number(isRL ? item.radius1 : item.radius3),
      prevSide = querySide(currentSide, 'prev'),
      width = byrest ? sides[currentSide].size - w : w,
      height = byrest ? sides[prevSide].size - h : h,
      start = new Point(0, height),
      middle = new Point(width, height),
      end = new Point(width, 0),
      start0 = start.add([0, r1]),
      start1 = start.add([r1, r1]).subtract(new Point(r1, 0).rotate(45)),
      start2 = start.add([r1, 0]),
      r2R = Math.max(r2, 4),
      middle0 = middle.add([-r2R, 0]),
      middle1 = middle.add([-r2R, -r2R]).add(new Point(r2R, 0).rotate(45)),
      middle2 = middle.add([0, -r2R]),
      end0 = end.add([0, r3]),
      end1 = end.add([r3, r3]).subtract(new Point(r3, 0).rotate(45)),
      end2 = end.add([r3, 0])
    if (
      width <= 0 ||
      height <= 0 ||
      r1 < 0 ||
      r2 < 0 ||
      r3 < 0 ||
      end2.x > sides[currentSide].size ||
      start0.y > sides[prevSide].size
    )
      return

    if (width < r1 + r2 || height < r2 + r3) {
      this.onValidationWarnings([
        { message: 'Операция с указанными параметрами невозможна' },
      ])
      return
    }
    let invalid = false
    if (Number(r2) < 4) {
      invalid = true
      this.onValidationWarnings([
        { message: 'Минимальный радиус закругления 4 мм' },
      ])
    }

    // cut end face
    this.pushEndFace(sides[currentSide].endFace, {
      end: 0,
      start: end2.x,
    })
    this.pushEndFace(sides[prevSide].endFace, {
      end: sides[prevSide].size - start0.y,
      start: sides[prevSide].size,
    })

    // measurements
    sides[currentSide].measurements.push({
      id,
      idCarve,
      margin: 0,
      deep: end0.x,
      width: end0.x,
    })
    if (r3) {
      sides[currentSide].measurements.push({
        id,
        idCarve,
        margin: end0.x,
        deep: r3,
        width: r3,
      })
    }
    sides[currentSide].measurements.push({
      id,
      idCarve,
      margin: end2.x,
      deep: sides[currentSide].size - end2.x,
      width: sides[currentSide].size - end2.x,
    })
    sides[prevSide].measurements.push({
      id,
      idCarve,
      margin: 0,
      deep: sides[prevSide].size - start0.y,
      width: sides[prevSide].size - start0.y,
    })
    if (r1) {
      sides[prevSide].measurements.push({
        id,
        idCarve,
        margin: sides[prevSide].size - start0.y,
        deep: r1,
        width: r1,
      })
    }
    sides[prevSide].measurements.push({
      id,
      idCarve,
      margin: 0,
      deep: start2.y,
      width: start2.y,
      side: currentSide,
    })

    // метрика радиуса
    let mH1 = heightScale,
      mH2 = heightScale,
      mH3 = heightScale
    if (height < heightScale || width < heightScale) {
      mH1 = (isRL ? 1 : 2.5) * heightScale
      mH2 = 1.5 * heightScale
      mH3 = (isRL ? 2.5 : 1) * heightScale
    }
    if (r1) {
      this.planMeasurementDiameter({
        sides,
        side: currentSide,
        force: 'opposite',
        params: {
          id,
          idCarve,
          x: start1.x,
          y: start1.y,
          diameter: r1,
          text: `R${isRL ? 3 : 1}-${r1}`,
          height: mH1,
          hover: true,
        },
      })
    }
    if (r2) {
      this.planMeasurementDiameter({
        sides,
        side: currentSide,
        force: 'opposite',
        params: {
          id,
          idCarve,
          x: middle1.x,
          y: middle1.y,
          diameter: r2,
          text: `R2-${r2}`,
          height: mH2,
          hover: true,
        },
      })
    }
    if (r3) {
      this.planMeasurementDiameter({
        sides,
        side: currentSide,
        force: 'opposite',
        params: {
          id,
          idCarve,
          x: end1.x,
          y: end1.y,
          diameter: r3,
          text: `R${isRL ? 1 : 3}-${r3}`,
          height: mH3,
          hover: true,
        },
      })
    }

    // draw line corner
    sides[currentSide].operations.push({
      type: 'carve_gcnc',
      start,
      middle,
      end,
      start0,
      start1,
      start2,
      middle0,
      middle1,
      middle2,
      end0,
      end1,
      end2,
      r1,
      r2,
      r3,
      id,
      idCarve,
      hover: true,
      invalid,
    })
  }
  drawCarveGcnc({ operation, side, EDGE_HEIGHT }) {
    const {
        start,
        middle,
        end,
        start0,
        start1,
        start2,
        middle0,
        middle1,
        middle2,
        end0,
        end1,
        end2,
        r1,
        r2,
        r3,
        hover,
        idCarve,
        invalid,
      } = operation,
      delta = r2 > 10 ? 10 : 4

    const line = new Path()
    line.add(start0)
    line.arcTo(start1, start2)
    line.lineTo(middle0)
    line.arcTo(middle1, middle2)
    line.lineTo(end0)
    line.arcTo(end1, end2)

    const line1 = line.clone(),
      line2 = line.clone(),
      linesGroup = new Group([line, line1, line2])

    line1.translate([-delta, -delta])
    line2.translate([-2 * delta, -2 * delta])
    line1.style.dashArray = DASH_ARRAY2
    linesGroup.style = {
      strokeWidth: STROKE_WIDTH2,
      strokeColor: this.styles.pathColor,
    }
    // обрезаем плоскость
    const bg = this.cutOut([...line.segments, [0, 0]], side)

    const clipRect = new Path.Rectangle({
      from: [0, 0],
      to: middle.add(r3, r1),
      // selected: true
    })
    const group = new Group([clipRect, linesGroup])
    group.clipped = true
    side.addChild(group)
    // показываем точку входа
    side.addChild(this.getDotCircle(line.firstSegment.point))
    if (hover) {
      this.hoverDrawings[idCarve].target = bg
    }
    this.drawings.add(operation.idCarve, operation, bg, linesGroup, [line])
    if (invalid) {
      linesGroup.style.strokeColor = COLORS.danger
    }
  }
  getDotCircle(p) {
    const { x, y } = new Point(p)
    const group = new Group([
      new Path.Circle({
        center: [x, y],
        radius: this.dotCircleRadius / 5,
        fillColor: '#628EFF',
      }),
      new Path.Circle({
        center: [x, y],
        radius: this.dotCircleRadius / 2,
        strokeColor: '#628EFF',
        strokeWidth: STROKE_WIDTH1,
      }),
      new Path.Circle({
        center: [x, y],
        radius: this.dotCircleRadius,
        strokeColor: '#628EFF',
        strokeWidth: STROKE_WIDTH1,
        opacity: 0.5,
      }),
    ])

    return group
  }

  operateEllipseIn({ item, sides, heightScale, EDGE_HEIGHT }) {
    const {
        tiltangle,
        centerwidth,
        centerheight,
        id,
        idCarve,
        cross_cutting,
        drilling_depth_z,
        face,
      } = item,
      isFace = face && face.id == 2,
      corner = Object.keys(item.angle).find(k => item.angle[k])
    if (!corner || !item.width || !item.height || !centerwidth || !centerheight)
      return
    const currentSide = getSideOfCorner(corner),
      isRL = ['left', 'right'].includes(currentSide),
      width = Number(isRL ? item.height : item.width),
      height = Number(isRL ? item.width : item.height),
      center = new Point(
        isRL ? [centerheight, centerwidth] : [centerwidth, centerheight]
      ),
      prevSide = querySide(currentSide, 'prev'),
      tiltAngle = Number(tiltangle),
      depthZ = Number(drilling_depth_z)
    if (
      width < 30 ||
      height < 30 ||
      center.x - width / 2 < 0 ||
      center.x + width / 2 > sides[currentSide].size ||
      center.y - height / 2 < 0 ||
      center.y + height / 2 > sides[prevSide].size
    )
      return

    // вычисляем метрики расстояния
    // вычисление нетривиальные при наклоне эллипса
    const ellipse = new Path.Ellipse({
      center,
      radius: [width / 2, height / 2],
      selected: true,
    })
    ellipse.rotate(tiltAngle)
    const { bounds } = ellipse
    ellipse.remove() // убираем сременный эллипс

    const left = Math.round(bounds.x),
      right = Math.round(sides[currentSide].size - bounds.topRight.x),
      top = Math.round(bounds.y),
      bottom = Math.round(sides[prevSide].size - bounds.bottomLeft.y),
      bWidth = sides[currentSide].size - left - right,
      bHeight = sides[prevSide].size - top - bottom

    // метрики размеров
    sides[currentSide].measurements.push({
      id,
      idCarve,
      margin: left,
      deep: bWidth,
      width: bWidth,
      hover: true,
    })
    sides[prevSide].measurements.push({
      id,
      idCarve,
      margin: bottom,
      deep: bHeight,
      width: bHeight,
      hover: true,
    })

    // расстояния до краев
    sides[currentSide].measurements.push({
      id,
      idCarve,
      margin: 0,
      deep: left,
      width: left,
      hover: true,
    })
    sides[currentSide].measurements.push({
      id,
      idCarve,
      margin: sides[currentSide].size - right,
      deep: right,
      width: right,
      hover: true,
    })
    sides[prevSide].measurements.push({
      id,
      idCarve,
      margin: 0,
      deep: bottom,
      width: bottom,
      hover: true,
    })
    sides[prevSide].measurements.push({
      id,
      idCarve,
      margin: sides[prevSide].size - top,
      deep: top,
      width: top,
      hover: true,
    })
    if (tiltAngle) {
      // метрика наклона
      sides[currentSide].measurements.push({
        id,
        idCarve,
        tiltAngle,
        point: center,
        hover: true,
      })
    }

    // прорисовка элементов на торцах
    const drillDeep = cross_cutting
      ? EDGE_HEIGHT
      : Math.min(depthZ, EDGE_HEIGHT)
    sides[currentSide].operations.push({
      type: 'edge_rect',
      offset: center.x,
      width: bWidth,
      dashed: true,
      dividers: [center.x],
      height: drillDeep,
      isFace,
      id,
      idCarve,
    })
    const prevOffset = sides[prevSide].size - center.y
    sides[prevSide].operations.push({
      type: 'edge_rect',
      offset: prevOffset,
      width: bHeight,
      dashed: true,
      dividers: [prevOffset],
      height: drillDeep,
      isFace,
      id,
      idCarve,
    })

    // draw ellipse_in
    sides[currentSide].operations.push({
      type: 'ellipse_in',
      center,
      width,
      height,
      tiltAngle,
      cross_cutting,
      isFace,
      id,
      idCarve,
      hover: true,
    })
  }
  drawEllipseIn({ operation, side, sides, property, heightScale }) {
    const {
        center,
        width,
        height,
        tiltAngle,
        hover,
        idCarve,
        cross_cutting,
        isFace,
      } = operation,
      delta = 10

    const ellipse = new Path.Ellipse({
      center,
      radius: [width / 2, height / 2],
      // selected: true
    })
    const elGroup = new Group([ellipse])
    const clipRect = new Path.Rectangle(new Point(0, 0), [
      sides[property].size,
      sides[querySide(property, 'prev')].size,
    ])
    // clipRect.selected = true
    const group = new Group([clipRect, elGroup])
    if (cross_cutting) {
      const ellipse1 = new Path.Ellipse({
          center,
          radius: [width / 2 - delta, height / 2 - delta],
          dashArray: DASH_ARRAY2,
        }),
        ellipse2 = new Path.Ellipse({
          center,
          radius: [width / 2 - 2 * delta, height / 2 - 2 * delta],
        })
      elGroup.addChildren([ellipse1, ellipse2])
    }
    elGroup.rotate(tiltAngle)

    let dLines
    if (cross_cutting || isFace) {
      dLines = this.drawDiagonalLines(
        ellipse,
        side,
        heightScale / 6,
        ellipse.clone(),
        0
      )
      group.addChild(dLines)
    } else {
      ellipse.style.dashArray = DASH_ARRAY2
    }
    group.clipped = true
    elGroup.style = {
      strokeWidth: STROKE_WIDTH2,
      strokeColor: this.styles.pathColor,
    }
    ellipse.style = {
      fillColor: COLORS.transparentWhite,
    }
    side.addChild(group)
    if (hover) {
      Object.assign(this.hoverDrawings[idCarve], {
        target: elGroup,
        options: { hideOther: true },
      })
    }
    this.drawings.add(
      operation.idCarve,
      operation,
      ellipse,
      [elGroup, dLines].filter(p => !!p),
      [ellipse]
    )
  }

  operateSquareIn({ item, sides, heightScale, EDGE_HEIGHT }) {
    const {
        rad = 0,
        tiltangle,
        centerwidth,
        centerheight,
        id,
        idCarve,
        cross_cutting,
        drilling_depth_z,
        face,
      } = item,
      isFace = face && face.id == 2,
      corner = Object.keys(item.angle).find(k => item.angle[k])
    if (!corner || !item.width || !item.height || !centerwidth || !centerheight)
      return
    const currentSide = getSideOfCorner(corner),
      isRL = ['left', 'right'].includes(currentSide),
      width = Number(isRL ? item.height : item.width),
      height = Number(isRL ? item.width : item.height),
      center = new Point(
        isRL ? [centerheight, centerwidth] : [centerwidth, centerheight]
      ),
      prevSide = querySide(currentSide, 'prev'),
      tiltAngle = Number(tiltangle),
      depthZ = Number(drilling_depth_z),
      radius = Math.max(Number(rad), 4)
    if (
      width < 30 ||
      height < 30 ||
      center.x - width / 2 < 0 ||
      center.x + width / 2 > sides[currentSide].size ||
      center.y - height / 2 < 0 ||
      center.y + height / 2 > sides[prevSide].size
    )
      return

    let invalid = false
    if (Number(rad) < 4) {
      invalid = true
      this.onValidationWarnings([
        { message: 'Минимальный радиус закругления 4 мм' },
      ])
    }

    // вычисляем метрики расстояния
    // вычисление нетривиальные при наклоне эллипса
    const square = new Path.Rectangle({
      point: center.subtract([width / 2, height / 2]),
      size: [width, height],
      radius,
      selected: true,
    })
    square.rotate(tiltAngle)
    const { bounds } = square
    const left = Math.round(bounds.topLeft.x),
      right = Math.round(sides[currentSide].size - bounds.topRight.x),
      top = Math.round(bounds.topLeft.y),
      bottom = Math.round(sides[prevSide].size - bounds.bottomLeft.y),
      bWidth = sides[currentSide].size - left - right,
      bHeight = sides[prevSide].size - top - bottom
    // метрики размеров
    sides[currentSide].measurements.push({
      id,
      idCarve,
      margin: left,
      deep: bWidth,
      width: bWidth,
      hover: true,
    })
    sides[prevSide].measurements.push({
      id,
      idCarve,
      margin: bottom,
      deep: bHeight,
      width: bHeight,
      hover: true,
    })
    // расстояния до краев
    sides[currentSide].measurements.push({
      id,
      idCarve,
      margin: 0,
      deep: left,
      width: left,
      hover: true,
    })
    sides[currentSide].measurements.push({
      id,
      idCarve,
      margin: sides[currentSide].size - right,
      deep: right,
      width: right,
      hover: true,
    })
    sides[prevSide].measurements.push({
      id,
      idCarve,
      margin: 0,
      deep: bottom,
      width: bottom,
      hover: true,
    })
    sides[prevSide].measurements.push({
      id,
      idCarve,
      margin: sides[prevSide].size - top,
      deep: top,
      width: top,
      hover: true,
    })
    if (tiltAngle) {
      // метрика наклона
      sides[currentSide].measurements.push({
        id,
        idCarve,
        tiltAngle,
        point: center,
        hover: true,
      })
    }
    // метрика радиуса скругления угла
    if (radius) {
      // метрику радиуса ставим на угле, который ближе к центру детали,
      // чтобы избежать наложений на боковые метрики по сторонам детали
      const c = new Point(
          sides[currentSide].size / 2,
          sides[prevSide].size / 2
        ),
        { curves } = square
      let p
      for (var i = 0; i < curves.length; i += 2) {
        let curve = curves[i],
          point = curve.getPointAt(curve.length / 2)
        if (!p) p = point
        else if (p.getDistance(c) > point.getDistance(c)) p = point
      }
      // метрика должна выходить как продолжение радиуса,
      // поэтому строим ее ближе к нормали прямоугольника в найденой точке
      const normal = square.getNormalAt(square.getOffsetOf(p)),
        qMap = { 1: 'opposite', 2: 'prev', 3: 'current', 4: 'next' }

      this.planMeasurementDiameter({
        sides,
        side: currentSide,
        force: qMap[normal.quadrant],
        params: {
          id,
          idCarve,
          x: p.x,
          y: p.y,
          diameter: radius * 2,
          text: `R${radius}`,
          height: heightScale,
          hover: true,
        },
      })
    }
    square.remove() // убираем сременный прямоугольник

    // прорисовка элементов на торцах
    const drillDeep = cross_cutting
      ? EDGE_HEIGHT
      : Math.min(depthZ, EDGE_HEIGHT)
    sides[currentSide].operations.push({
      type: 'edge_rect',
      offset: center.x,
      width: bWidth,
      dashed: true,
      dividers: [center.x],
      height: drillDeep,
      isFace,
      id,
      idCarve,
    })
    const prevOffset = sides[prevSide].size - center.y
    sides[prevSide].operations.push({
      type: 'edge_rect',
      offset: prevOffset,
      width: bHeight,
      dashed: true,
      dividers: [prevOffset],
      height: drillDeep,
      isFace,
      id,
      idCarve,
    })

    // draw line corner
    sides[currentSide].operations.push({
      type: 'square_in',
      center,
      width,
      height,
      tiltAngle,
      cross_cutting,
      isFace,
      radius,
      id,
      idCarve,
      invalid,
      hover: true,
    })
  }
  drawSquareIn({ operation, side, sides, property, heightScale }) {
    const {
        center,
        width,
        height,
        tiltAngle,
        radius,
        hover,
        idCarve,
        invalid,
        cross_cutting,
        isFace,
      } = operation,
      delta = radius > 10 ? 20 : 8

    const square = new Path.Rectangle({
      point: center.subtract([width / 2, height / 2]),
      size: [width, height],
      radius,
      // selected: true
    })
    const sqGroup = new Group([square])
    const clipRect = new Path.Rectangle(new Point(0, 0), [
      sides[property].size,
      sides[querySide(property, 'prev')].size,
    ])
    // clipRect.selected = true
    const group = new Group([clipRect, sqGroup])
    if (cross_cutting) {
      const square1 = new Path.Rectangle({
          point: center.subtract([(width - delta) / 2, (height - delta) / 2]),
          size: [width - delta, height - delta],
          radius: Math.max(radius - delta / 2, 4),
          dashArray: DASH_ARRAY2,
          // selected: true
        }),
        square2 = new Path.Rectangle({
          point: center.subtract([
            (width - 2 * delta) / 2,
            (height - 2 * delta) / 2,
          ]),
          size: [width - 2 * delta, height - 2 * delta],
          radius: Math.max(radius - delta, 4),
          // selected: true
        })
      sqGroup.addChildren([square1, square2])
    }
    sqGroup.rotate(tiltAngle)

    let dLines
    if (cross_cutting || isFace) {
      const dLines = this.drawDiagonalLines(
        square,
        side,
        heightScale / 6,
        square.clone(),
        0
      )
      group.addChild(dLines)
    } else {
      square.style.dashArray = DASH_ARRAY2
    }
    group.clipped = true
    sqGroup.style = {
      strokeWidth: STROKE_WIDTH2,
      strokeColor: this.styles.pathColor,
    }
    square.style = {
      fillColor: COLORS.transparentWhite,
    }
    side.addChild(group)
    if (hover) {
      Object.assign(this.hoverDrawings[idCarve], {
        target: sqGroup,
        options: { hideOther: true },
      })
    }
    this.drawings.add(
      operation.idCarve,
      operation,
      square,
      [sqGroup, dLines].filter(p => !!p),
      [square]
    )
    if (invalid) {
      new Array(sqGroup, dLines).forEach(
        p => (p.style.strokeColor = COLORS.danger)
      )
    }
  }

  operateHdlSmile({ item, sides, heightScale }) {
    const {
        by_side_center: isSideCenter,
        corner_offset: isCornerOffset,
        id,
        idCarve,
      } = item,
      height = Number(item.deep),
      width = Number(item.width),
      radius = Number(item.rad),
      offset = Number(item.cutout_center_side),
      currentSide = Object.keys(item.place_second).find(
        s => item.place_second[s]
      )
    if (
      !currentSide ||
      radius <= 0 ||
      height <= 0 ||
      width < 130 ||
      (isCornerOffset && offset < 0)
    )
      return
    const prevSide = querySide(currentSide, 'prev')
    if (
      height <= 0 ||
      height > sides[prevSide].size ||
      width > sides[currentSide].size ||
      (isCornerOffset && width + offset > sides[currentSide].size)
    )
      return
    const start = new Point(
        (isSideCenter && sides[currentSide].size / 2 - width / 2) ||
          (isCornerOffset && offset) ||
          0,
        0
      ),
      middle = start.add([width / 2, height]),
      end = start.add([width, 0])

    // cut end face
    this.pushEndFace(sides[currentSide].endFace, {
      end: start.x,
      start: end.x,
    })

    // measurements
    sides[currentSide].measurements.push({
      id,
      idCarve,
      margin: 0,
      deep: start.x,
      width: start.x,
      hover: true,
    })
    sides[currentSide].measurements.push({
      id,
      idCarve,
      margin: start.x,
      deep: width,
      width: width,
      hover: true,
    })
    sides[prevSide].measurements.push({
      id,
      idCarve,
      margin: 0,
      deep: height,
      width: height,
      side: currentSide,
      hover: true,
    })
    const isDMStart =
        Math.abs(end.x - sides[currentSide].size / 2) >
        Math.abs(start.x - sides[currentSide].size / 2),
      dmPoint = isDMStart ? start : end
    this.planMeasurementDiameter({
      sides,
      side: currentSide,
      force: isDMStart ? 'prev' : 'opposite',
      params: {
        id,
        idCarve,
        x: dmPoint.x,
        y: dmPoint.y,
        diameter: 50,
        text: `R${radius}`,
        height: heightScale,
        hover: true,
      },
    })

    // эллипс
    sides[currentSide].operations.push({
      type: 'hdl_smile',
      start,
      middle,
      end,
      width,
      height,
      radius,
      id,
      idCarve,
      hover: true,
      // debug: true
    })
  }
  drawHdlSmile({ operation, side, sides, property, heightScale, EDGE_HEIGHT }) {
    const {
        start,
        middle,
        end,
        width: L,
        height: h,
        radius: r,
        hover,
        idCarve,
        debug,
      } = operation,
      delta = 10

    const R = h / 2 + Math.pow(L, 2) / (8 * h) - r,
      o1 = new Point(middle.x, h - R),
      o2 = new Point(middle.x - L / 2, r),
      k1 = start,
      k2 = o2.add(o1.subtract(o2).normalize(r)),
      k22 = k2.add([2 * (middle.x - k2.x), 0])

    const smile = new Path.Arc({
      from: k1,
      through: o2.add(new Point(r, 0).rotate(-89)),
      to: k2,
    })
    smile.arcTo(middle, k22)
    smile.arcTo(o2.add([L, 0]).add(new Point(r, 0).rotate(-91)), end)

    const smile1 = smile.clone(),
      smile2 = smile.clone(),
      smilesGroup = new Group([smile, smile1, smile2])
    // обрезаем плоскость
    const smileBg = this.cutOut([...smile.segments], side)

    smile1.translate([0, -delta])
    smile2.translate([0, -2 * delta])
    smileBg.closed = true
    const smileBg2 = smile2.clone()
    smileBg2.closed = true
    smileBg2.style = {
      fillColor: '#fff',
    }

    // smileBg.selected = true
    const clipRect = new Path.Rectangle(start, [L, h]),
      // smileArea = smileBg.clone(),
      dLines = this.drawDiagonalLines(
        smileBg,
        side,
        heightScale / 6,
        smileBg.clone(),
        45
      )
    // clipRect.selected = true

    const group = new Group([clipRect, smileBg, smilesGroup, dLines, smileBg2])
    group.clipped = true
    smilesGroup.style = {
      fillColor: '#fff',
      strokeWidth: STROKE_WIDTH2,
      strokeColor: this.styles.pathColor,
    }
    smile1.style.dashArray = DASH_ARRAY2
    smileBg.style.strokeColor = null
    // smileBg.selected = true
    side.addChild(group)
    // показываем точку входа
    side.addChild(this.getDotCircle(start))
    if (hover) {
      this.hoverDrawings[idCarve].target = group
    }
    this.drawings.add(
      operation.idCarve,
      operation,
      [smileBg, smileBg2],
      [smilesGroup, dLines],
      [smile]
    )
    if (debug) {
      smile.selected = true
      side.addChildren([
        drawArrow(
          {
            start: o2,
            end: k2,
            size: heightScale / 4,
            angle: 0,
          },
          {
            start: false,
            end: true,
          },
          r,
          0
        ),
        drawArrow(
          {
            start: o1,
            end: middle,
            size: heightScale / 4,
            angle: 0,
          },
          {
            start: false,
            end: true,
          },
          R,
          0
        ),
        drawArrow(
          {
            start: o2.add([L, 0]),
            end: k22,
            size: heightScale / 4,
            angle: 0,
          },
          {
            start: false,
            end: true,
          },
          r,
          0
        ),
      ])
    }
  }

  operateCarveGroove({ item, sides }) {
    const {
      rad,
      bycenterheight: byCenterY,
      bycenterwidth: byCenterX,
      edgeheight: isOffsetYTop,
      edgewidth: isOffsetXLeft,
      id,
      idCarve,
      has_holes: hasHoles,
      holes_offset_y: holesY,
      holes_offset_x: holesX,
    } = item
    if (!item.width || !item.height) return
    const currentSide = 'top',
      prevSide = querySide(currentSide, 'prev'),
      sizeX = sides[currentSide].size,
      sizeY = sides[prevSide].size,
      width = Number(item.width),
      height = Number(item.height),
      deep = Number(item.deep),
      offsetY = Number(item.offsetheight) || 0,
      offsetX = Number(item.offsetwidth) || 0,
      radius = Math.max(Number(rad) || 0, 4)
    let x = 0,
      y = 0
    if (byCenterX) {
      x = sizeX / 2 - width / 2
    } else {
      x = isOffsetXLeft ? offsetX : sizeX - offsetX - width
    }
    if (byCenterY) {
      y = sizeY / 2 - height / 2
    } else {
      y = isOffsetYTop ? offsetY : sizeY - offsetY - height
    }
    const start = new Point(x, y)
    const points = hasHoles
      ? [start.add([holesX, holesY]), start.add([width - holesX, holesY])]
      : []

    if (
      deep <= 0 ||
      width <= 0 ||
      height <= 0 ||
      x < 0 ||
      x + width > sizeX ||
      y < 0 ||
      y + height > sizeY ||
      radius > width / 2 ||
      radius > height / 2
    )
      return

    // метрики расстояния и размеров
    sides[currentSide].measurements.push({
      id,
      idCarve,
      margin: isOffsetXLeft ? 0 : x + width,
      deep: true,
      width: isOffsetXLeft ? x : offsetX,
      hover: true,
    })
    sides[currentSide].measurements.push({
      id,
      idCarve,
      margin: x,
      deep: true,
      width: width,
      hover: true,
    })
    sides[prevSide].measurements.push({
      id,
      idCarve,
      margin: isOffsetYTop ? sides[prevSide].size - y : 0,
      deep: true,
      width: isOffsetYTop ? y : offsetY,
      hover: true,
    })
    sides[prevSide].measurements.push({
      id,
      idCarve,
      margin: sides[prevSide].size - y - height,
      deep: height,
      width: height,
      hover: true,
    })
    // прорисовка элементов на торцах
    sides[currentSide].operations.push({
      type: 'edge_rect',
      offset: x + width / 2,
      dividers: [x + width / 2],
      width: width,
      dashed: true,
      height: deep,
      isFace: true,
      id,
      idCarve,
    })
    sides[prevSide].operations.push({
      type: 'edge_rect',
      offset: sides[prevSide].size - y - height / 2,
      dividers: [sides[prevSide].size - y - height / 2],
      width: height,
      dashed: true,
      height: deep,
      isFace: true,
      id,
      idCarve,
    })

    // прорисовка врезной ручки
    sides[currentSide].operations.push({
      type: 'carve_groove',
      hasHoles,
      points,
      start,
      width,
      height,
      radius,
      id,
      idCarve,
      hover: true,
    })
  }
  drawCarveGroove({
    operation,
    side,
    sides,
    property,
    heightScale,
    EDGE_HEIGHT,
  }) {
    const {
        start,
        width,
        height,
        radius,
        hover,
        idCarve,
        hasHoles,
        points,
        holeDiameter = 5,
      } = operation,
      delta = Math.min(heightScale / 20, width, height) / 2
    const square = new Path.Rectangle({
      point: start,
      size: [width, height],
      radius,
      // selected: true
    })

    const clipRect = new Path.Rectangle(new Point(0, 0), [
        sides[property].size,
        sides[querySide(property, 'prev')].size,
      ]),
      centerLine = new Path.Line({
        from: square.bounds.leftCenter,
        to: square.bounds.rightCenter,
        strokeWidth: STROKE_WIDTH2,
        dashArray: DASH_ARRAY2,
        strokeColor: this.styles.pathColor,
      })
    // clipRect.selected = true
    const dLines = this.drawDiagonalLines(
      square,
      side,
      heightScale / 6,
      square.clone(),
      45
    )
    const group = new Group([clipRect, square, centerLine, dLines])

    if (hasHoles) {
      points.forEach(center => {
        const node = new Path.Circle({
          center,
          radius: holeDiameter / 2,
          strokeWidth: STROKE_WIDTH2,
          strokeColor: this.styles.pathColor,
          fillColor: '#628EFF',
        })
        group.addChildren([node])
      })
    }

    group.clipped = true
    square.style = {
      fillColor: COLORS.transparent,
      strokeWidth: STROKE_WIDTH2,
      strokeColor: this.styles.pathColor,
    }
    side.addChild(group)
    side.addChild(
      this.getDotCircle(
        square.bounds.topCenter.add(new Point(0, this.dotCircleRadius / 3))
      )
    )
    if (hover) {
      this.hoverDrawings[idCarve].target = square
    }
    this.drawings.add(
      operation.idCarve,
      operation,
      square,
      [square, centerLine, dLines],
      [square]
    )
  }

  operateCarveLedDirect({ item, sides, EDGE_HEIGHT, EDGE_OFFSET }) {
    const {
        face,
        direction,
        idCarve,
        id,
        is_limited: isLimited,
        is_opposite_start: isOppositeStart,
      } = item,
      deep = Number(item.deep),
      margin = Number(item.offset),
      width = Number(item.width),
      startOffset = Number(item.start_offset),
      grooveLength = Number(item.groove_length),
      isFace = face && face.id == 2

    const selectedSide = direction ? 'top' : 'left'
    if (!selectedSide || !face || !width) return
    const prevSide = querySide(selectedSide, 'prev'),
      nextSide = querySide(selectedSide, 'next'),
      oppositeSide = querySide(selectedSide, 'opposite')
    let x0 = 0,
      x1 = sides[selectedSide].size
    if (isLimited) {
      x0 = startOffset
      x1 = startOffset + grooveLength
      if (isOppositeStart) {
        x1 = sides[selectedSide].size - startOffset
        x0 = x1 - grooveLength
      }
    }
    if (x0 < 0 || x1 > sides[selectedSide].size) {
      return
    }
    // метрики: отступ и ширина паза, глубины выреза
    sides[direction ? prevSide : nextSide].measurements.push({
      id,
      idCarve,
      margin: direction ? sides[prevSide].size - margin : 0,
      deep: margin,
      width: margin,
      hover: true,
    })
    sides[selectedSide].measurements.push({
      id,
      idCarve,
      margin: direction
        ? -EDGE_OFFSET - (isFace ? deep : EDGE_HEIGHT)
        : sides[selectedSide].size +
          EDGE_OFFSET +
          (isFace ? 0 : EDGE_HEIGHT - deep),
      deep: deep,
      width: deep,
      hover: true,
    })
    if (isLimited) {
      sides[selectedSide].measurements.push({
        id,
        idCarve,
        margin: x0,
        deep: true,
        width: x1 - x0,
        hover: true,
      })
      if (startOffset) {
        sides[selectedSide].measurements.push({
          id,
          idCarve,
          margin: isOppositeStart ? x1 : 0,
          deep: true,
          width: startOffset,
          hover: true,
        })
      }
    }
    sides[direction ? prevSide : nextSide].measurements.push({
      id,
      idCarve,
      margin: direction ? sides[prevSide].size - margin - width : margin,
      deep: width,
      width: width,
      hover: true,
    })
    // прорисовка паза
    sides[selectedSide].operations.push({
      type: 'carve_led_direct',
      deep,
      width,
      from: x0,
      to: x1,
      isLimited,
      isFace,
      margin,
      id,
      idCarve,
      hover: true,
    })
    // линии выреза на торцах
    if (isLimited) {
      sides[selectedSide].operations.push({
        type: 'edge_rect',
        offset: (x1 + x0) / 2,
        width: Math.abs(x1 - x0),
        height: deep,
        isFace,
        id,
        idCarve,
        dashed: true,
      })
      sides[oppositeSide].operations.push({
        type: 'edge_rect',
        offset: sides[oppositeSide].size - (x1 + x0) / 2,
        width: Math.abs(x1 - x0),
        height: deep,
        isFace,
        id,
        idCarve,
        dashed: true,
      })
    } else {
      sides[selectedSide].operations.push({
        type: 'edge_line',
        offset: deep,
        from: x0,
        to: x1,
        isFace,
        id,
        idCarve,
      })
      sides[oppositeSide].operations.push({
        type: 'edge_line',
        offset: deep,
        from: sides[oppositeSide].size - x1,
        to: sides[oppositeSide].size - x0,
        isFace,
        id,
        idCarve,
      })
    }
    // прямоугольныики выреза на торцах
    const touchesStart = x0 <= 0
    sides[prevSide].operations.push({
      type: 'edge_rect',
      offset: sides[prevSide].size - margin - width / 2,
      width,
      height: deep,
      isFace,
      id,
      idCarve,
      dashed: !touchesStart,
      through: touchesStart,
    })
    const touchesEnd = x1 >= sides[selectedSide].size
    sides[nextSide].operations.push({
      type: 'edge_rect',
      offset: margin + width / 2,
      width,
      height: deep,
      isFace,
      id,
      idCarve,
      dashed: !touchesEnd,
      through: touchesEnd,
    })
  }
  drawCarveLedDirect({ operation, side, sides, property, EDGE_HEIGHT }) {
    const {
      width,
      isFace,
      margin,
      idCarve,
      hover,
      deep,
      from,
      to,
      isLimited,
    } = operation
    const bg = new Path.Rectangle({
      from: [from, margin],
      to: [to, margin + width],
      fillColor: COLORS.transparent,
    })
    let line, line1
    if (isLimited) {
      const r = 4
      line = new Path.Rectangle({
        from: [from, margin],
        to: [to, margin + width],
        radius: r,
      })
      line1 = line.clone()
    } else {
      line = new Path.Line({
        from: bg.bounds.topLeft,
        to: bg.bounds.topRight,
      })
      line1 = new Path.Line({
        from: bg.bounds.bottomLeft,
        to: bg.bounds.bottomRight,
      })
    }
    const lineGroup = new Group([line, line1]),
      group = new Group({
        children: [bg, lineGroup],
        data: { is: 'template', template: operation.type },
        // selected: true,
      })
    lineGroup.style = {
      strokeWidth: STROKE_WIDTH2,
      strokeColor: this.styles.pathColor,
    }
    if (!isFace) {
      lineGroup.style.dashArray = DASH_ARRAY2
    }
    if (isFace) {
      const centerLine = new Path.Line({
        from: bg.bounds.leftCenter,
        to: bg.bounds.rightCenter,
        strokeWidth: STROKE_WIDTH2,
        dashArray: DASH_ARRAY2,
        strokeColor: this.styles.pathColor,
      })
      lineGroup.addChildren([centerLine])
    }
    side.addChild(group)
    if (hover) {
      this.hoverDrawings[idCarve].target = group
    }
    this.drawings.add(operation.idCarve, operation, bg, lineGroup, [
      line,
      line1,
    ])
    if (deep > EDGE_HEIGHT) {
      lineGroup.style = {
        strokeColor: COLORS.danger,
      }
      this.onValidationWarnings([
        {
          message: `Максимальная глубина виборки не може быть больше ${EDGE_HEIGHT} mm`,
        },
      ])
    }
  }

  operateCarveLedCorner({ item, sides, EDGE_HEIGHT, EDGE_OFFSET }) {
    const {
        face,
        vertical: isOffsetYTop,
        horizontal: isOffsetXLeft,
        offsetheight,
        offsetwidth,
        idCarve,
        id,
      } = item,
      deep = Number(item.deep),
      width = Number(item.width),
      isFace = face && face.id == 2

    let selectedSide = 'top'
    if (isOffsetYTop && isOffsetXLeft) selectedSide = 'top'
    else if (isOffsetYTop && !isOffsetXLeft) selectedSide = 'right'
    else if (!isOffsetYTop && !isOffsetXLeft) selectedSide = 'bottom'
    else if (!isOffsetYTop && isOffsetXLeft) selectedSide = 'left'

    if (
      !selectedSide ||
      !face ||
      !deep ||
      !width ||
      !offsetheight ||
      !offsetwidth
    )
      return

    const isRL = ['left', 'right'].includes(selectedSide),
      marginX = Number(isRL ? offsetheight : offsetwidth),
      marginY = Number(isRL ? offsetwidth : offsetheight),
      prevSide = querySide(selectedSide, 'prev'),
      sizeX = sides[selectedSide].size,
      sizeY = sides[prevSide].size,
      delta = width / 2

    let x0 = 0,
      x1 = sizeX - marginX,
      x2 = x1,
      y0 = sizeY - marginY,
      y1 = y0,
      y2 = 0
    const start = new Point(x0, y0 - delta),
      start1 = start.add([0, delta]),
      start2 = start.add([0, -delta]),
      middle = new Point(x1, y1).subtract(delta),
      middle1 = middle.add([-delta, -delta]),
      end = new Point(x2 - delta, y2),
      end1 = end.add([-delta, 0]),
      end2 = end.add([delta, 0]),
      points = { start, middle, end, start1, start2, middle1, end1, end2 }

    const detailsBounds = new Rectangle([0, 0], [sizeX, sizeY])
    if (Object.values(points).some(point => !detailsBounds.contains(point))) {
      this.onValidationWarnings([
        { message: 'Недопустимое значение параметров!' },
      ])
      return
    }

    // метрики
    sides[prevSide].measurements.push({
      id,
      idCarve,
      margin: 0,
      deep: true,
      width: sizeY - start1.y,
      hover: true,
    })
    sides[prevSide].measurements.push({
      id,
      idCarve,
      margin: sizeY - start1.y,
      deep: true,
      width: width,
      hover: true,
    })
    sides[selectedSide].measurements.push({
      id,
      idCarve,
      margin: end2.x,
      deep: true,
      width: marginX,
      hover: true,
    })
    sides[selectedSide].measurements.push({
      id,
      idCarve,
      margin: -EDGE_OFFSET - (isFace ? deep : EDGE_HEIGHT),
      deep: true,
      width: deep,
      hover: true,
    })
    sides[prevSide].operations.push({
      type: 'edge_rect',
      offset: sides[prevSide].size - start.y,
      width,
      height: deep,
      isFace,
      id,
      idCarve,
      dashed: false,
      through: true,
    })
    sides[selectedSide].operations.push({
      type: 'edge_rect',
      offset: end.x,
      width,
      height: deep,
      isFace,
      id,
      idCarve,
      dashed: false,
      through: true,
    })
    // прорисовка паза
    sides[selectedSide].operations.push({
      type: 'carve_led_corner',
      deep,
      width,
      delta,
      isFace,
      points,
      isOffsetXLeft,
      isOffsetYTop,
      id,
      idCarve,
      hover: true,
    })
  }
  drawCarveLedCorner({ operation, side, heightScale, EDGE_HEIGHT }) {
    const {
      width,
      isFace,
      points: { start, middle, end, start1, start2, middle1, end1, end2 },
      radius = 4,
      idCarve,
      hover,
      deep,
      delta,
    } = operation
    const bg = new Path({
      segments: [
        middle.add([delta - radius, delta]),
        start1,
        start2,
        middle1,
        end1,
        end2,
        middle.add([delta, delta - radius]),
      ],
      fillColor: COLORS.transparent,
      // selected: true,
    })
    bg.arcTo(
      middle
        .add([delta, delta])
        .subtract([radius, radius])
        .add(new Point(radius, 0).rotate(45)),
      bg.firstSegment.point
    )
    const segs = bg.segments,
      line = new Path({
        segments: [segs[1], segs[0], segs[6]],
        // selected: true,
      }),
      line1 = new Path({
        segments: [segs[2], segs[3]],
        // selected: true
      }),
      line2 = new Path({
        segments: [segs[4], segs[3]],
        // selected: true
      }),
      line3 = new Path({
        segments: [segs[5], segs[6]],
        // selected: true
      }),
      lineGroup = new Group([line, line1, line2, line3]),
      group = new Group({
        children: [bg, lineGroup],
        data: { is: 'template', template: operation.type },
      })
    lineGroup.style = {
      strokeWidth: STROKE_WIDTH2,
      strokeColor: this.styles.pathColor,
    }
    if (!isFace) {
      lineGroup.style.dashArray = DASH_ARRAY2
    }
    if (isFace) {
      const centerLine = new Path({
        segments: [start, middle, end],
        strokeWidth: STROKE_WIDTH2,
        dashArray: DASH_ARRAY2,
        strokeColor: this.styles.pathColor,
      })
      lineGroup.addChildren([centerLine])
    }
    side.addChild(group)

    side.addChild(this.getDotCircle(start))
    if (hover) {
      this.hoverDrawings[idCarve].target = group
    }
    this.drawings.add(operation.idCarve, operation, bg, lineGroup, lineGroup)
    if (deep > EDGE_HEIGHT) {
      lineGroup.style = {
        strokeColor: COLORS.danger,
      }
      this.onValidationWarnings([
        {
          message: `Максимальная глубина виборки не може быть больше ${EDGE_HEIGHT} mm`,
        },
      ])
    }
  }

  operateHinge35({ item, sides }) {
    const {
        diameter,
        number_of_loops,
        distance_from_corner_to_center_of_bowl_mm,
        distances_are_equal: distancesAreEqual,
        face,
        off_start,
        off_3,
        off_4,
        off_5,
        off_6,
        off_end,
        idCarve,
        id,
      } = item,
      isFace = face && face.id == 2
    const selectedSide = Object.keys(item.edge).find(k => item.edge[k])
    const corner = Object.keys(item.angle).find(k => item.angle[k])
    const cornerSide = getSideOfCorner(corner)
    if (!selectedSide || !corner) return
    const prevSide = querySide(selectedSide, 'prev')
    const nextSide = querySide(selectedSide, 'next')
    const oppositeSide = querySide(selectedSide, 'opposite')
    // если рисовать на предыдущей стороне угла
    const isOpposite = cornerSide != selectedSide

    const distance = Number(distance_from_corner_to_center_of_bowl_mm),
      radius = diameter / 2,
      delta = distance >= radius ? 0 : radius - distance,
      count = Number(number_of_loops),
      items = []
    let offsets = null
    if (distancesAreEqual) {
      offsets = Array(count)
        .fill(0)
        .map(
          (v, i) => (i + 1) * Math.round(sides[selectedSide].size / (count + 1))
        )
    } else {
      offsets = [off_start, off_end, off_3, off_4, off_5, off_6]
        .slice(0, count)
        .map(Number)
        .sort((a, b) => (a < b ? -1 : 1))
    }
    for (var i = 0; i < count; i++) {
      let offset = offsets[i],
        prev = i == 0 ? 0 : offsets[i - 1]
      items.push({
        prev: isOpposite ? sides[selectedSide].size - prev : prev,
        offset: i == 0 ? offset : offset - offsets[i - 1],
        x: isOpposite ? sides[selectedSide].size - offset : offset,
      })
    }
    // console.table(items)

    // прорисовка метрик
    items.forEach(({ offset, prev, x }) => {
      if (isOpposite) {
        sides[selectedSide].measurements.push({
          id,
          idCarve,
          margin: x,
          deep: offset,
          width: offset,
          hover: true,
        })
      } else {
        sides[cornerSide].measurements.push({
          id,
          idCarve,
          margin: prev,
          deep: offset,
          width: offset,
          hover: true,
        })
      }
    })
    if (isOpposite) {
      sides[cornerSide].measurements.push({
        id,
        idCarve,
        margin: 0,
        deep: distance,
        width: distance,
        hover: true,
      })
    } else {
      sides[prevSide].measurements.push({
        id,
        idCarve,
        margin: sides[prevSide].size - distance,
        deep: distance,
        width: distance,
        hover: true,
      })
    }
    // прорисовка петель
    sides[selectedSide].operations.push({
      type: 'hinge35',
      distance,
      diameter,
      delta,
      count,
      items,
      isOpposite,
      isFace,
      id,
      idCarve,
      hover: true,
    })
    // прорисовка элементов на торцах
    const drillDeep = 12.5
    items.forEach(({ x }) => {
      const dashed = distance > diameter / 2
      sides[selectedSide].operations.push({
        type: 'edge_rect',
        offset: x,
        width: diameter,
        dashed,
        dividers: [x],
        height: drillDeep,
        isFace,
        id,
        idCarve,
      })
      sides[oppositeSide].operations.push({
        type: 'edge_rect',
        offset: sides[oppositeSide].size - x,
        width: diameter,
        dashed,
        dividers: [sides[oppositeSide].size - x],
        height: drillDeep,
        isFace,
        id,
        idCarve,
      })
    })
    const lateralSize = diameter - delta,
      lateralDistance = Math.max(lateralSize / 2, distance)
    sides[prevSide].operations.push({
      type: 'edge_rect',
      offset: sides[prevSide].size - lateralDistance,
      width: lateralSize,
      dashed: !(isOpposite
        ? items[items.length - 1].x < diameter / 2
        : items[0].x < diameter / 2),
      dividers: distance > 0 ? [sides[prevSide].size - distance] : [],
      height: drillDeep,
      isFace,
      id,
      idCarve,
    })
    sides[nextSide].operations.push({
      type: 'edge_rect',
      offset: lateralDistance,
      width: lateralSize,
      dashed: !(isOpposite
        ? items[0].x > sides[selectedSide].size - diameter / 2
        : items[items.length - 1].x > sides[selectedSide].size - diameter / 2),
      dividers: distance > 0 ? [distance] : [],
      height: drillDeep,
      isFace,
      id,
      idCarve,
    })
  }
  drawHinge35({ operation, side }) {
    const {
      distance,
      diameter,
      isFace,
      delta,
      items,
      hover,
      idCarve,
    } = operation
    const radius = diameter / 2,
      dy = radius - delta,
      dx = Math.sqrt(Math.pow(radius, 2) - Math.pow(dy, 2))

    const hingeGroup = new Group(),
      hinges = []
    let invalid = false
    items.forEach(({ x }) => {
      const center = new Point(x, distance)
      let hinge = null
      if (delta) {
        const point1 = center.add([-1 * dx, -1 * dy])
        const point2 = center.add([0, radius])
        const point3 = center.add([dx, -1 * dy])

        hinge = new Path.Arc({
          from: point1,
          through: point2,
          to: point3,
        })
        hinge.closed = true
      } else {
        hinge = new Path.Circle({
          center,
          radius,
        })
      }
      hinge.style = {
        dashArray: isFace ? null : DASH_ARRAY,
        strokeWidth: STROKE_WIDTH1,
        strokeColor: this.styles.pathColor,
        fillColor: 'white',
      }
      hinges.push(hinge)
      if (
        hingeGroup.intersects(hinge) ||
        hingeGroup.contains(hinge.firstSegment.point)
      ) {
        invalid = true
      }
      hingeGroup.addChild(hinge)
      if (isFace) {
        const cv = new Point(radius, 0)
        const centerMark = new Group({
          children: [
            hinge.clone(),
            new Path.Line(center.subtract(cv), center.add(cv)),
            new Path.Line(
              center.subtract(cv.rotate(90)),
              center.add(cv.rotate(90))
            ),
          ],
          strokeColor: '#628EFF',
          strokeWidth: STROKE_WIDTH1,
          dashArray: DASH_ARRAY,
          clipped: true,
        })
        hingeGroup.addChild(centerMark)
      }
    })
    if (invalid) {
      this.onValidationWarnings([
        {
          message:
            'Недопустимое значение - отверствия слишком близко друг к другу',
        },
      ])
      hingeGroup.style = {
        strokeColor: COLORS.danger,
        selectedColor: COLORS.danger,
      }
    }
    side.addChild(hingeGroup)
    if (hover) {
      this.hoverDrawings[idCarve].target = hingeGroup
    }

    this.drawings.add(operation.idCarve, operation, hinges, hingeGroup, hinges)
  }

  operateLockSocket({ item, sides }) {
    const {
        number_of_nodes,
        offset_1st_node: offset1,
        offset_2nd_node: offset2,
        offset_3rd_node: offset3,
        idCarve,
        id,
        is_mill_the_knot_under_the_screed: hasNodes,
        mill_the_knot_under_the_screed,
        lock_length_mm_input,
      } = item,
      diameter = 35,
      R = 20,
      height = 40,
      corner = Object.keys(item.tt_lock_socket_angle).find(
        k => item.tt_lock_socket_angle[k]
      )
    this.markPostformingRoundings({
      pfSide: 'bottom',
      sides,
      double: item.double_rounding,
    })

    if (!corner) return
    const cornerSide = getSideOfCorner(corner),
      selectedSide = ['top', 'right'].includes(cornerSide) ? 'top' : 'bottom',
      isRL = ['left', 'right'].includes(cornerSide),
      prevSide = querySide(selectedSide, 'prev'),
      nextSide = querySide(selectedSide, 'next'),
      oppositeSide = querySide(selectedSide, 'opposite')

    const deep = Number(mill_the_knot_under_the_screed),
      distance = deep / 2 + height,
      width = Number(lock_length_mm_input),
      count = Number(number_of_nodes),
      items = (hasNodes ? [offset1, offset2, offset3] : [])
        .slice(0, count)
        .map(Number)
        .map(off => (isRL ? sides[selectedSide].size - off : off))
        .sort((a, b) => (a < b ? -1 : 1))
        .map((offset, i, offsets) => ({
          prev: i == 0 ? 0 : offsets[i - 1],
          offset: i == 0 ? offset : offset - offsets[i - 1],
          x: offset,
          next:
            i == offsets.length - 1 ? sides[selectedSide].size : offsets[i + 1],
        }))

    if (width > sides[selectedSide].size) {
      return
    }
    const start = new Point(0, height),
      end = new Point(width, 0),
      dx = R / Math.SQRT2,
      dy = R - dx,
      middle = new Point(width - (height - dy + dx), height),
      middle1 = middle.add([0, -R]).add(new Point(R, 0).rotate(45 * 1.5)),
      middle2 = new Point(middle.x + dx, height - dy)
    if (isRL) {
      new Array(start, end, middle, middle1, middle2).forEach(
        p => (p.x = sides[selectedSide].size - p.x)
      )
    }

    // прорисовка метрик
    if (hasNodes) {
      items.forEach(({ offset, prev, next, x }) => {
        if ((isRL ? next : prev) == x) return
        sides[selectedSide].measurements.push({
          id,
          idCarve,
          margin: isRL ? x : 0,
          deep: true,
          width: isRL ? sides[selectedSide].size - x : x,
          hover: true,
        })
      })
    }
    sides[selectedSide].measurements.push({
      id,
      idCarve,
      margin: 0,
      deep: width,
      width: width,
      side: isRL && nextSide,
      hover: true,
    })
    sides[isRL ? nextSide : prevSide].measurements.push({
      id,
      idCarve,
      margin: isRL ? height : 0,
      deep: sides[prevSide].size - height,
      width: sides[prevSide].size - height,
      hover: true,
    })
    // метрика радиуса
    this.planMeasurementDiameter({
      sides,
      side: selectedSide,
      force: isRL ? 'prev' : 'opposite',
      params: {
        id,
        idCarve,
        x: middle1.x,
        y: middle1.y,
        diameter: R * 2,
        text: `R${R}`,
        hover: true,
      },
    })

    // cut end face
    this.pushEndFace(sides[selectedSide].endFace, {
      end: Math.min(start.x, end.x),
      start: Math.max(start.x, end.x),
    })
    this.pushEndFace(sides[isRL ? nextSide : prevSide].endFace, {
      end: isRL ? 0 : sides[prevSide].size - height,
      start: isRL ? height : sides[prevSide].size,
    })
    // помечаем торец
    this.markPostformingRoundings({
      pfIsReversed: isRL,
      pfSide: corner.includes('top') ? 'top' : 'bottom',
      sides,
      double: item.double_rounding,
      pfHeight: height,
    })

    // прорисовка петель
    sides[selectedSide].operations.push({
      type: 'tt_lock_socket',
      distance,
      diameter,
      items,
      count,
      R,
      width,
      height,
      start,
      end,
      middle,
      middle1,
      middle2,
      isRL,
      id,
      idCarve,
      hover: true,
    })

    // прорисовка элементов на торцах
    if (hasNodes) {
      const drillDeep = 12.5
      items.forEach(({ x }) => {
        sides[oppositeSide].operations.push({
          type: 'edge_rect',
          offset: sides[oppositeSide].size - x,
          width: diameter,
          dividers: [sides[oppositeSide].size - x],
          height: drillDeep,
          isFace: false,
          id,
          idCarve,
        })
      })
      sides[prevSide].operations.push({
        type: 'edge_rect',
        offset: sides[prevSide].size - distance,
        width: diameter,
        dividers: [sides[prevSide].size - distance],
        height: drillDeep,
        isFace: false,
        id,
        idCarve,
      })
      sides[nextSide].operations.push({
        type: 'edge_rect',
        offset: distance,
        width: diameter,
        dividers: [distance],
        height: drillDeep,
        isFace: false,
        id,
        idCarve,
      })
    }
  }
  drawLockSocket({ operation, side, EDGE_HEIGHT }) {
    const {
        distance,
        diameter,
        items,
        count,
        R,
        width,
        height,
        start,
        end,
        middle,
        middle1,
        middle2,
        isRL,
        hover,
        idCarve,
      } = operation,
      radius = diameter / 2,
      delta = Math.min(EDGE_HEIGHT, height, width) / 2

    // прорисовка выборки

    const line = new Path.Line({
      from: start,
      to: middle,
      // selected: true
    })
    line.arcTo(middle1, middle2)
    line.lineTo(end)
    // обрезаем плоскость
    const bg = this.cutOut([...line.segments, [0, 0]], side)
    const line1 = line.clone(),
      line2 = line.clone(),
      linesGroup = new Group([line, line1, line2])

    line1.translate([isRL ? delta : -delta, -delta])
    line1.style.dashArray = DASH_ARRAY2
    line2.translate([(isRL ? 2 : -2) * delta, -2 * delta])
    linesGroup.style = {
      strokeWidth: STROKE_WIDTH2,
      strokeColor: this.styles.pathColor,
    }
    const clipRect = new Path.Rectangle({
      from: [start.x, 0],
      to: [end.x, start.y],
      // selected: true
    })
    const lockGroup = new Group([clipRect, linesGroup])
    lockGroup.clipped = true

    // прорисовка отверствий
    const nodesGroup = new Group(),
      group = new Group(),
      holes = []
    let invalid = false
    items.forEach(({ x }) => {
      const center = new Point(x, distance),
        node = new Path.Circle({
          center,
          radius,
          strokeWidth: STROKE_WIDTH2,
          strokeColor: this.styles.pathColor,
          fillColor: 'white',
          dashArray: DASH_ARRAY,
        })
      const w = 20
      const stick = new Group({
        children: [
          new Path.Line({
            from: center.add([-w / 2, 0]),
            to: [center.x - w / 2, height],
            dashArray: DASH_ARRAY,
          }),
          new Path.Line({
            from: center.add([+w / 2, 0]),
            to: [center.x + w / 2, height],
            dashArray: DASH_ARRAY,
          }),
        ],
        strokeWidth: STROKE_WIDTH2,
        strokeColor: this.styles.pathColor,
      })
      if (
        nodesGroup.intersects(node) ||
        nodesGroup.contains(node.firstSegment.point)
      ) {
        invalid = true
      }
      nodesGroup.addChildren([stick, node])
      holes.push(node)
    })
    if (invalid) {
      this.onValidationWarnings([
        {
          message:
            'Недопустимое значение - отверствия слишком близко друг к другу',
        },
      ])
      nodesGroup.style = {
        strokeColor: COLORS.danger,
        selectedColor: COLORS.danger,
      }
    }

    group.addChildren([lockGroup, nodesGroup])
    side.addChild(group)
    side.addChild(this.getDotCircle(end.add([isRL ? delta : -delta, 0])))
    if (hover) {
      this.hoverDrawings[idCarve].target = bg
    }
    this.drawings.add(
      operation.idCarve,
      operation,
      [...holes, bg],
      [linesGroup, nodesGroup],
      [...holes, line]
    )
  }

  operateLockPlug({ item, sides }) {
    const {
        number_of_nodes,
        offset_1st_node: offset1,
        offset_2nd_node: offset2,
        offset_3rd_node: offset3,
        idCarve,
        id,
        mill_the_knot_under_the_screed,
        is_mill_the_knot_under_the_screed: hasNodes,
      } = item,
      diameter = 35,
      R = 20,
      height = 40,
      corner = Object.keys(item.tt_lock_plug_angle).find(
        k => item.tt_lock_plug_angle[k]
      )
    this.markPostformingRoundings({
      pfSide: 'bottom',
      sides,
      double: item.double_rounding,
    })

    if (!corner) return
    const cornerSide = getSideOfCorner(corner),
      selectedSide = corner.includes('left') ? 'left' : 'right',
      isRL = ['left', 'right'].includes(cornerSide),
      prevSide = querySide(selectedSide, 'prev'),
      nextSide = querySide(selectedSide, 'next'),
      oppositeSide = querySide(selectedSide, 'opposite')

    const deep = Number(mill_the_knot_under_the_screed),
      distance = deep / 2,
      count = Number(number_of_nodes),
      items = (hasNodes ? [offset1, offset2, offset3] : [])
        .slice(0, count)
        .map(Number)
        .map(off => (isRL ? sides[selectedSide].size - off : off))
        .sort((a, b) => (a < b ? -1 : 1))
        .map((offset, i, offsets) => ({
          prev: i == 0 ? 0 : offsets[i - 1],
          offset: i == 0 ? offset : offset - offsets[i - 1],
          x: offset,
          next:
            i == offsets.length - 1 ? sides[selectedSide].size : offsets[i + 1],
        }))

    const dx = R / Math.SQRT2,
      dy = R - dx,
      start = new Point(0, height),
      middle = new Point(height - dy, dy),
      end = new Point(middle.x + dx, 0),
      middle1 = end.add([0, R]).subtract(new Point(R, 0).rotate(45 * 1.5))

    if (!isRL) {
      new Array(start, end, middle, middle1).forEach(
        p => (p.x = sides[selectedSide].size - p.x)
      )
    }

    // прорисовка метрик
    if (hasNodes) {
      items.forEach(({ offset, prev, next, x }) => {
        if ((isRL ? next : prev) == x) return
        sides[selectedSide].measurements.push({
          id,
          idCarve,
          margin: isRL ? x : 0,
          deep: true,
          width: isRL ? sides[selectedSide].size - x : x,
          hover: true,
        })
      })
    }
    // метрика радиуса
    this.planMeasurementDiameter({
      sides,
      side: selectedSide,
      force: isRL ? 'opposite' : 'prev',
      params: {
        id,
        idCarve,
        x: middle1.x,
        y: middle1.y,
        diameter: R * 2,
        text: `R${R}`,
        hover: true,
      },
    })

    // cut end face
    this.pushEndFace(sides[selectedSide].endFace, {
      end: Math.min(start.x, end.x),
      start: Math.max(start.x, end.x),
    })
    this.pushEndFace(sides[isRL ? prevSide : nextSide].endFace, {
      end: isRL ? sides[prevSide].size - height : 0,
      start: isRL ? sides[prevSide].size : height,
    })
    // помечаем торец
    this.markPostformingRoundings({
      pfIsReversed: isRL,
      pfSide: corner.includes('top') ? 'top' : 'bottom',
      sides,
      double: item.double_rounding,
      pfHeight: Math.abs(start.x - end.x),
    })

    // прорисовка петель
    sides[selectedSide].operations.push({
      type: 'tt_lock_plug',
      distance,
      diameter,
      items,
      count,
      R,
      height,
      start,
      end,
      middle,
      middle1,
      isRL,
      id,
      idCarve,
      hover: true,
    })

    if (hasNodes) {
      // прорисовка элементов на торцах
      const drillDeep = 12.5
      items.forEach(({ x }) => {
        sides[selectedSide].operations.push({
          type: 'edge_rect',
          offset: x,
          width: diameter,
          dividers: [x],
          height: drillDeep,
          isFace: false,
          id,
          idCarve,
        })
        sides[oppositeSide].operations.push({
          type: 'edge_rect',
          offset: sides[oppositeSide].size - x,
          width: diameter,
          dividers: [sides[oppositeSide].size - x],
          height: drillDeep,
          isFace: false,
          id,
          idCarve,
        })
      })
      sides[prevSide].operations.push({
        type: 'edge_rect',
        offset: sides[prevSide].size - distance,
        width: diameter,
        dividers: [sides[prevSide].size - distance],
        height: drillDeep,
        isFace: false,
        id,
        idCarve,
      })
      sides[nextSide].operations.push({
        type: 'edge_rect',
        offset: distance,
        width: diameter,
        dividers: [distance],
        height: drillDeep,
        isFace: false,
        id,
        idCarve,
      })
    }
  }
  drawLockPlug({ operation, side, EDGE_HEIGHT }) {
    const {
        distance,
        diameter,
        items,
        count,
        R,
        width,
        height,
        start,
        end,
        middle,
        middle1,
        isRL,
        hover,
        idCarve,
      } = operation,
      radius = diameter / 2,
      delta = height / 4,
      group = new Group()

    // прорисовка выборки

    const line = new Path.Line({
      from: start,
      to: middle,
      // selected: true
    })
    line.arcTo(middle1, end)
    // обрезаем плоскость
    const bg = this.cutOut([...line.segments, [start.x, 0]], side)
    const line1 = line.clone(),
      line2 = line.clone(),
      linesGroup = new Group([line, line1, line2])

    line1.translate([isRL ? -delta : delta, -delta])
    line1.style.dashArray = DASH_ARRAY2
    line2.translate([(isRL ? -2 : 2) * delta, -2 * delta])
    linesGroup.style = {
      strokeWidth: STROKE_WIDTH2,
      strokeColor: this.styles.pathColor,
    }
    // linesGroup.selected = true
    const clipRect = new Path.Rectangle({
      from: [start.x, 0],
      to: [end.x, start.y],
      // selected: true
    })
    const lockGroup = new Group([clipRect, linesGroup])
    lockGroup.clipped = true
    group.addChild(lockGroup)

    // прорисовка отверствий
    const nodesGroup = new Group(),
      holes = []
    let invalid = false
    if (items && items.length) {
      items.forEach(({ x }) => {
        const center = new Point(x, distance),
          node = new Path.Circle({
            center,
            radius,
            strokeWidth: STROKE_WIDTH2,
            strokeColor: this.styles.pathColor,
            fillColor: 'white',
            dashArray: DASH_ARRAY,
          })
        const w = 20
        const stick = new Group({
          children: [
            new Path.Line({
              from: center.add([-w / 2, 0]),
              to: [center.x - w / 2, 0],
              dashArray: DASH_ARRAY,
            }),
            new Path.Line({
              from: center.add([+w / 2, 0]),
              to: [center.x + w / 2, 0],
              dashArray: DASH_ARRAY,
            }),
          ],
          strokeWidth: STROKE_WIDTH2,
          strokeColor: this.styles.pathColor,
        })
        if (
          nodesGroup.intersects(node) ||
          nodesGroup.contains(node.firstSegment.point)
        ) {
          invalid = true
        }
        nodesGroup.addChildren([stick, node])
        holes.push(node)
      })
      group.addChild(nodesGroup)
    }
    if (invalid) {
      this.onValidationWarnings([
        {
          message:
            'Недопустимое значение - отверствия слишком близко друг к другу',
        },
      ])
      nodesGroup.style = {
        strokeColor: COLORS.danger,
        selectedColor: COLORS.danger,
      }
    }

    side.addChild(group)
    side.addChild(this.getDotCircle(start.add([0, -height / 4])))
    if (hover) {
      this.hoverDrawings[idCarve].target = bg
    }
    this.drawings.add(
      operation.idCarve,
      operation,
      [...holes, bg],
      [linesGroup, nodesGroup],
      [...holes, line]
    )
  }

  operateRadiusFlange({ item, sides }) {
    const {
        tt_radius_flange_angle: angle,
        tt_radius_flange_radius_mm,
        id,
        idCarve,
      } = item,
      corner = Object.keys(angle).find(k => angle[k])
    this.markPostformingRoundings({
      pfSide: 'bottom',
      sides,
      double: item.double_rounding,
    })
    if (!corner || !tt_radius_flange_radius_mm) return

    const cornerSide = getSideOfCorner(corner),
      selectedSide = ['top', 'right'].includes(cornerSide) ? 'top' : 'bottom',
      isRL = ['left', 'right'].includes(cornerSide),
      prevSide = querySide(selectedSide, 'prev'),
      nextSide = querySide(selectedSide, 'next'),
      R = Number(tt_radius_flange_radius_mm),
      r = 20,
      dy = 5,
      dx = Math.sqrt(Math.pow(r, 2) - Math.pow(r - dy, 2)),
      start = new Point(0, R + dy),
      middle = new Point(R, dy),
      middle0 = start.add([R, 0]).subtract(new Point(R, 0).rotate(1)),
      middle1 = middle.add([20, 0]),
      middle2 = middle1.add([0, -r]).add(new Point(r, 0).rotate(49)),
      end = middle1.add([dx, -dy])

    if (isRL) {
      new Array(start, end, middle, middle0, middle1, middle2).forEach(
        p => (p.x = sides[selectedSide].size - p.x)
      )
    }

    sides[cornerSide].measurements.push({
      innerRadius: R,
      translate: [0, dy],
      id,
      idCarve,
    })
    sides[selectedSide].operations.push({
      type: 'tt_radius_flange',
      R,
      r,
      start,
      middle,
      middle0,
      middle1,
      middle2,
      isRL,
      end,
      id,
      idCarve,
    })
    // cut end face
    this.pushEndFace(sides[selectedSide].endFace, {
      end: Math.min(start.x, end.x),
      start: Math.max(start.x, end.x),
    })
    this.pushEndFace(sides[isRL ? nextSide : prevSide].endFace, {
      end: isRL ? 0 : sides[prevSide].size - start.y,
      start: isRL ? start.y : sides[prevSide].size,
    })
    // помечаем торец
    this.markPostformingRoundings({
      pfIsReversed: isRL,
      pfSide: corner.includes('top') ? 'top' : 'bottom',
      sides,
      double: item.double_rounding,
      // pfHeight: start.y,
    })
  }
  drawRadiusFlange({ operation, side, EDGE_HEIGHT }) {
    const {
        start,
        middle,
        middle0,
        middle1,
        middle2,
        end,
        R,
        r,
        isRL,
        hover,
        idCarve,
      } = operation,
      delta = 10

    const line = Path.Arc({
      from: start,
      through: middle0,
      to: middle,
      strokeWidth: STROKE_WIDTH2,
      strokeColor: this.styles.pathColor,
    })
    line.lineTo(middle1)
    line.arcTo(middle2, end)
    const bg = this.cutOut([...line.segments, [start.x, 0]], side)
    const line1 = line.clone(),
      line2 = line.clone(),
      linesGroup = new Group([line, line1, line2])

    // line.selected = true
    line1.translate(new Point(delta, 0).rotate(isRL ? -45 : 45))
    line1.style.dashArray = DASH_ARRAY2
    line2.translate(new Point(2 * delta, 0).rotate(isRL ? -45 : 45))
    linesGroup.style = {
      strokeWidth: STROKE_WIDTH2,
      strokeColor: this.styles.pathColor,
    }
    const clipRect = new Path.Rectangle({
      from: [start.x, 0],
      to: [end.x, start.y],
      // selected: true,
    })
    let group = new Group([clipRect, linesGroup])
    group.clipped = true
    side.addChild(group)
    side.addChild(
      this.getDotCircle(end.add(new Point(delta, 0).rotate(isRL ? -30 : -150)))
    )
    if (hover) {
      this.hoverDrawings[idCarve].target = group
    }
    this.drawings.add(operation.idCarve, operation, bg, linesGroup, [line])
  }

  operateCornerCut({ item, sides }) {
    const {
        byrest,
        cut_height_parts_mm,
        slice_width_parts_mm,
        round,
        id,
        idCarve,
      } = item,
      corner = Object.keys(item.angle).find(k => item.angle[k])
    this.markPostformingRoundings({
      pfSide: 'bottom',
      sides,
      double: item.double_rounding,
    })
    if (!corner || !slice_width_parts_mm || !cut_height_parts_mm) return
    const currentSide = getSideOfCorner(corner),
      isRL = ['left', 'right'].includes(currentSide),
      w = Number(isRL ? cut_height_parts_mm : slice_width_parts_mm),
      h = Number(isRL ? slice_width_parts_mm : cut_height_parts_mm),
      r = Number(round ? item.rad_round : 0),
      r1 = isRL ? 0 : r,
      r2 = isRL ? r : 0,
      prevSide = querySide(currentSide, 'prev')

    const width = byrest ? sides[currentSide].size - w : w,
      height = byrest ? sides[prevSide].size - h : h
    if (
      width <= 0 ||
      height <= 0 ||
      width > sides[currentSide].size ||
      height > sides[prevSide].size
    )
      return
    const points = calculateLineCorner({
        width,
        height,
        r1,
        r2,
        sizeX: sides[currentSide].size,
        sizeY: sides[prevSide].size,
      }),
      { start, end, m1, m2 } = points

    // cut end face
    this.pushEndFace(sides[currentSide].endFace, {
      end: 0,
      start: end.x,
    })
    this.pushEndFace(sides[prevSide].endFace, {
      end: sides[prevSide].size - start.y,
      start: sides[prevSide].size,
    })
    this.markPostformingRoundings({
      pfIsReversed: isRL,
      pfSide: corner.includes('top') ? 'top' : 'bottom',
      sides,
      double: item.double_rounding,
      pfHeight: 0,
    })

    // measurements
    sides[currentSide].measurements.push({
      id,
      idCarve,
      margin: 0,
      deep: true,
      width: Math.round(m2.x),
    })
    if (round && end.x != m2.x) {
      sides[currentSide].measurements.push({
        id,
        idCarve,
        margin: Math.round(m2.x),
        deep: true,
        width: Math.round(end.x) - Math.round(m2.x),
      })
    }
    sides[currentSide].measurements.push({
      id,
      idCarve,
      margin: Math.round(end.x),
      deep: true,
      width: sides[currentSide].size - Math.round(end.x),
    })
    sides[prevSide].measurements.push({
      id,
      idCarve,
      margin: sides[prevSide].size - Math.round(m1.y),
      deep: true,
      width: Math.round(m1.y),
    })
    if (round && start.y != m1.y) {
      sides[prevSide].measurements.push({
        id,
        idCarve,
        margin: sides[prevSide].size - Math.round(start.y),
        deep: true,
        width: Math.round(start.y) - Math.round(m1.y),
      })
    }
    sides[prevSide].measurements.push({
      id,
      idCarve,
      margin: 0,
      deep: true,
      width: sides[prevSide].size - Math.round(start.y),
    })
    if (round) {
      const { m0, m3 } = points
      new Array({ m: m0, r: r1 }, { m: m3, r: r2 }).forEach(({ m, r }) =>
        this.planMeasurementDiameter({
          sides,
          side: currentSide,
          force: 'opposite',
          params: {
            id,
            idCarve,
            x: m.x,
            y: m.y,
            diameter: 2 * r,
            text: `R${r}`,
            hover: true,
          },
        })
      )
    }

    // draw line corner
    sides[currentSide].operations.push({
      type: 'tt_corner_cut',
      points,
      width,
      height,
      r1,
      r2,
      round,
      id,
      idCarve,
      hover: true,
    })
  }
  drawCornerCut({
    operation,
    side,
    sides,
    property,
    EDGE_HEIGHT,
    heightScale,
  }) {
    const { width, height, points, hover, idCarve, round } = operation,
      delta = 10
    const { o1, o2, m0, m1, m2, m3, start, end } = points

    const line = new Path({
      segments: [start],
      strokeWidth: STROKE_WIDTH2,
      strokeColor: this.styles.pathColor,
    })
    line.arcTo(m0, m1)
    line.lineTo(m2)
    line.arcTo(m3, end)
    line.lineTo(end)

    const normal = line.getNormalAt(line.length / 2),
      line1 = line.clone(),
      line2 = line.clone()

    line1.style.dashArray = [delta / 5, delta / 5]
    line1.translate(normal.multiply(delta))
    line2.translate(normal.multiply(2 * delta))

    // обрезаем плоскость
    const bg = this.cutOut([...line.segments, [0, 0]], side)

    const clipRect = new Path.Rectangle(
      new Point(0, 0),
      new Size(end.x, start.y)
    )
    // clipRect.selected = true
    const group = new Group([clipRect, line, line1, line2])
    group.clipped = true
    side.addChild(group)
    // показываем точку входа
    side.addChild(this.getDotCircle(line1.firstSegment.point))
    this.drawings.add(
      operation.idCarve,
      operation,
      bg,
      [line, line1, line2],
      [line]
    )
    if (hover) {
      Object.assign(this.hoverDrawings[idCarve], {
        target: bg,
      })
    }

    // показываем размер
    const measurementNormal = new Array('bottom', 'left').includes(property)
      ? normal.multiply(-1)
      : normal
    const sideAngle = sides[property].angle
    const mH = heightScale / 2,
      angleMap = { 0: 0, 90: 0, 180: 180, 270: 180 },
      measurementGroup = new Group([
        drawArrow(
          {
            start: m1,
            end: m1.add(measurementNormal.multiply(mH)),
            size: heightScale,
            angle: 0,
          },
          {
            start: false,
            end: false,
          }
        ),
        drawArrow(
          {
            start: m2,
            end: m2.add(measurementNormal.multiply(mH)),
            size: heightScale,
            angle: 0,
          },
          {
            start: false,
            end: false,
          }
        ),
        drawArrow(
          {
            start: m1.add(measurementNormal.multiply(0.7 * mH)),
            end: m2.add(measurementNormal.multiply(0.7 * mH)),
            size: heightScale,
            angle: 0,
          },
          {
            start: true,
            end: true,
          },
          Math.round(m1.getDistance(m2)),
          angleMap[sideAngle]
        ),
      ])
    side.addChild(measurementGroup)
    if (hover) {
      Object.assign(this.hoverDrawings[idCarve], {
        target: bg,
        items: [measurementGroup],
      })
    }

    // показываем углы
    if (!round) {
      const dAngle = Math.min(heightScale / 2, width, height) / 2,
        textSize = 0.35 * heightScale,
        angleArc1 = new Path.Arc({
          from: [start.x, start.y + dAngle],
          through: [start.x + dAngle, start.y],
          to: line.getPointAt(dAngle),
          // selected: true
        }),
        angle1 = Math.round(90 - line.getTangentAt(dAngle).angle),
        angleLabel1 = new PointText({
          content: `${angle1}°`,
          fontSize: textSize,
          justification: 'center',
          fillColor: '#000000',
          point: start.add(
            new Point(0, 1).rotate(-angle1 / 4).multiply(2 * textSize)
          ),
          // selected: true
        }),
        angleArc2 = new Path.Arc({
          from: [end.x + dAngle, end.y],
          through: [end.x, end.y + dAngle],
          to: line.getPointAt(line.length - dAngle),
          // selected: true
        }),
        angle2 = 270 - angle1,
        angleLabel2 = new PointText({
          content: `${angle2}°`,
          fontSize: textSize,
          justification: 'center',
          fillColor: '#000000',
          point: end.add(
            new Point(1, 0).rotate(angle2 / 4).multiply(2 * textSize)
          ),
          // selected: true
        })
      angleLabel1.rotate(-sideAngle)
      angleLabel2.rotate(-sideAngle)
      const angleGroup = new Group({
        children: [angleArc1, angleLabel1, angleArc2, angleLabel2],
        data: { is: 'metric', metric: 'angle' },
      })
      if (angleLabel1.intersects(angleLabel2)) {
        angleLabel1.position.y += heightScale / 3
        angleLabel2.position.x += heightScale / 3
      }
      angleGroup.style = {
        strokeWidth: STROKE_WIDTH2,
        strokeColor: this.styles.pathColor,
      }
      side.addChild(angleGroup)
      if (hover) {
        this.hoverDrawings[idCarve].items.push(angleGroup)
      }
    }
  }

  operateRectInside({ item, sides, heightScale }) {
    const {
        internal_radius_corners_cutout_mm,
        width_to_the_center_of_the_cutout_mm,
        height_to_center_of_cutout_mm,
        id,
        idCarve,
      } = item,
      corner = Object.keys(item.angle).find(k => item.angle[k])
    this.markPostformingRoundings({
      pfSide: 'bottom',
      sides,
      double: item.double_rounding,
    })
    if (
      !corner ||
      !item.width_cutout_mm ||
      !item.cutout_height_mm ||
      !width_to_the_center_of_the_cutout_mm ||
      !height_to_center_of_cutout_mm
    )
      return
    const currentSide = getSideOfCorner(corner),
      isRL = ['left', 'right'].includes(currentSide),
      width = Number(isRL ? item.cutout_height_mm : item.width_cutout_mm),
      height = Number(isRL ? item.width_cutout_mm : item.cutout_height_mm),
      center = new Point(
        isRL
          ? [
              height_to_center_of_cutout_mm,
              width_to_the_center_of_the_cutout_mm,
            ]
          : [
              width_to_the_center_of_the_cutout_mm,
              height_to_center_of_cutout_mm,
            ]
      ),
      prevSide = querySide(currentSide, 'prev'),
      radius = Math.max(Number(internal_radius_corners_cutout_mm), 4)
    if (
      width < 30 ||
      height < 30 ||
      center.x - width / 2 < 0 ||
      center.x + width / 2 > sides[currentSide].size ||
      center.y - height / 2 < 0 ||
      center.y + height / 2 > sides[prevSide].size
    )
      return

    // вычисляем метрики расстояния
    const square = new Path.Rectangle({
      point: center.subtract([width / 2, height / 2]),
      size: [width, height],
      radius,
      selected: true,
    })
    const { bounds } = square
    const left = Math.round(bounds.topLeft.x),
      right = Math.round(sides[currentSide].size - bounds.topRight.x),
      top = Math.round(bounds.topLeft.y),
      bottom = Math.round(sides[prevSide].size - bounds.bottomLeft.y),
      bWidth = sides[currentSide].size - left - right,
      bHeight = sides[prevSide].size - top - bottom
    // метрики размеров
    sides[currentSide].measurements.push({
      id,
      idCarve,
      margin: left,
      deep: true,
      width: bWidth,
      hover: true,
    })
    sides[prevSide].measurements.push({
      id,
      idCarve,
      margin: bottom,
      deep: true,
      width: bHeight,
      hover: true,
    })

    // метрики отступов
    sides[currentSide].measurements.push({
      id,
      idCarve,
      margin: 0,
      deep: true,
      width: left,
      hover: true,
    })
    sides[prevSide].measurements.push({
      id,
      idCarve,
      margin: sides[prevSide].size - top,
      deep: true,
      width: top,
      hover: true,
    })
    // метрика радиуса скругления угла
    if (radius) {
      // метрику радиуса ставим на угле, который ближе к центру детали,
      // чтобы избежать наложений на боковые метрики по сторонам детали
      const c = new Point(
          sides[currentSide].size / 2,
          sides[prevSide].size / 2
        ),
        { curves } = square
      let p
      for (var i = 0; i < curves.length; i += 2) {
        let curve = curves[i],
          point = curve.getPointAt(curve.length / 2)
        if (!p) p = point
        else if (p.getDistance(c) > point.getDistance(c)) p = point
      }
      // метрика должна выходить как продолжение радиуса,
      // поэтому строим ее ближе к нормали прямоугольника в найденой точке
      const normal = square.getNormalAt(square.getOffsetOf(p)),
        qMap = { 1: 'opposite', 2: 'prev', 3: 'current', 4: 'next' }

      this.planMeasurementDiameter({
        sides,
        side: currentSide,
        force: qMap[normal.quadrant],
        params: {
          id,
          idCarve,
          x: p.x,
          y: p.y,
          diameter: radius * 2,
          text: `R${radius}`,
          height: heightScale,
          hover: true,
        },
      })
    }
    square.remove() // убираем сременный прямоугольник

    // cut end face
    // Object.assign(sides['bottom'].edge, {
    //   isPostformed: true,
    //   pfHeight: 0,
    // })
    this.markPostformingRoundings({
      pfIsReversed: isRL,
      pfSide: corner.includes('top') ? 'top' : 'bottom',
      sides,
      double: item.double_rounding,
      // pfHeight: start.y,
    })

    // draw line corner
    sides[currentSide].operations.push({
      type: 'tt_rect_inside',
      center,
      width,
      height,
      radius,
      id,
      idCarve,
      hover: true,
    })
  }
  drawRectInside({
    operation,
    side,
    sides,
    property,
    heightScale,
    EDGE_HEIGHT,
  }) {
    const { center, width, height, radius, hover, idCarve } = operation,
      delta = radius > 10 ? 20 : 8

    const square = new Path.Rectangle({
        point: center.subtract([width / 2, height / 2]),
        size: [width, height],
        radius,
        // selected: true
      }),
      square1 = new Path.Rectangle({
        point: center.subtract([(width - delta) / 2, (height - delta) / 2]),
        size: [width - delta, height - delta],
        radius: Math.max(radius - delta / 2, 4),
        dashArray: DASH_ARRAY2,
        // selected: true
      }),
      square2 = new Path.Rectangle({
        point: center.subtract([
          (width - 2 * delta) / 2,
          (height - 2 * delta) / 2,
        ]),
        size: [width - 2 * delta, height - 2 * delta],
        radius: Math.max(radius - delta, 4),
        // selected: true
      }),
      sqGroup = new Group([square, square1, square2])
    // обрезаем плоскость
    const bg = this.cutOut([...square.segments], side)

    const clipRect = new Path.Rectangle(new Point(0, 0), [
      sides[property].size,
      sides[querySide(property, 'prev')].size,
    ])
    // clipRect.selected = true
    const group = new Group([clipRect, sqGroup])
    group.clipped = true
    sqGroup.style = {
      strokeWidth: STROKE_WIDTH2,
      strokeColor: this.styles.pathColor,
    }
    square.style = {
      fillColor: COLORS.transparent,
    }
    side.addChild(group)
    if (hover) {
      this.hoverDrawings[idCarve].target = square
    }
    this.drawings.add(operation.idCarve, operation, bg, sqGroup, [square])
  }

  operateRafix({ item, sides }) {
    const { distance_to_rafix_mm, face, idCarve, id } = item
    const distance = Number(distance_to_rafix_mm)
    const diameter = 20,
      delta = 0.5,
      isFace = face && face.id == 2
    const selectedSide = Object.keys(item.edge).find(k => item.edge[k])
    const corner = Object.keys(item.angle).find(k => item.angle[k])
    const cornerSide = getSideOfCorner(corner)
    if (
      !selectedSide ||
      !corner ||
      !distance ||
      // не рисовать при недопустимой комбинации угла и стороны
      ![cornerSide, querySide(cornerSide, 'prev')].includes(selectedSide)
    )
      return
    // если рисовать на предыдущей стороне угла
    const isOpposite = cornerSide != selectedSide
    if (isOpposite) {
      sides[selectedSide].measurements.push({
        id,
        idCarve,
        margin: 0,
        deep: distance,
        width: distance,
        side: selectedSide,
      })
    } else {
      sides[cornerSide].measurements.push({
        id,
        idCarve,
        margin: 0,
        deep: distance,
        width: distance,
      })
    }
    // прорисовка рафикса
    sides[selectedSide].operations.push({
      type: 'rafix',
      distance,
      isFace,
      isFace,
      diameter,
      delta,
      isOpposite,
      id,
      idCarve,
    })
    // прорисовка элементов на торцах
    const drillDeep = 12.5
    sides[selectedSide].operations.push({
      type: 'edge_rect',
      offset: isOpposite ? sides[selectedSide].size - distance : distance,
      width: diameter / 2 - delta,
      height: drillDeep,
      isFace,
      dashed: false,
      validate: true,
      id,
      idCarve,
    })
    sides[querySide(selectedSide, 'prev')].operations.push({
      type: 'edge_rect',
      offset:
        sides[querySide(selectedSide, 'prev')].size - (diameter - delta) / 2,
      width: diameter - delta,
      isFace,
      height: drillDeep,
      id,
      idCarve,
    })
    sides[querySide(selectedSide, 'next')].operations.push({
      type: 'edge_rect',
      offset: (diameter - delta) / 2,
      width: diameter - delta,
      height: drillDeep,
      isFace,
      id,
      idCarve,
    })
  }
  drawRafix({ operation, side, sides, property, heightScale }) {
    const { distance, isFace, diameter, delta, isOpposite } = operation
    const radius = diameter / 2,
      dy = radius - delta,
      dx = Math.sqrt(Math.pow(radius, 2) - Math.pow(dy, 2))

    const center = new Point(
      isOpposite ? sides[property].size - distance : distance,
      radius - delta
    )

    const point1 = center.add([-1 * dx, -1 * dy])
    const point2 = center.add([0, radius])
    const point3 = center.add([dx, -1 * dy])

    const rafix = new Path.Arc({
      from: point1,
      through: point2,
      to: point3,
      dashArray: !isFace && DASH_ARRAY,
    })
    rafix.closed = true
    rafix.style = {
      strokeWidth: STROKE_WIDTH2,
      strokeColor: this.styles.pathColor,
      fillColor: COLORS.transparent,
    }
    const group = new Group([rafix])
    side.addChild(group)
    if (isFace) {
      const dLines = this.drawDiagonalLines(
        rafix,
        side,
        (0.5 * heightScale) / diameter,
        rafix.clone()
      )
      group.addChild(dLines)
    }
    this.drawings.add(operation.idCarve, operation, rafix, group, [rafix])
  }

  operateMinifixShkant({ item, sides, EDGE_OFFSET, EDGE_HEIGHT }) {
    const {
      minifix_knot_distance_mm,
      minifix_knot_to_the_dowel,
      face,
      idCarve,
      id,
    } = item
    const indent = Number(minifix_knot_distance_mm)
    const distance = Number(minifix_knot_to_the_dowel)
    const sideHoleDiameter = 8,
      depth = 24,
      edgeHoleDiameter = 15,
      skantDeep = 23,
      skantDiameter = 8,
      isFace = face && face.id == 2
    const selectedSide = Object.keys(item.edge).find(k => item.edge[k])
    const corner = Object.keys(item.angle).find(k => item.angle[k])
    const cornerSide = getSideOfCorner(corner)
    if (!selectedSide || !corner || !indent | !distance) return
    // если рисовать на предыдущей стороне угла
    const isOpposite = cornerSide != selectedSide
    if (isOpposite) {
      // мертика минификса
      sides[selectedSide].measurements.push({
        id,
        idCarve,
        margin: sides[selectedSide].size - indent - distance,
        deep: distance,
        width: distance,
      })
      // метрика шканта
      sides[selectedSide].measurements.push({
        id,
        idCarve,
        margin: sides[selectedSide].size - indent,
        deep: indent,
        width: indent,
      })
    } else {
      // мертика минификса
      sides[cornerSide].measurements.push({
        id,
        idCarve,
        margin: 0,
        deep: indent,
        width: indent,
      })
      // метрика шканта
      sides[cornerSide].measurements.push({
        id,
        idCarve,
        margin: indent,
        deep: distance,
        width: distance,
      })
    }
    // прорисовка минификса (квадраты на краях, пунктирный прямоугольник и заштрихованный круг)
    sides[cornerSide].operations.push({
      type: 'minifix',
      indent: indent,
      isFace,
      sideHoleDiameter,
      depth,
      edgeHoleDiameter,
      isOpposite,
      id,
      idCarve,
    })
    // прорисовка элементов на торцах
    const drillDeep = 13.4
    sides[selectedSide].operations.push({
      type: 'edge_rect',
      offset: isOpposite ? sides[selectedSide].size - indent : indent,
      circle: {
        diameter: sideHoleDiameter,
        zOffset: isFace ? 0 - EDGE_OFFSET - 8 : -EDGE_OFFSET - EDGE_HEIGHT + 8,
      },
      width: edgeHoleDiameter,
      height: drillDeep,
      isFace,
      id,
      idCarve,
      validateCircle: true,
    })
    sides[querySide(selectedSide, 'prev')].operations.push({
      type: 'edge_rect',
      offset: sides[querySide(selectedSide, 'prev')].size - depth,
      width: edgeHoleDiameter,
      height: drillDeep,
      isFace,
      id,
      idCarve,
    })
    sides[querySide(selectedSide, 'next')].operations.push({
      type: 'edge_rect',
      offset: depth,
      width: edgeHoleDiameter,
      height: drillDeep,
      isFace,
      id,
      idCarve,
    })
    // шкант
    sides[cornerSide].operations.push({
      type: 'edge_hole',
      distance: distance + indent,
      deep: skantDeep,
      radius: skantDiameter / 2,
      isOpposite,
      zCenter: false,
      zOffset: isFace ? 8 : EDGE_HEIGHT - 8,
      id,
      idCarve,
    })
  }
  operateMinifix({ item, sides, EDGE_OFFSET, EDGE_HEIGHT }) {
    const { indent_mm, face, idCarve, id } = item
    const indent = Number(indent_mm)
    const sideHoleDiameter = 8,
      depth = 24,
      edgeHoleDiameter = 15,
      isFace = face && face.id == 2
    const selectedSide = Object.keys(item.edge).find(k => item.edge[k])
    const corner = Object.keys(item.angle).find(k => item.angle[k])
    const cornerSide = getSideOfCorner(corner)
    if (!selectedSide || !corner || !indent) return
    // если рисовать на предыдущей стороне угла
    const isOpposite = cornerSide != selectedSide
    if (isOpposite) {
      sides[selectedSide].measurements.push({
        id,
        idCarve,
        margin: 0,
        deep: indent,
        width: indent,
        side: selectedSide,
      })
    } else {
      sides[cornerSide].measurements.push({
        id,
        idCarve,
        margin: 0,
        deep: indent,
        width: indent,
      })
    }
    // прорисовка минификса (квадраты на краях, пунктирный прямоугольник и заштрихованный круг)
    sides[cornerSide].operations.push({
      type: 'minifix',
      indent: indent,
      isFace,
      sideHoleDiameter,
      depth,
      edgeHoleDiameter,
      isOpposite,
      id,
      idCarve,
    })
    // прорисовка элементов на торцах
    const drillDeep = 13.4
    sides[selectedSide].operations.push({
      type: 'edge_rect',
      offset: isOpposite ? sides[selectedSide].size - indent : indent,
      circle: {
        diameter: sideHoleDiameter,
        zOffset: isFace ? 0 - EDGE_OFFSET - 8 : -EDGE_OFFSET - EDGE_HEIGHT + 8,
      },
      width: edgeHoleDiameter,
      height: drillDeep,
      isFace,
      id,
      idCarve,
      validateCircle: true,
    })
    sides[querySide(selectedSide, 'prev')].operations.push({
      type: 'edge_rect',
      offset: sides[querySide(selectedSide, 'prev')].size - depth,
      width: edgeHoleDiameter,
      height: drillDeep,
      isFace,
      id,
      idCarve,
    })
    sides[querySide(selectedSide, 'next')].operations.push({
      type: 'edge_rect',
      offset: depth,
      width: edgeHoleDiameter,
      height: drillDeep,
      isFace,
      id,
      idCarve,
    })
  }
  drawMinifix({ operation, side, heightScale }) {
    const {
      indent,
      sideHoleDiameter,
      depth,
      edgeHoleDiameter,
      isOpposite,
      isFace,
    } = operation
    const point = [indent - sideHoleDiameter / 2, 0]
    const size = [sideHoleDiameter, depth]
    let minifixRectTemp = new Path.Rectangle({
      point: isOpposite ? point.reverse() : point,
      size: isOpposite ? size.reverse() : size,
      strokeWidth: STROKE_WIDTH1,
      strokeColor: this.styles.pathColor,
      dashArray: DASH_ARRAY,
      fillColor: 'white',
    })
    const center = [indent, depth]
    let minifixCircle = new Path.Circle({
      center: isOpposite ? center.reverse() : center,
      radius: edgeHoleDiameter / 2,
      strokeColor: this.styles.pathColor,
      strokeWidth: STROKE_WIDTH2,
      fillColor: 'white',
      dashArray: !isFace && DASH_ARRAY,
    })
    let minifixRect = minifixRectTemp.subtract(minifixCircle)
    minifixRectTemp.remove()
    const group = new Group([minifixRect, minifixCircle])
    side.addChild(group)
    if (isFace) {
      const dLines = this.drawDiagonalLines(
        minifixCircle,
        side,
        (0.5 * heightScale) / edgeHoleDiameter,
        minifixCircle.clone()
      )
      group.addChild(dLines)
    }
    this.drawings.add(
      operation.idCarve,
      operation,
      [minifixRect, minifixCircle],
      group,
      [minifixRect, minifixCircle]
    )
  }

  operateEdgeHole({ item, sides, EDGE_OFFSET, EDGE_HEIGHT }) {
    let placeSecond = Object.keys(item.place_second).find(
      k => item.place_second[k]
    )
    const corner = Object.keys(item.angle).find(k => item.angle[k])
    let {
      tool_param_f,
      distance_mm,
      deep,
      mid_by_z_center: zCenter,
      z_offset,
      idCarve,
      id,
    } = item
    if (!placeSecond || !corner || !tool_param_f) return
    const zOffset = Number(z_offset)
    const radius = Number(tool_param_f) / 2
    const property = getSideOfCorner(corner)
    // если рисовать на предыдущей стороне угла
    const isOpposite = property != placeSecond
    if (isOpposite) {
      // center position metrics
      sides[getOpposite(property)].measurements.push({
        id,
        idCarve,
        margin: 0,
        deep: Number(distance_mm),
        width: Number(deep),
        hover: true,
        side: property,
      })
      // deep metrics
      sides[property].measurements.push({
        id,
        idCarve,
        margin: 0,
        deep: Number(deep),
        width: Number(deep),
        hover: true,
      })
      // radius metrics
      this.planMeasurementDiameter({
        sides,
        side: placeSecond,
        params: {
          id,
          idCarve,
          x: sides[placeSecond].size - Number(distance_mm),
          y: 0 - EDGE_OFFSET - (zCenter ? EDGE_HEIGHT / 2 : zOffset),
          diameter: radius * 2,
          hover: true,
        },
      })
    } else {
      // center position metrics
      sides[property].measurements.push({
        id,
        idCarve,
        margin: 0,
        deep: Number(deep),
        width: Number(distance_mm),
        hover: true,
      })
      // deep metrics
      sides[getOpposite(property)].measurements.push({
        id,
        idCarve,
        deep: Number(deep),
        width: Number(deep),
        hover: true,
        side: property,
      })
      // radius metrics
      this.planMeasurementDiameter({
        sides,
        side: property,
        params: {
          id,
          idCarve,
          x: Number(distance_mm),
          y: 0 - EDGE_OFFSET - (zCenter ? EDGE_HEIGHT / 2 : zOffset),
          diameter: radius * 2,
          hover: true,
        },
      })
    }
    // круг на торце, прямоугольник сверления на плоскости
    sides[property].operations.push({
      type: 'edge_hole',
      distance: Number(distance_mm),
      deep: Number(deep),
      radius: Number(radius),
      isOpposite,
      zCenter,
      zOffset,
      id,
      idCarve,
      hover: true,
    })
  }
  drawEdgeHole({ operation, side, EDGE_OFFSET, EDGE_HEIGHT }) {
    const {
      radius,
      distance,
      deep,
      idCarve,
      isOpposite,
      zCenter = true,
      zOffset,
      zinkovka = 0,
      hover,
    } = operation
    const center = [
      distance,
      0 - EDGE_OFFSET - (zCenter ? EDGE_HEIGHT / 2 : zOffset),
    ]
    let edgeHoleCircle = new Path.Circle({
      center: isOpposite ? center.reverse() : center,
      radius,
      strokeColor: this.styles.pathColor,
      strokeWidth: STROKE_WIDTH2,
      fillColor: 'white',
    })
    const zR = Math.max(zinkovka, radius)
    const points = [
      [distance + zR, 0],
      [distance + radius, zR],
      [distance + radius, deep],
      [distance - radius, deep],
      [distance - radius, zR],
      [distance - zR, 0],
    ]
    let edgeHolePath = new Path({
      segments: isOpposite ? points.map(p => p.reverse()) : points,
      strokeWidth: STROKE_WIDTH1,
      strokeColor: this.styles.pathColor,
      dashArray: DASH_ARRAY,
      fillColor: 'white',
    })
    const edgeHoleGroup = new Group([edgeHoleCircle, edgeHolePath])
    side.addChild(edgeHoleGroup)
    if (hover) {
      this.hoverDrawings[idCarve].target = edgeHoleGroup
    }
    this.drawings.add(
      operation.idCarve,
      operation,
      edgeHolePath,
      edgeHoleGroup,
      [edgeHolePath, edgeHoleCircle]
    )
  }

  operateSideHole({ item, sides, EDGE_HEIGHT }) {
    for (const property in item.angle) {
      if (item.angle[property]) {
        const {
            tool_param_f,
            distance_y_mm,
            distance_x_mm,
            id,
            idCarve,
            drilling_depth_z,
            cross_cutting,
            face,
          } = item,
          isFace = face && face.id == 2
        const side = getSideOfCorner(property),
          isRL = ['left', 'right'].includes(side),
          distanceX = Number(isRL ? distance_y_mm : distance_x_mm),
          distanceY = Number(isRL ? distance_x_mm : distance_y_mm),
          diameter = Number(tool_param_f),
          depthZ = Number(drilling_depth_z),
          prevSide = querySide(side, 'prev')
        if (!tool_param_f || !distanceX || !distanceY) return
        // center position metrics
        sides[side].measurements.push({
          id,
          idCarve,
          margin: 0,
          deep: distanceY,
          width: distanceX,
          // width_saw: Number(item.width_saw)
          hover: true,
        })
        sides[getOpposite(side)].measurements.push({
          id,
          idCarve,
          deep: distanceY,
          width: distanceY,
          offset: distanceY,
          side: side,
          hover: true,
        })
        // radius metrics
        this.planMeasurementDiameter({
          sides,
          side,
          params: {
            id,
            idCarve,
            x: distanceX,
            y: distanceY,
            diameter,
            hover: true,
          },
        })
        // side hole
        sides[side].operations.push({
          type: 'side_hole',
          x: distanceX,
          y: distanceY,
          diameter,
          cross_cutting: item.cross_cutting,
          isFace,
          id,
          idCarve,
        })
        // прорисовка элементов на торцах
        const drillDeep = cross_cutting
          ? EDGE_HEIGHT
          : Math.min(depthZ, EDGE_HEIGHT)
        sides[side].operations.push({
          type: 'edge_rect',
          offset: distanceX,
          width: diameter,
          dashed: true,
          dividers: [distanceX],
          height: drillDeep,
          isFace,
          id,
          idCarve,
        })
        const prevOffset = sides[prevSide].size - distanceY
        sides[prevSide].operations.push({
          type: 'edge_rect',
          offset: prevOffset,
          width: diameter,
          dashed: true,
          dividers: [prevOffset],
          height: drillDeep,
          isFace,
          id,
          idCarve,
        })
      }
    }
  }
  drawSideHole({ operation, side }) {
    const { x, y, diameter, isFace, cross_cutting, idCarve } = operation,
      center = new Point(x, y),
      radius = diameter / 2,
      group = new Group()
    let sideHole = new Path.Circle({
      center,
      radius,
      strokeColor: this.styles.pathColor,
      strokeWidth: STROKE_WIDTH2,
      fillColor: COLORS.white,
    })
    group.addChild(sideHole)
    // Если сверление сквозное то показываем круг сплошной линией
    // Если сверление не сквозное
    // - если выбрано лицо круг сплошной
    // - если выбран тыл круг пунктирный
    if (!cross_cutting && !isFace) {
      sideHole.dashArray = DASH_ARRAY
      sideHole.strokeWidth = STROKE_WIDTH1
    }
    if (isFace || cross_cutting) {
      const cv = new Point(radius, 0)
      const centerMark = new Group({
        children: [
          sideHole.clone(),
          new Path.Line(center.subtract(cv), center.add(cv)),
          new Path.Line(
            center.subtract(cv.rotate(90)),
            center.add(cv.rotate(90))
          ),
        ],
        strokeColor: '#628EFF',
        strokeWidth: STROKE_WIDTH1,
        dashArray: DASH_ARRAY,
        clipped: true,
      })
      group.addChild(centerMark)
    }
    side.addChild(group)
    this.hoverDrawings[idCarve].target = group
    // console.log(this.hoverDrawings)
    this.drawings.add(operation.idCarve, operation, sideHole, group, [sideHole])
  }

  validateOperations(sides) {
    for (const property in sides) {
      // Валидируем вырезы
      sides[property].operations.forEach(op => {
        if (op.type === 'arc_side_inner') {
          op.validated = true
        }
        if (op.type === 'radius_inner') {
          op.validated = true
        }
        if (op.type === 'radius_outer') {
          op.validated = true
        }
        if (op.type === 'carve_p') {
          op.validated = true
        }
        if (op.type === 'groove_edge') {
          op.validated = true
        }
        if (op.type === 'groove_side') {
          op.validated = true
        }
        if (op.type === 'groove_end') {
          op.validated = true
        }
        if (op.type === 'groove') {
          op.validated = true
        }
        if (op.type === 'arc_corner') {
          op.validated = true
        }
        if (op.type === 'arc_side') {
          op.validated = true
        }
        if (op.type === 'line_corner') {
          op.validated = true
        }
        if (op.type === 'carve_g') {
          op.validated = true
        }
        if (op.type === 'carve_gcnc') {
          op.validated = true
        }
        if (op.type === 'ellipse_in') {
          op.validated = true
        }
        if (op.type === 'square_in') {
          op.validated = true
        }
        if (op.type === 'hdl_smile') {
          op.validated = true
        }
        if (op.type === 'carve_groove') {
          op.validated = true
        }
        if (op.type === 'carve_led_direct') {
          op.validated = true
        }
        if (op.type === 'carve_led_corner') {
          op.validated = true
        }
        if (op.type === 'edge_line') {
          op.validated = op.offset >= 0
        }
        if (op.type === 'edge_rect') {
          const rectStart = op.offset - op.width / 2,
            rectEnd = op.offset + op.width / 2,
            sidePoints = sides[property].points,
            withinDetail =
              rectStart >= sidePoints[0].x &&
              rectEnd <= sidePoints[sidePoints.length - 1].x
          const withinEdge = !sides[property].endFace.some(
            ({ start, end }) =>
              (end < rectStart && rectStart < start) ||
              (end < rectEnd && rectEnd < start)
          )
          op.validated = withinDetail && withinEdge
        }
      })
      // Валидируем постформинг
      sides[property].operations.forEach(op => {
        if (op.type === 'tt_lock_socket') {
          op.validated = true
        }
        if (op.type === 'tt_lock_plug') {
          op.validated = true
        }
        if (op.type === 'tt_radius_flange') {
          op.validated = true
        }
        if (op.type === 'tt_corner_cut') {
          op.validated = true
        }
        if (op.type === 'tt_rect_inside') {
          op.validated = true
        }
      })
      // Валидируем сверления
      sides[property].operations.forEach(op => {
        if (op.type === 'side_hole') {
          op.validated = op.diameter > 0
        }
        if (op.type === 'edge_hole') {
          op.validated = op.radius > 0
        }
        if (op.type === 'minifix') {
          op.validated = op.indent > 0
        }
        if (op.type === 'minifix_shkant') {
          op.validated = op.indent > 0 && op.distance > 0
        }
        if (op.type === 'rafix') {
          // Отступ до рафикс  – (в мм) без ограничений но с предупреждением если расстояние меньше 30мм.
          op.validated = op.distance >= 10
        }
        if (op.type === 'hinge35') {
          op.validated = true
        }
      })
      // console.log(
      //   property,
      //   sides[property].operations.filter(operation => !operation.validated)
      // )
    }
  }
  get heightScale() {
    return Math.max(this.size[0], this.size[1]) / 6
  }
  get dotCircleRadius() {
    return this.heightScale / 5
  }
  drawOperations() {
    toggleHoverEvents(false, this)
    toggleClickEvents(false, this)
    this.drawings.clear()
    this.hoverDrawings = buildEmptyHoverDrawings(this)
    const { heightScale, dotCircleRadius } = this
    const sides = {
      top: {
        size: this.size[0],
        points: [new Point(0, 0), new Point(this.size[0], 0)],
        position: new Point(0, 0),
        measurements: [],
        operations: [],
        angle: 0,
        endFace: [],
        edge: {
          selected: false,
          angle: 45,
          isFace: true,
        },
        markers: {},
      },
      right: {
        size: this.size[1],
        points: [new Point(0, 0), new Point(this.size[1], 0)],
        position: new Point(this.size[0], 0),
        measurements: [],
        operations: [],
        angle: 90,
        endFace: [],
        edge: {
          selected: false,
          angle: 45,
          isFace: true,
        },
        markers: {},
      },
      bottom: {
        size: this.size[0],
        points: [new Point(0, 0), new Point(this.size[0], 0)],
        position: new Point(this.size[0], this.size[1]),
        measurements: [],
        operations: [],
        angle: 180,
        endFace: [],
        edge: {
          selected: false,
          angle: 45,
          isFace: true,
        },
        markers: {},
      },
      left: {
        size: this.size[1],
        points: [new Point(0, 0), new Point(this.size[1], 0)],
        position: new Point(0, this.size[1]),
        measurements: [],
        operations: [],
        angle: 270,
        endFace: [],
        edge: {
          selected: false,
          angle: 45,
          isFace: true,
        },
        markers: {},
      },
    }
    // Размер и отступ торца
    const EDGE_HEIGHT = this.thickness,
      EDGE_OFFSET = EDGE_HEIGHT

    // Прямоугольный (П-образный) вырез
    this.operations
      .filter(operation => operation.template === 'carve_p')
      .forEach(groove => {
        groove.item.forEach(item => this.operateCarveP({ item, sides }))
      })
    // Торец
    this.operations
      .filter(operation => operation.template === 'groove_edge')
      .forEach(groove => {
        groove.item.forEach(item =>
          this.operateGrooveEdge({
            item,
            sides,
            heightScale,
            EDGE_HEIGHT,
            EDGE_OFFSET,
          })
        )
      })
    // Паз в торце
    this.operations
      .filter(operation => operation.template === 'groove_end')
      .forEach(groove => {
        groove.item.forEach(item =>
          this.operateGrooveEnd({ item, sides, EDGE_HEIGHT, EDGE_OFFSET })
        )
      })
    // Паз
    this.operations
      .filter(operation => operation.template === 'groove')
      .forEach(groove => {
        groove.item.forEach(item =>
          this.operateGroove({ item, sides, EDGE_HEIGHT, EDGE_OFFSET })
        )
      })
    // Четверть
    this.operations
      .filter(operation => operation.template === 'groove_side')
      .forEach(groove => {
        groove.item.forEach(item =>
          this.operateGrooveSide({ item, sides, EDGE_HEIGHT, EDGE_OFFSET })
        )
      })
    // Вогнутый радиус
    this.operations
      .filter(operation => operation.template === 'arc_side_inner')
      .forEach(op => {
        op.item.forEach(item => this.operateArcSideInner({ item, sides }))
      })
    // Радиус наружный
    this.operations
      .filter(operation => operation.template === 'radius_inner')
      .forEach(op => {
        op.item.forEach(item => this.operateRadiusInner({ item, sides }))
      })
    // Радиус внутренний
    this.operations
      .filter(operation => operation.template === 'radius_outer')
      .forEach(op => {
        op.item.forEach(item => this.operateRadiusOuter({ item, sides }))
      })
    // Дуга
    this.operations
      .filter(operation => operation.template === 'arc_corner')
      .forEach(op => {
        op.item.forEach(item =>
          this.operateArcCorner({ item, sides, heightScale })
        )
      })
    // Дуга 2
    this.operations
      .filter(operation => operation.template === 'arc_side')
      .forEach(op => {
        op.item.forEach(item => this.operateArcSide({ item, sides }))
      })
    // Срез угла
    this.operations
      .filter(operation => operation.template === 'line_corner')
      .forEach(op => {
        op.item.forEach(item => this.operateLineCorner({ item, sides }))
      })
    // Г образный вырез на форматно раскроечном станке
    this.operations
      .filter(operation => operation.template === 'carve_g')
      .forEach(op => {
        op.item.forEach(item => this.operateCarveG({ item, sides }))
      })
    // Г-образный вырез на ЧПУ
    this.operations
      .filter(operation => operation.template === 'carve_gcnc')
      .forEach(op => {
        op.item.forEach(item =>
          this.operateCarveGcnc({ item, sides, heightScale })
        )
      })
    // Круг/ эллипс
    this.operations
      .filter(operation => operation.template === 'ellipse_in')
      .forEach(op => {
        op.item.forEach(item =>
          this.operateEllipseIn({
            item,
            sides,
            heightScale,
            EDGE_OFFSET,
            EDGE_HEIGHT,
          })
        )
      })
    // Прямоугольник
    this.operations
      .filter(operation => operation.template === 'square_in')
      .forEach(op => {
        op.item.forEach(item =>
          this.operateSquareIn({
            item,
            sides,
            heightScale,
            EDGE_OFFSET,
            EDGE_HEIGHT,
          })
        )
      })
    // Ручка улыбка
    this.operations
      .filter(operation => operation.template === 'hdl_smile')
      .forEach(op => {
        op.item.forEach(item =>
          this.operateHdlSmile({ item, sides, heightScale })
        )
      })
    // Врезная ручка
    this.operations
      .filter(operation => operation.template === 'carve_groove')
      .forEach(op => {
        op.item.forEach(item =>
          this.operateCarveGroove({ item, sides, heightScale })
        )
      })
    // Выборка под лед профиль
    this.operations
      .filter(operation => operation.template === 'carve_led_direct')
      .forEach(op => {
        op.item.forEach(item =>
          this.operateCarveLedDirect({
            item,
            sides,
            heightScale,
            EDGE_OFFSET,
            EDGE_HEIGHT,
          })
        )
      })
    // Выборка под LED профиль углом
    this.operations
      .filter(operation => operation.template === 'carve_led_corner')
      .forEach(op => {
        op.item.forEach(item =>
          this.operateCarveLedCorner({
            item,
            sides,
            heightScale,
            EDGE_OFFSET,
            EDGE_HEIGHT,
          })
        )
      })
    // Замок -20мм
    this.operations
      .filter(operation => operation.template === 'tt_lock_socket')
      .forEach(op => {
        op.item.forEach(item =>
          this.operateLockSocket({ item, sides, heightScale })
        )
      })
    // Замок +20мм
    this.operations
      .filter(operation => operation.template === 'tt_lock_plug')
      .forEach(op => {
        op.item.forEach(item =>
          this.operateLockPlug({ item, sides, heightScale })
        )
      })
    // Радиус на постформинге
    this.operations
      .filter(operation => operation.template === 'tt_radius_flange')
      .forEach(op => {
        op.item.forEach(item =>
          this.operateRadiusFlange({ item, sides, heightScale })
        )
      })
    // Срез со стороны софта
    this.operations
      .filter(operation => operation.template === 'tt_corner_cut')
      .forEach(op => {
        op.item.forEach(item =>
          this.operateCornerCut({ item, sides, heightScale })
        )
      })
    // Вырез под мойку
    this.operations
      .filter(operation => operation.template === 'tt_rect_inside')
      .forEach(op => {
        op.item.forEach(item =>
          this.operateRectInside({ item, sides, heightScale })
        )
      })
    // Петли
    this.operations
      .filter(operation => operation.template === 'hinge35')
      .forEach(hinge => {
        hinge.item.forEach(item => this.operateHinge35({ item, sides }))
      })
    // Рафикс
    this.operations
      .filter(operation => operation.template === 'rafix')
      .forEach(rafix => {
        rafix.item.forEach(item => this.operateRafix({ item, sides }))
      })
    // Узел-минификс + шкант
    this.operations
      .filter(operation => operation.template === 'minifix_shkant')
      .forEach(minifixShkant => {
        minifixShkant.item.forEach(item =>
          this.operateMinifixShkant({ item, sides, EDGE_OFFSET, EDGE_HEIGHT })
        )
      })
    // Узел-минификс
    this.operations
      .filter(operation => operation.template === 'minifix')
      .forEach(minifix => {
        minifix.item.forEach(item =>
          this.operateMinifix({ item, sides, EDGE_OFFSET, EDGE_HEIGHT })
        )
      })
    // Произвольное отверстие в торце edge_hole
    this.operations
      .filter(operation => operation.template === 'edge_hole')
      .forEach(edgeHole => {
        edgeHole.item.forEach(item =>
          this.operateEdgeHole({ item, sides, EDGE_OFFSET, EDGE_HEIGHT })
        )
      })
    // Произвольное отверстие в плоскости side_hole
    this.operations
      .filter(operation => operation.template === 'side_hole')
      .forEach(sideHole => {
        sideHole.item.forEach(item =>
          this.operateSideHole({ item, sides, EDGE_OFFSET, EDGE_HEIGHT })
        )
      })
    // Валидация операций
    this.validateOperations(sides)
    // Рисуем операции
    let operations = new Group()
    for (const property in sides) {
      let side = new Group()
      // Рисуем сторону
      let sidePathGroup = new Group([
        new Path({
          segments: sides[property].points,
          strokeColor: this.styles.pathColor,
          strokeWidth: STROKE_WIDTH2,
          // selected: property == 'top',
        }),
      ])
      side.addChild(sidePathGroup)
      if (sides[property].endFace.length) {
        // делаем обрезку сторон детали путем разделения линии
        // и обесцвечивания обрезаемой части
        // обрезаем сторону плоскости от начала
        const { start } =
          sides[property].endFace.find(({ end }) => end == 0) || {}
        if (start) {
          sidePathGroup.firstChild.splitAt(
            sidePathGroup.firstChild.getLocationOf([start, 0])
          )
          sidePathGroup.firstChild.strokeColor = null
        }
        // делаем внутренние разрывы боковой линии
        sides[property].endFace
          .filter(({ start, end }) => end != 0 && start != sides[property].size)
          .forEach(({ start, end }) => {
            const sidePath = sidePathGroup.children.find(path =>
              path.contains([end, 0])
            )
            const path = sidePath.splitAt(sidePath.getLocationOf([end, 0]))
            path.splitAt(path.getLocationOf([start, 0]))
            path.strokeColor = null
          })
        // обрезаем сторону плоскости от конца
        const { end } =
          sides[property].endFace.find(
            ({ start }) => start == sides[property].size
          ) || {}
        if (end) {
          sidePathGroup.lastChild.splitAt(
            sidePathGroup.lastChild.getLocationOf([end, 0])
          )
          sidePathGroup.lastChild.strokeColor = null
        }
      }
      if (property == 'top') {
        const bgOp =
          this.operations &&
          this.operations.find(({ template }) => template == 'background')
        renderTextureDirection({
          side,
          sides,
          property,
          EDGE_OFFSET,
          EDGE_HEIGHT,
          heightScale,
          size: this.size,
          backgroundURL: bgOp && bgOp.backgroundURL,
        })
      }
      sides[property].operations
        .sort(({ type: type1 }, { type: type2 }) => {
          const cuttings = [
            'arc_side_inner',
            'radius_inner',
            'radius_outer',
            'groove_side',
            'arc_corner',
            'arc_side',
            'line_corner',
            'carve_g',
            'carve_gcnc',
            'carve_p',
            'ellipse_in',
            'square_in',
            'hdl_smile',
            'carve_groove',
            'carve_led_direct',
            'carve_led_corner',
            'tt_lock_socket',
            'tt_lock_plug',
            'tt_radius_flange',
            'tt_corner_cut',
            'tt_rect_inside',
          ]
          const isCutting1 = cuttings.includes(type1),
            isCutting2 = cuttings.includes(type2)
          if (isCutting1 && isCutting2) {
            return 0
          } else if (isCutting1) {
            return 1
          } else {
            return -1
          }
        })
        .forEach(operation => {
          // Рисуем срез торца
          if (operation.type === 'groove_edge' && operation.validated) {
            this.drawGrooveEdge({
              operation,
              side,
              sides,
              property,
              EDGE_OFFSET,
              EDGE_HEIGHT,
              heightScale,
            })
          }
          // Рисуем петли
          if (operation.type === 'hinge35' && operation.validated) {
            this.drawHinge35({ operation, side, sides, property, heightScale })
          }
          // Рисуем рафикс
          if (operation.type === 'rafix' && operation.validated) {
            this.drawRafix({ operation, side, sides, property, heightScale })
          }
          // Рисуем узел-минификс
          if (operation.type === 'minifix' && operation.validated) {
            this.drawMinifix({
              operation,
              side,
              EDGE_OFFSET,
              EDGE_HEIGHT,
              heightScale,
            })
          }
          // Рисуем произвольное отверстие в торце
          if (operation.type === 'edge_hole' && operation.validated) {
            this.drawEdgeHole({ operation, side, EDGE_OFFSET, EDGE_HEIGHT })
          }
          // Рисуем произвольное отверстие в плоскости
          if (operation.type === 'side_hole' && operation.validated) {
            this.drawSideHole({ operation, side })
          }
          // Рисуем четверть
          if (operation.type === 'groove_side' && operation.validated) {
            this.drawGrooveSide({
              operation,
              side,
              sidePathGroup,
              sides,
              property,
              EDGE_OFFSET,
              EDGE_HEIGHT,
            })
          }
          // Рисуем вогнутый радиус
          if (operation.type === 'arc_side_inner' && operation.validated) {
            this.drawArcSideInner({
              operation,
              side,
              EDGE_HEIGHT,
              EDGE_OFFSET,
              sidePathGroup,
              sides,
              property,
            })
          }
          // Рисуем радиус наружный
          if (
            operation.type === 'radius_inner' &&
            operation.radius > 0 &&
            operation.validated
          ) {
            this.drawRadiusInner({ operation, side, radius: dotCircleRadius })
          }
          // Рисуем радиус внутренний
          if (
            operation.type === 'radius_outer' &&
            operation.radius > 0 &&
            operation.validated
          ) {
            this.drawRadiusOuter({
              operation,
              sidePathGroup,
              side,
              sides,
              property,
            })
          }
          // Рисуем дугу
          if (operation.type === 'arc_corner' && operation.validated) {
            this.drawArcCorner({
              operation,
              side,
              EDGE_HEIGHT,
              EDGE_OFFSET,
              sidePathGroup,
              sides,
              property,
            })
          }
          // Рисуем дугу 2
          if (operation.type === 'arc_side' && operation.validated) {
            this.drawArcSide({
              operation,
              side,
              EDGE_HEIGHT,
              EDGE_OFFSET,
              sidePathGroup,
              sides,
              property,
            })
          }
          // Рисуем срез угла
          if (operation.type === 'line_corner' && operation.validated) {
            this.drawLineCorner({
              operation,
              side,
              EDGE_HEIGHT,
              EDGE_OFFSET,
              sidePathGroup,
              sides,
              property,
              heightScale,
            })
          }
          // Круг / эллипс
          if (operation.type === 'ellipse_in' && operation.validated) {
            this.drawEllipseIn({
              operation,
              side,
              EDGE_HEIGHT,
              EDGE_OFFSET,
              sidePathGroup,
              heightScale,
              property,
              sides,
            })
          }
          // Прямоугольник
          if (operation.type === 'square_in' && operation.validated) {
            this.drawSquareIn({
              operation,
              side,
              EDGE_HEIGHT,
              EDGE_OFFSET,
              sidePathGroup,
              heightScale,
              property,
              sides,
            })
          }
          // Ручка улыбка
          if (operation.type === 'hdl_smile' && operation.validated) {
            this.drawHdlSmile({
              operation,
              side,
              EDGE_HEIGHT,
              EDGE_OFFSET,
              sidePathGroup,
              heightScale,
              property,
              sides,
            })
          }
          // Врезная ручка
          if (operation.type === 'carve_groove' && operation.validated) {
            this.drawCarveGroove({
              operation,
              side,
              EDGE_HEIGHT,
              EDGE_OFFSET,
              sidePathGroup,
              heightScale,
              property,
              sides,
            })
          }
          // Выборка под LED профиль прямая
          if (operation.type === 'carve_led_direct' && operation.validated) {
            this.drawCarveLedDirect({
              operation,
              side,
              EDGE_HEIGHT,
              EDGE_OFFSET,
              sidePathGroup,
              heightScale,
              property,
              sides,
            })
          }
          // Выборка под LED профиль углом
          if (operation.type === 'carve_led_corner' && operation.validated) {
            this.drawCarveLedCorner({
              operation,
              side,
              EDGE_HEIGHT,
              EDGE_OFFSET,
              sidePathGroup,
              heightScale,
              property,
              sides,
            })
          }
          // Рисуем Г-образный вырез на форматно раскроечном станке
          if (operation.type === 'carve_g' && operation.validated) {
            this.drawCarveG({
              operation,
              side,
              EDGE_HEIGHT,
              EDGE_OFFSET,
              sidePathGroup,
              sides,
            })
          }
          // Рисуем Г-образный вырез на ЧПУ
          if (operation.type === 'carve_gcnc' && operation.validated) {
            this.drawCarveGcnc({
              operation,
              side,
              EDGE_HEIGHT,
              EDGE_OFFSET,
              sidePathGroup,
              sides,
            })
          }
          // Рисуем замок -20мм
          if (operation.type === 'tt_lock_socket' && operation.validated) {
            this.drawLockSocket({
              operation,
              side,
              EDGE_HEIGHT,
              EDGE_OFFSET,
              sidePathGroup,
              sides,
            })
          }
          // Рисуем замок +20мм
          if (operation.type === 'tt_lock_plug' && operation.validated) {
            this.drawLockPlug({
              operation,
              side,
              EDGE_HEIGHT,
              EDGE_OFFSET,
              sidePathGroup,
              sides,
            })
          }
          // Рисуем радиус на постформинге
          if (operation.type === 'tt_radius_flange' && operation.validated) {
            this.drawRadiusFlange({
              operation,
              side,
              EDGE_HEIGHT,
              EDGE_OFFSET,
              sidePathGroup,
              sides,
            })
          }
          // Рисуем срез со стороны софта
          if (operation.type === 'tt_corner_cut' && operation.validated) {
            this.drawCornerCut({
              operation,
              side,
              EDGE_HEIGHT,
              EDGE_OFFSET,
              sidePathGroup,
              sides,
              heightScale,
              property,
            })
          }
          // Рисуем вырез под мойку/варочную поверхность
          if (operation.type === 'tt_rect_inside' && operation.validated) {
            this.drawRectInside({
              operation,
              side,
              EDGE_HEIGHT,
              EDGE_OFFSET,
              sidePathGroup,
              sides,
              heightScale,
              property,
            })
          }
          // Рисуем паз в торце
          if (operation.type === 'groove_end' && operation.validated) {
            this.drawGrooveEnd({
              operation,
              side,
              sides,
              heightScale,
              EDGE_HEIGHT,
              EDGE_OFFSET,
            })
          }
          // Рисуем паз
          if (operation.type === 'groove' && operation.validated) {
            this.drawGroove({
              operation,
              side,
              sides,
              heightScale,
              EDGE_HEIGHT,
              EDGE_OFFSET,
            })
          }
          // Рисуем прямоугольный (П-образный) вырез
          if (operation.type === 'carve_p' && operation.validated) {
            this.drawCarveP({
              operation,
              sidePathGroup,
              side,
              sides,
              property,
            })
          }
        })
      // Рисуем торец
      this.drawEdge({
        side,
        sides,
        property,
        EDGE_HEIGHT,
        EDGE_OFFSET,
        radius: dotCircleRadius,
      })

      sides[property].operations.forEach(operation => {
        // Рисуем сквозную линию на торце
        if (operation.type === 'edge_line' && operation.validated) {
          drawEdgeLine({
            operation,
            side,
            sides,
            property,
            EDGE_HEIGHT,
            EDGE_OFFSET,
            drawings: this.drawings,
          })
        }
        // Рисуем квадратики на торцах для сверлений
        if (operation.type === 'edge_rect' && operation.validated) {
          drawEdgeRect({
            operation,
            side,
            sides,
            property,
            EDGE_HEIGHT,
            EDGE_OFFSET,
            drawings: this.drawings,
          })
        }
      })
      // тестовые операции по отладке сетки
      // testMeasurementDeep({ sides })
      // this.testMeasurementDiameter({ sides, EDGE_OFFSET })

      // Рисуем метрики
      let levels = []
      if (sides[property].measurements.length > 0) {
        sides[property].measurements.sort((a, b) => {
          // Сортируем метрики
          // hover-метрики (метрики наведения) показываем выше всех,
          // а сначала отображаем постоянно видимые
          if (a.hover != b.hover) return a.hover ? 1 : -1
          else return a.width > b.width ? 1 : -1
        })
        for (let i = 0; i < sides[property].measurements.length; i++) {
          let measurement = sides[property].measurements[i]
          // console.log(measurement)
          if (measurement.innerRadius) {
            this.drawMeasurementInnerRadius({
              measurement,
              side,
              sides,
              heightScale,
              EDGE_OFFSET,
              EDGE_HEIGHT,
              property,
            })
          }
          if (measurement.outerRadius) {
            this.drawMeasurementOuterRadius({
              measurement,
              side,
              sides,
              heightScale,
              EDGE_OFFSET,
              EDGE_HEIGHT,
              property,
            })
          }
          if (measurement.customRadius) {
            this.drawMeasurementCustomRadius({
              measurement,
              side,
              sides,
              heightScale,
              EDGE_OFFSET,
              EDGE_HEIGHT,
              property,
            })
          }
          if (measurement.diameter) {
            this.drawMeasurementDiameter({
              measurement,
              side,
              sides,
              heightScale,
              EDGE_OFFSET,
              EDGE_HEIGHT,
              property,
            })
          }
          if (measurement.tiltAngle) {
            this.drawMeasurementTiltAngle({
              measurement,
              side,
              sides,
              heightScale,
              EDGE_OFFSET,
              EDGE_HEIGHT,
              property,
            })
          }
          if (measurement.deep) {
            drawMeasurementDeep({
              measurement,
              side,
              sides,
              heightScale,
              EDGE_OFFSET,
              EDGE_HEIGHT,
              property,
              levels,
              hoverDrawings: this.hoverDrawings,
            })
          }
        }
      }
      // проходимся по метриках сетки и перемещаем текст при надобности
      // Детали: https://prnt.sc/c0vXbRDkmUcm ранее была правка,
      // если попадает на перечение то переносится на другую сторону,
      // если на той стороне так же есть пересечение остается на прежней.
      justifyMeasurementDeepTextPosition({ side, sides, property })

      // Рисуем размер фигуры
      if (property === 'bottom' || property === 'right') {
        let widthSize = property === 'bottom' ? this.size[0] : this.size[1]
        let measurementBounds = new Rectangle(
          0,
          -(
            EDGE_HEIGHT +
            EDGE_OFFSET * 2 +
            heightScale * Math.max(levels.length + 1, 1)
          ),
          widthSize,
          heightScale * Math.max(levels.length + 1, 1)
        )
        side.addChild(
          drawArrow(
            {
              start: measurementBounds.topLeft,
              end: measurementBounds.bottomLeft,
              size: heightScale,
              angle: 0,
            },
            {
              start: false,
              end: false,
            }
          )
        )
        side.addChild(
          drawArrow(
            {
              start: measurementBounds.topRight,
              end: measurementBounds.bottomRight,
              size: heightScale,
              angle: 0,
            },
            {
              start: false,
              end: false,
            }
          )
        )
        side.addChild(
          drawArrow(
            {
              start: measurementBounds.topLeft.add(
                new Point(0, (heightScale * 15) / 100)
              ), // 1.5mm , heightScale is 10mm
              end: measurementBounds.topRight.add(
                new Point(0, (heightScale * 15) / 100)
              ), // 1.5mm , heightScale is 10mm
              size: heightScale,
              angle: 0,
            },
            {
              start: true,
              end: true,
            },
            widthSize,
            sides[property].angle
          )
        )
      }
      // Поворачиваем сторону
      side.pivot = side.bounds.topLeft
      side.position = sides[property].position.add(
        sidePathGroup.globalToLocal(side.bounds.topLeft)
      )
      side.pivot = sidePathGroup.internalBounds.topLeft
      side.rotate(sides[property].angle)
      operations.addChild(side)
    }
    if (this.graphLines) {
      let fitSize = new Size(
        (this.graphLines.bounds.width * 90) / 100 - this.padding * 2,
        (this.graphLines.bounds.height * 90) / 100 - this.padding * 2
      )
      operations.fitBounds(fitSize)
      operations.position = this.graphLines.bounds.center
    }
    paper.paperScope.view.draw()
    this.drawings.validate()
    const { warnings } = this.drawings
    if (warnings && warnings.length) {
      this.onValidationWarnings(warnings)
    }
    // перемещаем обьекты для правильного перекрытия
    zIndexing(paper.paperScope.project)
    toggleHoverEvents(true, this)
    toggleClickEvents(true, this)
  }

  drawMeasurementCustomRadius({
    measurement,
    side,
    sides,
    heightScale,
    property,
  }) {
    const { customRadius, point, level = 1, idCarve } = measurement
    const dy = point.y + level * heightScale
    const dx = dy
    const group = drawArrow(
      {
        start: point,
        end: new Point(point.x - dx, -level * heightScale),
        size: heightScale,
        angle: 0,
      },
      {
        start: true,
        end: false,
      },
      `R${customRadius}`,
      sides[property].angle,
      true
    )
    group.data = { is: 'metric', metric: 'custom_radius', idCarve }
    side.addChild(group)
  }

  drawMeasurementInnerRadius({
    measurement,
    side,
    sides,
    heightScale,
    property,
  }) {
    const { innerRadius, translate } = measurement
    const group = drawArrow(
      {
        start: new Point(innerRadius, innerRadius).subtract(
          new Point(innerRadius, 0).rotate(45)
        ),
        end: new Point(-heightScale, -heightScale),
        size: heightScale,
        angle: 0,
      },
      {
        start: true,
        end: false,
      },
      `R${innerRadius}`,
      sides[property].angle,
      true
    )
    if (translate) {
      group.translate(translate)
    }
    side.addChild(group)
  }

  drawMeasurementOuterRadius({
    measurement,
    side,
    sides,
    heightScale,
    property,
  }) {
    side.addChild(
      drawArrow(
        {
          start: new Point(0, 0).add(
            new Point(measurement.outerRadius, 0).rotate(45)
          ),
          end: new Point(-heightScale, -heightScale),
          size: heightScale,
          angle: 0,
        },
        {
          start: true,
          end: false,
        },
        `R${measurement.outerRadius}`,
        sides[property].angle,
        true
      )
    )
  }

  testMeasurementDiameter({ sides, EDGE_OFFSET }) {
    const selectedSide = 'top'
    this.planMeasurementDiameter({
      sides,
      side: selectedSide,
      params: {
        id: 1,
        idCarve: 11,
        x: 100,
        y: 100,
        diameter: 10,
        text: `100*100`,
      },
    })
    this.planMeasurementDiameter({
      sides,
      side: selectedSide,
      params: {
        id: 1,
        idCarve: 11,
        x: 400,
        y: 50,
        diameter: 15,
        text: `⌀400*50`,
      },
    })
    this.planMeasurementDiameter({
      sides,
      side: selectedSide,
      params: {
        id: 1,
        idCarve: 11,
        x: 450,
        y: 490,
        diameter: 20,
        text: `450*490`,
      },
    })
    this.planMeasurementDiameter({
      sides,
      side: selectedSide,
      params: {
        id: 1,
        idCarve: 11,
        x: 100,
        y: 400,
        diameter: 15,
        text: `⌀100*400`,
      },
    })
    this.planMeasurementDiameter({
      sides,
      side: selectedSide,
      params: {
        id: 1,
        idCarve: 11,
        x: 200,
        y: -EDGE_OFFSET,
        diameter: 15,
        text: `⌀200*${-EDGE_OFFSET}`,
      },
    })
  }
  planMeasurementDiameter({ sides, side, params, force }) {
    const { x, y } = params,
      prevSide = querySide(side, 'prev'),
      nextSide = querySide(side, 'next'),
      oppositeSide = querySide(side, 'opposite'),
      sizeX = sides[side].size,
      sizeY = sides[nextSide].size
    if (force == 'opposite' || (!force && x <= sizeX / 2 && y <= sizeY / 2)) {
      sides[oppositeSide].measurements.push({
        ...params,
        x: sizeX - x,
        y: sizeY - y,
      })
    } else if (force == 'prev' || (!force && x > sizeX / 2 && y <= sizeY / 2)) {
      sides[prevSide].measurements.push({
        ...params,
        x: sizeY - y,
        y: x,
      })
    } else if (force == 'next' || (!force && x < sizeX / 2 && y >= sizeY / 2)) {
      sides[nextSide].measurements.push({
        ...params,
        x: y,
        y: sizeX - x,
      })
    } else {
      sides[side].measurements.push({
        ...params,
      })
    }
  }
  drawMeasurementDiameter({ measurement, side, sides, heightScale, property }) {
    const {
        x,
        y,
        diameter,
        idCarve,
        text,
        hover,
        height = heightScale,
      } = measurement,
      start = new Point(x, y),
      offset = height / Math.SQRT2
    const radiusMetric = drawArrow(
      {
        start: new Point(x, y),
        end: start.add(new Point(-1, -1).multiply(offset)),
        size: heightScale,
      },
      {
        start: true,
        end: false,
      },
      text || `⌀${diameter}`,
      sides[property].angle,
      true
    )
    radiusMetric.data = { is: 'metric', metric: 'diameter', idCarve }
    side.addChild(radiusMetric)
    if (hover) {
      if (this.hoverDrawings[idCarve]) {
        this.hoverDrawings[idCarve].items.push(radiusMetric)
      }
    }
  }

  drawMeasurementTiltAngle({
    measurement,
    side,
    sides,
    heightScale,
    property,
  }) {
    const {
        startAngle = 0,
        tiltAngle,
        point,
        idCarve,
        text = `${tiltAngle}°`,
        hover,
        height = heightScale,
      } = measurement,
      sideAngle = sides[property].angle,
      start = point,
      lineLength = 1.1 * height

    const line = new Path.Line({
        from: start,
        to: start.add([lineLength, 0]),
        strokeColor: '#000000',
        strokeWidth: STROKE_WIDTH1,
      }),
      line1 = line.clone()
    line1.rotate(tiltAngle, start)
    const arc = new Path.Arc({
        from: line.getPointAt(height),
        through: start.add(new Point(height, 0).rotate(tiltAngle / 2)),
        to: line1.getPointAt(height),
        strokeColor: '#000000',
        strokeWidth: STROKE_WIDTH1,
      }),
      angleLabel = new PointText({
        content: `${text}`,
        fontSize: 0.35 * heightScale,
        justification: 'center',
        fillColor: '#000000',
        // selected: true
      }),
      lineGroup = new Group([line, line1, arc])

    if (startAngle) {
      lineGroup.rotate(startAngle, start)
    }

    angleLabel.position = start.add(
      new Point(
        lineLength +
          angleLabel.bounds.topLeft.getDistance(angleLabel.bounds.bottomRight) /
            2,
        0
      ).rotate(startAngle + tiltAngle / 2)
    )
    angleLabel.rotate(-sideAngle)

    const group = new Group({
      children: [lineGroup, angleLabel],
      data: { is: 'metric', metric: 'angle' },
    })
    side.addChild(group)
    if (hover) {
      if (this.hoverDrawings[idCarve]) {
        this.hoverDrawings[idCarve].items.push(group)
      }
    }
  }

  markPostformingRoundings({
    pfIsReversed,
    pfSide,
    sides,
    double = false,
    pfHeight,
  }) {
    const isBottom = pfSide == 'bottom'
    Object.assign(sides.bottom.edge, {
      isPostformed: true,
      pfHeight: isBottom ? Math.abs(pfHeight) : 0,
      pfIsReversed: isBottom ? pfIsReversed : false,
    })
    if (double) {
      Object.assign(sides.top.edge, {
        isPostformed: true,
        pfHeight: !isBottom ? Math.abs(pfHeight) : 0,
        pfIsReversed: isBottom ? false : pfIsReversed,
      })
    }
  }

  pushEndFace(endFace, { start, end }) {
    const endFaceNormalized = [...endFace, { start, end }]
      .sort(({ end: end1 }, { end: end2 }) => (end1 <= end2 ? -1 : 1))
      .reduce((dest, { start, end }) => {
        const current = dest[dest.length - 1]
        if (current && end <= current.start) {
          current.start = Math.max(current.start, start)
        } else {
          dest.push({ start, end })
        }
        return dest
      }, [])
    endFace.splice(0, endFace.length, ...endFaceNormalized)
  }

  cutOut(segments, side) {
    // обрезаем плоскость
    const cutting = new Path({
      segments,
      fillColor: COLORS.white,
      closed: true,
      // selected: true,
    })
    side.addChild(cutting)
    return cutting
  }
  fixCenter() {
    this.shift.fixCenter(paper.paperScope)
  }
}
