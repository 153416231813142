<template>
  <Furniture :orderId="Number($route.params.orderId)" />
</template>

<script>
import Furniture from '@/components/order/Furniture.vue'

export default {
  name: 'OrderFurniture',
  components: {
    Furniture,
  },
  data: () => ({}),
  computed: {},
  created() {},
}
</script>

<style lang="scss"></style>
