<template>
  <div class="circle-cards__body">
    <ul class="circle-cards__info">
      <li>({{ selectedMaterial.article }}) {{ selectedMaterial.name }}</li>
      <li v-if="swiperInstance">
        N:{{ 1 + swiperInstance.realIndex }}/{{ maps.length }}
      </li>
      <li v-if="p">S:{{ p.$.l }}/{{ p.$.w }}</li>
      <li v-if="p && sheet">
        C:{{ p.$.c }}/{{ sheet.results.cMaterialCount }}
      </li>
      <li v-if="p && sheet">
        Cp:{{ p.d.length }}/{{ sheet.results.cPartCount }}
      </li>
      <li v-if="p">T:{{ p.$.tl }}/{{ p.$.tw }}</li>
    </ul>
    <div class="row">
      <div class="col-xl-9">
        <div class="js-circle-cards slider-circle-cards">
          <div class="swiper">
            <div class="swiper-wrapper">
              <div
                class="swiper-slide"
                v-for="(item, index) in maps"
                :key="index"
              >
                <MapImage :p="item" />
              </div>
            </div>
          </div>
          <div class="slider-nav-box" v-show="maps && maps.length > 1">
            <div class="slider-nav">
              <div class="slider-nav-prev ico_chevron-left"></div>
              <div class="slider-nav-pagination"></div>
              <div class="slider-nav-next ico_chevron-right"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xl-3">
        <div class="circle-cards__table">
          <table>
            <thead>
              <tr>
                <th>№</th>
                <th>Дл</th>
                <th>Шр</th>
                <th>Кл</th>
                <th>Мт</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in details" :key="index">
                <td>{{ item.position }}</td>
                <td>{{ item.length }}</td>
                <td>{{ item.width }}</td>
                <td>{{ item.quantity }}</td>
                <td>
                  {{
                    (item.is_texture_set ? 'Т' : '') +
                      (item.is_grooves ? '=' : '') +
                      (item.is_milling ? '*' : '')
                  }}
                </td>
              </tr>
              <tr>
                <td colspan="5" v-if="kromkas">
                  <span
                    v-for="(item, index) in kromkas"
                    :key="index"
                    class="d-block"
                  >
                    {{ alphabet[index] }}: {{ item.name }}
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
          <ul>
            <li>[ T ] Текстура</li>
            <li>[ = ] Пазование</li>
            <li>[ * ] Фрезерование (Радиусы)</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { parseStringPromise } from 'xml2js'
import Swiper, { Navigation, Pagination } from 'swiper'
Swiper.use([Navigation, Pagination])
import MapImage from '@/components/order/in-cut-map/MapImage.vue'

export default {
  name: 'Map',
  components: { MapImage },
  props: ['selectedMaterial', 'kromkas', 'materials', 'details', 'sheet'],
  data: () => ({
    swiperInstance: null,
    alphabet: 'ABCDEFGHIJKLMNOPQRSTUVWXYZ',
    draw: null,
  }),
  computed: {
    maps() {
      const { draw } = this
      if (!draw) return []
      return draw.nesting.p
    },
    p() {
      const { maps, swiperInstance } = this
      if (!maps || !swiperInstance) return null
      return maps[swiperInstance.realIndex]
    },
  },
  watch: {
    async selectedMaterial(selectedMaterial) {
      if (selectedMaterial && selectedMaterial.id) {
        await this.performDrawParsing()
      }
    },
  },
  methods: {
    async performDrawParsing() {
      const { sheet } = this
      if (!sheet) {
        this.draw = null
        return
      }
      this.draw = await parseStringPromise(sheet.results.draw)
      if (this.swiperInstance) {
        this.destroySlider()
      }
      this.initSlider()
    },
    initSlider() {
      this.$nextTick(() => {
        this.swiperInstance = new Swiper('.js-circle-cards .swiper', {
          slidesPerView: 1,
          spaceBetween: 10,
          loop: true,
          watchOverflow: true,
          observeParents: true,
          observeSlideChildren: true,
          observer: true,
          speed: 800,
          navigation: {
            nextEl: '.js-circle-cards .slider-nav-next',
            prevEl: '.js-circle-cards .slider-nav-prev',
          },
          pagination: {
            el: '.js-circle-cards .slider-nav-pagination',
            type: 'fraction',
            clickable: true,
          },
        })
      })
    },
    destroySlider() {
      if (this.swiperInstance) {
        this.swiperInstance.destroy()
      }
      this.swiperInstance = null
    },
  },
  async mounted() {
    await this.performDrawParsing()
  },
  beforeDestroy() {
    this.destroySlider()
  },
}
</script>

<style lang="scss">
.slider-circle-cards {
  .slider-nav-box {
    text-align: right;
    padding-top: 1rem;
    .slider-nav {
      position: static;
    }
  }
}
</style>
