import axios from 'axios'
import store from '@/store'

const api = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  timeout: 100000,
  headers: {
    'Access-Control-Allow-Origin': '*',
    'Content-Type': 'application/json'
  }
})

api.interceptors.request.use(function (config) {
  const token = store.getters['auth/getToken']
  if (token) {
    config.headers.Authorization = 'Bearer ' + token
  }
  const locale = store.getters['getLocale']
  if (locale) {
    config.headers['Content-Language'] = locale
  }
  return config
}, function (error) {
  return Promise.reject(error)
})

api.interceptors.response.use(function (response) {
  return response
}, function (error) {
  if (error.response.status === 401) {
    store.dispatch('auth/logout')
  }
  return Promise.reject(error)
})

export default api
