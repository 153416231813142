import api from '@/gateway/api'

export default {
  namespaced: true,
  state: {
    token: null,
    user: null,
    userChangeInfoRequest: null
  },
  getters: {
    isAuthenticated: state => state.token && state.user,
    getToken: state => state.token,
    getUser: state => {
      const user = state.user || null
      if (!user) { return null }
      user.hasRole = function (role) {
        return user.roles && user.roles.indexOf (role) != -1
      }
      return user
    }
  },
  mutations: {
    updateToken (state, token) {
      state.token = token
    },
    updateUser (state, user) {
      state.user = user
    },
    updateUserChangeInfoRequest (state, user) {
      state.userChangeInfoRequest = user
    }
  },
  actions: {
    register ({ commit }, { user }) {
      return new Promise((resolve, reject) => {
        api.post('auth/register', user).then(response => {
          resolve(response)
        }).catch(error => {
          reject(error)
        });
      })
    },
    login ({ commit, dispatch }, { phone, password }) {
      return new Promise((resolve, reject) => {
        api.post('auth/login', { phone: phone, password: password }).then(response => {
          if (response.data && response.data.token) {
            commit('updateUser', response.data.user)
            commit('updateToken', response.data.token)
            dispatch('setLocale', response.data.user.locale, {root:true})
          }
          resolve(response)
        }).catch(error => {
          reject(error)
        });
      })
    },
    tokenLogin ({ commit, dispatch }, { token }) {
      commit('updateToken', token)
      return new Promise((resolve, reject) => {
        api.post('auth/login/token', { token: token }).then(response => {
          if (response.data) {
            commit('updateUser', response.data.user)
            dispatch('setLocale', response.data.user.locale, {root:true})
          }
          resolve(response)
        }).catch(error => {
          reject(error)
        });
      })
    },
    logout ({ commit }) {
      return new Promise((resolve, reject) => {
        api.post('auth/logout').then(result => {
          commit('updateToken', null)
          commit('updateUser', null)
          commit('updateUserChangeInfoRequest', null)
          resolve(result)
        }).catch(error => {
          commit('updateToken', null)
          commit('updateUser', null)
          commit('updateUserChangeInfoRequest', null)
          reject(error)
        })
      })
    },
    passwordSms ({ commit, dispatch }, { phone }) {
      return new Promise((resolve, reject) => {
        api.post('auth/password/sms', { phone: phone }).then(response => {
          resolve(response.data)
        }).catch(error => {
          reject(error)
        });
      })
    },
    changePassword ({ commit }, { password }) {
      return new Promise((resolve, reject) => {
        api.post('auth/password/change', { password: password }).then(response => {
          resolve(response)
        }).catch(error => {
          reject(error)
        });
      })
    },
    getProfile ({ commit }) {
      return new Promise((resolve, reject) => {
        api.get('profile').then(result => {
          if (result.data.user_change_info_request) {
            commit('updateUserChangeInfoRequest', result.data.user_change_info_request)
          }
          commit('updateUser', result.data.user)
          resolve(result)
        }).catch(error => {
          reject(error)
        })
      })
    },
    updateProfile ({ commit }, { user }) {
      return new Promise((resolve, reject) => {
        api.put('profile', user).then(result => {
          if (result.data.need_moderation) {
            commit('updateUserChangeInfoRequest', result.data.user)
          } else {
            commit('updateUser', result.data.user)
          }
          resolve(result)
        }).catch(error => {
          reject(error)
        })
      })
    }
  }
}
