export const templates = [
  'groove_end',
  'groove',
  'groove_edge',
  'groove_side',
  'arc_side_inner',
  'radius_inner',
  'radius_outer',
  'arc_corner',
  'arc_side',
  'line_corner',
  'carve_p',
  'carve_g',
  'carve_gcnc',
  'ellipse_in',
  'square_in',
  'hdl_smile',
  'carve_groove',
  'carve_led_direct',
  'carve_led_corner',
  'edge_hole',
  'side_hole',
  'minifix',
  'minifix_shkant',
  'rafix',
  'hinge35',
  'tt_lock_socket',
  'tt_lock_plug',
  'tt_radius_flange',
  'tt_corner_cut',
  'tt_rect_inside',
  'tt_2corner_radius',
  'tt_4corner_radius',
]
