<template>
  <div v-if="status == 'loading'" class="text-center">loading...</div>
  <div v-else-if="status == 'error'" class="text-center">
    {{ $t('auth.registration_error_message') }}
  </div>
  <div v-else class="constructor">
    <div class="constructor__head">
      <div class="constructor__title">
        {{ template ? $t('operations.' + template) : '' }}
      </div>
      <Toolbar
        :detailId="detailId"
        :orderId="orderId"
        :materialId="materialId"
        @print="print()"
        @resetZoom="resetZoom()"
        @copy="copyDetail()"
        @delete="deleteDetail()"
      />
    </div>
    <Layout
      :template="template"
      :items="items"
      @addItem="handleAddItem()"
      @toggleBackground="toggleDrawerBackround"
    >
      <template v-slot:info>
        <Info :detail="detail" :material="material" />
      </template>
      <template v-slot:tabs>
        <Tabs v-model="tab" @input="item = null" />
      </template>
      <template v-slot:inner-tabs>
        <InnerTabs :group="tab" v-model="template" @input="item = null" />
      </template>
      <template v-slot:form>
        <Form
          v-if="item"
          :item="item"
          @update="handleChangeItem"
          :errors="errors"
        />
        <div v-else class="text-muted small p-2 text-center">
          <span v-if="template">
            {{ $t('dateylor.choose_op') }}
          </span>
          <span v-else>
            {{ $t('dateylor.choose_template') }}
          </span>
        </div>
      </template>
      <template v-slot:activeOperations>
        <ActiveOperations
          :items="items"
          :item="item"
          :itemsErrors="itemsErrors"
          v-if="items"
          @select="selectItem"
          @delete-item="deleteItem"
        />
      </template>
      <ProcessingDraw
        :detail="detail"
        :material="material"
        :items="items"
        v-if="items && detail"
        @select="handleProcessingSelect"
        @warnings="handleDrawerWarnings"
        ref="processing"
      />
    </Layout>
    <div class="constructor__footer">
      <router-link
        tag="button"
        class="btn btn-secondary"
        type="button"
        :to="{ name: 'OrderDetails', params: { orderId } }"
      >
        {{ $t('dateylor.cancel') }}</router-link
      >
      <router-link
        tag="button"
        class="btn btn-primary ms-3"
        type="button"
        :to="{ name: 'OrderDetails', params: { orderId } }"
      >
        {{ $t('dateylor.save') }}</router-link
      >
    </div>
    <!-- Keep it to make drawings backround load right way -->
    <img class="d-none" :src="materialBackroundURL" alt="" />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { paper } from 'paper'
// import isEqual from 'lodash/isEqual'
// import omit from 'lodash/omit'
import api from '@/gateway/api'
import { templates } from './templates'
import getItemsDefault from './itemsDefault'
import readyToSave from './readyToSave'
import getItems, { findItemByIdCarve } from './items'
import { getGroupOfTemplate } from './construction/groups'
import ProcessingDraw from '@/components/processing/ProcessingDraw'
import Toolbar from '@/components/dateylor/construction/Toolbar'
import Layout from '@/components/dateylor/construction/Layout'
import ActiveOperations from '@/components/dateylor/construction/ActiveOperations'
import Tabs from '@/components/dateylor/construction/Tabs'
import InnerTabs from '@/components/dateylor/construction/InnerTabs'
import Info from '@/components/dateylor/construction/Info'
import Form from '@/components/dateylor/construction/Form'

export default {
  name: 'Dateylor',
  components: {
    ProcessingDraw,
    Toolbar,
    Layout,
    ActiveOperations,
    Tabs,
    InnerTabs,
    Info,
    Form,
  },
  props: ['orderId', 'detailId', 'materialId'],
  data() {
    return {
      tab: 2,
      template: null,
      items: null,
      // item being edited
      item: null,
      // list options
      errors: null,
      itemsErrors: {},
      status: 'loading',
    }
  },
  computed: {
    ...mapState('order', ['order', 'details']),
    detail() {
      return (this.details || []).find(({ id }) => id == this.detailId)
    },
    material() {
      return (this.$store.getters['order/materials'] || []).find(
        ({ id }) => id == this.materialId
      )
    },
    areaTools() {
      return this.$store.getters['order/areaTools'] || []
    },
    edgeTools() {
      return this.$store.getters['order/edgeTools'] || []
    },
    selection() {
      const selection = templates.indexOf(this.template)
      return selection == -1 ? null : selection
    },
    itemsDefault() {
      return getItemsDefault(this)
    },
    materialBackroundURL() {
      const { material } = this
      return material && material.image
    },
    hasErrors() {
      return Object.values(this.itemsErrors).some(v => v)
    },
  },
  watch: {
    detailId() {
      this.init()
    },
  },
  methods: {
    handleDrawerWarnings(warnings) {
      if (!(Array.isArray(warnings) && warnings.length)) return
      warnings.forEach(({ invalid, message }) => {
        if (invalid && invalid.op && invalid.op.idCarve) {
          this.itemsErrors = {
            ...this.itemsErrors,
            [invalid.op.idCarve]: { intersections: [message] },
          }
        }
      })
    },
    toggleDrawerBackround() {
      const bgOperations = this.items.find(
        ({ template }) => template == 'background'
      )
      if (bgOperations.backgroundURL) {
        bgOperations.backgroundURL = null
      } else {
        if (!this.materialBackroundURL) {
          // должна подставляться картинка материала, если ее нет,
          // то писать ошибку что функция для данного материала невозможна
          return this.$toast.error('Функция для данного материала невозможна')
        }
        bgOperations.backgroundURL = this.materialBackroundURL
      }
      this.renderDrawings()
    },
    renderDrawings() {
      if (!this.$refs.processing) return
      this.$refs.processing.update()
      if (this.item) {
        this.highlightItem(this.item)
      }
      // Коррекция значения paper.paperScope.view.center,
      // чтобы центр детали оставался на месте при перерисовке
      this.$refs.processing.drawer.fixCenter()
      this.$nextTick(() => this.showErrorMessages())
    },
    showErrorMessages() {
      this.$toast.clear()
      Object.values(this.itemsErrors || {}).forEach(o =>
        Object.values(o || {}).forEach(warnings => {
          new Array(...new Set(warnings)).forEach(message => {
            this.$toast.error(message)
          })
        })
      )
    },
    handleProcessingSelect(op) {
      const item = findItemByIdCarve(this.items, op.idCarve)
      this.selectItem(item)
    },
    selectItem(item) {
      this.item = item
      this.tab = getGroupOfTemplate(item.template)
      this.template = item.template
      this.errors = null
      this.highlightItem(item)
    },
    highlightItem(item) {
      window.requestAnimationFrame(() =>
        this.$refs.processing.drawer.drawings.select(item)
      )
    },
    resetZoom() {
      paper.paperScope.view.zoom = 1
      paper.paperScope.view.center =
        paper.paperScope.project.activeLayer.bounds.center
    },
    print() {
      document.querySelectorAll('.print-block').forEach(el => el.remove())
      // const svg = paper.paperScope.project.exportSVG({})
      const canvas = document.querySelector('.canvas-wrapper > canvas')
      const block = document.createElement('div')
      block.classList.add('print-block')
      const svg = document.createElement('img')
      svg.classList.add('w-100')
      svg.src = canvas.toDataURL('image/png')
      block.appendChild(svg)
      document.body.appendChild(block)
      window.requestAnimationFrame(window.print)
    },
    handleAddItem() {
      const defaultData = this.itemsDefault[this.selection].item[0]
      this.item = JSON.parse(JSON.stringify(defaultData))
    },
    async handleChangeItem(item) {
      // this.renderDrawings()
      if (item.idCarve) {
        await this.updateItem(item)
      } else if (readyToSave(item)) {
        // костыль для валидации при сохранении;
        // на момент написания валидация на беке работала только при пересохранении операции,
        // а при создании все пропускается без валидации
        await this.createItem(item)
        await this.updateItem(this.item)
      }
      this.renderDrawings()
    },
    createItem(item) {
      if (this.items[this.selection].item.length > 0) {
        const test = JSON.parse(
          JSON.stringify(
            this.items[this.selection].item[
              this.items[this.selection].item.length - 1
            ]
          )
        )
        item.id = test.id + 1
      } else {
        item.id += 1
      }

      this.errors = null
      return new Promise((resolve, reject) => {
        api
          .post('order/detail/' + this.detailId + '/carve', item)
          .then(result => {
            const { carve } = result.data.data
            let idCarve = carve.id
            let params =
              typeof carve.params == 'string'
                ? JSON.parse(carve.params)
                : carve.params
            params.idCarve = idCarve
            this.items[this.selection].item.push(params)
            this.item = params
            this.highlightItem(this.item)
            resolve(result)
          })
          .catch(error => {
            if (error.response.status === 422) {
              this.errors = error.response.data.errors
            }
            reject(error)
          })
      })

      // this.items[this.selection].item.push(item)
    },
    deleteItem(item) {
      const { items } = this
      let selection, index
      for (var i = 0; i < items.length; i++) {
        for (var j = 0; j < items[i].item.length; j++) {
          let { id, idCarve } = items[i].item[j]
          if (id == item.id && idCarve == item.idCarve) {
            selection = i
            index = j
            break
          }
        }
      }
      if (this.items[selection].item.length > 0) {
        this.items[selection].item.splice(index, 1)

        this.$root.$emit('playPreloader')
        this.$store
          .dispatch('order/deleteCarve', {
            orderDetail: this.detailId,
            idCarve: item.idCarve,
          })
          .then(() => {
            this.$root.$emit('stopPreloader')
            if (this.item && item.idCarve == this.item.idCarve) {
              this.item = null
            }
            this.itemsErrors = { ...this.itemsErrors, [item.idCarve]: null }
            this.renderDrawings()
          })
          .catch(() => {
            this.$root.$emit('stopPreloader')
          })
      }
    },

    updateItem(item) {
      // add additional data
      if (item.template === 'side_hole') {
        if (item.tool_selection) {
          const tool = this.areaTools.find(
            ({ id }) => id == item.tool_selection
          )
          item.tool_param_f = tool && tool.diameter
        } else {
          item.tool_param_f = null
        }
      } else if (item.template === 'edge_hole') {
        if (item.tool_selection) {
          const tool = this.edgeTools.find(
            ({ id }) => id == item.tool_selection
          )
          item.tool_param_f = tool && tool.diameter
        } else {
          item.tool_param_f = null
        }
      }

      this.highlightItem(item)

      Object.keys(this.$refs)
        .filter(el => el.includes(`${item.id}${item.idCarve}`))
        .forEach(ref => {
          if (typeof this.$refs[ref] !== 'undefined') {
            this.$refs[ref].classList.remove('error')
          }
        })
      this.$root.$emit('playPreloader')
      this.errors = null
      this.itemsErrors = { ...this.itemsErrors, [item.idCarve]: null }
      return this.$store
        .dispatch('order/updateCarves', {
          orderDetail: this.detailId,
          item: item,
        })
        .then(() => {
          this.$root.$emit('stopPreloader')
        })
        .catch(error => {
          if (error.response.status === 422) {
            this.errors = error.response.data.errors
            this.itemsErrors = {
              ...this.itemsErrors,
              [item.idCarve]: error.response.data.errors,
            }
          }
          this.$root.$emit('stopPreloader')
        })
    },
    copyDetail() {
      const acceptAction = () => {
        return this.$store
          .dispatch('order/copyMaterialDetail', {
            detail_id: this.detailId,
            material_id: this.materialId,
          })
          .then(result => {
            const { data: detail } = result.data || {}
            this.$root.$emit('openDialog', 'AskDialog', {
              acceptAction: () => {
                this.$router.push({
                  name: 'Dateylor',
                  params: {
                    orderId: detail.order_id,
                    materialId: detail.material_id,
                    detailId: detail.id,
                  },
                })
              },
              title:
                'Деталь скопійовано. Відкрити сторінку редагування нової деталі?',
              acceptText: this.$i18n.t('yes'),
              denyText: this.$i18n.t('no'),
            })
          })
          .catch(() => {
            this.$toast.error('An error occured!')
            // this.$Progress.fail()
            // this.$root.$emit("stopPreloader")
          })
      }
      this.$root.$emit('openDialog', 'AskDialog', {
        acceptAction,
        title: 'Копіювати деталь?',
        acceptText: this.$i18n.t('yes'),
        denyText: this.$i18n.t('no'),
      })
    },
    deleteDetail() {
      this.$root.$emit('openDialog', 'AskDialog', {
        title: 'Ви дійсно хочете видалити деталь?',
        acceptAction: () => {
          // this.$Progress.start()
          // this.$root.$emit("playPreloader")
          this.$store
            .dispatch('order/deleteDetail', {
              detail_id: this.detailId,
              order_id: this.orderId,
              material_id: this.materialId,
            })
            .then(() => {
              // this.$Progress.finish()
              // this.$root.$emit("stopPreloader")
              this.$router.push({
                name: 'OrderDetails',
                params: { orderId: this.orderId },
              })
            })
            .catch(() => {
              // this.$Progress.fail()
              // this.$root.$emit("stopPreloader")
            })
        },
      })
    },
    getDetailCarves() {
      this.$root.$emit('playPreloader')
      return this.$store
        .dispatch('order/getCarves', {
          orderDetail: this.detailId,
        })
        .then(res => {
          const itemsErrors = {}
          const result = []
          res.data.data.forEach(function(valueItem) {
            let { id: idCarve, valid } = valueItem
            let params =
              typeof valueItem.params == 'string'
                ? JSON.parse(valueItem.params)
                : valueItem.params
            result.push({ ...params, idCarve, valid })
            if (!valid) itemsErrors[idCarve] = true
          })
          this.itemsErrors = itemsErrors

          // {"template":"groove","id":1,"face":{"id":"1","name":"T\u044b\u043b"},"place_second":{"top":false,"bottom":false,"right":false,"left":false},"margin":"452","deep":"500","width":"744","width_saw":"3.2","action":null,"idCarve":96}
          this.items = getItems(this)
          result.forEach(el => {
            this.items.forEach((section, index) => {
              if (!Array.isArray(this.items[index].item)) {
                this.items[index].item = []
              }
              if (section.template == el.template) {
                this.items[index].item.push(el)
              }
            })
          })
          // this.items[this.selection].item.push(result[0])
          this.$root.$emit('stopPreloader')
        })
    },
    async init() {
      Object.assign(this, {
        item: null,
        errors: null,
        itemsErrors: {},
      })
      try {
        if (!this.order || this.order.id != this.orderId) {
          await this.$store.dispatch('order/getOrder', { id: this.orderId })
        }
        if (!this.material || this.material.id != this.materialId) {
          await this.$store.dispatch('order/getOrderProducts', {
            orderId: this.orderId,
          })
        }
        if (!this.detail || this.detail.id != this.detailId) {
          await this.$store.dispatch('order/getMaterialDetails', {
            order_id: this.orderId,
            material_id: this.materialId,
          })
        }
        if (!this.edgeTools.length && !this.areaTools.length) {
          await this.$store.dispatch('order/getTools')
        }
        await this.getDetailCarves()

        this.status = null

        this.$nextTick(() => {
          this.renderDrawings()
          this.resetZoom()
        })
      } catch (e) {
        this.status = 'error'
      }
    },
    onExit(event) {
      if (!this.hasErrors) {
        return
      }
      event.preventDefault()
      return (event.returnValue = '')
    },
    confirmExit(to, from, next) {
      if (!this.hasErrors) {
        return next()
      }
      const answer = window.confirm(
        'Ви впевнені, що хочете вийти? Операції з помилками не будуть збережені!'
      )
      if (answer) {
        next()
      } else {
        next(false)
      }
    },
  },
  mounted() {
    this.init().then(() => this.$el.scrollIntoView())
    addEventListener('beforeunload', this.onExit, {
      capture: true,
    })
  },
  beforeDestroy() {
    removeEventListener('beforeunload', this.onExit, { capture: true })
  },
}
</script>

<style scoped lang="scss"></style>
