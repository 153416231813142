var render = function render(){var _vm=this,_c=_vm._self._c;return _c('MatchMedia',{scopedSlots:_vm._u([{key:"default",fn:function({ xs, sm, md, lg }){return [_c('fragment',[_c('tr',{attrs:{"data-id":_vm.item.id}},[_c('td',[_c('div',{staticClass:"btn btn-icon ico_move"})]),_c('td',{class:{ 'footable-last-visible': xs || sm }},[(xs || sm)?_c('span',{staticClass:"footable-toggle fooicon last-column",class:_vm.isOpen ? 'fooicon-minus' : 'fooicon-plus',on:{"click":function($event){return _vm.toggle()}}}):_vm._e(),_c('span',[_vm._v(" "+_vm._s(_vm.materialIndex + 1)+"-"+_vm._s(_vm.item.position))])]),_c('td',{staticClass:"d-none d-md-table-cell"},[_c('div',{staticClass:"inputs"},[_c('div',[_c('div',{staticClass:"text-length text-validate",class:{
                error: _vm.touched.l && !_vm.isLengthValid,
              }},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.item.length),expression:"item.length"}],ref:"widthInp",staticClass:"input-length form-control",attrs:{"placeholder":"Довжина","type":"text","name":"length"},domProps:{"value":(_vm.item.length)},on:{"focus":function($event){return $event.target.select()},"blur":function($event){_vm.touched.l = true},"change":function($event){_vm.item.width
                    ? _vm.updateDetails(
                        _vm.item,
                        _vm.item.is_grooves || _vm.item.is_curves || _vm.item.is_milling
                      )
                    : _vm.planUpdateDetails(_vm.item)},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.item, "length", $event.target.value)}}}),_c('div',{staticClass:"text-error"},[_vm._v(" Число должно находиться в диапазоне от "+_vm._s(_vm.minLength)+" до "+_vm._s(_vm.maxLength)+" ")])])]),_c('div',[_c('div',{staticClass:"text-width text-validate",class:{
                error: _vm.touched.w && !_vm.isWidthValid,
              }},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.item.width),expression:"item.width"}],staticClass:"input-width form-control",attrs:{"placeholder":"Ширина","type":"text","name":"width"},domProps:{"value":(_vm.item.width)},on:{"focus":function($event){$event.target.select()
                  _vm.cancelUpdateDetails()},"blur":function($event){_vm.touched.w = true},"change":function($event){return _vm.updateDetails(
                    _vm.item,
                    _vm.item.is_grooves || _vm.item.is_curves || _vm.item.is_milling
                  )},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.item, "width", $event.target.value)}}}),_c('div',{staticClass:"text-error"},[_vm._v(" Число должно находиться в диапазоне от "+_vm._s(_vm.minWidth)+" до "+_vm._s(_vm.maxWidth)+". ")])])]),_c('div',[_c('div',{staticClass:"text-amount text-validate",class:{
                error: _vm.touched.q && !_vm.isQuantityValid,
              }},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.item.quantity),expression:"item.quantity"}],staticClass:"input-amount form-control",attrs:{"placeholder":"Кількість","type":"text","name":"quantity"},domProps:{"value":(_vm.item.quantity)},on:{"focus":function($event){return $event.target.select()},"blur":function($event){_vm.touched.q = true},"change":function($event){return _vm.updateDetails(_vm.item)},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.item, "quantity", $event.target.value)}}}),_c('div',{staticClass:"text-error"},[_vm._v(" Число должно находится в диапазоне от "+_vm._s(_vm.minQuantity)+" до "+_vm._s(_vm.maxQuantity)+" ")])])])])]),_c('DetailEnds',{attrs:{"detail":_vm.item,"orderId":_vm.order.id,"material_id":_vm.material.id,"defaultKromka":_vm.defaultKromka,"firstClass":"d-none d-md-table-cell","secondClass":{
          'd-none d-md-table-cell': true,
          'footable-last-visible': md || lg,
        }},on:{"no-default-kromka":function($event){_vm.$toast.warning('Виберіть крайку по замовчуванню')
          _vm.$refs.defaultKromka.click()}}},[(md || lg)?_c('span',{staticClass:"footable-toggle fooicon last-column",class:_vm.isOpen ? 'fooicon-minus' : 'fooicon-plus',on:{"click":function($event){return _vm.toggle()}}}):_vm._e()]),_c('td',{staticClass:"text-center d-none d-xl-table-cell"},[_c('label',{staticClass:"check"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.item.is_texture_set),expression:"item.is_texture_set"}],staticClass:"check__input",attrs:{"type":"checkbox","tabindex":"-1"},domProps:{"checked":Array.isArray(_vm.item.is_texture_set)?_vm._i(_vm.item.is_texture_set,null)>-1:(_vm.item.is_texture_set)},on:{"change":[function($event){var $$a=_vm.item.is_texture_set,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.item, "is_texture_set", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.item, "is_texture_set", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.item, "is_texture_set", $$c)}},function($event){return _vm.updateDetails(
                _vm.item,
                _vm.item.is_grooves || _vm.item.is_curves || _vm.item.is_milling,
                $event.target.checked
              )}]}}),_c('span',{staticClass:"check__box me-0"})])]),_c('td',{staticClass:"d-none d-xl-table-cell"},[_c('Dropdown',{staticClass:"drop --select --arrow",scopedSlots:_vm._u([{key:"default",fn:function({ show, hide, isOpen }){return [_c('button',{staticClass:"form-control drop-button",class:{
              'opacity-50': !_vm.material.thickness_changeable,
            },attrs:{"type":"button","tabindex":"-1","disabled":!_vm.material.thickness_changeable},on:{"click":function($event){return show()}}},[_vm._v(" "+_vm._s(_vm.item.thickness)+" ")]),_c('div',{staticClass:"drop-box",style:({ display: isOpen ? 'block' : 'none' })},[_c('div',{staticClass:"drop-overflow"},[_c('ul',{staticClass:"drop-list"},_vm._l((_vm.material.thickness_changeable
                    ? [1 + 'x', 2 + 'x', 3 + 'x']
                    : [1 + 'x']),function(thicknessItem,thicknessIndex){return _c('li',{key:thicknessIndex,staticClass:"drop-list-item",on:{"click":function($event){_vm.changeThickness(_vm.item, thicknessItem)
                    hide()}}},[_vm._v(" "+_vm._s(thicknessItem)+" ")])}),0)])])]}}],null,true)})],1),_c('td',{staticClass:"d-none d-xl-table-cell"},[_c('div',{staticClass:"text-name text-validate",class:{
            error: _vm.item.name !== null && _vm.item.name.length > 15,
          }},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.item.name),expression:"item.name"}],staticClass:"input-name form-control",attrs:{"placeholder":"Назва","type":"text","name":"name","id":`${_vm.item.id}-1`,"tabindex":"-1"},domProps:{"value":(_vm.item.name)},on:{"focus":function($event){return $event.target.select()},"change":function($event){return _vm.updateDetails(_vm.item)},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.item, "name", $event.target.value)}}}),_c('div',{staticClass:"text-error"},[_vm._v(" Количество вводимых знаков не должно превышать 15 ")])])]),_c('td',{staticClass:"d-none d-xl-table-cell"},[_c('div',{staticClass:"btns"},[_c('div',[_c('button',{staticClass:"btn btn-icon ico_setting",attrs:{"tabindex":"-1"},on:{"click":function($event){return _vm.toDateylor(_vm.item)}}})]),_c('div',[_c('button',{staticClass:"js-add-row btn btn-icon ico_copy",attrs:{"type":"button","tabindex":"-1"},on:{"click":function($event){return _vm.copyItem(_vm.item)}}})]),_c('div',[_c('button',{staticClass:"btn btn-icon ico_trash",attrs:{"type":"button","tabindex":"-1"},on:{"click":function($event){return _vm.deleteItem(_vm.item)}}})])])])],1),(_vm.isOpen)?_c('tr',{staticClass:"footable-detail-row"},[_c('td',{attrs:{"colspan":xs || sm ? 2 : 5}},[_c('table',{staticClass:"footable-details"},[_c('tbody',[_c('tr',{staticClass:"d-table-row d-md-none"},[_c('th',[_vm._v("Розмір")]),_c('td',[_c('div',{staticClass:"inputs"},[_c('div',[_c('div',{staticClass:"text-length text-validate",class:{
                        error: _vm.touched.l && !_vm.isLengthValid,
                      }},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.item.length),expression:"item.length"}],ref:"widthInp",staticClass:"input-length form-control",attrs:{"placeholder":"Довжина","type":"text","name":"length"},domProps:{"value":(_vm.item.length)},on:{"focus":function($event){return $event.target.select()},"blur":function($event){_vm.touched.l = true},"change":function($event){_vm.item.width
                            ? _vm.updateDetails(
                                _vm.item,
                                _vm.item.is_grooves ||
                                  _vm.item.is_curves ||
                                  _vm.item.is_milling
                              )
                            : _vm.planUpdateDetails(_vm.item)},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.item, "length", $event.target.value)}}}),_c('div',{staticClass:"text-error"},[_vm._v(" Число должно находиться в диапазоне от "+_vm._s(_vm.minLength)+" до "+_vm._s(_vm.maxLength)+" ")])])]),_c('div',[_c('div',{staticClass:"text-width text-validate",class:{
                        error: _vm.touched.w && !_vm.isWidthValid,
                      }},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.item.width),expression:"item.width"}],staticClass:"input-width form-control",attrs:{"placeholder":"Ширина","type":"text","name":"width"},domProps:{"value":(_vm.item.width)},on:{"focus":function($event){$event.target.select()
                          _vm.cancelUpdateDetails()},"blur":function($event){_vm.touched.w = true},"change":function($event){return _vm.updateDetails(
                            _vm.item,
                            _vm.item.is_grooves ||
                              _vm.item.is_curves ||
                              _vm.item.is_milling
                          )},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.item, "width", $event.target.value)}}}),_c('div',{staticClass:"text-error"},[_vm._v(" Число должно находиться в диапазоне от "+_vm._s(_vm.minWidth)+" до "+_vm._s(_vm.maxWidth)+". ")])])]),_c('div',[_c('div',{staticClass:"text-amount text-validate",class:{
                        error: _vm.touched.q && !_vm.isQuantityValid,
                      }},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.item.quantity),expression:"item.quantity"}],staticClass:"input-amount form-control",attrs:{"placeholder":"Кількість","type":"text","name":"quantity","data-id":"4"},domProps:{"value":(_vm.item.quantity)},on:{"focus":function($event){return $event.target.select()},"blur":function($event){_vm.touched.q = true},"change":function($event){return _vm.updateDetails(_vm.item)},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.item, "quantity", $event.target.value)}}}),_c('div',{staticClass:"text-error"},[_vm._v(" Число должно находится в диапазоне от "+_vm._s(_vm.minQuantity)+" до "+_vm._s(_vm.maxQuantity)+" ")])])])])])]),_c('tr',{staticClass:"d-table-row d-md-none"},[_c('th',[_vm._v("Кромки")]),_c('td',[_c('div',{staticClass:"d-flex justify-content-end"},[_c('DetailEnds',{attrs:{"detail":_vm.item,"orderId":_vm.order.id,"material_id":_vm.material.id,"detailId":_vm.item.id,"defaultKromka":_vm.defaultKromka,"wrappedTag":"div","firstClass":"me-2","secondClass":"ms-1"},on:{"no-default-kromka":function($event){_vm.$toast.warning('Виберіть крайку по замовчуванню')
                      _vm.$refs.defaultKromka.click()}}})],1)])]),_c('tr',[_c('th',[_vm._v("Структура")]),_c('td',[_c('label',{staticClass:"check"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.item.is_texture_set),expression:"item.is_texture_set"}],staticClass:"check__input",attrs:{"type":"checkbox","tabindex":"-1"},domProps:{"checked":Array.isArray(_vm.item.is_texture_set)?_vm._i(_vm.item.is_texture_set,null)>-1:(_vm.item.is_texture_set)},on:{"change":[function($event){var $$a=_vm.item.is_texture_set,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.item, "is_texture_set", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.item, "is_texture_set", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.item, "is_texture_set", $$c)}},function($event){return _vm.updateDetails(
                        _vm.item,
                        _vm.item.is_grooves || _vm.item.is_curves || _vm.item.is_milling,
                        $event.target.checked
                      )}]}}),_c('span',{staticClass:"check__box me-0"})])])]),_c('tr',[_c('th',[_vm._v("Зшивка")]),_c('td',[_c('Dropdown',{staticClass:"drop --select --arrow",scopedSlots:_vm._u([{key:"default",fn:function({ show, hide, isOpen }){return [_c('button',{staticClass:"form-control drop-button",class:{
                      'opacity-50': !_vm.material.thickness_changeable,
                    },attrs:{"type":"button","tabindex":"-1","disabled":!_vm.material.thickness_changeable},on:{"click":function($event){return show()}}},[_vm._v(" "+_vm._s(_vm.item.thickness)+" ")]),_c('div',{staticClass:"drop-box",style:({
                      display: isOpen ? 'block' : 'none',
                    })},[_c('div',{staticClass:"drop-overflow"},[_c('ul',{staticClass:"drop-list"},_vm._l((_vm.material.thickness_changeable
                            ? [1 + 'x', 2 + 'x', 3 + 'x']
                            : [1 + 'x']),function(thicknessItem,thicknessIndex){return _c('li',{key:thicknessIndex,staticClass:"drop-list-item",on:{"click":function($event){_vm.changeThickness(_vm.item, thicknessItem)
                            hide()}}},[_vm._v(" "+_vm._s(thicknessItem)+" ")])}),0)])])]}}],null,true)})],1)]),_c('tr',[_c('th',[_vm._v("Назва")]),_c('td',[_c('div',{staticClass:"text-name text-validate",class:{
                    error: _vm.item.name !== null && _vm.item.name.length > 15,
                  }},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.item.name),expression:"item.name"}],staticClass:"input-name form-control",attrs:{"placeholder":"Назва","type":"text","name":"name","id":`${_vm.item.id}-1`,"data-id":"1","tabindex":"-1","autocomplete":""},domProps:{"value":(_vm.item.name)},on:{"focus":function($event){return $event.target.select()},"change":function($event){return _vm.updateDetails(_vm.item)},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.item, "name", $event.target.value)}}}),_c('div',{staticClass:"text-error"},[_vm._v(" Количество вводимых знаков не должно превышать 15 ")])])])]),_c('tr',[_c('th'),_c('td',[_c('div',{staticClass:"btns"},[_c('div',[_c('button',{staticClass:"btn btn-icon ico_setting",attrs:{"tabindex":"-1"},on:{"click":function($event){return _vm.toDateylor(_vm.item)}}})]),_c('div',[_c('button',{staticClass:"js-add-row btn btn-icon ico_copy",attrs:{"type":"button","tabindex":"-1"},on:{"click":function($event){return _vm.copyItem(_vm.item)}}})]),_c('div',[_c('button',{staticClass:"btn btn-icon ico_trash",attrs:{"type":"button","tabindex":"-1"},on:{"click":function($event){return _vm.deleteItem(_vm.item)}}})])])])])])])])]):_vm._e()])]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }