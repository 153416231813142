<template>
  <Details
    :orderId="Number($route.params.orderId)"
    :materialId="Number($route.params.materialId)"
  />
</template>

<script>
import Details from '@/components/order/Details.vue'

export default {
  name: 'OrderDetails',
  components: {
    Details,
  },
  data: () => ({}),
  computed: {},
  created() {},
}
</script>

<style lang="scss"></style>
