<template>
  <fragment>
    <MatchMedia v-slot="{ xs, sm }" wrapperTag="tr">
      <td
        :class="{ 'footable-last-visible': xs || sm }"
        :colspan="xs || sm ? 2 : 1"
      >
        <span
          class="footable-toggle fooicon last-column"
          :class="isOpen ? 'fooicon-minus' : 'fooicon-plus'"
          @click="isOpen = !isOpen"
          v-if="xs || sm"
        ></span>
        <span>{{ item.name }}</span>
      </td>
      <td class="d-none d-md-table-cell text-end">
        <span class="nowrap">{{ item.price.toFixed(2) || 0 }} грн</span>
      </td>
      <td class="d-none d-md-table-cell text-center">
        <span>{{ item.quantity }}</span>
      </td>
      <td class="d-none d-md-table-cell text-center" v-if="showDetails">
        <span>{{ item.details_count }}</span>
      </td>
      <td class="d-none d-md-table-cell text-end">
        <strong class="nowrap">{{ item.total.toFixed(2) }} грн</strong>
      </td>
    </MatchMedia>
    <tr class="footable-detail-row" v-show="isOpen">
      <td colspan="1">
        <table class="footable-details js-table">
          <tbody>
            <tr>
              <th>Вартість</th>
              <td>
                <span class="nowrap">{{ item.price.toFixed(2) || 0 }} грн</span>
              </td>
            </tr>
            <tr>
              <th>Товарів</th>
              <td>
                <span>{{ item.quantity }}</span>
              </td>
            </tr>
            <tr v-if="showDetails">
              <th>Деталей</th>
              <td>
                <span>{{ item.details_count }}</span>
              </td>
            </tr>
            <tr>
              <th>Сума</th>
              <td>
                <strong class="nowrap">{{ item.total.toFixed(2) }} грн</strong>
              </td>
            </tr>
          </tbody>
        </table>
      </td>
    </tr>
  </fragment>
</template>

<script>
import { Fragment } from 'vue-frag'
import { MatchMedia } from 'vue-component-media-queries'

export default {
  name: 'GeneralMaterialsTableRow',
  components: { Fragment, MatchMedia },
  props: ['item', 'index', 'showDetails'],
  data: () => ({
    isOpen: false,
  }),

  methods: {
    toggle() {
      this.isOpen = !this.isOpen
    },
  },
}
</script>

<style lang="scss"></style>
