import { paper, Point, Path, PointText, Group, Size, Rectangle } from 'paper'
import { STROKE_WIDTH1 } from './variables'

export function drawArrow(bounds, arrow, text, sideAngle = 0, radius = false) {
  let arrowGroup = new Group()
  let arrowSize = (bounds.size * 25) / 100 // 2.5mm , bounds.size is 10mm
  let textSize = (bounds.size * 35) / 100 // 3.5mm , bounds.size is 10mm
  let textOffset = (bounds.size * 15) / 100 // 1.5mm , bounds.size is 10mm
  let textFitsInsideLine = true
  let arrowAngle = 10
  arrowGroup.addChild(
    new Path.Line({
      from: bounds.start,
      to: bounds.end,
      strokeColor: '#000000',
      strokeWidth: STROKE_WIDTH1,
    })
  )
  if (text) {
    let textElement = new PointText({
      content: text,
      fontSize: textSize,
      justification: 'center',
      fillColor: '#000000',
    })
    textFitsInsideLine =
      textElement.bounds.width < bounds.end.subtract(bounds.start).length
        ? true
        : false
    let textVector = bounds.start.subtract(bounds.end)
    textVector.length = textFitsInsideLine
      ? textVector.length / 2
      : textVector.length + textElement.bounds.width / 2 + arrowSize
    let textVectorOffset = textVector.normalize(textOffset * 2)
    textVectorOffset.angle =
      sideAngle === 90 || sideAngle === 180
        ? textVectorOffset.angle - 90
        : textVectorOffset.angle + 90
    textElement.position = bounds.start
      .subtract(textVector)
      .add(textVectorOffset)
    textElement.rotate(textVector.angle - 180 - getTextAngle(sideAngle))
    arrowGroup.addChild(textElement)
    if (!radius && !textFitsInsideLine) {
      arrowGroup.data = { is: 'arrow', textFitsInsideLine }
      textVector.length = textElement.bounds.width + arrowSize * 2
      arrowGroup.addChild(
        new Path.Line({
          from: bounds.end,
          to: bounds.end.subtract(textVector),
          strokeColor: '#000000',
          strokeWidth: STROKE_WIDTH1,
        })
      )
      _drawArrow(
        arrowGroup,
        {
          start: bounds.end,
          end: bounds.end.subtract(textVector),
        },
        {
          start: true,
          end: false,
        },
        arrowSize,
        arrowAngle
      )
      textVector.length = textVector.length - textElement.bounds.width / 2
      arrowGroup.addChild(
        new Path.Line({
          from: bounds.start,
          to: bounds.start.add(textVector),
          strokeColor: '#000000',
          strokeWidth: STROKE_WIDTH1,
        })
      )
      _drawArrow(
        arrowGroup,
        {
          start: bounds.start,
          end: bounds.start.add(textVector),
        },
        {
          start: true,
          end: false,
        },
        arrowSize,
        arrowAngle
      )
    }
    if (radius) {
      // require some refactor in future
      let textRadiusVector = bounds.end.subtract(bounds.start)
      let textRadiusVectorLine = textRadiusVector.normalize(
        textElement.bounds.width
      )
      textRadiusVectorLine.angle = -sideAngle
      textElement.rotate(textElement.rotation)
      let textRadiusVectorOffset = textRadiusVectorLine.normalize(
        textOffset * 2
      )
      textRadiusVectorOffset.angle = textRadiusVectorOffset.angle + 90
      arrowGroup.addChild(
        new Path.Line({
          from: bounds.end,
          to:
            sideAngle === 90 || sideAngle === 180
              ? bounds.end.add(textRadiusVectorLine)
              : bounds.end.subtract(textRadiusVectorLine),
          strokeColor: '#000000',
          strokeWidth: STROKE_WIDTH1,
        })
      )
      textRadiusVectorLine.length = textRadiusVectorLine.length / 2
      if (sideAngle === 0) {
        textElement.rotate(-90)
      } else if (sideAngle === 90) {
        textElement.rotate(-180)
      } else if (sideAngle === 180) {
        textElement.rotate(90)
      } else {
        getTextAngle(sideAngle)
      }
      textElement.position =
        sideAngle === 90 || sideAngle === 180
          ? bounds.end
              .add(textRadiusVectorLine)
              .subtract(textRadiusVectorOffset)
          : bounds.end
              .subtract(textRadiusVectorLine)
              .subtract(textRadiusVectorOffset)
    }
  }
  if ((arrow.start || arrow.end) && (radius || textFitsInsideLine))
    _drawArrow(arrowGroup, bounds, arrow, arrowSize, arrowAngle)
  return arrowGroup
}

export function getTextAngle(angle) {
  if (Math.abs(angle) === 0) return 0
  if (Math.abs(angle) === 90) return 180
  if (Math.abs(angle) === 270) return 0
  return angle
}

export function _drawArrow(group, bounds, arrow, size, angle) {
  if (arrow.start) {
    let arrowSideVector = bounds.start.subtract(bounds.end)
    arrowSideVector.length = size
    arrowSideVector.angle = arrowSideVector.angle - angle
    let arrowSideVector2 = bounds.start.subtract(bounds.end)
    arrowSideVector2.length = size
    arrowSideVector2.angle = arrowSideVector2.angle + angle
    let sideLine = new Path({
      segments: [
        bounds.start,
        bounds.start.subtract(arrowSideVector),
        bounds.start.subtract(arrowSideVector2),
      ],
      fillColor: '#000000',
      closed: true,
    })
    group.addChild(sideLine)
  }
  if (arrow.end) {
    let arrowSideVector = bounds.end.subtract(bounds.start)
    arrowSideVector.length = size
    arrowSideVector.angle = arrowSideVector.angle - angle
    let arrowSideVector2 = bounds.end.subtract(bounds.start)
    arrowSideVector2.length = size
    arrowSideVector2.angle = arrowSideVector2.angle + angle
    let sideLine = new Path({
      segments: [
        bounds.end,
        bounds.end.subtract(arrowSideVector),
        bounds.end.subtract(arrowSideVector2),
      ],
      fillColor: '#000000',
      closed: true,
    })
    group.addChild(sideLine)
  }
}

/**
 * Deprecated
 * Draws simple diagonal metric for a circle
 */
export function drawDiameterMetric(options) {
  const center = new Point(options.center),
    diameter = Number(options.diameter),
    textSize = Number(options.textSize) || 10,
    angle = Number(options.angle),
    { text = `⌀${diameter}`, mirror = false } = options,
    group = new Group()

  const long = 30,
    short = 7
  const line = new Path.Line({
    from: [center.x - diameter / 2 - (mirror ? short : long), center.y],
    to: [center.x + diameter / 2 + (mirror ? long : short), center.y],
  })
  let textElement = new PointText({
    content: text,
    fontSize: textSize,
    fontWeight: 200,
  })
  textElement.bounds[mirror ? 'bottomRight' : 'bottomLeft'] =
    line.bounds[mirror ? 'topRight' : 'topLeft']

  group.addChildren([line, textElement])

  group.style = {
    fillColor: '#000000',
    strokeColor: '#000000',
    strokeWidth: STROKE_WIDTH1,
  }
  group.rotate(angle, center)
  const radius = diameter / 2
  const delta = radius * Math.cos(Math.PI / 4)
  let start = new Point(center.x + delta, center.y + delta)
  let end = new Point(center.x - delta, center.y - delta)
  _drawArrow(
    group,
    {
      start: start.add(1),
      end: start,
    },
    {
      start: false,
      end: true,
    },
    5,
    10
  )
  _drawArrow(
    group,
    {
      start: end.add(1),
      end: end,
    },
    {
      start: true,
      end: false,
    },
    5,
    10
  )
  return group
}
