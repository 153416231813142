import { Project, Group, Item } from 'paper'

export default function zIndexing(project: InstanceType<typeof Project>) {
  // Операции перемещения по z-index выполняются по порядку
  const zIndexes = [
    // четверть и паз на задний план
    {
      query: {
        data: ({ is, template }) =>
          is == 'template' &&
          [
            'groove',
            'groove_side',
            'carve_led_direct',
            'carve_led_corner',
          ].includes(template),
      },
      handler: (p: InstanceType<typeof Item>) => {
        project.activeLayer.addChild(p)
        p.sendToBack()
        // p.selected = true
      },
    },
    // направление текстуры на самый задний план
    {
      query: {
        data: {
          is: 'texture_direction',
        },
      },
      handler: (p: InstanceType<typeof Item>) => {
        project.activeLayer.addChild(p)
        p.sendToBack()
        // p.selected = true
      },
    },

    // направление текстуры на самый задний план
    {
      query: {
        data: {
          is: 'background',
        },
      },
      handler: (p: InstanceType<typeof Item>) => {
        project.activeLayer.addChild(p)
        p.sendToBack()
        // p.selected = true
      },
    },
    // перемещаем наверх стирание торцов
    {
      query: {
        data: {
          is: 'edge_overlay',
        },
      },
      handler: p => {
        p.bringToFront()
      },
    },
    // перемечаем наверх невалидные рисунки
    {
      query: {
        data: {
          invalid: true,
        },
      },
      handler: p => {
        {
          project.activeLayer.addChild(p)
          p.bringToFront()
        }
      },
    },
    // перемещаем наверх метрики
    {
      query: {
        data: {
          is: 'metric',
        },
      },
      handler: p => {
        {
          project.activeLayer.addChild(p)
          p.bringToFront()
        }
      },
    },
  ]

  // обработка перемещений
  zIndexes.forEach(({ query, handler }) =>
    project.getItems(query).forEach(handler)
  )
}
