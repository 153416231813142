<template>
  <div class="modal-content import-file-dialog">
    <div class="modal-header">
      <h5 class="modal-title">
        {{ title }}
        <small>на www.detailor.ua</small>
      </h5>
      <button
        class="btn-close"
        type="button"
        @click="$root.$emit('closeDialog')"
      ></button>
    </div>
    <div class="modal-body">
      <div class="text-import">
        Выберите подходящий тип файла
      </div>

      <FileImportDialogItem
        :label="item.label"
        :accept="item.accept"
        :type="type"
        :img="item.img"
        :disabled="selection && selection.type != type"
        v-for="(item, type) in formats"
        :key="type"
        ref="files"
        @change="onChange"
      />

      <div class="d-flex mt-4">
        <div>
          <button
            class="btn btn-primary"
            type="button"
            @click="$root.$emit('closeDialog')"
          >
            Ок
          </button>
        </div>
        <div class="mx-2">
          <button
            class="btn btn-secondary"
            type="button"
            @click="$root.$emit('closeDialog')"
          >
            Скасувати
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FileImportDialogItem from '@/components/dialogs/fileimport/FileImportDialogItem.vue'

export default {
  name: 'FileImportDialog',
  components: { FileImportDialogItem },
  props: {
    title: String,
  },
  data: () => ({
    formats: {
      pro100: {
        accept: ['.sto'],
        label: 'PRO100',
        img: require('@/assets/img/pro100.png'),
      },
      imos: {
        accept: ['.fxm'],
        label: 'Imos',
        img: require('@/assets/img/imos.png'),
      },
      bm: {
        accept: ['.xml', '.obl'],
        label: 'БМ',
        img: require('@/assets/img/bm.png'),
      },
      bcad: {
        accept: ['.xml'],
        label: 'BCAD',
        img: require('@/assets/img/bcad.png'),
      },
      woody2: {
        accept: ['.xml', '.wes'],
        label: 'Woody 2',
        img: require('@/assets/img/woody.png'),
      },
      woodwop: {
        accept: ['.mpr'],
        label: 'Wood Wop',
        img: require('@/assets/img/wood-wop.png'),
      },
      ilenia: {
        accept: ['.pd4'],
        label: 'iLENIA',
        img: require('@/assets/img/ilenia.png'),
      },
    },

    selection: null,
  }),
  computed: {},
  methods: {
    onChange(selection) {
      this.selection = selection
    },
  },
}
</script>

<style lang="scss"></style>
