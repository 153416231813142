<template>
  <div ref="parent" class="canvas-wrapper"></div>
</template>

<script>
import CoordinateGrid from './CoordinateGrid'

export default {
  name: 'ProcessingDraw',
  props: {
    detail: {
      type: Object,
      required: true,
    },
    material: {
      type: Object,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
    onSelect: {
      type: Function,
    },
    onDrawerWarnings: {
      type: Function,
    },
  },
  data() {
    return {
      drawer: null,
    }
  },
  computed: {
    size() {
      const { detail } = this
      if (!detail) return null
      return [Number(detail.length), Number(detail.width)]
    },
    thickness() {
      const { detail, material } = this
      if (!detail || !material) return null
      return parseFloat(material.thickness) * parseFloat(detail.thickness)
    },
  },
  methods: {
    update() {
      if (!this.drawer) return
      this.drawer.update({
        size: this.size,
        thickness: this.thickness,
        operations: this.items,
      })
    },
    init() {
      this.drawer = new CoordinateGrid({
        el: this.$refs.parent,
        size: this.size,
        thickness: this.thickness,
        onValidationWarnings: warnings => this.$emit('warnings', warnings),
        onSelect: op => this.$emit('select', op),
      })
      // initial render and rerender on hot-reloading
      if (module.hot) {
        this.update()
      }
    },
  },
  mounted() {
    this.init()
  },
}
</script>

<style scoped>
.canvas-wrapper {
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
</style>
