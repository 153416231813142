<template>
  <div>
    <!-- Submenu -->
    <div class="table-head --page-new-order">
      <div>
        <Submenu :orderId="orderId" :materialId="materialId" />
      </div>
      <div>
        <button class="btn btn-primary" type="button">Зберегти</button
        ><button class="btn btn-icon ico_trash" type="button"></button>
      </div>
    </div>
    <div
      class="row justify-content-center align-items-center py-3"
      v-if="isLoading"
    >
      Карти завантажуються...
    </div>
    <div class="row" v-else>
      <div class="col-xxl-8">
        <!-- Cut map -->
        <div class="circle-cards">
          <!-- circle-cards__head -->
          <div class="circle-cards__head">
            <div class="circle-cards__title">Карти крою</div>
            <div class="circle-cards__action">
              <Dropdown
                class="nice-select"
                active-class="open"
                v-slot:default="{ show, hide }"
              >
                <span
                  class="current flex-grow-1 h-100 text-truncate"
                  style="line-height: inherit;"
                  @click="show()"
                >
                  {{
                    selectedMaterial
                      ? selectedMaterial.name
                      : 'Вибрати материал'
                  }}
                </span>
                <ul class="list">
                  <li
                    class="option text-truncate"
                    v-for="(item, index) in materials"
                    :key="index"
                    @click="
                      hide()
                      onMaterialSelect(item)
                    "
                  >
                    {{ item.name }}
                  </li>
                </ul>
              </Dropdown>

              <label class="input-file">
                <a target="_blank" :href="zipFileUrl" class="btn btn-secondary"
                  ><i class="ico_arrow-down"></i
                  ><span>Завантажити кресленя</span></a
                >
              </label>
            </div>
          </div>

          <!-- circle-cards__body -->
          <Map
            :selectedMaterial="selectedMaterial"
            :materials="materials"
            :kromkas="kromkas"
            :details="details"
            :sheet="sheet"
            v-if="sheet && sheet.results && sheet.results.draw"
          />
          <div class="circle-cards__body" v-else>
            <div class="row justify-content-center align-items-center py-3">
              Немає даних.
            </div>
          </div>
        </div>
      </div>

      <!-- Order Summary -->
      <OrderInfo class="col-xxl-4" :orderId="orderId" />
    </div>
    <!-- End -->
  </div>
</template>

<script>
import api from '@/gateway/api'
import Submenu from '@/components/order/Submenu.vue'
import Map from '@/components/order/Map.vue'
import OrderInfo from '@/components/order/OrderInfo.vue'

export default {
  name: 'InCutMap',
  props: ['orderId', 'materialId'],
  components: { Submenu, Map, OrderInfo },

  data: () => ({
    isLoading: false,
  }),
  computed: {
    details() {
      return (this.$store.state.order.details || []).filter(
        ({ width, length }) => width > 0 && length > 0
      )
    },
    order: {
      get: function() {
        return this.$store.state.order.order || {}
      },
      set: function(order) {
        this.$store.commit('order/updateOrder', order)
      },
    },
    locale: {
      get: function() {
        return this.$store.state.locale || 'uk'
      },
    },
    materials() {
      return this.$store.getters['order/materials'].filter(
        ({ details_count }) => details_count > 0
      )
    },
    kromkas() {
      const { materialCuttingEdges: v } = this.$store.state.order
      return (v && v.data && v.data.edges) || []
    },
    selectedMaterial: {
      get() {
        const { materialId } = this
        return this.materials.find(product => product.id === materialId)
      },
      async set(material) {
        let dataNew = {
          order: this.order,
          productIdCutMap: material.id,
        }
        await this.$store.dispatch('order/updateMaterialCutMap', dataNew)
        this.$router.replace({
          name: 'OrderInCutMap',
          params: { orderId: this.orderId, materialId: material.id },
        })
      },
    },

    cutMaps: {
      get: function() {
        return this.$store.state.order.materialCutMap
      },
    },

    sheet() {
      const { cutMaps } = this
      if (
        !this.selectedMaterial ||
        !cutMaps ||
        !cutMaps.data ||
        !Array.isArray(cutMaps.data.sheets)
      )
        return null
      return cutMaps.data.sheets.find(
        ({ order_pid }) => order_pid == this.selectedMaterial.id
      )
    },

    zipFileUrl() {
      const { order, materialCutMap } = this.$store.state.order
      return (
        (order && order.is_cut_map && order.is_cut_map.zipUrl) ||
        (materialCutMap && materialCutMap.data && materialCutMap.data.zipUrl)
      )
    },
  },

  watch: {
    materialId(materialId) {
      this.loadMaterialData(materialId)
    },
  },

  methods: {
    onMaterialSelect(material) {
      this.selectedMaterial = material
    },
    async loadMaterialData(materialId) {
      await this.$store.dispatch('order/getMaterialDetails', {
        material_id: materialId,
      })
      await this.$store.dispatch('order/getMaterialCuttingEdges', {
        material_id: materialId,
      })
    },
    bigShowDialog() {
      this.bigShow = !this.bigShow
    },
  },

  async mounted() {
    // this.$Progress.start()
    this.$root.$emit('playPreloader')
    this.isLoading = true
    try {
      await this.$store.dispatch('order/getOrder', {
        id: this.orderId,
      })

      await this.$store.dispatch('order/getOrderProducts', {
        orderId: this.order.id,
      })

      await this.$store.dispatch('order/getMaterialCutMap', {
        order: this.order.id,
      })
      await this.loadMaterialData(this.materialId)
      await this.$store.dispatch('order/getOrder', {
        id: this.orderId,
      })
    } catch (e) {
      // do nothing
    }
    this.isLoading = false
    this.$root.$emit('stopPreloader')
  },
}
</script>

<style lang="scss">
table.footable > tbody > tr > td,
table.footable > tbody > tr > th {
  display: table-cell;
}
</style>
