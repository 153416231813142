import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'

import Orders from '@/views/orders/Orders.vue'
import ViewDateylor from '@/views/ViewDateylor.vue'
import OrderGeneral from '@/views/OrderGeneral.vue'
import OrderFurniture from '@/views/OrderFurniture.vue'
import OrderMaterials from '@/views/OrderMaterials.vue'
import OrderDetails from '@/views/OrderDetails.vue'
import OrderAddMaterial from '@/views/OrderAddMaterial.vue'
import OrderAddKromka from '@/views/OrderAddKromka.vue'
import OrderAddFurniture from '@/views/OrderAddFurniture.vue'
import OrderInCutMap from '@/views/OrderInCutMap.vue'
import NotAuthorized from '@/views/errors/NotAuthorized.vue'
import NotFound from '@/views/errors/NotFound.vue'

import store from '@/store'

Vue.use(VueRouter)

const ifAuthenticated = (to, from, next) => {
  if (store.getters['auth/isAuthenticated']) {
    next()
    return
  }
  next('auth')
}

const ifNotAuthenticated = (to, from, next) => {
  if (!store.getters['auth/isAuthenticated']) {
    next()
    return
  }
  next('/')
}

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Orders',
    component: Orders,
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/orders/:orderId/',
    name: 'OrderGeneral',
    component: OrderGeneral,
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/orders/:orderId/materials',
    name: 'OrderMaterials',
    component: OrderMaterials,
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/orders/:orderId/materials/add',
    name: 'OrderAddMaterial',
    component: OrderAddMaterial,
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/orders/:orderId/materials/add-kromka',
    name: 'OrderAddKromka',
    component: OrderAddKromka,
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/orders/:orderId/materials/:materialId/details',
    name: 'OrderDetails',
    component: OrderDetails,
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/orders/:orderId/furniture',
    name: 'OrderFurniture',
    component: OrderFurniture,
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/orders/:orderId/furniture/add/:template?',
    name: 'OrderAddFurniture',
    component: OrderAddFurniture,
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/orders/:orderId/materials/:materialId/in-cut-map',
    name: 'OrderInCutMap',
    component: OrderInCutMap,
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/orders/:orderId/materials/:materialId/details/:detailId',
    name: 'Dateylor',
    component: ViewDateylor,
    beforeEnter: ifAuthenticated,
  },

  { path: '*', name: '404', component: NotFound },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

export default router
