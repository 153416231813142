<template>
  <MatchMedia v-slot="{ xs, sm }">
    <fragment>
      <tr>
        <td :class="{ 'footable-last-visible': xs || sm }">
          <span
            class="footable-toggle fooicon last-column"
            :class="isOpen ? 'fooicon-minus' : 'fooicon-plus'"
            @click="isOpen = !isOpen"
            v-if="xs || sm"
          ></span>
          <div class="d-flex align-items-center">
            <div class="product-img-small">
              <img
                :src="item.picture_url || defaultPicture"
                :alt="item.name"
                @error="
                  $event.target.src = require('@/assets/img/no_plita.svg')
                "
              />
            </div>
            <a href="#!" class="text-wrap"> {{ item.name }}</a>
          </div>
        </td>
        <td class="d-none d-md-table-cell">
          <span>{{ item.article }}</span>
        </td>
        <td class="d-none d-md-table-cell">
          <span>{{ item.price.toFixed(2) || 0 }} ₴</span>
        </td>
        <td class="d-none d-md-table-cell">
          <div class="btn-status" :class="item.stock > 0 ? 'confirmed' : 'new'">
            <span class="circle"></span
            ><span>
              {{
                item.stock > 0
                  ? $i18n.t('product.available')
                  : $i18n.t('product.not_available')
              }}
            </span>
          </div>
        </td>
        <td class="d-none d-md-table-cell">
          <input
            class="form-control"
            type="text"
            placeholder="0"
            :value="quantity"
            @input="onQuantityChange($event.target.value)"
          />
        </td>
        <td class="w-1 d-none d-md-table-cell">
          <button
            v-if="selectedData"
            class="btn btn-icon ico_trash"
            type="button"
            @click="selectedItems.splice(selectedIndex, 1)"
          ></button>
          <button
            v-else
            class="btn btn-icon ico_plus"
            type="button"
            @click="selectedItems.push({ ...item, quantity: 1 })"
          ></button>
        </td>
      </tr>
      <tr class="footable-detail-row" v-show="isOpen">
        <td :colspan="1">
          <table class="footable-details">
            <tbody>
              <tr>
                <th>Артикул</th>
                <td>
                  <span>{{ item.article }}</span>
                </td>
              </tr>
              <tr>
                <th>Ціна</th>
                <td>
                  <span>{{ item.price.toFixed(2) || 0 }} ₴</span>
                </td>
              </tr>
              <tr>
                <th>Наявність</th>
                <td>
                  <div
                    class="btn-status"
                    :class="item.stock > 0 ? 'confirmed' : 'new'"
                  >
                    <span class="circle"></span
                    ><span>
                      {{
                        item.stock > 0
                          ? $i18n.t('product.available')
                          : $i18n.t('product.not_available')
                      }}
                    </span>
                  </div>
                </td>
              </tr>
              <tr>
                <th>Кількість</th>
                <td>
                  <input
                    class="form-control"
                    type="text"
                    placeholder="0"
                    :value="quantity"
                    @input="onQuantityChange($event.target.value)"
                  />
                </td>
              </tr>
              <tr>
                <th></th>
                <td>
                  <button
                    v-if="selectedItems.some(({ id }) => id == item.id)"
                    class="btn btn-icon ico_trash"
                    type="button"
                    @click="selectedItems.splice(selectedIndex, 1)"
                  ></button>
                  <button
                    v-else
                    class="btn btn-icon ico_plus"
                    type="button"
                    @click="selectedItems.push(item)"
                  ></button>
                </td>
              </tr>
            </tbody>
          </table>
        </td>
      </tr>
    </fragment>
  </MatchMedia>
</template>

<script>
import { Fragment } from 'vue-frag'
import { MatchMedia } from 'vue-component-media-queries'

export default {
  name: 'SelectFurnitureTableRow',
  components: { Fragment, MatchMedia },
  props: ['item', 'selectedItems'],
  data: () => ({
    isOpen: false,
  }),
  computed: {
    defaultPicture() {
      return require('@/assets/img/no_plita.svg')
    },
    selectedIndex() {
      return this.selectedItems.findIndex(({ id }) => id == this.item.id)
    },
    selectedData() {
      return this.selectedItems.find(({ id }) => id == this.item.id)
    },
    quantity() {
      return (this.selectedData && this.selectedData.quantity) || null
    },
  },
  methods: {
    toggle() {
      this.isOpen = !this.isOpen
    },
    onQuantityChange(quantity) {
      if (!this.selectedData) {
        this.selectedItems.push({ ...this.item, quantity })
      } else {
        this.selectedItems.splice(this.selectedIndex, 1, {
          ...this.item,
          quantity,
        })
      }
    },
  },
}
</script>

<style lang="scss"></style>
