import { Group, Path } from 'paper'
import {
  STROKE_WIDTH1,
  STROKE_WIDTH2,
  STYLES,
  DASH_ARRAY,
  COLORS,
} from '../variables'

export function drawEdgeRect({
  operation,
  side,
  sides,
  property,
  EDGE_HEIGHT,
  EDGE_OFFSET,
  drawings,
}) {
  const {
    offset, // отступ от края детали
    zOffset, // отступ по Z
    height = 0.75 * EDGE_HEIGHT,
    width = height,
    circle, // добавить круг внутри
    dashed = true, // пунктир
    isFace = true, // лицевая сторона
    dividers = null, // вертикальная линия (обозначения центра кругового выреза)
    through = false, // сквозном вырез
    validate,
    validateCircle,
    selected,
  } = operation
  let x1 = offset - width / 2,
    x2 = offset + width / 2,
    y1 = -EDGE_OFFSET - (isFace ? 0 : EDGE_HEIGHT),
    y2 = -EDGE_OFFSET - (isFace ? height : EDGE_HEIGHT - height)
  let edgeRectPath
  if (zOffset) {
    y1 -= zOffset
    y2 -= zOffset
    edgeRectPath = new Path({
      segments: [
        [x2, y2],
        [x1, y2],
        [x1, y1],
        [x2, y1],
      ],
      closed: true,
      selected,
    })
  } else {
    edgeRectPath = new Path({
      segments: [
        [x1, y1],
        [x1, y2],
        [x2, y2],
        [x2, y1],
      ],
      selected,
    })
  }
  if (validate) {
    drawings.add(operation.idCarve, null, null, edgeRectPath, edgeRectPath)
  }
  const group = new Group([edgeRectPath])
  if (circle) {
    const edgeCircle = new Path.Circle({
      center: [
        edgeRectPath.bounds.center.x,
        circle.zOffset || 0 - EDGE_OFFSET - EDGE_HEIGHT / 2,
      ],
      radius: circle.diameter / 2,
    })
    group.addChild(edgeCircle)
    if (validateCircle) {
      drawings.add(operation.idCarve, null, null, edgeCircle, edgeCircle)
    }
  }
  group.style = {
    strokeWidth: STROKE_WIDTH2,
    strokeColor: STYLES.pathColor,
  }
  if (dashed) {
    edgeRectPath.style.dashArray = DASH_ARRAY
    edgeRectPath.strokeWidth = STROKE_WIDTH1
  }
  if (through) {
    const delta = Math.max(3, 4 * STROKE_WIDTH2)
    const bgRect = new Path.Rectangle({
      rectangle: edgeRectPath.strokeBounds,
      closed: true,
      fillColor: COLORS.white,
      strokeWidth: 0,
      // selected: true,
      data: {
        is: 'edge_overlay',
      },
    })
    // затираем линию торца
    if (isFace && !zOffset) {
      bgRect.bounds.height += delta
    }
    if (!isFace) {
      bgRect.bounds.height += delta
      bgRect.bounds.y -= delta
    }
    // если вырез находится на краю, стираем и боковую его часть
    if (x1 == 0) {
      bgRect.bounds.width += delta
      bgRect.bounds.x -= delta
    }
    if (x2 >= sides[property].size) {
      bgRect.bounds.width += delta
    }
    side.addChild(bgRect)
  }
  if (Array.isArray(dividers)) {
    dividers.forEach(distance => {
      group.addChild(
        new Path.Line({
          from: [distance, y1],
          to: [distance, y2],
          strokeColor: '#628EFF',
          strokeWidth: STROKE_WIDTH1,
          dashArray: DASH_ARRAY,
        })
      )
    })
  }
  const edgeItem = side
    .getItems({
      data: { isEdge: true },
    })
    .find(edge =>
      edgeRectPath.segments.every(({ point }) => edge.contains(point))
    )
  if (edgeItem) {
    if (!through) {
      // edgeItem.selected = true
      group.insertChild(0, edgeItem.clone())
      group.clipped = !through
    }
  } else {
    group.visible = false
  }
  side.addChild(group)
}
