export default function getItemsDefault(parent) {
  return [
    {
      // groove_end
      item: [
        {
          id: 0,
          template: 'groove_end',
          place_second: {
            top: false,
            bottom: false,
            right: false,
            left: false,
          },
          deep: '8',
          width: '4',
          width_saw: '3.2',
          mid_by_z_center: true,
          z_offset: 10,
        },
      ],
    },
    {
      // groove
      item: [
        {
          id: 0,
          template: 'groove',
          face: {
            id: '1',
            name: 'Tыл',
          },
          place_second: {
            top: false,
            bottom: false,
            right: false,
            left: false,
          },
          margin: '15',
          deep: '8',
          width: '4',
          width_saw: '3.2',
          is_limited: false,
          groove_length: 200,
          start_offset: 0,
          is_opposite_start: false,
          action: '',
        },
      ],
    },
    {
      // groove_edge
      item: [
        {
          template: 'groove_edge',
          id: 0,
          face: {
            id: '1',
            name: 'Tыл',
          },
          place: {
            value: 45,
            checkboxes: {
              top: false,
              bottom: false,
              right: false,
              left: false,
            },
          },
          action: '',
        },
      ],
    },
    {
      // groove_side
      item: [
        {
          template: 'groove_side',
          id: 0,
          face: {
            id: '1',
            name: 'Tыл',
          },
          place_second: {
            top: false,
            bottom: false,
            right: false,
            left: false,
          },
          margin: 0, // Нужен для бэка
          deep: 10,
          width: 4,
          width_saw: '3.2',
          is_limited: false,
          groove_length: 200,
          start_offset: 0,
          is_opposite_start: false,
          action: '',
        },
      ],
    },
    {
      // arc_side_inner
      item: [
        {
          template: 'arc_side_inner',
          id: 0,
          place_second: {
            top: false,
            bottom: false,
            right: false,
            left: false,
          },
          arc_height: 0,
          round: false,
          rad_left: 0,
          rad_right: 0,
          close_kromka: false,
          kromka: null,
        },
      ],
    },
    {
      // radius_inner
      item: [
        {
          template: 'radius_inner',
          id: 0,
          rad_outside: {
            top_left: false,
            top_right: false,
            bottom_left: false,
            bottom_right: false,
          },
          rad_simple: '',
          close_kromka: true,
          kromka: null,
          action: '',
        },
      ],
    },
    {
      // radius_outer
      item: [
        {
          template: 'radius_outer',
          id: 0,
          rad_inside: {
            top_left: false,
            top_right: false,
            bottom_left: false,
            bottom_right: false,
          },
          rad_simple: '',
          round: false,
          rad_round: 0,
          rad_round1: 0,
          close_kromka: true,
          kromka: null,
          action: '',
        },
      ],
    },
    {
      // arc_corner
      item: [
        {
          template: 'arc_corner',
          id: 0,
          angle: {
            top_left: false,
            top_right: false,
            bottom_left: false,
            bottom_right: false,
          },
          byrest: true,
          height: 0,
          width: 0,
          rad: 0,
          close_kromka: false,
          kromka: null,
          action: '',
        },
      ],
    },
    {
      // arc_side
      item: [
        {
          template: 'arc_side',
          id: 0,
          place_second: {
            top: false,
            bottom: false,
            right: false,
            left: false,
          },
          byrest: false,
          remaining_size_1: 0,
          remaining_size_2: 0,
          round: false,
          rad_round: 0,
          rad_round1: 0,
          close_kromka: false,
          kromka: null,
          action: '',
        },
      ],
    },
    {
      // line_corner
      item: [
        {
          template: 'line_corner',
          id: 0,
          angle: {
            top_left: false,
            top_right: false,
            bottom_left: false,
            bottom_right: false,
          },
          byrest: false,
          height: 500,
          width: 500,
          round: false,
          rad_round: 30,
          rad_round1: 30,
          close_kromka: false,
          kromka: null,
        },
      ],
    },
    {
      // carve_p  Прямоугольный (П-образный) вырез
      item: [
        {
          template: 'carve_p',
          id: 0,
          place_second: {
            top: false,
            bottom: false,
            right: false,
            left: false,
          },
          cutout_size_width: 0,
          cutout_size_deep: 0,
          cutout_center_side: 0,
          by_side_center: false,
          corner_offset: false,
          rad: 4,
          close_kromka: false,
          kromka: null,
          action: '',
        },
      ],
    },
    {
      // carve_g  Г- образный вырез на форматно-раскроечном станке
      item: [
        {
          template: 'carve_g',
          id: 0,
          face: {
            id: '1',
            name: 'Tыл',
          },
          angle: {
            top_left: false,
            top_right: false,
            bottom_left: false,
            bottom_right: false,
          },
          byrest: false,
          height: '',
          width: '',
          close_kromka: false,
          kromka: null,
          action: '',
        },
      ],
    },
    {
      // carve_gcnc Г- образный вырез на ЧПУ.
      item: [
        {
          template: 'carve_gcnc',
          id: 0,
          angle: {
            top_left: false,
            top_right: false,
            bottom_left: false,
            bottom_right: false,
          },
          byrest: false,
          height: '',
          width: '',
          radius1: 0,
          radius2: 4,
          radius3: 0,
          close_kromka: false,
          kromka: null,
          action: '',
        },
      ],
    },
    {
      //  ellipse_in  Круг, элипс.
      item: [
        {
          template: 'ellipse_in',
          id: 0,
          angle: {
            top_left: false,
            top_right: false,
            bottom_left: false,
            bottom_right: false,
          },
          centerheight: 0,
          centerwidth: 0,
          height: 0,
          width: 0,
          tiltangle: 0,
          cross_cutting: true,
          drilling_depth_z: 10,
          face: {
            id: '1',
            name: 'Tыл',
          },
          close_kromka: false,
          kromka: null,
          action: '',
        },
      ],
    },
    {
      //  square_in   Прямоугольник
      item: [
        {
          template: 'square_in',
          id: 0,
          angle: {
            top_left: false,
            top_right: false,
            bottom_left: false,
            bottom_right: false,
          },
          centerheight: 0,
          centerwidth: 0,
          height: 0,
          width: 0,
          rad: 4,
          tiltangle: 0,
          cross_cutting: true,
          drilling_depth_z: 10,
          face: {
            id: '1',
            name: 'Tыл',
          },
          close_kromka: false,
          kromka: null,
          action: '',
        },
      ],
    },
    {
      //  hdl_smile   Ручка «Улыбка»
      item: [
        {
          template: 'hdl_smile',
          id: 0,
          place_second: {
            top: false,
            bottom: false,
            right: false,
            left: false,
          },
          width: 0,
          deep: 0,
          cutout_center_side: 0,
          by_side_center: false,
          corner_offset: false,
          rad: 0,
          close_kromka: false,
          kromka: null,
          action: '',
        },
      ],
    },
    {
      //  carve_groove  Выборка под врезную ручку.
      item: [
        {
          template: 'carve_groove',
          id: 0,
          width: 0,
          height: 0,
          deep: 0,
          bycenterwidth: true,
          bycenterheight: true,
          offsetwidth: 0,
          offsetheight: 0,
          edgewidth: true,
          edgeheight: true,
          rad: 4,
          has_holes: false,
          holes_offset_x: 20,
          holes_offset_y: 20,
          action: '',
        },
      ],
    },
    {
      //  carve_led_direct  Выборка под LED профиль прямая
      item: [
        {
          template: 'carve_led_direct',
          id: 0,
          face: {
            id: '1',
            name: 'Tыл',
          },
          width: 0,
          deep: 0,
          offset: 0,
          direction: true,
          is_limited: false,
          groove_length: 200,
          start_offset: 0,
          is_opposite_start: false,
          action: '',
        },
      ],
    },
    {
      //  carve_led_corner  Выборка под LED профиль углом
      item: [
        {
          template: 'carve_led_corner',
          id: 0,
          face: {
            id: '1',
            name: 'Tыл',
          },
          width: 0,
          deep: 0,
          offsetwidth: 0,
          offsetheight: 0,
          horizontal: true,
          vertical: true,
          action: '',
        },
      ],
    },
    {
      //  edge_hole  Произвольное отверстие в торце
      item: [
        {
          template: 'edge_hole',
          id: 0,
          angle: {
            top_left: false,
            top_right: false,
            bottom_left: false,
            bottom_right: false,
          },
          place_second: {
            top: false,
            bottom: false,
            right: false,
            left: false,
          },
          tool_selection: 0,
          distance_mm: 0,
          deep: 36,
          z_offset: 10,
          mid_by_z_center: true,
          action: '',
        },
      ],
    },
    {
      //  side_hole  Произвольное отверстие в плоскости
      item: [
        {
          template: 'side_hole',
          id: 0,
          angle: {
            top_left: false,
            top_right: false,
            bottom_left: false,
            bottom_right: false,
          },
          tool_selection: 0,
          distance_x_mm: 50.0,
          distance_y_mm: 50.0,
          cross_cutting: true,
          drilling_depth_z: 10,
          face: {
            id: '1',
            name: 'Tыл',
          },
          action: '',
        },
      ],
    },
    {
      //  minifix  Узел минификс
      item: [
        {
          template: 'minifix',
          id: 0,
          angle: {
            top_left: false,
            top_right: false,
            bottom_left: false,
            bottom_right: false,
          },
          edge: {
            top: false,
            bottom: false,
            right: false,
            left: false,
          },
          indent_mm: 50.0,
          processing_side_list: Object.entries(
            parent.$t('treatment.drilling.headers.third.processing_side_list')
          ).map(([key, value]) => ({ value: key, text: value })),
          processing_side: 'face',
          face: {
            id: '1',
            name: 'Tыл',
          },
          action: '',
        },
      ],
    },
    {
      //  minifix_shkant  Узел минификс
      item: [
        {
          template: 'minifix_shkant',
          id: 0,
          angle: {
            top_left: false,
            top_right: false,
            bottom_left: false,
            bottom_right: false,
          },
          edge: {
            top: false,
            bottom: false,
            right: false,
            left: false,
          },
          minifix_knot_distance_mm: 50.0,
          minifix_knot_to_the_dowel: 32.0,
          /*processing_side_list: Object.entries(parent.$t('treatment.drilling.headers.third.processing_side_list'))
                  .map(([key,value]) => ({ value: key, text: value})),
              processing_side: 'face',*/
          face: {
            id: '1',
            name: 'Tыл',
          },
          action: '',
        },
      ],
    },
    {
      //  rafix  Рафикс
      item: [
        {
          template: 'rafix',
          id: 0,
          angle: {
            top_left: false,
            top_right: false,
            bottom_left: false,
            bottom_right: false,
          },
          edge: {
            top: false,
            bottom: false,
            right: false,
            left: false,
          },
          distance_to_rafix_mm: 50.0,
          /*processing_side_list: Object.entries(parent.$t('treatment.drilling.headers.third.processing_side_list'))
                  .map(([key,value]) => ({ value: key, text: value})),
              processing_side: 'face',*/
          face: {
            id: '1',
            name: 'Tыл',
          },
          action: '',
        },
      ],
    },
    {
      //  hinge35  Петли (d = 35мм)
      item: [
        {
          template: 'hinge35',
          id: 0,
          face: {
            id: '1',
            name: 'Tыл',
          },
          angle: {
            top_left: false,
            top_right: false,
            bottom_left: false,
            bottom_right: false,
          },
          edge: {
            top: false,
            bottom: false,
            right: false,
            left: false,
          },
          diameter_list: Object.entries(
            parent.$t('treatment.drilling.headers.sixth.diameter_list')
          ).map(([key, value]) => ({ value: Number(key), text: value })),
          diameter: 26,
          loops_list: [
            {
              id: 1,
              text: '1',
            },
            {
              id: 2,
              text: '2',
            },
            {
              id: 3,
              text: '3',
            },
            {
              id: 4,
              text: '4',
            },
            {
              id: 5,
              text: '5',
            },
            {
              id: 6,
              text: '6',
            },
          ],
          number_of_loops: 1,
          distance_from_corner_to_center_of_bowl_mm: 17.0,
          distances_are_equal: false,
          off_start: 100,
          off_end: 200,
          off_3: 300,
          off_4: 400,
          off_5: 500,
          off_6: 600,
          action: '',
        },
      ],
    },
    {
      //  Постформинг tt_lock_socket
      item: [
        {
          template: 'tt_lock_socket',
          id: 0,
          double_rounding: false,
          tt_lock_socket_angle: {
            top_left: false,
            top_right: false,
            bottom_left: false,
            bottom_right: false,
          },
          lock_length_mm_input: 600,
          is_mill_the_knot_under_the_screed: false,
          mill_the_knot_under_the_screed: 150,
          mill_the_knot_under_the_screed_list: Object.entries(
            parent.$t(
              'treatment.postforming.data.tt_lock_socket.mill_the_knot_under_the_screed_list'
            )
          ).map(([key, value]) => ({ value: Number(key), text: value })),
          number_of_nodes: 3,
          number_of_nodes_list: Object.entries(
            parent.$t(
              'treatment.postforming.data.tt_lock_socket.number_of_nodes_list'
            )
          ).map(([key, value]) => ({ value: Number(key), text: value })),
          offset_1st_node: 50,
          offset_2nd_node: 100,
          offset_3rd_node: 150,
          action: '',
        },
      ],
    },
    {
      //  Постформинг tt_lock_plug
      item: [
        {
          template: 'tt_lock_plug',
          id: 0,
          double_rounding: false,
          tt_lock_plug_angle: {
            top_left: false,
            top_right: false,
            bottom_left: false,
            bottom_right: false,
          },
          lock_length_mm_input: 600,
          is_mill_the_knot_under_the_screed: false,
          mill_the_knot_under_the_screed: 150,
          mill_the_knot_under_the_screed_list: Object.entries(
            parent.$t(
              'treatment.postforming.data.tt_lock_socket.mill_the_knot_under_the_screed_list'
            )
          ).map(([key, value]) => ({ value: Number(key), text: value })),
          number_of_nodes: 3,
          number_of_nodes_list: Object.entries(
            parent.$t(
              'treatment.postforming.data.tt_lock_socket.number_of_nodes_list'
            )
          ).map(([key, value]) => ({ value: Number(key), text: value })),
          offset_1st_node: 50,
          offset_2nd_node: 100,
          offset_3rd_node: 150,
          action: '',
        },
      ],
    },
    {
      //  Постформинг tt_radius_flange
      item: [
        {
          template: 'tt_radius_flange',
          id: 0,
          double_rounding: false,
          tt_radius_flange_angle: {
            top_left: false,
            top_right: false,
            bottom_left: false,
            bottom_right: false,
          },
          tt_radius_flange_radius_mm: 500,
          close_kromka: true,
          tt_radius_flange_select_kromka: 0,
          action: '',
        },
      ],
    },
    {
      //  Постформинг tt_corner_cut
      item: [
        {
          template: 'tt_corner_cut',
          id: 0,
          double_rounding: false,
          angle: {
            top_left: false,
            top_right: false,
            bottom_left: false,
            bottom_right: false,
          },
          byrest: false,
          cut_height_parts_mm: 500,
          slice_width_parts_mm: 500,
          round: false,
          rad_round: 30,
          close_kromka: true,
          tt_corner_cut_select_kromka: 0,
          action: '',
        },
      ],
    },
    {
      //  Постформинг tt_rect_inside
      item: [
        {
          template: 'tt_rect_inside',
          id: 0,
          double_rounding: false,
          angle: {
            top_left: false,
            top_right: false,
            bottom_left: false,
            bottom_right: false,
          },
          cutout_height_mm: 500,
          width_cutout_mm: 500,
          internal_radius_corners_cutout_mm: 50,
          height_to_center_of_cutout_mm: 500,
          width_to_the_center_of_the_cutout_mm: 500,
          action: '',
        },
      ],
    },
    {
      //  Постформинг tt_2corner_radius
      item: [
        {
          template: 'tt_2corner_radius',
          id: 0,
          place_second: {
            top: false,
            bottom: false,
            right: false,
            left: false,
          },
          cut_soft_chamfer: true,
          cut_soft_chamfer_list: Object.entries(
            parent.$t(
              'treatment.postforming.data.tt_2corner_radius.cut_soft_chamfer_list'
            )
          ).map(([key, value]) => ({ value: key === 'true', text: value })),
          part_height_mm: 500,
          radius_corners_mm: 500,
          close_kromka: false,
          tt_2corner_radius_select_kromka: 0,
          action: '',
        },
      ],
    },
    {
      //  Постформинг tt_4corner_radius
      item: [
        {
          template: 'tt_4corner_radius',
          id: 0,
          cut_soft_chamfer: false,
          cut_soft_chamfer_list: Object.entries(
            parent.$t(
              'treatment.postforming.data.tt_2corner_radius.cut_soft_chamfer_list'
            )
          ).map(([key, value]) => ({ value: key === 'true', text: value })),
          part_height_mm: 500,
          radius_corners_mm: 50,
          close_kromka: false,
          tt_4corner_radius_select_kromka: 0,
          action: '',
        },
      ],
    },
  ]
}
