<template>
  <fragment>
    <MatchMedia v-slot="{ xs, sm, md, lg }" wrapperTag="tr">
      <td
        :class="{ 'footable-last-visible': xs || sm }"
        :colspan="xs || sm ? 2 : 1"
      >
        <span
          class="footable-toggle fooicon last-column"
          :class="isOpen ? 'fooicon-minus' : 'fooicon-plus'"
          @click="isOpen = !isOpen"
          v-if="xs || sm"
        ></span>
        <span>{{ item.name }}</span>
      </td>
      <td class="d-none d-md-table-cell">
        <span>{{ item.article }}</span>
      </td>
      <td class="d-none d-md-table-cell">
        <span>{{ parseInt(item.width) }}x{{ parseInt(item.height) }}</span>
      </td>
      <td class="d-none d-md-table-cell">
        <span>{{ item.details_count }}</span>
      </td>
      <td
        class="d-none d-md-table-cell"
        :class="{ 'footable-last-visible': md || lg }"
        :colspan="md || lg ? 2 : 1"
      >
        <span
          class="footable-toggle fooicon last-column"
          :class="isOpen ? 'fooicon-minus' : 'fooicon-plus'"
          @click="isOpen = !isOpen"
          v-if="md || lg"
        ></span>
        <span class="text-nowrap">{{ item.price.toFixed(2) || 0 }} ₴</span>
      </td>
      <td class="d-none d-xl-table-cell">
        <span>{{ item.quantity }}</span>
      </td>
      <td class="d-none d-xl-table-cell">
        <span class="text-nowrap"
          >{{ (item.price * item.quantity).toFixed(2) }} ₴</span
        >
      </td>
      <td class="d-none d-xl-table-cell">
        <div
          class="btn-status"
          :class="
            item.stock !== 0 && item.stock >= item.quantity
              ? 'confirmed'
              : 'new'
          "
        >
          <span class="circle"></span
          ><span>
            {{
              item.stock !== 0 && item.stock >= item.quantity
                ? $i18n.t('product.available')
                : $i18n.t('product.not_available')
            }}
          </span>
        </div>
      </td>
      <td class="d-none d-xl-table-cell">
        <div class="d-flex justify-content-end">
          <button
            class="btn btn-icon ico_plus"
            type="button"
            @click="
              $root.$emit('openDialog', 'FileImportDialog', {
                title: 'Выгрузить детали для данного материала',
              })
            "
          ></button>
          <router-link
            class="btn btn-primary"
            :to="{
              name: 'OrderDetails',
              params: { orderId, materialId: item.id },
            }"
            >Обробка</router-link
          >
          <button
            class="btn btn-icon ico_trash"
            type="button"
            @click="$emit('remove')"
          ></button>
        </div>
      </td>
    </MatchMedia>
    <MatchMedia v-slot="{ xs, sm, md, lg }" :wrapperTag="null" v-show="isOpen">
      <tr class="footable-detail-row">
        <td :colspan="xs || sm ? 2 : 6">
          <table class="footable-details">
            <tbody>
              <tr class="d-table-row d-md-none">
                <th>Артикул</th>
                <td>
                  <span>{{ item.article }}</span>
                </td>
              </tr>
              <tr class="d-table-row d-md-none">
                <th>Розмір плити</th>
                <td>
                  <span>{{ item.width }}x{{ item.height }}</span>
                </td>
              </tr>
              <tr class="d-table-row d-md-none">
                <th>Кількість деталей</th>
                <td>
                  <span>{{ item.details_count }}</span>
                </td>
              </tr>
              <tr>
                <th>Кількість панелей</th>
                <td>
                  <span>{{ item.quantity }}</span>
                </td>
              </tr>
              <tr>
                <th>Сума</th>
                <td>
                  <span>{{ (item.price * item.quantity).toFixed(2) }} ₴</span>
                </td>
              </tr>
              <tr>
                <th>Наявність</th>
                <td>
                  <div
                    class="btn-status"
                    :class="
                      item.stock !== 0 && item.stock >= item.quantity
                        ? 'confirmed'
                        : 'new'
                    "
                  >
                    <span class="circle"></span
                    ><span>
                      {{
                        item.stock !== 0 && item.stock >= item.quantity
                          ? $i18n.t('product.available')
                          : $i18n.t('product.not_available')
                      }}
                    </span>
                  </div>
                </td>
              </tr>
              <tr>
                <th></th>
                <td>
                  <div class="d-flex justify-content-end">
                    <button
                      class="btn btn-icon ico_plus"
                      type="button"
                      @click="
                        $root.$emit('openDialog', 'FileImportDialog', {
                          title: 'Выгрузить детали для данного материала',
                        })
                      "
                    ></button>
                    <router-link
                      class="btn btn-primary"
                      :to="{
                        name: 'OrderDetails',
                        params: { orderId, materialId: item.id },
                      }"
                      >Обробка</router-link
                    >
                    <button
                      class="btn btn-icon ico_trash"
                      type="button"
                      @click="$emit('remove')"
                    ></button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </td>
      </tr>
    </MatchMedia>
  </fragment>
</template>

<script>
import { Fragment } from 'vue-frag'
import { MatchMedia } from 'vue-component-media-queries'

export default {
  name: 'GoodsTableRow',
  components: { Fragment, MatchMedia },
  props: ['item', 'index', 'orderId'],
  data: () => ({
    isOpen: false,
  }),

  methods: {
    toggle() {
      this.isOpen = !this.isOpen
    },
  },
}
</script>

<style lang="scss"></style>
