<template>
  <div v-if="order" class="order-info">
    <div class="widjet --services-price">
      <div class="widjet__box">
        <div class="widjet__head">
          <div class="widjet__title">Вартiсть послуг</div>
          <div class="widjet__action">
            <a
              class="js-print btn btn-icon d-print-none"
              href="#!"
              @click.prevent="print()"
              ><i class="ico_print"></i
            ></a>
          </div>
        </div>
        <div class="widjet__body pl-print-0">
          <div class="scroll-box">
            <table class="js-table footable">
              <tbody>
                <tr v-for="(item, index) in operations" :key="index">
                  <td>
                    <a href="#!" @click.prevent="">№{{ item.name }}</a>
                  </td>
                  <td>{{ item.price.toFixed(2) }} ₴</td>
                  <td>{{ item.value }} {{ item.measure }}</td>
                  <td>{{ item.total.toFixed(2) }} ₴</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="widjet__foot">
          <div class="total">
            <span>Разом</span
            ><strong
              >{{
                calculations.total > 0
                  ? parseFloat(calculations.total).toFixed(2)
                  : 0 || 0
              }}
              ₴</strong
            >
          </div>
          <div class="total">
            <span>Разом зі знижкою</span
            ><strong
              >{{
                calculations.total > 0
                  ? parseFloat(calculations.total).toFixed(2)
                  : 0 || 0
              }}
              ₴</strong
            >
          </div>
        </div>
      </div>
    </div>
    <div class="widjet --quantity-goods">
      <div class="widjet__box">
        <div class="widjet__head">
          <div class="widjet__title">Перелiк товарiв</div>
          <div class="widjet__action"></div>
        </div>
        <div class="widjet__body">
          <div class="scroll-box">
            <table class="footable breakpoint">
              <thead>
                <tr>
                  <th>Артикул</th>
                  <th>Вартість</th>
                  <th>Товарів</th>
                  <th data-breakpoints="xs" class="d-none d-md-table-cell">
                    Деталей
                  </th>
                  <th data-breakpoints="xs" class="d-none d-md-table-cell">
                    Сума
                  </th>
                </tr>
              </thead>
              <tbody>
                <GoodsTableRow
                  :item="item"
                  v-for="(item, index) in materials"
                  :key="index"
                />
              </tbody>
            </table>
          </div>
        </div>
        <div class="widjet__foot">
          <div class="total">
            <span>Разом</span
            ><strong
              >{{
                calculations.total_products > 0
                  ? parseFloat(calculations.total_products).toFixed(2)
                  : 0 || 0
              }}
              ₴</strong
            >
          </div>
          <div class="total">
            <span>Разом зі знижкою</span
            ><strong
              >{{
                calculations.total_products > 0
                  ? parseFloat(calculations.total_products).toFixed(2)
                  : 0 || 0
              }}
              ₴</strong
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import GoodsTableRow from '@/components/order/info/GoodsTableRow.vue'

export default {
  name: 'GeneralInfo',
  components: { GoodsTableRow },
  props: ['orderId'],
  data: () => ({}),
  computed: {
    order() {
      return this.$store.state.order.order || {}
    },
    calculations() {
      return this.$store.state.order.calculations
    },
    materials() {
      const { calculations } = this.$store.state.order
      return (calculations && calculations.materials) || []
    },
    operations() {
      const { calculations } = this.$store.state.order
      return (calculations && calculations.operations) || []
    },
  },
  methods: {
    print() {
      document.querySelectorAll('.print-block').forEach(el => el.remove())
      const block = document.createElement('div')
      block.classList.add('print-block')
      document.body.appendChild(block)
      block.innerHTML = this.$el.innerHTML
      block.querySelectorAll('.widjet').forEach(el => el.classList.remove("widjet"))
      window.requestAnimationFrame(window.print)
    },
  },
  mounted() {
    this.$store.dispatch('order/getCalculations', this.orderId)
  },
}
</script>

<style lang="scss">
table.footable > tbody > tr > td,
table.footable > tbody > tr > th {
  display: table-cell;
}
</style>
