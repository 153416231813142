<template>
  <ul class="nav nav-pills" role="tablist" :class="{ 'is-invalid': error }">
    <li
      class="nav-item"
      role="presentation"
      v-for="item in items"
      :key="item.id"
    >
      <button
        class="nav-link"
        :class="{ active: item.id == value.id }"
        type="button"
        role="tab"
        @click="$emit('input', item)"
      >
        {{ item.name }}
      </button>
    </li>
  </ul>
</template>

<script>
export default {
  name: 'FaceInput',
  props: {
    value: {
      type: Object,
      default: function() {
        return {}
      },
    },
    error: Boolean,
  },
  data() {
    return {}
  },
  computed: {
    items() {
      return [
        {
          id: '1',
          name: this.$t('fields.face.back'),
        },
        {
          id: '2',
          name: this.$t('fields.face.face'),
        },
      ]
    },
  },
  methods: {},
}
</script>

<style scoped lang="scss"></style>
