<template>
  <div>
    <div class="table-head --page-new-order">
      <div>
        <Submenu
          :orderId="orderId"
          :materialId="materials[0] && materials[0].id"
        />
      </div>
      <div>
        <router-link class="btn btn-primary" :to="{ name: 'Orders' }"
          >Зберегти</router-link
        ><button
          class="btn btn-icon ico_trash"
          type="button"
          @click="deleteOrder()"
        ></button>
      </div>
    </div>
    <!-- Materials -->
    <div class="table-caption">
      <h3>Плитний матеріал</h3>
      <router-link
        class="btn btn-secondary"
        :to="{ name: 'OrderAddMaterial', params: { orderId: orderId } }"
        >Додати плиту</router-link
      >
    </div>
    <div class="table-body">
      <table class="footable breakpoint">
        <thead>
          <tr class="footable-header">
            <th>Назва</th>
            <th data-breakpoints="xs" class="d-none d-md-table-cell">
              Артикул
            </th>
            <th data-breakpoints="xs" class="d-none d-md-table-cell">
              Розмір плити
            </th>
            <th data-breakpoints="xs" class="d-none d-md-table-cell">
              Кількість деталей
            </th>
            <th data-breakpoints="xs" class="d-none d-md-table-cell">Ціна</th>
            <th data-breakpoints="xs sm md" class="d-none d-xl-table-cell">
              Кількість панелей
            </th>
            <th data-breakpoints="xs sm md" class="d-none d-xl-table-cell">
              Сума
            </th>
            <th class="w-1 d-none d-xl-table-cell" data-breakpoints="xs sm md">
              Наявність
            </th>
            <th class="w-1" data-breakpoints="xs sm md">
              <button
                class="btn-icon ico_plus"
                type="button"
                @click="
                  $root.$emit('openDialog', 'FileImportDialog', {
                    title: 'Выгрузить датели с материалами',
                  })
                "
              ></button>
            </th>
          </tr>
        </thead>
        <tbody>
          <MaterialsTableRow
            :item="item"
            :orderId="orderId"
            v-for="(item, index) in materials"
            :key="index"
            @remove="deleteProduct(item)"
          />
        </tbody>
        <tfoot></tfoot>
      </table>
    </div>

    <!-- Kromkas -->
    <div class="table-caption">
      <h3>Крайка</h3>
      <router-link
        class="btn btn-secondary"
        :to="{ name: 'OrderAddKromka', params: { orderId: orderId } }"
        >Додати крайку</router-link
      >
    </div>
    <div class="table-body">
      <table class="footable breakpoint">
        <thead>
          <tr class="footable-header">
            <th>Назва</th>
            <th data-breakpoints="xs" class="d-none d-md-table-cell">
              Артикул
            </th>
            <th data-breakpoints="xs" class="d-none d-md-table-cell">
              Розмір
            </th>
            <th data-breakpoints="xs" class="d-none d-md-table-cell">Ціна</th>
            <th data-breakpoints="xs sm md" class="d-none d-xl-table-cell">
              Сума
            </th>
            <th
              data-breakpoints="xs sm md"
              class="d-none d-xl-table-cell"
              style="width:300px"
            >
              Наявність
            </th>
            <th
              class="w-1 d-none d-xl-table-cell"
              data-breakpoints="xs sm md"
            ></th>
          </tr>
        </thead>
        <tbody>
          <KromkasTableRow
            :item="item"
            :orderId="orderId"
            v-for="(item, index) in kromkas"
            :key="index"
            @remove="deleteKromka(item)"
          />
        </tbody>
        <tfoot></tfoot>
      </table>
    </div>
  </div>
</template>

<script>
import api from '@/gateway/api'
import Submenu from '@/components/order/Submenu.vue'
import MaterialsTableRow from '@/components/order/materials/MaterialsTableRow.vue'
import KromkasTableRow from '@/components/order/materials/KromkasTableRow.vue'

export default {
  name: 'Materials',
  props: ['orderId'],
  components: { Submenu, MaterialsTableRow, KromkasTableRow },

  data: () => ({}),
  computed: {
    order: {
      get: function() {
        let order = this.$store.state.order.order || {}
        if (order.materials) {
          order.materials = order.materials.map(material => {
            if (material.isShowList === undefined) {
              material.isShowList = false
            }
            if (material.kromkas === undefined) {
              material.kromkas = [...this.kromkas]
            }
            return material
          })
        }
        return this.$store.state.order.order
      },
      set: function(order) {
        this.$store.commit('order/updateOrder', order)
      },
    },
    materials() {
      return this.$store.getters['order/materials']
    },
    kromkas() {
      return this.$store.getters['order/kromkas']
    },
  },

  methods: {
    addProduct() {
      this.$root.$emit('openDialog', 'SelectProduct', { type: 'plate' })
    },
    addKromka(material_id, product_children_edge, item) {
      if (product_children_edge >= 5) {
        this.$root.$emit('openDialog', 'AlertMaterialCount')
      } else {
        this.$root.$emit('openDialog', 'SelectKromka', {
          type: 'edge',
          item: item,
        })
      }
    },
    deleteKromka(kromka) {
      const acceptAction = () => {
        this.$store
          .dispatch('order/removeOrderProduct', {
            orderId: this.order.id,
            product: kromka,
          })
          .then(() =>
            this.$store.dispatch('order/getOrderProducts', {
              orderId: this.order.id,
            })
          )
          .then(result => {})
          .catch(error => {})
      }
      this.$root.$emit('openDialog', 'AskDialog', {
        acceptAction,
        title: this.$i18n.t('order.remove_material_query'),
        acceptText: this.$i18n.t('yes'),
        denyText: this.$i18n.t('no'),
      })
    },
    updateProduct(item) {
      // this.$Progress.start();
      this.$root.$emit('playPreloader')
      this.$store
        .dispatch('order/updateOrderProduct', {
          order_id: this.order.id,
          product: item,
        })
        .then(() => {
          // this.$Progress.finish();
          this.$root.$emit('stopPreloader')
        })
        .catch(() => {
          // this.$Progress.fail();
          this.$root.$emit('stopPreloader')
        })
    },
    deleteProduct(item) {
      const acceptAction = () => {
        // this.$Progress.start();
        this.$root.$emit('playPreloader')
        this.$store
          .dispatch('order/removeOrderProduct', {
            orderId: this.order.id,
            product: item,
          })
          .then(() =>
            this.$store.dispatch('order/getOrderProducts', {
              orderId: this.order.id,
            })
          )
          .then(() => {
            // this.$Progress.finish();
            this.$root.$emit('stopPreloader')
          })
          .catch(() => {
            // this.$Progress.fail();
            this.$root.$emit('stopPreloader')
          })
      }
      this.$root.$emit('openDialog', 'AskDialog', {
        acceptAction,
        title: this.$i18n.t('order.remove_material_query'),
        acceptText: this.$i18n.t('yes'),
        denyText: this.$i18n.t('no'),
      })
    },
    showAction() {
      this.actionHidden = !this.actionHidden
      if (!this.actionHidden) {
        this.order.materials = this.order.materials.map(item => ({
          ...item,
          action: '',
        }))
      }
    },
    getEdge() {
      return new Promise((resolve, reject) => {
        api
          .post('order/material/edge', {
            order_id: this.$store.state.order.order.id,
            material_id: this.material_id,
          })
          .then(result => {
            this.kromkas = result.data.material_edges
            resolve(result)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    deleteOrder() {
      this.$root.$emit('openDialog', 'AskDialog', {
        acceptAction: () => {
          // this.$Progress.start();
          this.$root.$emit('playPreloader')
          this.$store
            .dispatch('order/deleteOrder', this.orderId)
            .then(() => {
              this.$router.push({ name: 'Orders' })
            })
            .then(() => {
              // this.$Progress.finish();
              this.$root.$emit('stopPreloader')
            })
            .catch(() => {
              // this.$Progress.fail();
              this.$root.$emit('stopPreloader')
            })
        },
        title: this.$i18n.t('order.remove_order_query'),
        acceptText: this.$i18n.t('yes'),
        denyText: this.$i18n.t('no'),
      })
    },
  },

  mounted() {},

  created() {
    this.$root.$emit('playPreloader')
    this.$store
      .dispatch('order/getOrder', {
        id: this.orderId,
      })
      .then(() =>
        this.$store.dispatch('order/getOrderProducts', {
          orderId: this.order.id,
        })
      )
      .then(() => {
        this.$root.$emit('stopPreloader')
      })
      .catch(() => {
        this.$root.$emit('stopPreloader')
      })
  },
}
</script>

<style lang="scss">
table.footable > tbody > tr > td,
table.footable > tbody > tr > th {
  display: table-cell;
}
</style>
