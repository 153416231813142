<template>
  <fragment>
    <MatchMedia v-slot="{ xs, sm }" wrapperTag="tr">
      <td
        :class="{ 'footable-last-visible': xs || sm }"
        :colspan="xs || sm ? 2 : 1"
      >
        <span
          class="footable-toggle fooicon last-column"
          :class="isOpen ? 'fooicon-minus' : 'fooicon-plus'"
          @click="isOpen = !isOpen"
          v-if="xs || sm"
        ></span>
        <span>{{ item.name }}</span>
      </td>
      <td class="d-none d-md-table-cell">
        <span class="nowrap">№{{ item.article }}</span>
      </td>
      <td class="d-none d-md-table-cell">
        <span class="nowrap">{{ item.price.toFixed(2) || 0 }} ₴</span>
      </td>
      <td class="d-none d-md-table-cell">
        <div
          class="position-relative text-validate d-inline-block"
          :class="{
            error: item.quantity < item.min_quantity,
          }"
        >
          <input
            class="form-control"
            type="text"
            placeholder="Кількість"
            v-model.number="item.quantity"
            :class="{
              'border-danger': item.quantity < item.min_quantity,
            }"
            @change="$emit('change', { quantity: $event.target.value })"
          />
          <div class="text-error">
            Мінімальна кількість: {{ item.min_quantity }}
          </div>
        </div>
      </td>
      <td class="d-none d-md-table-cell">
        <strong class="nowrap"
          >{{ (item.price * item.quantity).toFixed(2) }} ₴</strong
        >
      </td>
      <td class="d-none d-md-table-cell">
        <div class="btn-status consideration">
          <span class="circle"></span><span>В наявності</span>
        </div>
      </td>
      <td class="w-1 text-end d-none d-md-table-cell">
        <button
          @click="$emit('remove')"
          class="btn btn-icon ico_trash"
        ></button>
      </td>
    </MatchMedia>
    <tr class="footable-detail-row" v-show="isOpen">
      <td colspan="1">
        <table class="footable-details js-table">
          <tbody>
            <tr>
              <th>Артикул</th>
              <td>
                <span class="nowrap">№{{ item.article }}</span>
              </td>
            </tr>
            <tr>
              <th>Ціна</th>
              <td>
                <span class="nowrap">{{ item.price.toFixed(2) || 0 }} ₴</span>
              </td>
            </tr>
            <tr>
              <th>Кількість</th>
              <td>
                <div
                  class="position-relative text-validate d-inline-block"
                  :class="{
                    error: item.quantity < item.min_quantity,
                  }"
                >
                  <input
                    class="form-control"
                    type="text"
                    placeholder="Кількість"
                    v-model.number="item.quantity"
                    :class="{
                      'border-danger': item.quantity < item.min_quantity,
                    }"
                    @change="$emit('change', { quantity: $event.target.value })"
                  />
                  <div class="text-error">
                    Мінімальна кількість: {{ item.min_quantity }}
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <th>Сума</th>
              <td>
                <strong class="nowrap"
                  >{{ (item.price * item.quantity).toFixed(2) }} ₴</strong
                >
              </td>
            </tr>
            <tr>
              <th>Наявність</th>
              <td>
                <div class="btn-status consideration">
                  <span class="circle"></span><span>В наявності</span>
                </div>
              </td>
            </tr>
            <tr>
              <th></th>
              <td class="w-1 text-end">
                <button
                  @click="$emit('remove')"
                  class="btn btn-icon ico_trash"
                ></button>
              </td>
            </tr>
          </tbody>
        </table>
      </td>
    </tr>
  </fragment>
</template>

<script>
import { Fragment } from 'vue-frag'
import { MatchMedia } from 'vue-component-media-queries'

export default {
  name: 'FurnitureTableRow',
  components: { Fragment, MatchMedia },
  props: ['item', 'index'],
  data: () => ({
    isOpen: false,
  }),

  methods: {
    toggle() {
      this.isOpen = !this.isOpen
    },
  },
}
</script>

<style lang="scss"></style>
