<template>
  <MatchMedia v-slot="{ xxl }">
    <div>
      <div class="table-head --page-new-order">
        <div>
          <Submenu :orderId="orderId" />
        </div>
        <div class="filter-adaptive-position">
          <portal-target
            v-if="!xxl"
            name="select-product-filters"
          ></portal-target>
        </div>
        <div>
          <button class="btn btn-primary ms-0" type="button" @click="save">
            Зберегти
          </button>
        </div>
      </div>
      <div class="row">
        <div class="col-xxl-3">
          <portal-target
            v-if="xxl"
            name="select-product-filters"
          ></portal-target>
          <portal to="select-product-filters">
            <Dropdown class="filters" v-slot:default="{ toggle, isOpen }">
              <div class="filter-btn" :class="{ 'is-active': isOpen }">
                <button type="button" @click="toggle()">
                  <i class="ico_filter"></i> <span>Фільтр</span
                  ><i class="ico_chevron-down"></i>
                </button>
              </div>
              <!-- Filters -->
              <div class="filter-box" :class="{ 'is-show': isOpen }">
                <!-- Search field -->
                <div class="drop --search">
                  <span
                    class="drop-clear"
                    :class="{ _active: !!searchProduct }"
                    @click="searchOrClear()"
                  ></span
                  ><input
                    class="form-control drop-input"
                    type="text"
                    autocomplete="off"
                    v-model="searchProduct"
                    :placeholder="$i18n.t('product.search_product')"
                    ref="search"
                    @change="searchProducts()"
                  />
                </div>

                <!-- Additional filters -->
                <div class="accordion">
                  <div v-show="showAdditionalFilters">
                    <a
                      class="btn btn-primary d-block mb-2"
                      @click="resetFilter"
                    >
                      {{ $t('filter.reset') }}
                    </a>
                  </div>

                  <div class="accordion-item">
                    <h2 class="accordion-header">
                      <button
                        class="accordion-button"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#filter-item-1"
                        aria-expanded="true"
                      >
                        {{ $i18n.t('product.category') }}
                      </button>
                    </h2>
                    <div
                      class="accordion-collapse collapse show"
                      id="filter-item-1"
                      data-bs-parent="#filter"
                      style=""
                    >
                      <div class="accordion-body">
                        <ul class="filter-list">
                          <li
                            v-for="(category, index) in categories"
                            :key="index"
                          >
                            <label class="check"
                              ><input
                                class="check__input"
                                type="checkbox"
                                :id="'dialog-checategory-ckbox' + category.id"
                                :checked="category.checked"
                                @change="
                                  onCategoryChanged(
                                    category.id,
                                    $event.target.checked
                                  )
                                "
                              /><span class="check__box"></span
                              >{{ category.name }}</label
                            >
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>

                  <div v-show="showAdditionalFilters && filterOpen">
                    <div class="accordion-item">
                      <h2 class="accordion-header">
                        <button
                          class="accordion-button"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#filter-item-2"
                          aria-expanded="true"
                        >
                          {{ $i18n.t('product.price') }}
                        </button>
                      </h2>
                      <div
                        class="accordion-collapse collapse show"
                        id="filter-item-2"
                        data-bs-parent="#filter"
                        style=""
                      >
                        <div class="accordion-body">
                          <div class="row gx-2">
                            <div class="col-6">
                              <input
                                class="form-control"
                                type="number"
                                :placeholder="$t('filter.from')"
                                v-model.number="priceRange[0]"
                                @change="priceRangeChanged"
                              />
                            </div>
                            <div class="col-6">
                              <input
                                class="form-control"
                                type="number"
                                :placeholder="$t('filter.to')"
                                onkeyup="this.value = this.value.replace (/D/, '')"
                                v-model.number="priceRange[1]"
                                @change="priceRangeChanged"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      class="accordion-item"
                      v-for="(category, index) in attributes"
                      :key="index"
                    >
                      <h2 class="accordion-header">
                        <button
                          class="accordion-button"
                          type="button"
                          data-bs-toggle="collapse"
                          :data-bs-target="'#filter-item-' + (index + 30)"
                          aria-expanded="true"
                        >
                          {{ category.name }}
                        </button>
                      </h2>
                      <div
                        class="accordion-collapse collapse show"
                        :id="'filter-item-' + (index + 30)"
                        data-bs-parent="#filter"
                      >
                        <div class="accordion-body">
                          <ul class="filter-list">
                            <li
                              v-for="(attribute, index) in category.values"
                              :key="index"
                            >
                              <label class="check"
                                ><input
                                  class="check__input"
                                  type="checkbox"
                                  :id="
                                    'dialog-checategory-ckbox' +
                                      category.id +
                                      index
                                  "
                                  :checked="attribute.checked"
                                  @change="
                                    attributeChecked(
                                      category.id,
                                      attribute.slug,
                                      $event.target.checked
                                    )
                                  "
                                /><span class="check__box"></span
                                >{{ attribute.name }}</label
                              >
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Dropdown>
          </portal>
        </div>
        <div class="col-xxl-9">
          <div class="table-body">
            <table class="footable breakpoint">
              <thead>
                <tr class="footable-header">
                  <th>Матеріал</th>
                  <th data-breakpoints="xs" class="d-none d-md-table-cell">
                    Артикул
                  </th>
                  <th data-breakpoints="xs" class="d-none d-md-table-cell">
                    Розмір
                  </th>
                  <th data-breakpoints="xs" class="d-none d-md-table-cell">
                    Ціна
                  </th>
                  <th data-breakpoints="xs" class="d-none d-md-table-cell">
                    Наявність
                  </th>
                  <th
                    class="w-1 d-none d-md-table-cell"
                    data-breakpoints="xs"
                  ></th>
                </tr>
              </thead>
              <tbody>
                <SelectProductTableRow
                  :item="item"
                  :selectedItems="selectedItems"
                  v-for="(item, index) in products"
                  :key="index"
                />
              </tbody>
            </table>
          </div>
          <div class="table-footer">
            <div></div>
            <div>
              <Pagination
                :perPage="perPage"
                v-model="currPage"
                :length="totalPages"
                @perPageChanged="
                  perPage = $event
                  paginateList()
                "
                @input="paginateList"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </MatchMedia>
</template>

<script>
import api from '@/gateway/api'
import Submenu from '@/components/order/Submenu.vue'
import Pagination from '@/components/order/Pagination.vue'
import SelectProductTableRow from '@/components/order/materials/SelectProductTableRow.vue'
import { MatchMedia } from 'vue-component-media-queries'

export default {
  name: 'SelectProduct',
  components: { Submenu, Pagination, SelectProductTableRow, MatchMedia },
  props: {
    type: {
      type: String,
      default: 'plate',
    },
    orderId: [String, Number],
  },
  filters: {
    price(val) {
      return Number.isInteger(val) ? val : `${val.toFixed(2)}`
    },
  },
  data: () => ({
    products: [],
    categories: [],
    attributes: [],
    filterOpen: true,
    selectedCategories: [],
    selectedCategory: null,
    searchProduct: '',
    attrChecked: [],
    lengthCategories: [],
    showAdditionalFilters: false,
    fromPrice: 0,
    toPrice: 0,
    rangeMin: 0,
    rangeMax: 0,
    isRangeChanged: false,
    emptyProducts: false,
    priceRange: [],
    selectedCategoryAttributes: [],
    filteredSubCategories: [],
    currPage: 1,
    totalElements: 0,
    totalPages: 0,
    perPage: 10,
    selectedItems: [],
  }),
  computed: {
    order: {
      get: function() {
        return this.$store.state.order.order || {}
      },
      set: function(order) {
        this.$store.commit('order/updateOrder', order)
      },
    },
    locale: {
      get: function() {
        return this.$store.state.locale || 'uk'
      },
    },
  },
  methods: {
    async save() {
      const { selectedItems } = this
      if (!selectedItems.length) return

      const addItems = selectedItems.filter(
        ({ id }) =>
          !this.$store.getters['order/materials'].some(
            ({ product_id }) => id == product_id
          )
      )
      const removeItems = (this.$store.getters['order/materials'] || []).filter(
        ({ product_id }) => !selectedItems.some(({ id }) => id == product_id)
      )
      console.log(addItems, removeItems)
      await Promise.all(addItems.map(item => this.addProduct(item)))
      await Promise.all(removeItems.map(item => this.removeProduct(item)))

      this.$emit('close')
    },
    addProduct(item) {
      this.$root.$emit('playPreloader')
      return this.$store
        .dispatch('order/addOrderProduct', {
          orderId: this.order.id,
          product: item,
        })
        .then(() => {
          // this.$Progress.finish()
          this.$root.$emit('stopPreloader')
          this.$root.$emit('closeDialog')
        })
        .catch(error => {
          // todo : add text en, ru, uk style alert
          this.$toast.error(error.response.data.message)
          this.$root.$emit('stopPreloader')
        })
    },
    removeProduct(item) {
      this.$root.$emit('playPreloader')
      return this.$store
        .dispatch('order/removeOrderProduct', {
          orderId: this.order.id,
          product: item,
        })
        .then(() => {
          // this.$Progress.finish()
          this.$root.$emit('stopPreloader')
          this.$root.$emit('closeDialog')
        })
        .catch(error => {
          // todo : add text en, ru, uk style alert
          this.$toast.error(error.response.data.message)
          this.$root.$emit('stopPreloader')
        })
    },
    getData(
      page = 1,
      limit = this.perPage,
      searchProduct = this.searchProduct,
      attrChecked = this.attrChecked,
      attrCategoryId = this.selectedCategories
    ) {
      return new Promise((resolve, reject) => {
        api
          .get('productFilter', {
            params: {
              type: this.type,
              category: this.selectedCategories.join(','),
              page: page,
              limit: limit,
              search: searchProduct,
              attrChecked: attrChecked,
              lengthCategories: this.lengthCategories.length,
              attrCategoryId: attrCategoryId,
              minRange: Math.floor(this.rangeMin),
              maxRange: Math.ceil(this.rangeMax),
              minPrice: Math.floor(this.priceRange[0]),
              maxPrice: Math.ceil(this.priceRange[1]),
              isRangeChanged: this.isRangeChanged,
            },
          })
          .then(result => {
            // console.log('work', result)
            this.products = result.data.products
            this.categories = result.data.categories
            this.attributes = result.data.attributes
            this.totalElements = result.data.total
            this.totalPages = result.data.pages
            this.fromPrice = Math.floor(result.data.min_price)
            this.toPrice = Math.ceil(result.data.max_price)
            this.rangeMin = Math.floor(result.data.min_range)
            this.rangeMax = Math.ceil(result.data.max_range)
            this.priceRange = [this.fromPrice, this.toPrice]
            if (
              this.fromPrice === this.rangeMin &&
              this.toPrice === this.rangeMax
            )
              this.isRangeChanged = false
            resolve(result)
          })
          .catch(error => {
            // debugger
            this.$toast.error(error.response.data.message)
            reject(error)
          })
      })
    },
    onCategoryChanged(categoryId, checked, oneItem = false) {
      // debugger
      if (checked) {
        if (oneItem) {
          // debugger
          this.selectedCategories = [categoryId]
        } else {
          this.lengthCategories.push(categoryId)
          this.selectedCategories.push(categoryId)
        }
      } else {
        // debugger
        const index = this.selectedCategories.indexOf(categoryId)

        this.lengthCategories.splice([0], 1)
        this.selectedCategories.splice(index, 1)
        this.lengthCategories.splice(index, 1)
        // }
      }
      this.getData()
    },
    attributeChecked(attributeId, attributeVal, checked) {
      let attrCategoryId =
        this.type == 'furniture'
          ? [this.categories[0].id]
          : this.selectedCategories
      if (checked) {
        this.attrChecked.push({
          attributeId: attributeId,
          attributeVal: attributeVal,
        })

        let attrChecked = this.attrChecked

        // this.getData( 1,10, null, attrChecked, attrCategoryId);
        this.getData()
      } else {
        const item = this.attrChecked.find(
          item =>
            item.attributeId === attributeId &&
            item.attributeVal === attributeVal
        )
        let keyIndex = this.attrChecked.indexOf(item)
        if (keyIndex !== -1) {
          this.attrChecked.splice(keyIndex, 1)
          let attrChecked = this.attrChecked

          // this.getData(1,10, null,  attrChecked, attrCategoryId);
          this.getData()
        }
      }
    },
    searchProducts() {
      let searchProduct = this.searchProduct

      this.getData(1, this.perPage, searchProduct)
    },
    searchOrClear() {
      if (this.searchProduct.length > 0) this.searchProduct = ''
      this.getData()
    },
    hideShowSubCategory(attribute) {
      const index = this.filteredSubCategories.indexOf(attribute.attribute_id)
      if (index !== -1) {
        this.filteredSubCategories.splice(index, 1)
      } else {
        this.filteredSubCategories.push(attribute.attribute_id)
      }
    },
    paginateList() {
      this.getData(
        this.currPage,
        this.perPage,
        this.searchProduct,
        this.attrChecked,
        this.categories[0].id
      )
    },
    resetFilter() {
      this.searchProduct = ''
      this.attrChecked = []
      this.selectedCategories = []
      this.filteredSubCategories = []
      this.selectedCategoryAttributes = []
      this.attributes = []
      this.minPrice = 0
      this.maxPrice = 0
      this.minRange = 0
      this.maxRange = 0
      this.priceRange = []
      this.isRangeChanged = false
      this.getData()
    },
    priceRangeChanged() {
      this.isRangeChanged = true
      this.getData(1, this.perPage)
    },
  },
  watch: {
    selectedCategory: {
      deep: true,
      handler(selectedCategory) {
        this.onCategoryChanged(selectedCategory.id, true, true)
      },
    },
    products(val) {
      this.emptyProducts = val.length === 0
    },
    categories(val) {
      if (!val || !val.length) return
      if (val.length === 1) {
        this.selectedCategories.push(val[0].id)
        this.onCategoryChanged(val[0].id, true, true)
      }
      if (typeof val.find(category => !category.parent_id) === 'undefined') {
        this.currPage = 1
        this.attrChecked = []
        this.filteredSubCategories = []
        this.selectedCategories = []
        this.selectedCategoryAttributes = []
        this.attributes = []
        this.getData()
        // return;
      }
    },
    selectedCategories(val) {
      this.showAdditionalFilters = val.length > 1
      if (val.length <= 1) {
        this.currPage = 1
        this.attrChecked = []
        this.filteredSubCategories = []
        this.selectedCategoryAttributes = []
        this.attributes = []
      }
    },
    priceRange(val) {
      if (Number(val[0]) < this.minPrice) {
        this.$nextTick(() => {
          this.priceRange[0] = this.minPrice
        })
      }
      if (Number(val[1]) > this.maxPrice && this.maxPrice !== 0) {
        console.log(`${val[1]}, ${this.maxPrice}`)
        this.$nextTick(() => {
          this.priceRange[1] = this.maxPrice
        })
      }
    },
  },
  created() {
    this.getData()
    this.$store
      .dispatch('order/getOrder', {
        id: this.orderId,
      })
      .then(() =>
        this.$store.dispatch('order/getOrderProducts', {
          orderId: this.order.id,
        })
      )
      .then(() => {
        this.selectedItems = this.$store.getters['order/materials'].map(o => ({
          ...o,
          id: o.product_id,
        }))
      })
  },
}
</script>

<style lang="scss" scoped></style>
