<template>
  <form action="#!" @submit.prevent="">
    <template v-for="(field, index) in fields">
      <template v-if="field.type == 'number'">
        <div
          :key="index"
          class="constructor-form-group"
          v-if="isVisible(field)"
        >
          <label class="constructor-text-field"
            ><span>{{ $t(field.text) }}</span
            ><input
              type="number"
              placeholder="0"
              :class="{ 'is-invalid': isInvalid(field) }"
              onclick="this.select();"
              v-bind="field.attrs"
              v-model.number="item[field.value]"
              @change="handleInput"
          /></label>
        </div>
      </template>
      <template v-else-if="field.value">
        <component
          v-if="isVisible(field)"
          :key="index"
          :is="field.type + '-input'"
          :label="$t(field.text)"
          :options="getOptions(field)"
          :item-text="field.itemText"
          :item-value="field.itemValue"
          v-model="item[field.value]"
          :error="isInvalid(field)"
          :is-wide="field.value == 'kromka'"
          @input="handleInput"
        />
      </template>
      <template v-else>
        <component
          v-if="isVisible(field)"
          :key="index"
          :is="field.type[0].toUpperCase() + field.type.slice(1) + 'Input'"
          :label="$t(field.text)"
          v-model="item"
          :errors="errors"
          @input="handleInput"
        />
      </template>
    </template>
  </form>
</template>

<script>
import { debounce } from 'throttle-debounce'
import { templateFields } from '@/components/dateylor/construction/fields'
import FaceInput from '@/components/dateylor/construction/inputs/FaceInput'
import SideInput from '@/components/dateylor/construction/inputs/SideInput'
import CornerInput from '@/components/dateylor/construction/inputs/CornerInput'
import CheckInput from '@/components/dateylor/construction/inputs/CheckInput'
import SelectInput from '@/components/dateylor/construction/inputs/SelectInput'
import BtnToggleInput from '@/components/dateylor/construction/inputs/BtnToggleInput'
import Hinge35Input from '@/components/dateylor/construction/inputs/Hinge35Input'
import GrooveEdgeInput from '@/components/dateylor/construction/inputs/GrooveEdgeInput'

export default {
  name: 'Form',
  components: {
    FaceInput,
    SideInput,
    CornerInput,
    CheckInput,
    SelectInput,
    BtnToggleInput,
    Hinge35Input,
    GrooveEdgeInput,
  },
  props: { item: Object, errors: Object },
  data() {
    return {
      face: {
        id: '1',
        name: 'Tыл',
      },
      side: {
        top: false,
        bottom: false,
        right: false,
        left: true,
      },
      corner: {
        top_left: true,
        top_right: false,
        bottom_left: false,
        bottom_right: false,
      },
      checked: false,
      selectedOption: 20,
    }
  },
  computed: {
    kromkas() {
      return this.$store.getters['order/kromkas']
    },
    areaTools() {
      const tools = this.$store.getters['order/areaTools']
      return tools && tools.map(t => ({ ...t, name: `Свер.` + t.diameter }))
    },
    edgeTools() {
      const tools = this.$store.getters['order/edgeTools']
      return tools && tools.map(t => ({ ...t, name: `Свер.` + t.diameter }))
    },
    fields() {
      return templateFields[this.item.template]
    },
  },
  methods: {
    getOptions({ options }) {
      if (!options) return
      return Array.isArray(options) ? options : this[options]
    },
    isVisible(field) {
      if (!field.visible) return true
      if (typeof field.visible == 'function') {
        return field.visible(this.item)
      }
    },
    isInvalid(field) {
      return this.errors && !!this.errors[field.value]
    },
    // https://www.npmjs.com/package/throttle-debounce
    handleInput: debounce(300, function() {
      this.$emit('update', this.item)
    }),
  },
}
</script>

<style scoped lang="scss"></style>
