<template>
  <div class="submenu-box">
    <div class="submenu-wrap">
      <ul class="submenu drop-menu" :class="{ open: isOpen }">
        <li @click="toggle">
          <router-link
            class=""
            exact-active-class="active"
            :to="{ name: 'OrderMaterials', params: { orderId } }"
            >Обрати товар</router-link
          >
        </li>
        <li v-if="materialId">
          <router-link
            class=""
            exact-active-class="active"
            :to="{ name: 'OrderDetails', params: { orderId, materialId } }"
            >Список деталей</router-link
          >
        </li>
        <li>
          <router-link
            class=""
            exact-active-class="active"
            :to="{ name: 'OrderFurniture', params: { orderId } }"
            v-if="order && order.is_cut_map"
            ><span>Список фурнітури</span></router-link
          >
          <a
            v-else
            href="#"
            @click.prevent=""
            data-bs-toggle="tooltip"
            v-tooltip
            data-bs-trigger="hover"
            data-bs-original-title="Сформуйте карти крою"
            ><span>Список фурнітури</span></a
          >
        </li>
        <li v-if="materialId">
          <router-link
            class=""
            exact-active-class="active"
            :to="{ name: 'OrderInCutMap', params: { orderId, materialId } }"
            ><span>Карти крою</span></router-link
          >
        </li>
        <li>
          <router-link
            class=""
            exact-active-class="active"
            :to="{ name: 'OrderGeneral', params: { orderId } }"
            ><span>Загальні дані по замовленню</span></router-link
          >
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Submenu',
  components: {},
  props: ['orderId', 'materialId'],
  data: () => ({
    isOpen: false,
  }),
  computed: {
    order() {
      return this.$store.state.order.order
    },
  },
  methods: {
    toggle() {
      if (window.innerWidth < 1360) {
        this.isOpen = !this.isOpen
      }
    },
  },
}
</script>

<style lang="scss"></style>
