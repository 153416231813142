<template>
  <SelectFurniture
    :orderId="Number($route.params.orderId)"
    :template="$route.params.template"
    @close="
      $router.push({
        name: 'OrderFurniture',
        params: { orderId: $route.params.orderId },
      })
    "
  />
</template>

<script>
import SelectFurniture from '@/components/order/SelectFurniture.vue'

export default {
  name: 'OrderAddFurniture',
  components: {
    SelectFurniture,
  },
  data: () => ({}),
  computed: {},
  created() {},
}
</script>

<style lang="scss"></style>
