<template>
  <div>
    <!-- Submenu -->
    <div class="table-head --page-new-order">
      <div>
        <Submenu
          :orderId="orderId"
          :materialId="orderMaterials[0] && orderMaterials[0].id"
        />
      </div>
      <div>
        <button class="btn btn-primary" type="button">Зберегти</button>
        <button class="btn btn-icon ico_trash" type="button"></button>
      </div>
    </div>
    <div
      class="row justify-content-center align-items-center py-3"
      v-if="isLoading"
    >
      Завантаження...
    </div>
    <div v-else class="table-body">
      <!-- Table head -->
      <div class="accordion-furniture-head">
        <div class="table-row">
          <div class="table-cell"><span>Назва послуги</span></div>
          <div class="table-cell"><span>Мінімальна кількість</span></div>
          <div class="table-cell"></div>
        </div>
      </div>

      <div class="accordion accordion-furniture">
        <Dropdown
          class="accordion-item"
          v-slot:default="{ toggle, isOpen }"
          v-for="(operation, operationIndex) in operations"
          :key="operationIndex"
          :closeOnClickOutside="false"
        >
          <!-- Group -->
          <div
            class="accordion-header"
            :class="{
              'is-show': isOpen,
            }"
          >
            <div class="table-row">
              <div class="table-cell">
                <label class="check"
                  ><input
                    class="check__input"
                    type="checkbox"
                    tabindex="-1"/><span class="check__box"></span></label
                ><span>{{ operation.title }}</span>
              </div>
              <div class="table-cell">
                <span v-if="operation.template">{{
                  Math.ceil(operation.total)
                }}</span>
              </div>
              <div class="table-cell">
                <button
                  v-if="operation.items.length"
                  class="btn btn-icon ico_arrow-down"
                  :class="isOpen ? 'collapsed' : 'is-active'"
                  :aria-expanded="String(!!isOpen)"
                  type="button"
                  @click.stop="toggle()"
                ></button
                ><router-link
                  tag="button"
                  :to="{
                    name: 'OrderAddFurniture',
                    params: { orderId, template: operation.template },
                  }"
                  class="btn btn-icon ico_plus"
                  type="button"
                ></router-link>
              </div>
            </div>
          </div>

          <!-- Furniture rows -->
          <div
            class="accordion-collapse collapse"
            :class="{
              show: isOpen,
            }"
          >
            <div class="accordion-body">
              <table class="footable breakpoint">
                <thead>
                  <tr class="footable-header">
                    <th>Назва</th>
                    <th data-breakpoints="xs" class="d-none d-md-table-cell">
                      Артикул
                    </th>
                    <th data-breakpoints="xs" class="d-none d-md-table-cell">
                      Ціна
                    </th>
                    <th data-breakpoints="xs" class="d-none d-md-table-cell">
                      Кількість
                    </th>
                    <th data-breakpoints="xs" class="d-none d-md-table-cell">
                      Сума
                    </th>
                    <th data-breakpoints="xs" class="d-none d-md-table-cell">
                      Наявність
                    </th>
                    <th
                      data-breakpoints="xs"
                      class="d-none d-md-table-cell"
                    ></th>
                  </tr>
                </thead>
                <tbody>
                  <FurnitureTableRow
                    :item="item"
                    v-for="(item, index) in operation.items"
                    :key="index"
                    @change="
                      $store.dispatch('order/changeOrderProduct', {
                        orderId,
                        productId: item.id,
                        data: $event,
                      })
                    "
                    @remove="deleteFurniture(item)"
                  />
                </tbody>
              </table>
            </div>
          </div>
        </Dropdown>
      </div>
    </div>
    <!-- End -->
  </div>
</template>

<script>
import Submenu from '@/components/order/Submenu.vue'
import FurnitureTableRow from '@/components/order/furniture/FurnitureTableRow.vue'

export default {
  name: 'Furniture',
  props: ['orderId'],
  components: {
    Submenu,
    FurnitureTableRow,
  },

  data: () => ({
    isLoading: false,
  }),
  computed: {
    order() {
      return this.$store.state.order.order || {}
    },
    furnitureOperations() {
      return this.$store.state.order.furnitureOperations
    },
    furniture() {
      return this.$store.getters['order/furniture']
    },
    orderMaterials() {
      return this.$store.getters['order/materials']
    },
    operations() {
      const { furnitureOperations, furniture } = this
      return [
        ...(furnitureOperations || []),
        {
          title: 'Додатково',
        },
      ].map(operation => ({
        ...operation,
        items: (furniture || []).filter(({ template }) =>
          operation.template ? operation.template == template : !template
        ),
      }))
    },
  },

  methods: {
    deleteFurniture(item) {
      const acceptAction = () => {
        // this.$Progress.start();
        this.$root.$emit('playPreloader')
        this.$store
          .dispatch('order/removeOrderProduct', {
            orderId: this.order.id,
            product: item,
          })
          .then(() =>
            this.$store.dispatch('order/getOrderProducts', {
              orderId: this.order.id,
            })
          )
          .then(() => {
            // this.$Progress.finish();
            this.$root.$emit('stopPreloader')
          })
          .catch(() => {
            // this.$Progress.fail();
            this.$root.$emit('stopPreloader')
          })
      }
      this.$root.$emit('openDialog', 'AskDialog', {
        acceptAction,
        title: this.$i18n.t('order.remove_material_query'),
        acceptText: this.$i18n.t('yes'),
        denyText: this.$i18n.t('no'),
      })
    },
  },

  async mounted() {
    // this.$Progress.start()
    this.$root.$emit('playPreloader')
    this.isLoading = true
    try {
      if (!this.order || this.order.id != this.orderId) {
        await this.$store.dispatch('order/getOrder', {
          id: this.orderId,
        })
      }
      await this.$store.dispatch('order/getOrderProducts', {
        orderId: this.orderId,
      })
      await this.$store.dispatch('order/getFurnitureOperations', this.orderId)
    } catch (e) {
      // do nothing
    }
    this.isLoading = false
    this.$root.$emit('stopPreloader')
  },
}
</script>

<style lang="scss">
table.footable > tbody > tr > td,
table.footable > tbody > tr > th {
  display: table-cell;
}
</style>
