<template>
  <svg
    v-if="p"
    class="w-100"
    :viewBox="`0 0 ${p.$.l} ${p.$.w}`"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <defs>
      <pattern
        id="diagonalHatch"
        patternUnits="userSpaceOnUse"
        :width="strokeWidth"
        :height="strokeWidth * 2"
        patternTransform="rotate(-45 2 2)"
      >
        <path
          d="M -1,2 l 6,0"
          :stroke="strokeColor"
          :stroke-width="strokeWidth / 4"
        />
      </pattern>
    </defs>
    <rect
      x="0"
      y="0"
      width="100%"
      height="100%"
      :stroke="strokeColor"
      :stroke-width="strokeWidth * 2"
    ></rect>
    <rect
      v-for="(item, index) in p.d"
      :key="'d' + index"
      :x="item.$.x"
      :y="item.$.y"
      :width="item.$.l"
      :height="item.$.w"
      :stroke="strokeColor"
      :stroke-width="strokeWidth"
    ></rect>
    <text
      v-for="(item, index) in p.d"
      :key="'d-id-' + index"
      :x="item.$.x"
      :y="item.$.y"
      :transform="`translate(${item.$.l / 2}, ${item.$.w / 2})`"
      :font-size="fontSize"
      class="detail-id"
      dominant-baseline="middle"
      text-anchor="middle"
    >
      {{ getDetailPositionById(item.$.id) }}
    </text>
    <text
      v-for="(item, index) in p.d"
      :key="'d-l-' + index"
      :x="item.$.x"
      :y="Number(item.$.y) + Number(item.$.w)"
      :transform="`translate(${offset}, -${offset})`"
      :font-size="fontSizeSm"
      class="detail-length"
      dominant-baseline="text-bottom"
      text-anchor="start"
    >
      {{ item.$.l }}
    </text>
    <text
      v-for="(item, index) in p.d"
      :key="'d-w-' + index"
      :x="Number(item.$.x) + Number(item.$.l)"
      :y="Number(item.$.y)"
      :transform="`translate(-${offset}, ${offset})`"
      :font-size="fontSizeSm"
      class="detail-width"
      dominant-baseline="hanging"
      text-anchor="start"
    >
      {{ item.$.w }}
    </text>
    <rect
      v-for="(item, index) in p.r"
      :key="'r' + index"
      :x="item.$.x"
      :y="item.$.y"
      :width="item.$.l"
      :height="item.$.w"
      :stroke="strokeColor"
      :stroke-width="strokeWidth"
      fill="url(#diagonalHatch)"
    ></rect>
    <text
      v-for="(item, index) in p.r"
      :key="'r-id-' + index"
      :x="item.$.x"
      :y="item.$.y"
      :transform="`translate(${item.$.l / 2}, ${item.$.w / 2})`"
      :font-size="fontSize"
      class="detail-id"
      dominant-baseline="middle"
      text-anchor="middle"
    >
      O{{ item.$.id }}
    </text>
    <text
      v-for="(item, index) in p.r"
      :key="'r-l-' + index"
      :x="item.$.x"
      :y="Number(item.$.y) + Number(item.$.w)"
      :transform="`translate(${offset}, -${offset})`"
      :font-size="fontSizeSm"
      class="detail-length"
      dominant-baseline="text-bottom"
      text-anchor="start"
    >
      {{ item.$.l }}
    </text>
    <text
      v-for="(item, index) in p.r"
      :key="'r-w-' + index"
      :x="Number(item.$.x) + Number(item.$.l)"
      :y="Number(item.$.y)"
      :transform="`translate(-${offset}, ${offset})`"
      :font-size="fontSizeSm"
      class="detail-width"
      dominant-baseline="hanging"
      text-anchor="start"
    >
      {{ item.$.w }}
    </text>
  </svg>
</template>

<script>
export default {
  name: 'MapImage',
  components: {},
  props: {
    p: Object,
    strokeColor: {
      type: String,
      default: '#5B6485',
    },
  },
  data: () => ({}),
  computed: {
    strokeWidth() {
      const { l, w } = this.p.$
      const size = Math.max(l, w)
      return Math.round((2 * size) / 800)
    },
    offset() {
      return this.strokeWidth * 3
    },
    fontSize() {
      return this.strokeWidth * 10
    },
    fontSizeSm() {
      return this.fontSize / 2
    },
    cutMapsDetails() {
      const { materialCutMap } = this.$store.state.order
      return (
        (materialCutMap &&
          materialCutMap.data &&
          materialCutMap.data.details) ||
        []
      )
    },
  },
  methods: {
    getDetailPositionById(id) {
      const detail = this.cutMapsDetails.find(({ gl_id }) => gl_id == id)
      return detail && detail.position
    },
  },
}
</script>

<style lang="scss">
$color: #5b6485;
.detail-id {
  fill: $color;
}
.detail-length {
  fill: $color;
}
.detail-width {
  fill: $color;
  writing-mode: tb;
}
</style>
