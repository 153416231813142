import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate';
import i18n, { selectedLocale } from '@/plugins/i18n'
import api from '@/gateway/api'

import authStore from '@/store/auth.store'
import userStore from '@/store/user.store'
import orderStore from '@/store/order.store'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    auth: authStore,
    user: userStore,
    order: orderStore
  },
  state: {
    locale: selectedLocale,
    activeTab: 1,
    activeTabButton: 2,
    fromUrl: null
  },
  getters: {
    getLocale: state => state.locale || null,
  },
  mutations: {
    setLocale (state, newLocale) {
      i18n.locale = newLocale
      document.documentElement.lang = newLocale
      state.locale = newLocale
    },
    setActiveTab (state, activeTab) {
      state.activeTab = activeTab
    },
    setActiveTabButton (state, activeTabButton) {
      state.activeTabButton = activeTabButton
    },
    setFromUrl (state, fromUrl) {
      state.fromUrl = fromUrl
    },
  },
  actions: {
    getLocale ({ commit }) {
      return new Promise((resolve, reject) => {
        api.get('locale').then(result => {
          commit('setLocale', result.data.locale)
          resolve(result.data.locale)
        }).catch(error => {
          reject(error)
        })
      })
    },
    setLocale ({ commit }, locale) {
      return new Promise((resolve, reject) => {
        api.post('locale/' + locale).then(() => {
          commit('setLocale', locale)
          resolve(locale)
        }).catch(error => {
          reject(error)
        })
      })
    }
  },
  plugins: [
    createPersistedState({
      paths: [
        'locale',
        'activeTab', 'activeTabButton',
        'fromUrl',
        'auth.token', 'auth.user',
      ]
    })
  ]
})
