import { Group, Path, Raster, Point } from 'paper'
import {
  STROKE_WIDTH1,
  STROKE_WIDTH2,
  STYLES,
  DASH_ARRAY,
  DASH_ARRAY2,
  COLORS,
} from '../variables'

export function renderTextureDirection({
  operation,
  side,
  sides,
  property,
  EDGE_HEIGHT,
  EDGE_OFFSET,
  drawings,
  backgroundURL,
  size,
}) {
  const [sizeX, sizeY] = size,
    scale = Math.max(1, (0.04 * Math.min(sizeX, sizeY)) / EDGE_HEIGHT),
    height = scale * EDGE_HEIGHT,
    width = 2 * height,
    center = new Point(sizeX / 2, sizeY / 2)

  if (backgroundURL) {
    const backgroundClip = new Path.Rectangle(
        [STROKE_WIDTH2, STROKE_WIDTH2],
        [sizeX - STROKE_WIDTH2, sizeY - STROKE_WIDTH2]
      ),
      background = new Raster({
        source: backgroundURL,
        position: center,
        size,
      })
    const backgroundGroup = new Group({
      children: [backgroundClip, background],
      clipped: true,
      data: { is: 'background' },
    })
    side.addChild(backgroundGroup)
  }
  const line = new Path.Line({
      from: [-width / 2, 0],
      to: [width / 2, 0],
    }),
    line1 = line.clone(),
    line2 = line.clone()
  line1.translate([0, -height])
  line2.translate([0, height])

  const group = new Group({
    children: [line, line1, line2],
    strokeWidth: STROKE_WIDTH2,
    strokeColor: STYLES.pathColor,
    data: { is: 'texture_direction' },
    visible: !backgroundURL,
  })
  group.translate([sizeX / 2, sizeY / 2])
  // group.selected = true
  side.addChild(group)
}
