export const templateFields = {
  groove_end: [
    {
      text: 'fields.side',
      value: 'place_second',
      type: 'side',
    },
    {
      text: 'fields.deep',
      value: 'deep',
      type: 'number',
    },
    {
      text: 'fields.width',
      value: 'width',
      type: 'number',
    },
    {
      text: 'fields.groove.saw',
      value: 'width_saw',
      type: 'number',
    },
    {
      text: 'fields.edge_hole.mid_by_z_center',
      value: 'mid_by_z_center',
      type: 'check',
    },
    {
      text: 'fields.edge_hole.z_offset',
      value: 'z_offset',
      type: 'number',
      visible: ({ mid_by_z_center }) => !mid_by_z_center,
    },
  ],
  groove: [
    {
      text: '',
      value: 'face',
      type: 'face',
    },
    {
      text: 'fields.side',
      value: 'place_second',
      type: 'side',
    },
    {
      text: 'fields.groove.margin',
      value: 'margin',
      type: 'number',
    },
    {
      text: 'fields.deep',
      value: 'deep',
      type: 'number',
    },
    {
      text: 'fields.width',
      value: 'width',
      type: 'number',
    },
    {
      text: 'fields.groove.saw',
      value: 'width_saw',
      type: 'number',
    },
    {
      text: 'fields.groove.is_limited',
      value: 'is_limited',
      type: 'check',
    },
    {
      text: 'fields.groove.groove_length',
      value: 'groove_length',
      type: 'number',
      visible: ({ is_limited }) => is_limited,
    },
    {
      text: 'fields.groove.start_offset',
      value: 'start_offset',
      type: 'number',
      visible: ({ is_limited }) => is_limited,
    },
    {
      text: 'fields.groove.is_opposite_start',
      value: 'is_opposite_start',
      type: 'check',
      visible: ({ is_limited }) => is_limited,
    },
  ],
  groove_edge: [
    {
      text: 'Тыл/Лицо',
      value: 'face',
      type: 'face',
    },
    {
      text: '',
      value: '',
      type: 'groove-edge',
    },
  ],
  groove_side: [
    {
      text: '',
      value: 'face',
      type: 'face',
    },
    {
      text: 'fields.side',
      value: 'place_second',
      type: 'side',
    },
    {
      text: 'fields.deep',
      value: 'deep',
      type: 'number',
    },
    {
      text: 'fields.width',
      value: 'width',
      type: 'number',
    },
    {
      text: 'fields.groove.saw',
      value: 'width_saw',
      type: 'number',
    },
    {
      text: 'fields.groove.is_limited',
      value: 'is_limited',
      type: 'check',
    },
    {
      text: 'fields.groove_side.groove_length',
      value: 'groove_length',
      type: 'number',
      visible: ({ is_limited }) => is_limited,
    },
    {
      text: 'fields.groove.start_offset',
      value: 'start_offset',
      type: 'number',
      visible: ({ is_limited }) => is_limited,
    },
    {
      text: 'fields.groove.is_opposite_start',
      value: 'is_opposite_start',
      type: 'check',
      visible: ({ is_limited }) => is_limited,
    },
  ],
  arc_side_inner: [
    {
      text: 'fields.side',
      value: 'place_second',
      type: 'side',
    },
    {
      text: 'fields.radius_depth',
      value: 'arc_height',
      type: 'number',
    },
    {
      text: 'fields.rounding',
      value: 'round',
      type: 'check',
    },
    {
      text: 'fields.carve_gcnc.radius1',
      value: 'rad_left',
      type: 'number',
      visible: ({ round }) => round,
    },
    {
      text: 'fields.carve_gcnc.radius2',
      value: 'rad_right',
      type: 'number',
      visible: ({ round }) => round,
    },
    {
      text: 'fields.close_kromka',
      value: 'close_kromka',
      type: 'check',
    },
    {
      text: 'fields.select_kromka',
      value: 'kromka',
      type: 'select',
      options: 'kromkas',
      itemText: 'name',
      itemValue: 'id',
    },
  ],
  radius_inner: [
    {
      text: 'fields.corner',
      value: 'rad_outside',
      type: 'corner',
    },
    {
      text: 'fields.radius',
      value: 'rad_simple',
      type: 'number',
    },
    {
      text: 'fields.close_kromka',
      value: 'close_kromka',
      type: 'check',
    },
    {
      text: 'fields.select_kromka',
      value: 'kromka',
      type: 'select',
      options: 'kromkas',
      itemText: 'name',
      itemValue: 'id',
    },
  ],
  radius_outer: [
    {
      text: 'fields.corner',
      value: 'rad_inside',
      type: 'corner',
    },
    {
      text: 'fields.radius',
      value: 'rad_simple',
      type: 'number',
    },
    {
      text: 'fields.rounding',
      value: 'round',
      type: 'check',
    },
    {
      text: 'fields.carve_gcnc.radius1',
      value: 'rad_round',
      type: 'number',
      visible: ({ round }) => round,
    },
    {
      text: 'fields.carve_gcnc.radius2',
      value: 'rad_round1',
      type: 'number',
      visible: ({ round }) => round,
    },
    {
      text: 'fields.close_kromka',
      value: 'close_kromka',
      type: 'check',
    },
    {
      text: 'fields.select_kromka',
      value: 'kromka',
      type: 'select',
      options: 'kromkas',
      itemText: 'name',
      itemValue: 'id',
    },
  ],
  arc_corner: [
    {
      text: 'fields.corner',
      value: 'angle',
      type: 'corner',
    },
    {
      text: 'fields.byrest',
      value: 'byrest',
      type: 'check',
    },
    {
      text: 'fields.height',
      value: 'height',
      type: 'number',
    },
    {
      text: 'fields.width',
      value: 'width',
      type: 'number',
    },
    {
      text: 'fields.radius',
      value: 'rad',
      type: 'number',
    },
    {
      text: 'fields.close_kromka',
      value: 'close_kromka',
      type: 'check',
    },
    {
      text: 'fields.select_kromka',
      value: 'kromka',
      type: 'select',
      options: 'kromkas',
      itemText: 'name',
      itemValue: 'id',
    },
  ],
  arc_side: [
    {
      text: 'fields.side',
      value: 'place_second',
      type: 'side',
    },
    {
      text: 'fields.byrest',
      value: 'byrest',
      type: 'check',
    },
    {
      text: 'fields.remaining_size',
      value: 'remaining_size_1',
      type: 'number',
    },
    {
      text: 'fields.rounding',
      value: 'round',
      type: 'check',
    },
    {
      text: 'fields.carve_gcnc.radius1',
      value: 'rad_round',
      type: 'number',
      visible: ({ round }) => round,
    },
    {
      text: 'fields.carve_gcnc.radius2',
      value: 'rad_round1',
      type: 'number',
      visible: ({ round }) => round,
    },
    {
      text: 'fields.close_kromka',
      value: 'close_kromka',
      type: 'check',
    },
    {
      text: 'fields.select_kromka',
      value: 'kromka',
      type: 'select',
      options: 'kromkas',
      itemText: 'name',
      itemValue: 'id',
    },
  ],
  line_corner: [
    {
      text: 'fields.corner',
      value: 'angle',
      type: 'corner',
    },
    {
      text: 'fields.byrest',
      value: 'byrest',
      type: 'check',
    },
    {
      text: 'fields.height',
      value: 'height',
      type: 'number',
    },
    {
      text: 'fields.width',
      value: 'width',
      type: 'number',
    },
    {
      text: 'fields.rounding',
      value: 'round',
      type: 'check',
    },
    {
      text: 'fields.carve_gcnc.radius1',
      value: 'rad_round',
      type: 'number',
      visible: ({ round }) => round,
    },
    {
      text: 'fields.carve_gcnc.radius2',
      value: 'rad_round1',
      type: 'number',
      visible: ({ round }) => round,
    },
    {
      text: 'fields.close_kromka',
      value: 'close_kromka',
      type: 'check',
    },
    {
      text: 'fields.select_kromka',
      value: 'kromka',
      type: 'select',
      options: 'kromkas',
      itemText: 'name',
      itemValue: 'id',
    },
  ],
  carve_p: [
    // carve_p  Прямоугольный (П-образный) вырез
    {
      text: 'fields.side',
      value: 'place_second',
      type: 'side',
    },
    {
      text: 'fields.carve_p.cutout_size_width',
      value: 'cutout_size_width',
      type: 'number',
    },
    {
      text: 'fields.carve_p.cutout_size_deep',
      value: 'cutout_size_deep',
      type: 'number',
    },
    {
      text: 'fields.by_side_center',
      value: 'by_side_center',
      type: 'check',
    },
    {
      text: 'fields.corner_offset',
      value: 'corner_offset',
      type: 'check',
    },
    {
      text: 'fields.cutout_center_side',
      value: 'cutout_center_side',
      type: 'number',
    },
    {
      text: 'fields.square_in.rad',
      value: 'rad',
      type: 'number',
    },
    {
      text: 'fields.close_kromka',
      value: 'close_kromka',
      type: 'check',
    },
    {
      text: 'fields.select_kromka',
      value: 'kromka',
      type: 'select',
      options: 'kromkas',
      itemText: 'name',
      itemValue: 'id',
    },
  ],
  carve_g: [
    // carve_g  Г- образный вырез на форматно-раскроечном станке
    {
      text: '',
      value: 'face',
      type: 'face',
    },
    {
      text: 'fields.corner',
      value: 'angle',
      type: 'corner',
    },
    {
      text: 'fields.byrest',
      value: 'byrest',
      type: 'check',
    },
    {
      text: 'fields.height',
      value: 'height',
      type: 'number',
    },
    {
      text: 'fields.width',
      value: 'width',
      type: 'number',
    },
    {
      text: 'fields.close_kromka',
      value: 'close_kromka',
      type: 'check',
    },
    {
      text: 'fields.select_kromka',
      value: 'kromka',
      type: 'select',
      options: 'kromkas',
      itemText: 'name',
      itemValue: 'id',
    },
  ],
  carve_gcnc: [
    // carve_gcnc Г- образный вырез на ЧПУ.
    {
      text: 'fields.corner',
      value: 'angle',
      type: 'corner',
    },
    {
      text: 'fields.byrest',
      value: 'byrest',
      type: 'check',
    },
    {
      text: 'fields.height',
      value: 'height',
      type: 'number',
    },
    {
      text: 'fields.width',
      value: 'width',
      type: 'number',
    },
    {
      text: 'fields.carve_gcnc.radius1',
      value: 'radius1',
      type: 'number',
    },
    {
      text: 'fields.carve_gcnc.radius2',
      value: 'radius2',
      type: 'number',
    },
    {
      text: 'fields.carve_gcnc.radius3',
      value: 'radius3',
      type: 'number',
    },
    {
      text: 'fields.close_kromka',
      value: 'close_kromka',
      type: 'check',
    },
    {
      text: 'fields.select_kromka',
      value: 'kromka',
      type: 'select',
      options: 'kromkas',
      itemText: 'name',
      itemValue: 'id',
    },
  ],
  ellipse_in: [
    //  ellipse_in  Круг, элипс.
    {
      text: 'fields.corner',
      value: 'angle',
      type: 'corner',
    },
    {
      text: 'fields.ellipse_in.centerheight',
      value: 'centerheight',
      type: 'number',
    },
    {
      text: 'fields.ellipse_in.centerwidth',
      value: 'centerwidth',
      type: 'number',
    },
    {
      text: 'fields.ellipse_in.height',
      value: 'height',
      type: 'number',
    },
    {
      text: 'fields.ellipse_in.width',
      value: 'width',
      type: 'number',
    },
    {
      text: 'fields.tiltangle',
      value: 'tiltangle',
      type: 'number',
    },
    {
      text: 'fields.side_hole.cross_cutting',
      value: 'cross_cutting',
      type: 'check',
    },
    {
      text: '',
      value: 'face',
      type: 'face',
      visible: ({ cross_cutting }) => !cross_cutting,
    },
    {
      text: 'fields.side_hole.drilling_depth_z',
      value: 'drilling_depth_z',
      type: 'number',
      visible: ({ cross_cutting }) => !cross_cutting,
    },
    {
      text: 'fields.close_kromka',
      value: 'close_kromka',
      type: 'check',
    },
    {
      text: 'fields.select_kromka',
      value: 'kromka',
      type: 'select',
      options: 'kromkas',
      itemText: 'name',
      itemValue: 'id',
    },
  ],
  square_in: [
    //  square_in   Прямоугольник
    {
      text: 'fields.corner',
      value: 'angle',
      type: 'corner',
    },
    {
      text: 'fields.square_in.centerheight',
      value: 'centerheight',
      type: 'number',
    },
    {
      text: 'fields.square_in.centerwidth',
      value: 'centerwidth',
      type: 'number',
    },
    {
      text: 'fields.square_in.height',
      value: 'height',
      type: 'number',
    },
    {
      text: 'fields.square_in.width',
      value: 'width',
      type: 'number',
    },
    {
      text: 'fields.square_in.rad',
      value: 'rad',
      type: 'number',
    },
    {
      text: 'fields.tiltangle',
      value: 'tiltangle',
      type: 'number',
    },
    {
      text: 'fields.side_hole.cross_cutting',
      value: 'cross_cutting',
      type: 'check',
    },
    {
      text: '',
      value: 'face',
      type: 'face',
      visible: ({ cross_cutting }) => !cross_cutting,
    },
    {
      text: 'fields.side_hole.drilling_depth_z',
      value: 'drilling_depth_z',
      type: 'number',
      visible: ({ cross_cutting }) => !cross_cutting,
    },
    {
      text: 'fields.close_kromka',
      value: 'close_kromka',
      type: 'check',
    },
    {
      text: 'fields.select_kromka',
      value: 'kromka',
      type: 'select',
      options: 'kromkas',
      itemText: 'name',
      itemValue: 'id',
    },
  ],
  hdl_smile: [
    //  hdl_smile   Ручка «Улыбка»
    {
      text: 'fields.side',
      value: 'place_second',
      type: 'side',
    },
    {
      text: 'fields.hdl_smile.width',
      value: 'width',
      type: 'number',
    },
    {
      text: 'fields.hdl_smile.deep',
      value: 'deep',
      type: 'number',
    },
    {
      text: 'fields.rad_round',
      value: 'rad',
      type: 'number',
    },
    {
      text: 'fields.by_side_center',
      value: 'by_side_center',
      type: 'check',
    },
    {
      text: 'fields.corner_offset',
      value: 'corner_offset',
      type: 'check',
    },
    {
      text: 'fields.cutout_center_side',
      value: 'cutout_center_side',
      type: 'number',
    },
    {
      text: 'fields.close_kromka',
      value: 'close_kromka',
      type: 'check',
    },
    {
      text: 'fields.select_kromka',
      value: 'kromka',
      type: 'select',
      options: 'kromkas',
      itemText: 'name',
      itemValue: 'id',
    },
  ],
  carve_groove: [
    //  carve_groove  Выборка под врезную ручку.
    {
      text: 'fields.carve_groove.width',
      value: 'width',
      type: 'number',
    },
    {
      text: 'fields.carve_groove.height',
      value: 'height',
      type: 'number',
    },
    {
      text: 'fields.carve_groove.deep',
      value: 'deep',
      type: 'number',
    },
    {
      text: 'fields.carve_groove.bycenterwidth',
      value: 'bycenterwidth',
      type: 'check',
    },
    {
      text: 'fields.carve_groove.bycenterheight',
      value: 'bycenterheight',
      type: 'check',
    },
    {
      text: 'fields.carve_groove.offsetwidth',
      value: 'offsetwidth',
      type: 'number',
    },
    {
      text: 'fields.carve_groove.offsetheight',
      value: 'offsetheight',
      type: 'number',
    },
    {
      text: 'fields.carve_groove.edgewidth',
      value: 'edgewidth',
      type: 'check',
    },
    {
      text: 'fields.carve_groove.edgeheight',
      value: 'edgeheight',
      type: 'check',
    },
    {
      text: 'fields.square_in.rad',
      value: 'rad',
      type: 'number',
    },
    {
      text: 'fields.carve_groove.has_holes',
      value: 'has_holes',
      type: 'check',
    },
    {
      text: 'fields.carve_groove.holes_offset_x',
      value: 'holes_offset_x',
      type: 'number',
      visible: ({ has_holes }) => has_holes,
    },
    {
      text: 'fields.carve_groove.holes_offset_y',
      value: 'holes_offset_y',
      type: 'number',
      visible: ({ has_holes }) => has_holes,
    },
  ],
  carve_led_direct: [
    //  carve_led_direct  Выборка под LED профиль прямая
    {
      text: '',
      value: 'face',
      type: 'face',
    },
    {
      text: 'fields.carve_led_direct.width',
      value: 'width',
      type: 'number',
    },
    {
      text: 'fields.carve_led_direct.deep',
      value: 'deep',
      type: 'number',
    },
    {
      text: 'fields.carve_led_direct.offset',
      value: 'offset',
      type: 'number',
    },
    {
      text: 'fields.horizontal',
      value: 'direction',
      type: 'check',
    },
    {
      text: 'fields.groove.is_limited',
      value: 'is_limited',
      type: 'check',
    },
    {
      text: 'fields.groove.groove_length',
      value: 'groove_length',
      type: 'number',
      visible: ({ is_limited }) => is_limited,
    },
    {
      text: 'fields.groove.start_offset',
      value: 'start_offset',
      type: 'number',
      visible: ({ is_limited }) => is_limited,
    },
    {
      text: 'fields.groove.is_opposite_start',
      value: 'is_opposite_start',
      type: 'check',
      visible: ({ is_limited }) => is_limited,
    },
  ],
  carve_led_corner: [
    //  carve_led_corner  Выборка под LED профиль углом
    {
      text: '',
      value: 'face',
      type: 'face',
    },
    {
      text: 'fields.carve_led_direct.width',
      value: 'width',
      type: 'number',
    },
    {
      text: 'fields.carve_led_direct.deep',
      value: 'deep',
      type: 'number',
    },
    {
      text: 'fields.carve_led_corner.offsetwidth',
      value: 'offsetwidth',
      type: 'number',
    },
    {
      text: 'fields.carve_led_corner.offsetheight',
      value: 'offsetheight',
      type: 'number',
    },
    {
      text: 'fields.carve_led_corner.horizontal',
      value: 'horizontal',
      type: 'check',
    },
    {
      text: 'fields.carve_led_corner.vertical',
      value: 'vertical',
      type: 'check',
    },
  ],
  edge_hole: [
    //  сверление Произвольное отверстие в торце
    {
      text: 'fields.corner',
      value: 'angle',
      type: 'corner',
    },
    {
      text: 'fields.side',
      value: 'place_second',
      type: 'side',
    },
    {
      text: 'fields.tool_selection',
      value: 'tool_selection',
      type: 'select',
      options: 'edgeTools',
      itemText: 'name',
      itemValue: 'id',
    },
    {
      text: 'fields.offset',
      value: 'distance_mm',
      type: 'number',
    },
    {
      text: 'fields.edge_hole.deep',
      value: 'deep',
      type: 'number',
    },
    {
      text: 'fields.edge_hole.mid_by_z_center',
      value: 'mid_by_z_center',
      type: 'check',
    },
    {
      text: 'fields.edge_hole.z_offset',
      value: 'z_offset',
      type: 'number',
      visible: ({ mid_by_z_center }) => !mid_by_z_center,
    },
  ],
  side_hole: [
    //  сверление Произвольное отверстие в плоскости
    {
      text: '',
      value: 'face',
      type: 'face',
    },
    {
      text: 'fields.corner',
      value: 'angle',
      type: 'corner',
    },
    {
      text: 'fields.tool_selection',
      value: 'tool_selection',
      type: 'select',
      options: 'areaTools',
      itemText: 'name',
      itemValue: 'id',
    },
    {
      text: 'fields.side_hole.distance_x_mm',
      value: 'distance_x_mm',
      type: 'number',
    },
    {
      text: 'fields.side_hole.distance_y_mm',
      value: 'distance_y_mm',
      type: 'number',
    },
    {
      text: 'fields.side_hole.cross_cutting',
      value: 'cross_cutting',
      type: 'check',
    },
    {
      text: 'fields.side_hole.drilling_depth_z',
      value: 'drilling_depth_z',
      type: 'number',
      visible: ({ cross_cutting }) => !cross_cutting,
    },
  ],
  minifix: [
    //  сверление Узел минификс
    {
      text: '',
      value: 'face',
      type: 'face',
    },
    {
      text: 'fields.corner',
      value: 'angle',
      type: 'corner',
    },
    {
      text: 'fields.minifix.edge',
      value: 'edge',
      type: 'side',
    },
    {
      text: 'fields.indent_mm',
      value: 'indent_mm',
      type: 'number',
    },
  ],
  minifix_shkant: [
    // сверление Узел минификс + шкант
    {
      text: '',
      value: 'face',
      type: 'face',
    },
    {
      text: 'fields.corner',
      value: 'angle',
      type: 'corner',
    },
    {
      text: 'fields.minifix.edge',
      value: 'edge',
      type: 'side',
    },
    {
      text: 'fields.indent_mm',
      value: 'minifix_knot_distance_mm',
      type: 'number',
    },
    {
      text: 'fields.minifix.minifix_knot_to_the_dowel',
      value: 'minifix_knot_to_the_dowel',
      type: 'number',
    },
  ],
  rafix: [
    // сверление Рафикс
    {
      text: '',
      value: 'face',
      type: 'face',
    },
    {
      text: 'fields.corner',
      value: 'angle',
      type: 'corner',
    },
    {
      text: 'fields.minifix.edge',
      value: 'edge',
      type: 'side',
    },
    {
      text: 'fields.indent_mm',
      value: 'distance_to_rafix_mm',
      type: 'number',
    },
  ],
  hinge35: [
    // сверление Петли (d = 35мм)
    {
      text: 'fields.corner',
      value: 'angle',
      type: 'corner',
    },
    {
      text: 'fields.minifix.edge',
      value: 'edge',
      type: 'side',
    },
    {
      text: '',
      value: 'face',
      type: 'face',
    },
    // {
    //   text: 'fields.diameter',
    //   value: 'diameter',
    //   type: 'btn-toggle',
    //   options: [
    //     { value: 26, text: '26 mm' },
    //     { value: 35, text: '35 mm' },
    //   ],
    // },
    // {
    //   text: 'fields.hinge35.distance_from_corner_to_center_of_bowl_mm',
    //   value: 'distance_from_corner_to_center_of_bowl_mm',
    //   type: 'number',
    // },
    // {
    //   text: 'fields.hinge35.number_of_loops',
    //   value: 'number_of_loops',
    //   type: 'select',
    //   options: [1, 2, 3, 4, 5, 6].map(i => ({ value: i, text: i })),
    // },
    {
      text: '',
      value: '',
      type: 'hinge35',
    },
  ],
  tt_lock_socket: [
    {
      text: 'fields.double_rounding',
      value: 'double_rounding',
      type: 'check',
    },
    {
      text: 'fields.corner',
      value: 'tt_lock_socket_angle',
      type: 'corner',
    },
    {
      text: 'fields.tt_lock_socket.lock_length_mm_input',
      value: 'lock_length_mm_input',
      type: 'number',
    },
    {
      text: 'fields.tt_lock_socket.is_mill_the_knot_under_the_screed',
      value: 'is_mill_the_knot_under_the_screed',
      type: 'check',
    },
    {
      text: 'fields.tt_lock_socket.mill_the_knot_under_the_screed',
      value: 'mill_the_knot_under_the_screed',
      type: 'btn-toggle',
      options: [65, 100, 150].map(i => ({ value: i, text: i })),
      visible: ({ is_mill_the_knot_under_the_screed }) =>
        !!is_mill_the_knot_under_the_screed,
    },
    {
      text: 'fields.tt_lock_socket.number_of_nodes',
      value: 'number_of_nodes',
      type: 'btn-toggle',
      options: [1, 2, 3].map(i => ({ value: i, text: i })),
      visible: ({ is_mill_the_knot_under_the_screed }) =>
        !!is_mill_the_knot_under_the_screed,
    },
    {
      text: 'fields.offsets.off_1',
      value: 'offset_1st_node',
      type: 'number',
      visible: ({ is_mill_the_knot_under_the_screed, number_of_nodes }) =>
        !!is_mill_the_knot_under_the_screed && number_of_nodes >= 1,
    },
    {
      text: 'fields.offsets.off_2',
      value: 'offset_2nd_node',
      type: 'number',
      visible: ({ is_mill_the_knot_under_the_screed, number_of_nodes }) =>
        !!is_mill_the_knot_under_the_screed && number_of_nodes >= 2,
    },
    {
      text: 'fields.offsets.off_3',
      value: 'offset_3rd_node',
      type: 'number',
      visible: ({ is_mill_the_knot_under_the_screed, number_of_nodes }) =>
        !!is_mill_the_knot_under_the_screed && number_of_nodes >= 3,
    },
  ],
  tt_lock_plug: [
    {
      text: 'fields.double_rounding',
      value: 'double_rounding',
      type: 'check',
    },
    {
      text: 'fields.corner',
      value: 'tt_lock_plug_angle',
      type: 'corner',
    },
    {
      text: 'fields.tt_lock_socket.is_mill_the_knot_under_the_screed',
      value: 'is_mill_the_knot_under_the_screed',
      type: 'check',
    },
    {
      text: 'fields.tt_lock_socket.mill_the_knot_under_the_screed',
      value: 'mill_the_knot_under_the_screed',
      type: 'btn-toggle',
      options: [65, 100, 150].map(i => ({ value: i, text: i })),
      visible: ({ is_mill_the_knot_under_the_screed }) =>
        !!is_mill_the_knot_under_the_screed,
    },
    {
      text: 'fields.tt_lock_socket.number_of_nodes',
      value: 'number_of_nodes',
      type: 'btn-toggle',
      options: [1, 2, 3].map(i => ({ value: i, text: i })),
      visible: ({ is_mill_the_knot_under_the_screed }) =>
        !!is_mill_the_knot_under_the_screed,
    },
    {
      text: 'fields.offsets.off_1',
      value: 'offset_1st_node',
      type: 'number',
      visible: ({ is_mill_the_knot_under_the_screed, number_of_nodes }) =>
        !!is_mill_the_knot_under_the_screed && number_of_nodes >= 1,
    },
    {
      text: 'fields.offsets.off_2',
      value: 'offset_2nd_node',
      type: 'number',
      visible: ({ is_mill_the_knot_under_the_screed, number_of_nodes }) =>
        !!is_mill_the_knot_under_the_screed && number_of_nodes >= 2,
    },
    {
      text: 'fields.offsets.off_3',
      value: 'offset_3rd_node',
      type: 'number',
      visible: ({ is_mill_the_knot_under_the_screed, number_of_nodes }) =>
        !!is_mill_the_knot_under_the_screed && number_of_nodes >= 3,
    },
  ],
  tt_radius_flange: [
    // Постформинг tt_radius_flange
    {
      text: 'fields.double_rounding',
      value: 'double_rounding',
      type: 'check',
    },
    {
      text: 'fields.corner',
      value: 'tt_radius_flange_angle',
      type: 'corner',
    },
    {
      text: 'fields.radius',
      value: 'tt_radius_flange_radius_mm',
      type: 'number',
    },
    {
      text: 'fields.close_kromka',
      value: 'close_kromka',
      type: 'check',
    },
    {
      text: 'fields.select_kromka',
      value: 'tt_radius_flange_select_kromka',
      type: 'select',
    },
  ],
  tt_corner_cut: [
    // Постформинг tt_corner_cut
    {
      text: 'fields.double_rounding',
      value: 'double_rounding',
      type: 'check',
    },
    {
      text: 'fields.corner',
      value: 'angle',
      type: 'corner',
    },
    {
      text: 'fields.byrest',
      value: 'byrest',
      type: 'check',
    },
    {
      text: 'fields.tt_corner_cut.cut_height_parts_mm',
      value: 'cut_height_parts_mm',
      type: 'number',
    },
    {
      text: 'fields.tt_corner_cut.slice_width_parts_mm',
      value: 'slice_width_parts_mm',
      type: 'number',
    },
    {
      text: 'fields.rounding',
      value: 'round',
      type: 'check',
    },
    {
      text: 'fields.carve_gcnc.radius1',
      value: 'rad_round',
      type: 'number',
      visible: ({ round }) => round,
    },
    {
      text: 'fields.close_kromka',
      value: 'close_kromka',
      type: 'check',
    },
    {
      text: 'fields.select_kromka',
      value: 'tt_corner_cut_select_kromka',
      type: 'select',
    },
  ],
  tt_rect_inside: [
    // Постформинг tt_rect_inside
    {
      text: 'fields.double_rounding',
      value: 'double_rounding',
      type: 'check',
    },
    {
      text: 'fields.corner',
      value: 'angle',
      type: 'corner',
    },
    {
      text: 'fields.tt_rect_inside.cutout_height_mm',
      value: 'cutout_height_mm',
      type: 'number',
    },
    {
      text: 'fields.tt_rect_inside.width_cutout_mm',
      value: 'width_cutout_mm',
      type: 'number',
    },
    {
      text: 'fields.tt_rect_inside.internal_radius_corners_cutout_mm',
      value: 'internal_radius_corners_cutout_mm',
      type: 'number',
    },
    {
      text: 'fields.tt_rect_inside.height_to_center_of_cutout_mm',
      value: 'height_to_center_of_cutout_mm',
      type: 'number',
    },
    {
      text: 'fields.tt_rect_inside.width_to_the_center_of_the_cutout_mm',
      value: 'width_to_the_center_of_the_cutout_mm',
      type: 'number',
    },
  ],
}
