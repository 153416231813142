<template>
  <div>
    <SideInput
      :label="$t('fields.side')"
      v-model="value.place.checkboxes"
      @input="$emit('input', value)"
    />
    <div class="constructor-form-group">
      <label class="constructor-text-field"
        ><span>{{ $t('fields.angle') }}</span
        ><input
          type="number"
          placeholder="0"
          onclick="this.select();"
          v-model.number="value.place.value"
          @input="$emit('input', value)"
      /></label>
    </div>
  </div>
</template>

<script>
import SideInput from '@/components/dateylor/construction/inputs/SideInput'

export default {
  name: 'GrooveEdgeInput',
  components: {
    SideInput,
  },
  props: {
    value: {
      type: Object,
      default: function() {
        return {}
      },
    },
  },
  data() {
    return {}
  },
  computed: {},
  methods: {},
}
</script>

<style scoped lang="scss"></style>
