<template>
  <div>
    <div class="delivery-col col-sm">
      <multiselect
        v-model="selectedPayment"
        :placeholder="'Оплата'"
        :options="['Готівкова', 'Безготівкова']"
        :searchable="false"
        class="mb-2"
        :allowEmpty="false"
        :hideSelected="true"
        :showLabels="false"
        :class="!selectedPayment ? 'delivery-service-not-selected' : ''"
      >
        <template slot="noOptions">
          {{ $i18n.t('user.no_data') }}
        </template>
      </multiselect>
      <input type="text" placeholder="Отримувач ПІБ" class="form-control" />
    </div>
    <div class="delivery-col col-sm">
      <multiselect
        v-model="selectedDelivery"
        :placeholder="$i18n.t('order.select_delivery_service')"
        :options="deliveryOptions"
        :searchable="false"
        class="mb-2"
        :allowEmpty="false"
        :hideSelected="true"
        :showLabels="false"
        track-by="value"
        label="text"
        ref="selectedDelivery"
        :class="
          this.isDeliveryServiceNotSelected
            ? 'delivery-service-not-selected'
            : ''
        "
      >
        <template slot="noOptions">
          {{ $i18n.t('user.no_data') }}
        </template>
      </multiselect>

      <template v-if="this.order.delivery_service === 'novaposhta'">
        <div
          id="group-courier"
          class="mb-2"
          :class="
            selectedNovaPochtaDeliveryType === ''
              ? 'delivery-service-not-selected'
              : ''
          "
        >
          <label class="check d-block mb-2">
            <input
              type="radio"
              value="courier"
              v-model="selectedNovaPochtaDeliveryType"
              name="legal_entity"
              class="check__input"
            />
            <span class="check__box"></span>
            Курьером
          </label>
          <label class="check d-block">
            <input
              type="radio"
              value="branch"
              v-model="selectedNovaPochtaDeliveryType"
              name="legal_entity"
              class="check__input"
            />
            <span class="check__box"></span>
            В отделение
          </label>
        </div>

        <multiselect
          v-if="selectedNovaPochtaDeliveryType"
          v-model="selectedNovaPochtaRegion"
          :placeholder="'Выберите область'"
          :options="novaposhtaRegionList"
          :searchable="true"
          :class="
            !isNovaPochtaRegionSelected ? 'delivery-service-not-selected' : ''
          "
          class="mb-2"
          :allowEmpty="false"
          :hideSelected="true"
          :showLabels="false"
          track-by="value"
          label="text"
          autocomplete="on"
          @input="novaPochtaRegionSelected(selectedNovaPochtaRegion)"
        >
          <span slot="noOptions">
            {{ $i18n.t('user.no_data') }}
          </span>
        </multiselect>

        <template v-if="selectedNovaPochtaRegion">
          <multiselect
            v-model="selectedNovaPochtaCity"
            :placeholder="'Выберите город'"
            :options="novaposhtaCityList"
            :searchable="true"
            :class="
              !isNovaPochtaCitySelected ? 'delivery-service-not-selected' : ''
            "
            class="mb-2"
            :allowEmpty="false"
            :hideSelected="true"
            :showLabels="false"
            track-by="value"
            label="text"
            autocomplete="on"
            @input="novaPochtaCitySelected(selectedNovaPochtaCity)"
          >
            <span slot="noOptions">
              {{ $i18n.t('user.no_data') }}
            </span>
          </multiselect>
        </template>

        <template v-if="selectedNovaPochtaRegion && selectedNovaPochtaCity">
          <multiselect
            v-if="selectedNovaPochtaDeliveryType === 'branch'"
            v-model="selectedNovaPochtaAddress"
            :placeholder="'Адреса'"
            :options="novaposhtaAddressList"
            :searchable="true"
            :class="
              !isNovaPochtaAddressSelected
                ? 'delivery-service-not-selected'
                : ''
            "
            class="mb-2"
            :allowEmpty="false"
            :hideSelected="true"
            :showLabels="false"
            track-by="value"
            label="text"
            autocomplete="on"
            @input="novaPochtaAddressSelected"
          >
            <span slot="noOptions">
              {{ $i18n.t('user.no_data') }}
            </span>
          </multiselect>
          <div v-else class="value mt-2 mb-0">
            <textarea
              :class="
                selectedNovaPochtaCourierAddress === null ||
                selectedNovaPochtaCourierAddress === ''
                  ? 'delivery-service-not-selected'
                  : ''
              "
              class=" form-control"
              v-model="selectedNovaPochtaCourierAddress"
              :placeholder="'Введіть адресу'"
            >
            </textarea>
          </div>
        </template>
      </template>
      <template v-if="this.order.delivery_service === 'delivery'">
        <template>
          <multiselect
            v-model="selectedDeliveryCity"
            :placeholder="'Выберите город'"
            :options="deliveryCityList"
            :searchable="true"
            :class="
              !selectedDeliveryCity ? 'delivery-service-not-selected' : ''
            "
            class="mb-2"
            :allowEmpty="false"
            :hideSelected="true"
            :showLabels="false"
            track-by="id"
            label="name"
            autocomplete="on"
            @input="deliveryCitySelected(selectedDeliveryCity)"
          >
            <span slot="noOptions">
              {{ $i18n.t('user.no_data') }}
            </span>
          </multiselect>
        </template>

        <template v-if="selectedDeliveryCity">
          <multiselect
            v-model="selectedDeliveryType"
            :placeholder="'Тип доставки'"
            :options="getDeliveryType"
            :searchable="true"
            :class="
              selectedDeliveryType === null
                ? 'delivery-service-not-selected'
                : ''
            "
            class="mb-2"
            :allowEmpty="false"
            :hideSelected="true"
            :showLabels="false"
            track-by="value"
            label="text"
            autocomplete="on"
            @input="deliveryTypeSelected(selectedDeliveryType)"
          >
            <span slot="noOptions">
              {{ $i18n.t('user.no_data') }}
            </span>
          </multiselect>
        </template>

        <template v-if="selectedDeliveryCity && selectedDeliveryType">
          <multiselect
            v-if="selectedDeliveryType.value === 'warehouse'"
            v-model="selectedDeliveryWarehouse"
            :placeholder="'Виберіть склад'"
            :options="deliveryWarehousesList"
            :searchable="true"
            :class="
              selectedDeliveryWarehouse === null
                ? 'delivery-service-not-selected'
                : ''
            "
            class="mb-2"
            :allowEmpty="false"
            :hideSelected="true"
            :showLabels="false"
            track-by="id"
            label="name"
            autocomplete="on"
            @input="deliveryWarehouseSelected(selectedDeliveryWarehouse)"
          >
            <span slot="noOptions">
              {{ $i18n.t('user.no_data') }}
            </span>
          </multiselect>
          <div v-else class="value mt-2 mb-0">
            <textarea
              :class="
                selectedDeliveryAddress === null ||
                selectedDeliveryAddress === ''
                  ? 'delivery-service-not-selected'
                  : ''
              "
              class=" form-control"
              v-model="selectedDeliveryAddress"
              :placeholder="'Введіть адресу'"
            >
            </textarea>
          </div>
        </template>
      </template>
      <template v-if="this.order.delivery_service === 'sat'">
        <template>
          <multiselect
            v-model="selectedSatType"
            :placeholder="'Выберите тип'"
            :options="satTypeList"
            :searchable="false"
            :class="!selectedSatType ? 'delivery-service-not-selected' : ''"
            class="mb-2"
            :allowEmpty="false"
            :hideSelected="true"
            :showLabels="false"
            track-by="value"
            label="name"
            autocomplete="on"
            @input="satTypeSelected(selectedSatType)"
          >
            <span slot="noOptions">
              {{ $i18n.t('user.no_data') }}
            </span>
          </multiselect>
        </template>

        <template
          v-if="selectedSatType && selectedSatType.value === 'warehouse'"
        >
          <multiselect
            disabled
            v-model="selectedSatCity"
            :placeholder="'Виберіть населений пункт'"
            :options="satCityList"
            :searchable="true"
            class="mb-2"
            :allowEmpty="false"
            :hideSelected="true"
            :showLabels="false"
            track-by="ref"
            label="description"
            autocomplete="on"
            @input="satCitySelected(selectedSatCity)"
          >
            <span slot="noOptions">
              {{ $i18n.t('user.no_data') }}
            </span>
          </multiselect>

          <div>
            <multiselect
              v-model="selectedSatWarehouse"
              :placeholder="'Виберіть склад'"
              :options="satWarehouseList"
              :searchable="true"
              :class="
                selectedSatWarehouse === null
                  ? 'delivery-service-not-selected'
                  : ''
              "
              class="mb-2"
              :allowEmpty="false"
              :hideSelected="true"
              :showLabels="false"
              track-by="ref"
              label="description"
              autocomplete="on"
              @input="satWarehousesSelected(selectedSatWarehouse)"
            >
              <span slot="noOptions">
                {{ $i18n.t('user.no_data') }}
              </span>
            </multiselect>
          </div>

          <div v-if="selectedSatWarehouse">
            <div class="value mt-2 mb-0">
              <input
                class="form-control"
                :class="
                  satContactPerson === null
                    ? 'delivery-service-not-selected'
                    : ''
                "
                v-model="satContactPerson"
                :placeholder="$i18n.t('delivery_service.sat_contact_person')"
              />
            </div>
            <div class="value mt-2 mb-0">
              <input
                class="form-control"
                :class="
                  satPhoneNumber === null ? 'delivery-service-not-selected' : ''
                "
                v-model="satPhoneNumber"
                :placeholder="$i18n.t('auth.phone')"
              />
            </div>
            <div class="value mt-2 mb-0">
              <date-picker
                :input-class="
                  satDepartureDate === null
                    ? 'delivery-service-not-selected mx-input'
                    : 'mx-input'
                "
                class="w-100"
                v-model="satDepartureDate"
                valueType="format"
                :placeholder="$t('delivery_service.departure_date')"
                :disabled-date="date => date.getTime() < Date.now()"
              ></date-picker>
            </div>
          </div>
        </template>

        <template v-if="selectedSatType && selectedSatType.value === 'doors'">
          <multiselect
            disabled
            v-model="selectedSatCity"
            :placeholder="'Виберіть населений пункт'"
            :options="satCityList"
            :searchable="true"
            class="mb-2"
            :allowEmpty="false"
            :hideSelected="true"
            :showLabels="false"
            track-by="ref"
            label="description"
            autocomplete="on"
            @input="satCitySelected(selectedSatCity)"
          >
            <span slot="noOptions">
              {{ $i18n.t('user.no_data') }}
            </span>
          </multiselect>

          <div v-if="isSatCitySelected">
            <div class="value mt-2 mb-0">
              <input
                :class="
                  satPersonAddress === null || satPersonAddress === ''
                    ? 'delivery-service-not-selected'
                    : ''
                "
                class="form-control"
                v-model="satPersonAddress"
                :placeholder="'Введіть адресу'"
              />
            </div>
            <div class="value mt-2 mb-0">
              <input
                :class="
                  satContactPerson === null || satContactPerson === ''
                    ? 'delivery-service-not-selected'
                    : ''
                "
                class="form-control"
                v-model="satContactPerson"
                :placeholder="$i18n.t('delivery_service.sat_contact_person')"
              />
            </div>
          </div>
          <div class="value mt-2 mb-0">
            <date-picker
              :input-class="
                satDepartureDate === null
                  ? 'delivery-service-not-selected mx-input'
                  : 'mx-input'
              "
              class="w-100"
              v-model="satDepartureDate"
              valueType="format"
              :placeholder="$t('delivery_service.departure_date')"
              :disabled-date="date => date.getTime() < Date.now()"
            ></date-picker>
          </div>
        </template>
      </template>
      <template v-if="this.order.delivery_service === 'within_city'">
        <template>
          <multiselect
            v-model="selectedWithinCityCity"
            :placeholder="'Выберите город'"
            :options="withinCityCityList"
            :searchable="true"
            :class="
              !selectedWithinCityCity ? 'delivery-service-not-selected' : ''
            "
            class="mb-2"
            :allowEmpty="false"
            :hideSelected="true"
            :showLabels="false"
            label="city"
            autocomplete="on"
            @input="withinCityCitySelected(selectedSatType)"
          >
            <span slot="noOptions">
              {{ $i18n.t('user.no_data') }}
            </span>
          </multiselect>
        </template>

        <div class="value mt-2 mb-0" v-if="selectedWithinCityCity">
          <textarea
            :class="
              selectedWithinCityAddress === null ||
              selectedWithinCityAddress === ''
                ? 'delivery-service-not-selected'
                : ''
            "
            class=" form-control"
            v-model="selectedWithinCityAddress"
            :placeholder="'Введіть адресу'"
          >
          </textarea>
        </div>

        <div class="value mt-2 mb-0" v-if="selectedWithinCityCity">
          <textarea
            :class="
              selectedWithinCityDescription === null ||
              selectedWithinCityDescription === ''
                ? 'delivery-service-not-selected'
                : ''
            "
            class=" form-control"
            v-model="selectedWithinCityDescription"
            :placeholder="$i18n.t('delivery_service.description')"
          >
          </textarea>
        </div>

        <div class="value my-2" v-if="selectedWithinCityCity">
          <date-picker
            :input-class="
              withinCityDepartureDate === null
                ? 'delivery-service-not-selected mx-input'
                : 'mx-input'
            "
            class="w-100"
            v-model="withinCityDepartureDate"
            valueType="format"
            :placeholder="$t('delivery_service.departure_date')"
            :disabled-date="date => date.getTime() < Date.now()"
          ></date-picker>
        </div>

        <span>* {{ $t('delivery_service.within_service_warning') }}</span>
      </template>

      <template v-if="this.order.delivery_service === 'pickup'">
        <template>
          <multiselect
            v-model="selectedPickupStore"
            :placeholder="'Выберите город'"
            :options="pickupStoresList"
            :searchable="true"
            :class="!selectedPickupStore ? 'delivery-service-not-selected' : ''"
            class="mb-2"
            :allowEmpty="false"
            :hideSelected="true"
            :showLabels="false"
            label="name"
            track-by="id"
            autocomplete="on"
            @input="pickupStoreSelected(selectedPickupStore)"
          >
            <span slot="noOptions">
              {{ $i18n.t('user.no_data') }}
            </span>
          </multiselect>
        </template>

        <template v-if="selectedPickupStore">
          <multiselect
            v-model="selectedPickupStoreInfo"
            :placeholder="'Выберите отдел'"
            :options="pickupStoreInfo"
            :searchable="true"
            :class="
              selectedPickupStoreInfo === null
                ? 'delivery-service-not-selected'
                : ''
            "
            class="mb-2"
            :allowEmpty="false"
            :hideSelected="true"
            :showLabels="false"
            label="name"
            track-by="id"
            autocomplete="on"
            @input="pickupStoreInfoSelected(selectedPickupStoreInfo)"
          >
            <span slot="noOptions">
              {{ $i18n.t('user.no_data') }}
            </span>
          </multiselect>
        </template>
      </template>
    </div>
  </div>
</template>

<script>
import api from '@/gateway/api'
import withinCities from '@/assets/cities.json'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/locale/uk'
// import moment from 'moment'

export default {
  name: 'Delivery',
  components: { DatePicker },
  props: ['orderId'],
  data: () => ({
    selectedPayment: null,

    isDeliveryServiceNotSelected: false,

    isNovaPochtaRegionSelected: false,
    isNovaPochtaCitySelected: false,
    isNovaPochtaAddressSelected: false,

    isDeliveryCitySelected: false,
    isDeliveryTypeSelected: false,
    isDeliveryWarehouseSelected: false,

    isSatTypeSelected: false,
    isSatWarehouseSelected: false,
    isSatCitySelected: true,
    isSatShowDepartureDate: false,

    isWithinCityCitySelected: false,
    isWithinCityShowDepartureDate: false,

    isPickupStoreSelected: false,
    isPickupStoreInfoSelected: false,

    selectedNovaPochtaDeliveryType: '',
    selectedNovaPochtaRegion: null,
    selectedNovaPochtaCity: null,
    selectedNovaPochtaAddress: null,
    selectedNovaPochtaCourierAddress: '',

    selectedDeliveryCity: null,
    selectedDeliveryWarehouse: null,
    selectedDeliveryType: null,
    selectedDeliveryAddress: null,

    selectedSatType: null,
    selectedSatCity: null,
    selectedSatWarehouse: null,
    satPersonAddress: null,
    satContactPerson: null,
    satPhoneNumber: null,
    satDepartureDate: null,

    selectedWithinCityCity: null,
    withinCityDepartureDate: null,
    selectedWithinCityAddress: null,
    selectedWithinCityDescription: null,

    selectedPickupStore: null,
    selectedPickupStoreInfo: null,
    users: [],
  }),
  computed: {
    customerIdModel: {
      get() {
        return this.users.find(user => user.id === this.order.customer_id)
      },
      set(val) {
        this.order = {
          ...this.order,
          customer_id: val.id,
        }
      },
    },
    order: {
      get: function() {
        const order = this.$store.state.order.order || {
          note: '',
        }
        return {
          ...order,
          // created_at: order.created_at
          //   ? moment(order.created_at).format('DD.MM.YYYY')
          //   : null,
          // updated_at: order.updated_at
          //   ? moment(order.updated_at).format('DD.MM.YYYY')
          //   : null,
          // processed_at: order.processed_at
          //   ? moment(order.processed_at).format('DD.MM.YYYY')
          //   : null,
          // paid_at: order.paid_at
          //   ? moment(order.paid_at).format('DD.MM.YYYY')
          //   : null,
          // ready_to_dispatch_at: order.ready_to_dispatch_at
          //   ? moment(order.ready_to_dispatch_at).format('DD.MM.YYYY')
          //   : null,
          // dispatched_at: order.dispatched_at
          //   ? moment(order.dispatched_at).format('DD.MM.YYYY')
          //   : null,
          // delivered_at: order.delivered_at
          //   ? moment(order.delivered_at).format('DD.MM.YYYY')
          //   : null,
          // completed_at: order.completed_at
          //   ? moment(order.completed_at).format('DD.MM.YYYY')
          //   : null,
        }
      },
      set: function(order) {
        this.$store.commit('order/updateOrder', order)
      },
    },
    selectedDelivery: {
      get: function() {
        // console.log(`del ser: ${this.order.delivery_service}`)

        if (this.order.delivery_service === null) {
          return null
        } else {
          return {
            value: this.order.delivery_service,
            text: this.$i18n.t(
              'delivery_service.' + this.order.delivery_service
            ),
          }
        }
        // ,console.log(this.$i18n.t('delivery_service.' + this.order.delivery_service))
      },
      set: function(newData) {
        this.order.delivery_service = newData.value
        this.$store.dispatch('order/updateOrder', { order: this.order })
      },
    },

    getDeliveryType: {
      get: function() {
        return Object.entries(this.$t('delivery_service.type'))
          .map(([key, value]) => ({ value: key, text: value }))
          .filter(item => {
            return (
              (this.selectedDeliveryCity.IsWarehouse &&
                item.value === 'warehouse') ||
              item.value !== 'warehouse'
            )
          })
      },
    },

    novaposhtaRegionList: {
      get: function() {
        // debugger
        return this.$store.state.order.novaPochtaRegions.delivery || []
      },
      set: function() {},
    },
    novaposhtaCityList: {
      get: function() {
        return this.$store.state.order.novaPochtaCities.delivery || []
      },
      set: function() {},
    },
    novaposhtaAddressList: {
      get: function() {
        return this.$store.state.order.novaPochtaAddress.delivery || []
      },
      set: function() {},
    },

    deliveryCityList: {
      get: function() {
        return this.$store.state.order.deliveryCities.data || []
      },
      set: function() {},
    },
    deliveryWarehousesList: {
      get: function() {
        return this.$store.state.order.deliveryWarehouses.data || []
      },
      set: function() {},
    },
    deliveryWarehousesInfo: {
      get: function() {
        return this.$store.state.order.deliveryWarehousesInfo || []
      },
      set: function() {},
    },
    satTypeList: {
      get: function() {
        return Object.entries(
          this.$t('delivery_service.sat_type')
        ).map(([key, value]) => ({ value: key, name: value }))
      },
    },
    satCityList: {
      get: function() {
        return this.$store.state.order.satCity.data || []
      },
      set: function() {},
    },
    satWarehouseList: {
      get: function() {
        return this.$store.state.order.satWarehouses.data || []
      },
      set: function() {},
    },
    withinCityCityList: {
      get: function() {
        return withinCities
      },
      set: function() {},
    },
    pickupStoresList: {
      get: function() {
        return this.$store.state.order.pickupStores.data || []
      },
      set: function() {},
    },
    pickupStoreInfo: {
      get: function() {
        return this.$store.state.order.pickupStoreInfo.data || []
      },
      set: function() {},
    },

    deliveryOptions: {
      get: function() {
        return [
          {
            value: 'novaposhta',
            text: this.$i18n.t('delivery_service.novaposhta'),
          },
          { value: 'sat', text: this.$i18n.t('delivery_service.sat') },
          {
            value: 'delivery',
            text: this.$i18n.t('delivery_service.delivery'),
          },
          {
            value: 'within_city',
            text: this.$i18n.t('delivery_service.within_city'),
          },
          { value: 'pickup', text: this.$i18n.t('delivery_service.pickup') },
        ]
      },
    },

    /*users: {
      get: function () {
        return this.$store.state.user.users || []
      }
    },*/
    currentUser: {
      get: function() {
        return this.$store.getters['auth/getUser'] || {}
      },
    },
    locale: {
      get: function() {
        return this.$store.state.locale || 'uk'
      },
    },
  },
  methods: {
    updateOrder() {
      // this.$Progress.start()
      this.$root.$emit('playPreloader')
      this.$store
        .dispatch('order/updateOrder', { order: this.order })
        .then(() => {
          // this.$Progress.finish()
          this.$root.$emit('stopPreloader')
        })
        .catch(e => {
          // this.$Progress.fail()
          this.$root.$emit('stopPreloader')
          this.$toast.error(e.response.data.errors)
        })
    },
    novaPochtaRegionSelected(val) {
      this.isNovaPochtaRegionSelected = true
      this.selectedNovaPochtaCity = null
      this.selectedNovaPochtaAddress = null
      // this.$Progress.start()
      // this.$root.$emit("playPreloader")
      return new Promise((resolve, reject) => {
        this.$store
          .dispatch('order/getNovaPochtaCities', {
            delivery_name: this.order.delivery_service,
            lng: this.$store.state.locale || 'uk',
            region_cod: val.value,
          })
          .then(result => {
            // this.$Progress.finish()
            // this.$root.$emit("stopPreloader")
            resolve(result)
          })
          .catch(error => {
            // this.$Progress.fail()
            // this.$root.$emit("stopPreloader")
            reject(error)
          })
      })
    },
    novaPochtaCitySelected(val) {
      this.isNovaPochtaCitySelected = true
      this.isNovaPochtaAddressSelected = false
      this.selectedNovaPochtaAddress = null
      // this.$Progress.start()
      // this.$root.$emit("playPreloader")
      return new Promise((resolve, reject) => {
        this.$store
          .dispatch('order/getNovaPochtaAddress', {
            delivery_name: this.order.delivery_service,
            lng: this.$store.state.locale || 'uk',
            cityRef: val.value,
          })
          .then(result => {
            // this.$Progress.finish()
            // this.$root.$emit("stopPreloader")
            resolve(result)
          })
          .catch(error => {
            // this.$Progress.fail()
            // this.$root.$emit("stopPreloader")
            reject(error)
          })
      })
    },
    novaPochtaAddressSelected() {
      this.isNovaPochtaAddressSelected = true
    },
    deliveryCitySelected(val) {
      // this.$Progress.start()
      // this.$root.$emit("playPreloader")
      this.isDeliveryCitySelected = true
      return new Promise((resolve, reject) => {
        this.$store
          .dispatch('order/getDeliveryWarehousesByCity', { cityId: val.id })
          .then(response => {
            // this.$Progress.finish();
            // this.$root.$emit("stopPreloader")
            resolve(response)
          })
          .catch(error => {
            // this.$Progress.finish()
            // this.$root.$emit("stopPreloader")
            reject(error)
          })
      })
    },
    deliveryTypeSelected(val) {
      this.isDeliveryTypeSelected = true
      if (val.value === 'address') {
        this.selectedDeliveryWarehouse = null
      } else {
        this.selectedDeliveryAddress = ''
      }
    },
    deliveryWarehouseSelected(val) {
      // this.$Progress.start()
      this.$root.$emit('playPreloader')
      this.isDeliveryWarehouseSelected = true
      this.$store
        .dispatch('order/getDeliveryWarehousesInfoById', {
          warehousesId: val.id,
        })
        .then(() => {
          // this.$Progress.finish()
          this.$root.$emit('stopPreloader')
        })
        .catch(() => {
          // this.$Progress.finish()
          this.$root.$emit('stopPreloader')
        })
    },
    satTypeSelected() {
      this.isSatTypeSelected = true
      this.satContactPerson = null
      this.satPersonAddress = null
      this.satPhoneNumber = null
      this.satDepartureDate = null
    },
    satCitySelected() {
      this.isSatCitySelected = true
    },
    satWarehousesSelected() {
      this.isSatWarehouseSelected = true
    },
    withinCityCitySelected() {
      this.isWithinCityCitySelected = true
    },
    pickupStoreSelected(val) {
      this.isPickupStoreSelected = true
      return new Promise((resolve, reject) => {
        this.$store
          .dispatch('order/getPickupStoresInfo', {
            id: val.id,
          })
          .then(result => {
            resolve(result)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    pickupStoreInfoSelected() {
      this.isPickupStoreInfoSelected = true
    },
    submitOrder() {
      let error = false
      switch (this.order.delivery_service) {
        case 'novaposhta':
          error =
            !this.isNovaPochtaRegionSelected ||
            !this.isNovaPochtaCitySelected ||
            ((this.selectedNovaPochtaDeliveryType !== 'branch' ||
              !this.isNovaPochtaAddressSelected) &&
              (this.selectedNovaPochtaDeliveryType !== 'courier' ||
                this.selectedNovaPochtaCourierAddress === null ||
                this.selectedNovaPochtaCourierAddress === ''))
          break
        case 'delivery':
          error =
            this.selectedDeliveryCity === null ||
            this.selectedDeliveryType === null ||
            (this.selectedDeliveryWarehouse === null &&
              this.selectedDeliveryAddress === null &&
              this.selectedDeliveryAddress === '')
          break
        case 'sat':
          error =
            this.selectedSatType === null ||
            (this.selectedSatWarehouse === null &&
              this.satContactPerson === null &&
              this.satPhoneNumber === null &&
              this.satDepartureDate === null) ||
            (this.satPersonAddress === null &&
              this.satContactPerson === null &&
              this.satDepartureDate === null)

          break
        case 'within_city':
          error =
            this.selectedWithinCityCity === null ||
            this.selectedWithinCityAddress === null ||
            this.selectedWithinCityAddress === '' ||
            this.selectedWithinCityDescription === null ||
            this.selectedWithinCityDescription === '' ||
            this.withinCityDepartureDate === null
          break
        case 'pickup':
          error =
            this.selectedPickupStore === null ||
            this.selectedPickupStoreInfo === null
          break
      }
      if (error) {
        this.$toast.error('Пожалуйста заполните все необходимые поля')
      } else {
        if (this.selectedDelivery === null) {
          this.$toast.error(this.$t('order.delivery_service_not_select'))
          this.isDeliveryServiceNotSelected = true
          setTimeout(() => (this.isDeliveryServiceNotSelected = false), 3000)
        } else {
          this.saveDeliveryInfo()
          this.$root.$emit('openDialog', 'OrderingDialog')
        }
      }
    },
    saveDeliveryInfo() {
      let data = {}
      data['delivery_service'] = this.selectedDelivery.value
      switch (this.order.delivery_service) {
        case 'novaposhta':
          data['shipping_type'] = this.selectedNovaPochtaDeliveryType
          data['shipping_region'] = this.selectedNovaPochtaRegion
          data['shipping_city'] = this.selectedNovaPochtaCity
          data['shipping_otdel'] = this.selectedNovaPochtaAddress
          if (this.selectedNovaPochtaDeliveryType === 'branch')
            data['shipping_address'] = this.selectedNovaposhtaAddress.text
          else data['shipping_address'] = this.selectedNovaPochtaCourierAddress
          break
        case 'delivery':
          data['shipping_city'] = this.selectedDeliveryCity
          data['shipping_type'] = this.selectedDeliveryType.value
          if (this.selectedDeliveryType.value === 'warehouse')
            data['shipping_otdel'] = this.selectedDeliveryWarehouse.value
          else data['shipping_address'] = this.selectedDeliveryAddress
          break
        case 'sat':
          data['shipping_type'] = this.selectedSatType
          data['shipping_city'] = this.selectedSatCity
          if (this.selectedSatType.value === 'warehouse') {
            data['shipping_otdel'] = this.selectedSatWarehouse
            data['shipping_fio'] = this.satContactPerson
            data['shipping_phone'] = this.satPhoneNumber
            data['shipping_date'] = this.satDepartureDate
          } else {
            data['shipping_address'] = this.satPersonAddress
            data['shipping_fio'] = this.satContactPerson
            data['shipping_date'] = this.satDepartureDate
          }
          break
        case 'within_city':
          data['shipping_city'] = this.selectedWithinCityCity
          data['shipping_address'] = this.selectedWithinCityAddress
          data['shipping_comment'] = this.selectedWithinCityDescription
          data['shipping_date'] = this.withinCityDepartureDate
          break
        case 'pickup':
          data['shipping_city'] = this.selectedPickupStore
          data['shipping_otdel'] = this.selectedPickupStoreInfo
          break
      }
      this.$store.dispatch('order/saveDeliveryInfo', {
        orderId: this.order.orderId,
        data: data,
      })
    },
    async restoreDeliveryInfo() {
      // this.$Progress.start()
      this.$root.$emit('playPreloader')
      return await new Promise((resolve, reject) => {
        this.$store
          .dispatch('order/getOrder', {
            id: this.orderId,
          })
          .then(response => {
            let order = response.data.order
            switch (order.delivery_service) {
              case 'novaposhta':
                this.selectedNovaPochtaDeliveryType = order['shipping_type']
                this.$store
                  .dispatch('order/getNovaPochtaRegions', {
                    delivery_name: 'novaposhta',
                    lng: this.$store.state.locale || 'uk',
                  })
                  .then(() => {
                    this.selectedNovaPochtaRegion = this.novaposhtaRegionList.find(
                      region => region.value === order['shipping_region_id']
                    )
                    this.novaPochtaRegionSelected(this.selectedNovaPochtaRegion)
                      .then(() => {
                        this.selectedNovaPochtaCity = this.novaposhtaCityList.find(
                          city => city.value === order['shipping_city_id']
                        )
                        this.novaPochtaCitySelected(this.selectedNovaPochtaCity)
                          .then(() => {
                            this.selectedNovaPochtaAddress = this.novaposhtaAddressList.find(
                              address =>
                                address.value === order['shipping_otdel_id']
                            )
                            this.novaPochtaAddressSelected()
                            if (
                              this.selectedNovaPochtaDeliveryType === 'branch'
                            )
                              this.selectedNovaposhtaAddress.text =
                                order['shipping_address']
                            else
                              this.selectedNovaPochtaCourierAddress =
                                order['shipping_address']
                            resolve()
                          })
                          .catch(() => {
                            reject()
                          })
                        resolve()
                      })
                      .catch(() => {
                        reject()
                      })
                    resolve()
                  })
                  .catch(() => {
                    reject()
                  })
                break
              case 'delivery':
                this.$store
                  .dispatch('order/getAllDeliveryCities')
                  .then(() => {
                    this.selectedDeliveryCity = this.deliveryCityList.find(
                      city => city.externalId === order['shipping_city_id']
                    )
                    this.deliveryCitySelected(this.selectedDeliveryCity)
                      .then(() => {
                        this.selectedDeliveryType = this.getDeliveryType.find(
                          type => type.value === order['shipping_type']
                        )
                        this.deliveryTypeSelected(this.selectedDeliveryType)
                        if (this.selectedDeliveryType.value === 'warehouse') {
                          this.selectedDeliveryWarehouse.value = this.deliveryWarehousesList.find(
                            warehouse =>
                              warehouse.value === order['shipping_otdel']
                          )
                          this.deliveryWarehouseSelected(
                            this.selectedDeliveryWarehouse
                          )
                        } else
                          this.selectedDeliveryAddress =
                            order['shipping_address']
                        resolve()
                      })
                      .catch(() => reject())
                    resolve()
                  })
                  .catch(() => reject())
                break
              case 'sat':
                this.$store
                  .dispatch('order/getSatWarehouses')
                  .then(() => {
                    this.selectedSatType = this.satTypeList.find(
                      type => type.value === order['shipping_type']
                    )
                    this.satTypeSelected(this.selectedSatType)
                    if (this.selectedSatType.value === 'warehouse') {
                      this.selectedSatWarehouse = this.satWarehouseList.find(
                        warehouse =>
                          warehouse.cityRef === order['shipping_otdel_id']
                      )
                      this.satWarehousesSelected(this.selectedSatWarehouse)
                      this.satContactPerson = order['shipping_fio']
                      this.satPhoneNumber = order['shipping_phone']
                      this.satDepartureDate = order['shipping_date']
                    } else {
                      this.satPersonAddress = order['shipping_address']
                      this.satContactPerson = order['shipping_fio']
                      this.satDepartureDate = order['shipping_date']
                    }
                    resolve()
                  })
                  .catch(() => reject())
                break
              case 'within_city':
                this.selectedWithinCityCity = this.withinCityCityList.find(
                  city => city.city === order['shipping_city']
                )
                this.withinCityCitySelected(this.selectedWithinCityCity)
                this.selectedWithinCityAddress = order['shipping_address']
                this.selectedWithinCityDescription = order['shipping_comment']
                this.withinCityDepartureDate = order['shipping_date']
                resolve()
                break
              case 'pickup':
                this.$store
                  .dispatch('order/getPickupStores')
                  .then(() => {
                    this.selectedPickupStore = this.pickupStoresList.find(
                      store => store.id === parseInt(order['shipping_city_id'])
                    )
                    this.pickupStoreSelected(this.selectedPickupStore)
                      .then(() => {
                        this.selectedPickupStoreInfo = this.pickupStoreInfo.find(
                          info =>
                            info.id === parseInt(order['shipping_otdel_id'])
                        )
                        this.pickupStoreInfoSelected()
                        resolve()
                      })
                      .catch(() => reject())
                    resolve()
                  })
                  .catch(() => reject())
                break
            }
            // this.$Progress.finish()
            this.$root.$emit('stopPreloader')
            resolve()
          })
          .catch(() => {
            // this.$Progress.fail()
            this.$root.$emit('stopPreloader')
            reject()
          })
      })

      // this.$store.dispatch('order/getAllDeliveryCities')
    },
    async loadUsers() {
      if (
        this.currentUser.hasRole('admin') ||
        this.currentUser.hasRole('manager')
      ) {
        await api
          .get('user', {
            params: {
              type: 'for_order',
            },
          })
          .then(result => {
            this.users = result.data.users
          })
      }
    },
  },
  watch: {
    selectedDelivery(newVal) {
      this.isNovaPochtaRegionSelected = false
      this.isNovaPochtaCitySelected = false
      this.isNovaPochtaAddressSelected = false
      this.isDeliveryCitySelected = false
      this.isDeliveryWarehouseSelected = false
      this.isDeliveryTypeSelected = false
      this.isSatWarehouseSelected = false
      this.isSatTypeSelected = false
      this.isSatWarehouseSelected = false
      this.isWithinCityCitySelected = false

      switch (newVal.value) {
        case 'delivery':
          this.$store.dispatch('order/getAllDeliveryCities')
          this.selectedDeliveryCity = null
          this.selectedDeliveryWarehouse = null
          this.selectedDeliveryType = null
          break
        case 'novaposhta':
          this.$store.dispatch('order/getNovaPochtaRegions', {
            delivery_name: newVal.value,
            lng: this.$store.state.locale || 'uk',
          })
          this.selectedDeliveryCity = null
          this.selectedDeliveryWarehouse = null
          this.selectedDeliveryType = null
          break
        case 'sat':
          this.$store.dispatch('order/getSatWarehouses')
          this.selectedSatType = null
          this.selectedSatWarehouse = null
          this.selectedSatCity = null
          break
        case 'within_city':
          this.selectedWithinCityCity = null
          this.selectedWithinCityAddress = null
          this.selectedWithinCityDescription = null
          this.withinCityDepartureDate = null
          break
        case 'pickup':
          this.$store.dispatch('order/getPickupStores')
          this.selectedPickupStore = null
          this.selectedPickupStoreInfo = null
          break
      }
    },
  },

  created: function() {
    this.loadUsers()
    this.restoreDeliveryInfo()
  },
}
</script>

<style lang="scss">
.delivery-service-not-selected {
  border: 1px solid #ff0000 !important;
  border-radius: 5px;
}
.delivery-col {
  @media only screen and (min-width: 768px) {
    width: 260px;
  }
  @media only screen and (min-width: 1200px) {
    width: 350px;
  }
}
</style>
