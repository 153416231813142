<template>
  <fragment>
    <MatchMedia v-slot="{ xs, sm }" wrapperTag="tr">
      <td>
        <a
          href="#!"
          :data-bs-original-title="item.name"
          v-tooltip
          @click.prevent=""
          >№{{ item.article }}</a
        >
      </td>
      <td>
        <span class="nowrap">{{ item.price.toFixed(2) || 0 }} ₴</span>
      </td>
      <td :class="{ 'footable-last-visible': xs || sm }">
        <span
          class="footable-toggle fooicon last-column d-print-none"
          :class="isOpen ? 'fooicon-minus' : 'fooicon-plus'"
          @click="isOpen = !isOpen"
          v-if="xs || sm"
        ></span>
        <span>{{ item.quantity }}</span>
      </td>
      <td class="d-none d-md-table-cell">
        <span>{{ item.details_count }}</span>
      </td>
      <td class="d-none d-md-table-cell">
        <strong>{{ (item.price * item.quantity).toFixed(2) }} ₴</strong>
      </td>
    </MatchMedia>
    <tr class="footable-detail-row" v-show="isOpen">
      <td colspan="3">
        <table class="footable-details js-table">
          <tbody>
            <tr>
              <th>Деталей</th>
              <td>
                <span>{{ item.details_count }}</span>
              </td>
            </tr>
            <tr>
              <th>Сума</th>
              <td>
                <strong>{{ (item.price * item.quantity).toFixed(2) }} ₴</strong>
              </td>
            </tr>
          </tbody>
        </table>
      </td>
    </tr>
  </fragment>
</template>

<script>
import { Fragment } from 'vue-frag'
import { MatchMedia } from 'vue-component-media-queries'

export default {
  name: 'GoodsTableRow',
  components: { Fragment, MatchMedia },
  props: ['item', 'index'],
  data: () => ({
    isOpen: false,
  }),

  methods: {
    toggle() {
      this.isOpen = !this.isOpen
    },
  },
}
</script>

<style lang="scss"></style>
