import { Point, Path } from 'paper'

export function radToDeg(radians) {
  var pi = Math.PI
  return radians * (180 / pi)
}

export function degToRad(degrees) {
  var pi = Math.PI
  return degrees * (pi / 180)
}

export function calculateRadiusOuter(R, r1, r2) {
  const len = 0.5 * Math.PI
  const o1 = new Point(0, 0)
  const o2 = new Point(r1, Math.sqrt(Math.pow(R + r1, 2) - Math.pow(r1, 2)))
  const alpha = Math.asin(r1 / (R + r1))
  const k1 = new Point(R * Math.sin(alpha), R * Math.cos(alpha))
  const k2 = new Point(0, o2.y)
  const m0 = new Point(R * Math.cos(len / 2), R * Math.sin(len / 2))
  const m1 = o2.subtract(new Point(r1, 0).rotate(30, new Point(0, 0)))

  const o3 = new Point(Math.sqrt(Math.pow(R + r2, 2) - Math.pow(r2, 2)), r2)
  const beta = Math.asin(r2 / (R + r2))
  const k3 = new Point(R * Math.cos(beta), R * Math.sin(beta))
  const k4 = new Point(o3.x, 0)
  const m2 = o3.subtract(new Point(r2, 0).rotate(60, new Point(0, 0)))
  return { o1, o2, alpha, k1, k2, m0, m1, o3, beta, k3, k4, m2 }
}

export function calculateArcSideInner({ h, r1, r2, sizeX, sizeY }) {
  let start = new Point(0, 0),
    end = new Point(sizeX, 0)
  const L = sizeX,
    R = Math.round(h / 2 + Math.pow(L, 2) / (8 * h)),
    H = Math.sqrt(Math.pow(R, 2) - Math.pow(L / 2, 2)),
    middle = new Point(sizeX / 2, h),
    alpha = radToDeg(Math.asin(L / (2 * R))),
    center = new Point(L / 2, -H),
    arcMiddle = center.add(new Point(0, R).rotate(alpha / 2, new Point(0, 0)))

  const arc = new Path.Arc({
    from: start,
    through: middle,
    to: end,
    selected: true,
  })
  const normal1 = arc.getNormalAt(0),
    normal2 = arc.getNormalAt(arc.length)
  const arc1 = new Path.Arc({
    from: center.add(new Point(0, R + r1).rotate(alpha, new Point(0, 0))),
    through: middle.add(new Point(0, r1)),
    to: center.add(new Point(0, R + r1).rotate(-alpha, new Point(0, 0))),
    selected: true,
  })
  const arc2 = new Path.Arc({
    from: center.add(new Point(0, R + r2).rotate(alpha, new Point(0, 0))),
    through: middle.add(new Point(0, r2)),
    to: center.add(new Point(0, R + r2).rotate(-alpha, new Point(0, 0))),
    selected: true,
  })
  const lineY1 = new Path.Line({
    from: [r1, 0],
    to: [r1, sizeY],
    selected: true,
  })
  const lineY2 = new Path.Line({
    from: [sizeX - r2, 0],
    to: [sizeX - r2, sizeY],
    selected: true,
  })

  let o1, o2, m0, m1, m2, m3
  const intersections1 = arc1.getCrossings(lineY1)
  if (r1 && intersections1.length) {
    o1 = intersections1[0].point
    start = new Point(0, o1.y)
    m0 = o1.subtract(new Point(r1, 0).rotate(1, new Point(0, 0)))
    m1 = arc.getNearestPoint(o1)
  } else {
    o1 = start
    m0 = start
    m1 = start
  }
  const intersections2 = arc2.getCrossings(lineY2)
  if (r2 && intersections2.length) {
    o2 = intersections2[0].point
    end = new Point(sizeX, o2.y)
    m2 = arc.getNearestPoint(o2)
    m3 = o2.add(new Point(r2, 0).rotate(-1, new Point(0, 0)))
  } else {
    o2 = end
    m2 = end
    m3 = end
  }

  new Array(arc, arc1, arc2, lineY1, lineY2).forEach(path => path.remove())
  return {
    start,
    middle,
    end,
    L,
    R,
    H,
    h,
    alpha,
    arcMiddle,
    o1,
    o2,
    m0,
    m1,
    m2,
    m3,
    center,
  }
}

export function calculateArcSide({ height, r1, r2, sizeX, sizeY }) {
  let start = new Point(0, height),
    middle = new Point(sizeX / 2, 0),
    end = new Point(sizeX, height)
  const L = sizeX,
    R = Math.round(height / 2 + Math.pow(L, 2) / (8 * height)),
    alpha = radToDeg(Math.asin(L / (2 * R))),
    center = middle.add(new Point(0, R)),
    arcMiddle = center.add(
      new Point(R, 0).rotate(-90 - alpha / 2, new Point(0, 0))
    )

  const arc = new Path.Arc({
    from: start,
    through: middle,
    to: end,
    selected: true,
  })
  const normal1 = arc.getNormalAt(0),
    normal2 = arc.getNormalAt(arc.length)
  const arc1 = new Path.Arc({
    from: center.add(new Point(-(R - r1), 0)),
    through: middle.add(new Point(0, r1)),
    to: center.add(new Point(R - r1, 0)),
    selected: true,
  })
  const arc2 = new Path.Arc({
    from: center.add(new Point(-(R - r2), 0)),
    through: middle.add(new Point(0, r2)),
    to: center.add(new Point(R - r2, 0)),
    selected: true,
  })
  const lineY1 = new Path.Line({
    from: [r1, 0],
    to: [r1, sizeY],
    selected: true,
  })
  const lineY2 = new Path.Line({
    from: [sizeX - r2, 0],
    to: [sizeX - r2, sizeY],
    selected: true,
  })

  let o1, o2, m0, m1, m2, m3
  const intersections1 = arc1.getCrossings(lineY1)
  if (r1 && intersections1.length) {
    o1 = intersections1[0].point
    start = new Point(0, o1.y)
    m0 = o1.subtract(new Point(r1, 0).rotate(1, new Point(0, 0)))
    m1 = arc.getNearestPoint(o1)
  } else {
    o1 = start
    m0 = start
    m1 = start
  }
  const intersections2 = arc2.getCrossings(lineY2)
  if (r2 && intersections2.length) {
    o2 = intersections2[0].point
    end = new Point(sizeX, o2.y)
    m2 = arc.getNearestPoint(o2)
    m3 = o2.add(new Point(r2, 0).rotate(-1, new Point(0, 0)))
  } else {
    o2 = end
    m2 = end
    m3 = end
  }
  // new Path.Arc({
  //   from: start,
  //   through: m0,
  //   to: m1,
  //   selected: true,
  // })
  // new Path.Arc({
  //   from: m2,
  //   through: m3,
  //   to: end,
  //   selected: true,
  // })

  new Array(arc, arc1, arc2, lineY1, lineY2).forEach(path => path.remove())
  return {
    start,
    middle,
    end,
    L,
    R,
    alpha,
    arcMiddle,
    o1,
    o2,
    m0,
    m1,
    m2,
    m3,
    center,
  }
}

export function calculateLineCorner({ width, height, r1, r2, sizeX, sizeY }) {
  const line = new Path.Line({
    from: [0, height],
    to: [width, 0],
  })
  const normal = line.getNormalAt(line.length / 2)
  const line1 = line.clone()
  const line2 = line.clone()
  line1.translate(normal.multiply(-r1))
  line2.translate(normal.multiply(-r2))
  const lineY = new Path.Line({
    from: [r1, 0],
    to: [r1, sizeY],
  })
  const lineX = new Path.Line({
    from: [0, r2],
    to: [sizeX, r2],
  })
  let o1, o2, m0, m1, m2, m3, start, end
  if (r1) {
    o1 = line1.getCrossings(lineY)[0].point
    m0 = o1.subtract(new Point(r1, 0).rotate(1, new Point(0, 0)))
    m1 = line.getNearestPoint(o1)
    start = new Point(0, o1.y)
  } else {
    start = new Point(0, height)
    o1 = start
    m0 = start
    m1 = start
  }
  if (r2) {
    o2 = line2.getCrossings(lineX)[0].point
    m2 = line.getNearestPoint(o2)
    m3 = o2.subtract(new Point(0, r2).rotate(-1, new Point(0, 0)))
    end = new Point(o2.x, 0)
  } else {
    end = new Point(width, 0)
    o2 = end
    m2 = end
    m3 = end
  }

  new Array(line, line1, line2, lineY, lineX).forEach(path => path.remove())

  return { o1, o2, m0, m1, m2, m3, start, end }
}

export function isEqual(parent, bound): boolean {
  if (
    Math.floor(parent.x) === Math.floor(bound.x) &&
    Math.floor(parent.y) === Math.floor(bound.y) &&
    Math.floor(parent.width) === Math.floor(bound.width) &&
    Math.floor(parent.height) === Math.floor(bound.height)
  ) {
    return true
  }
  return false
}
