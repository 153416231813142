<template>
  <Dateylor
    ref="dateylor"
    :detailId="Number($route.params.detailId)"
    :materialId="Number($route.params.materialId)"
    :orderId="Number($route.params.orderId)"
  />
</template>

<script>
import Dateylor from '@/components/dateylor/Dateylor.vue'

export default {
  name: 'ViewDateylor',
  components: {
    Dateylor,
  },
  data: () => ({}),
  computed: {},
  created() {},
  beforeRouteLeave(to, from, next) {
    return this.$refs.dateylor.confirmExit(to, from, next)
  },
  beforeRouteUpdate(to, from, next) {
    return this.$refs.dateylor.confirmExit(to, from, next)
  },
}
</script>

<style lang="scss"></style>
