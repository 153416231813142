<template>
  <!-- pro100 -->
  <div class="form-group">
    <div class="upload-unit" :class="{ ' is-disabled': disabled }">
      <label class="upload-unit__label"
        ><input
          class="upload-unit__input"
          type="file"
          :accept="accept.join(',')"
          :id="type + 'section'"
          @change="onChange($event)"
        />
        <div class="upload-unit__icon">
          <div class="upload-unit__icon-file">
            <img :src="img" :alt="label" />
          </div>
          <div
            class="upload-unit__status"
            :class="{ success: isValid === true, cancel: isValid === false }"
          ></div>
        </div>
        <div class="upload-unit__info">
          <div class="upload-unit__title">
            {{ file ? file.name : label }}
          </div>
          <div class="upload-unit__size cancel" v-if="isValid === false">
            Імпорт неможливий. Недійсний формат
          </div>
          <div class="upload-unit__size" v-else-if="isValid === true">
            {{ parseInt(file.size / 1024) + 'kb. Завантаженно' }}
          </div>
          <div class="upload-unit__size" v-else-if="disabled">
            Імпорт неможливий
          </div>
        </div>
      </label>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FileImportDialogItem',
  components: {},
  props: {
    label: String,
    accept: Array,
    type: String,
    img: String,
    disabled: Boolean,
  },
  data: () => ({
    file: null,
    fileUrl: null,
    fileSelected: false,
  }),
  computed: {
    isValid() {
      const { file, accept } = this
      if (file && file.name) {
        return accept.some(ext => file.name.endsWith(ext))
      }
      return null
    },
  },
  methods: {
    onChange(event) {
      const files = event.target.files
      const fileReader = new FileReader()
      fileReader.addEventListener('load', () => {
        this.fileUrl = fileReader.result
        this.$emit('change', {
          file: this.file,
          fileSelected: this.fileSelected,
          fileUrl: this.fileUrl,
          type: this.type,
        })
      })
      fileReader.readAsDataURL(files[0])
      this.file = files[0]
      this.fileSelected = true
    },
  },
}
</script>

<style lang="scss"></style>
