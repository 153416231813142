import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)

function getParamLocale(): string {
  const matches = window.location.search.match(/lang=([\w]{2})/)
  return (matches && matches[1]) || ''
}
function loadLocaleMessages() {
  const locales = require.context(
    '@/assets/lang',
    true,
    /[A-Za-z0-9-_,\s]+\.json$/i
  )
  const messages = {}
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    if (matched && matched.length > 1) {
      const locale = matched[1]
      messages[locale] = locales(key)
    }
  })
  return messages
}

// @ts-ignore
const vuexState = JSON.parse(localStorage.getItem('vuex'))

const localeParam = getParamLocale()
export const selectedLocale = ['uk', 'en', 'ru'].includes(localeParam)
  ? localeParam
  : 'uk'

export default new VueI18n({
  locale: selectedLocale,
  fallbackLocale: 'uk',
  messages: loadLocaleMessages(),
})
