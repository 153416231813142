<template>
  <fragment>
    <MatchMedia
      v-slot="{ xs, sm }"
      wrapperTag="tr"
      :class="{ 'bg-light': selected }"
    >
      <td>
        <span>{{ index + 1 }}</span>
      </td>
      <td :class="{ 'footable-last-visible': xs || sm }">
        <span
          class="footable-toggle fooicon last-column"
          :class="isOpen ? 'fooicon-minus' : 'fooicon-plus'"
          @click="isOpen = !isOpen"
          v-if="xs || sm"
        ></span>
        <span>{{ item.name }}</span>
      </td>
      <td class="d-none d-md-table-cell">
        <span>{{ item.details_count }} деталі</span>
      </td>
      <td class="d-none d-md-table-cell">
        <span>{{ item.quantity }} плиты</span>
      </td>
      <td class="d-none d-md-table-cell">
        <span>{{ item.price.toFixed(2) || 0 }} ₴</span>
      </td>
      <td class="w-1 d-none d-md-table-cell">
        <div class="btns">
          <div>
            <button
              class="btn btn-icon ico_edit"
              type="button"
              @click="$emit('edit')"
              :disabled="selected"
            ></button>
          </div>
          <div>
            <button
              class="btn btn-icon ico_trash"
              type="button"
              @click="$emit('remove')"
            ></button>
          </div>
        </div>
      </td>
    </MatchMedia>
    <tr class="footable-detail-row" v-show="isOpen">
      <td colspan="3">
        <table class="footable-details">
          <tbody>
            <tr>
              <th>Кількість</th>
              <td>
                <span>{{ item.details_count }} деталі</span>
              </td>
            </tr>
            <tr>
              <th>Плити</th>
              <td>
                <span>{{ item.quantity }} плиты</span>
              </td>
            </tr>
            <tr>
              <th>Ціна</th>
              <td>
                <span>{{ item.price.toFixed(2) || 0 }} ₴</span>
              </td>
            </tr>
            <tr>
              <th></th>
              <td class="w-1">
                <div class="btns">
                  <div>
                    <button
                      class="btn btn-icon ico_edit"
                      type="button"
                      @click="$emit('edit')"
                    ></button>
                  </div>
                  <div>
                    <button
                      class="btn btn-icon ico_trash"
                      type="button"
                      @click="$emit('remove')"
                    ></button>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </td>
    </tr>
  </fragment>
</template>

<script>
import { Fragment } from 'vue-frag'
import { MatchMedia } from 'vue-component-media-queries'

export default {
  name: 'GoodsTableRow',
  components: { Fragment, MatchMedia },
  props: ['item', 'index', 'selected'],
  data: () => ({
    isOpen: false,
  }),

  methods: {
    toggle() {
      this.isOpen = !this.isOpen
    },
  },
}
</script>

<style lang="scss"></style>
