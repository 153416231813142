<template>
  <SelectProduct
    :orderId="Number($route.params.orderId)"
    type="plate"
    @close="
      $router.push({
        name: 'OrderMaterials',
        params: { orderId: $route.params.orderId },
      })
    "
  />
</template>

<script>
import SelectProduct from '@/components/order/SelectProduct.vue'

export default {
  name: 'OrderAddMaterial',
  components: {
    SelectProduct,
  },
  data: () => ({}),
  computed: {},
  created() {},
}
</script>

<style lang="scss"></style>
