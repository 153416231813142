var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"constructor__btns"},[(_vm.showPrevNext)?_c('div',{staticClass:"constructor__btns-steps"},[_c('router-link',{directives:[{name:"tooltip",rawName:"v-tooltip"}],staticClass:"constructor__btn ico_back",class:{ disabled: !_vm.prevDetail || _vm.detailIndex <= 0 },attrs:{"type":"button","data-bs-toggle":"tooltip","data-bs-trigger":"hover","data-bs-placement":"top","data-bs-original-title":_vm.$t('toolbar.prev'),"aria-label":_vm.$t('toolbar.prev'),"to":{
        name: 'Dateylor',
        params: {
          orderId: _vm.orderId,
          materialId: _vm.materialId,
          detailId: _vm.prevDetail && _vm.prevDetail.id,
        },
      },"replace":""}}),_c('router-link',{directives:[{name:"tooltip",rawName:"v-tooltip"}],staticClass:"constructor__btn ico_return",class:{
        disabled: !_vm.nextDetail || _vm.detailIndex >= _vm.allDetails.length - 1,
      },attrs:{"type":"button","data-bs-toggle":"tooltip","data-bs-trigger":"hover","data-bs-placement":"top","data-bs-original-title":_vm.$t('toolbar.next'),"aria-label":_vm.$t('toolbar.next'),"to":{
        name: 'Dateylor',
        params: {
          orderId: _vm.orderId,
          materialId: _vm.materialId,
          detailId: _vm.nextDetail && _vm.nextDetail.id,
        },
      },"replace":""}})],1):_vm._e(),_c('div',{staticClass:"constructor__btns-group"},[_c('button',{directives:[{name:"tooltip",rawName:"v-tooltip"}],staticClass:"constructor__btn ico_copy",attrs:{"type":"button","data-bs-toggle":"tooltip","data-bs-trigger":"hover","data-bs-placement":"top","data-bs-original-title":_vm.$t('toolbar.copy'),"aria-label":_vm.$t('toolbar.copy')},on:{"click":function($event){return _vm.$emit('copy')}}}),_c('button',{directives:[{name:"tooltip",rawName:"v-tooltip"}],staticClass:"constructor__btn ico_print",attrs:{"type":"button","data-bs-toggle":"tooltip","data-bs-trigger":"hover","data-bs-placement":"top","data-bs-original-title":_vm.$t('toolbar.print'),"aria-label":_vm.$t('toolbar.print')},on:{"click":function($event){return _vm.$emit('print')}}}),_c('button',{directives:[{name:"tooltip",rawName:"v-tooltip"}],staticClass:"constructor__btn ico_trash",attrs:{"type":"button","data-bs-toggle":"tooltip","data-bs-trigger":"hover","data-bs-placement":"top","data-bs-original-title":_vm.$t('toolbar.remove'),"aria-label":_vm.$t('toolbar.remove')},on:{"click":function($event){return _vm.$emit('delete')}}}),_c('button',{directives:[{name:"tooltip",rawName:"v-tooltip"}],staticClass:"constructor__btn ico_size",attrs:{"type":"button","data-bs-toggle":"tooltip","data-bs-trigger":"hover","data-bs-placement":"top","data-bs-original-title":_vm.$t('toolbar.scale'),"aria-label":_vm.$t('toolbar.scale')},on:{"click":function($event){return _vm.$emit('resetZoom')}}},[_vm._v(" 100% ")])])])
}
var staticRenderFns = []

export { render, staticRenderFns }