<template>
  <div>
    <div class="table-head --page-new-order">
      <div>
        <Submenu :orderId="orderId" :materialId="materialId" />
      </div>
      <div>
        <button class="btn btn-primary" type="button">Зберегти</button
        ><button class="btn btn-icon ico_trash" type="button"></button>
      </div>
    </div>
    <!-- Materials -->
    <div class="table-body">
      <div class="table-body-overflow">
        <table class="footable breakpoint">
          <tbody>
            <ProductsTableRow
              :item="item"
              :index="index"
              :selected="material && material.id == item.id"
              v-for="(item, index) in materials"
              :key="index"
              @edit="
                $router.replace({
                  name: 'OrderDetails',
                  params: { orderId, materialId: item.id },
                })
              "
              @remove="deleteProduct(item)"
            />
          </tbody>
        </table>
      </div>
    </div>

    <!-- Material details -->
    <div class="details-list">
      <div class="details-list__box">
        <!-- Selected Material info -->
        <div class="details-list__left">
          <div class="details-list__material" v-if="material">
            <h4>Матеріал</h4>
            <div class="material-card">
              <div class="material-card__img">
                <a
                  ref="pic"
                  rel="popover"
                  :data-img="material.image || defaultPicture"
                  data-cls="popover-img"
                >
                  <img
                    :src="material.image || defaultPicture"
                    :alt="material.name"
                    @error="
                      $event.target.src = require('@/assets/img/no_plita.svg')
                    "
                  />
                </a>
              </div>
              <div class="material-card__info">
                <h3>{{ material.name }}</h3>
                <ul>
                  <li>
                    <span>Формат</span
                    ><strong>{{ material.width }}x{{ material.height }}</strong>
                  </li>
                  <li>
                    <span>Товщина</span
                    ><strong>{{ parseInt(material.thickness) }} мм</strong>
                  </li>
                  <li><span>Напрямок</span><strong>Горизонтальний</strong></li>
                </ul>
              </div>
              <div class="material-card__action">
                <label class="check"
                  ><input
                    class="check__input"
                    type="checkbox"
                    :checked="!!material.with_waste"
                    @click.prevent="
                      changeProduct(material.id, {
                        with_waste: !material.with_waste,
                      })
                    "
                  /><span class="check__box"></span>Забрати залишок</label
                >
                <div class="">
                  <Dropdown
                    class="drop --select"
                    v-slot:default="{ show, hide, isOpen }"
                  >
                    <button
                      class="form-control drop-button"
                      type="button"
                      @click="show()"
                      ref="defaultKromka"
                    >
                      {{
                        defaultKromka
                          ? defaultKromka.name
                          : 'Вибір кромки по замовченю'
                      }}
                    </button>
                    <div
                      class="drop-box"
                      :style="{ display: isOpen ? 'block' : 'none' }"
                    >
                      <div class="drop-overflow">
                        <ul class="drop-list">
                          <li
                            class="drop-list-item text-muted"
                            v-if="!kromkas.length"
                          >
                            Кромка вiдсутня
                          </li>
                          <li
                            class="drop-list-item"
                            :class="{
                              'text-muted pe-none':
                                defaultKromka && defaultKromka.id == item.id,
                            }"
                            v-for="(item, index) in kromkas"
                            :key="index"
                            @click="
                              hide()
                              onDefaultKromkaChange(item)
                            "
                          >
                            {{ item.name }}
                          </li>
                        </ul>
                      </div>
                    </div>
                  </Dropdown>
                </div>
                <router-link
                  class="btn btn-primary mt-4"
                  :to="{
                    name: 'OrderAddMaterial',
                    params: { orderId: orderId },
                  }"
                  >Додати матеріал</router-link
                >
              </div>
            </div>
          </div>
        </div>

        <!-- List of details -->
        <div class="details-list__right" v-if="material">
          <div class="details-list__table">
            <label class="input-file"
              ><button
                class="btn btn-primary"
                @click="
                  $root.$emit('openDialog', 'FileImportDialog', {
                    title: 'Выгрузить детали для данного материала',
                  })
                "
              >
                <i class="ico_arrow-down"></i><span>Завантажити файл</span>
              </button></label
            >
            <div class="table-body">
              <table class="footable breakpoint">
                <thead>
                  <tr class="footable-header">
                    <th class="w-1">
                      <div
                        class="btn-icon ico_plus"
                        @click="addDetail(material)"
                      ></div>
                    </th>
                    <th>№</th>
                    <th
                      class="w-1 d-none d-md-table-cell"
                      data-breakpoints="xs"
                    >
                      Розмір
                    </th>
                    <th
                      class="w-1 d-none d-md-table-cell"
                      data-breakpoints="xs"
                    ></th>
                    <th
                      class="w-1 d-none d-md-table-cell"
                      data-breakpoints="xs"
                    >
                      Кромки
                    </th>
                    <th
                      class="w-1 d-none d-xl-table-cell"
                      data-breakpoints="xs sm md"
                    >
                      <div class="d-flex align-items-center flex-column">
                        <span>Структура</span
                        ><label class="check mt-1"
                          ><input
                            class="check__input"
                            type="checkbox"
                            tabindex="-1"
                            :checked="isTextureSetForAll"
                            @click.stop.prevent="
                              updateAllDetails({
                                is_texture_set: !isTextureSetForAll,
                              })
                            "/><span class="check__box me-0"></span
                        ></label>
                      </div>
                    </th>
                    <th
                      class="w-1 d-none d-xl-table-cell"
                      data-breakpoints="xs sm md"
                    >
                      Зшивка
                    </th>
                    <th
                      class="w-1 d-none d-xl-table-cell"
                      data-breakpoints="xs sm md"
                    >
                      Назва
                    </th>
                    <th
                      class="w-1 d-none d-xl-table-cell"
                      data-breakpoints="xs sm md"
                    ></th>
                  </tr>
                </thead>
                <tbody ref="tbody">
                  <DetailsTableRow
                    v-for="(item, index) in details"
                    :key="index"
                    :item="item"
                    :material="material"
                    :order="order"
                    :defaultKromka="defaultKromka"
                    :anyDetailHasDefaultKromka="anyDetailHasDefaultKromka"
                    @no-default-kromka="
                      $toast.warning('Виберіть крайку по замовчуванню')
                      $refs.defaultKromka.click()
                    "
                    ref="detailRows"
                  >
                  </DetailsTableRow>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/gateway/api'
import Submenu from '@/components/order/Submenu.vue'
import ProductsTableRow from '@/components/order/details/ProductsTableRow.vue'
import DetailsTableRow from '@/components/order/details/DetailsTableRow.vue'

export default {
  name: 'Details',
  props: ['orderId', 'materialId'],
  components: {
    Submenu,
    ProductsTableRow,
    DetailsTableRow,
  },

  data: () => ({
    // check if used
    actionHidden: false,
    productNotSelected: false,
    hideFooter: '',
    importFile: null,
    importFileUrl: null,
    oldDetails: null,
  }),
  computed: {
    material() {
      const { materialId } = this
      return this.materials.find(product => product.id === materialId)
    },
    defaultKromka() {
      const { material, kromkas } = this
      if (!material || !Array.isArray(kromkas)) return null
      return kromkas.find(({ id }) => material.edge_line == id)
    },
    defaultPicture() {
      return require('@/assets/img/no_plita.svg')
    },
    order: {
      get: function() {
        return this.$store.state.order.order || {}
      },
      set: function(order) {
        this.$store.commit('order/updateOrder', order)
      },
    },
    locale: {
      get: function() {
        return this.$store.state.locale || 'uk'
      },
    },
    materials() {
      return this.$store.getters['order/materials']
    },
    kromkas() {
      return this.$store.getters['order/kromkas']
    },
    details: {
      get: function() {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.oldDetails = JSON.parse(
          JSON.stringify(this.$store.state.order.details || [])
        )
        return (this.$store.state.order.details || []).sort((a, b) =>
          a.order_no > b.order_no ? 1 : -1
        )
      },
      set(details) {
        // keep here for draggable component
        // ToDo: remove line once implementend on backend
        // this.$store.commit('order/getDetails', details)
      },
    },
    anyDetailHasOperations() {
      return (this.details || []).some(
        ({ is_hole_additive, is_milling, is_texture_set }) =>
          is_hole_additive || is_milling || is_texture_set
      )
    },
    anyDetailHasDefaultKromka() {
      const { defaultKromka } = this
      if (!defaultKromka) return false
      return (this.details || []).some(
        ({ edges }) =>
          Array.isArray(edges) &&
          edges.some(({ material_id }) => material_id == defaultKromka.id)
      )
    },
    isTextureSetForAll: {
      get: function() {
        return (this.details || []).every(
          ({ is_texture_set }) => is_texture_set
        )
      },
    },
  },

  watch: {
    materialId() {
      this.loadDetails()
    },
  },

  methods: {
    updateAllDetails(data) {
      const acceptAction = () => {
        return Promise.all(
          this.$store
            .dispatch('order/updateAllDetailsOfMaterial', {
              material_id: this.materialId,
              data,
            })
            .catch(e => {
              if (
                e &&
                e.response &&
                e.response.data &&
                e.response.data.errors
              ) {
                for (let field in e.response.data.errors) {
                  let messages = e.response.data.errors[field]
                  if (Array.isArray(messages))
                    messages.forEach(message => this.$toast.error(message))
                }
              }
            })
            .then(() => {
              this.$store.dispatch('order/getMaterialDetails', {
                material_id: this.materialId,
              })
            })
        )
      }
      this.$root.$emit('openDialog', 'AskDialog', {
        acceptAction,
        title: 'Змінити значення для всіх деталей?',
        subtitle: 'Увага! Значення буде змінено для всіх деталей',
        acceptText: this.$i18n.t('yes'),
        denyText: this.$i18n.t('no'),
      })
    },
    async changeDefaultKromka({ kromka, detail_edge_action }) {
      const { material } = this
      if (!material) return
      await this.changeProduct(material.id, {
        edge_line: kromka.id,
        detail_edge_action,
      })
      return await this.$store.dispatch('order/getMaterialDetails', {
        order_id: this.order.id,
        material_id: material.id,
        active_tab: 3,
      })
    },
    onDefaultKromkaChange(kromka) {
      if (this.defaultKromka && this.anyDetailHasDefaultKromka) {
        // Решили задачу по кромке таким путем.
        // Вообще не привязываясь к валидации и к куче запросов.
        // Выбрана кромка по умолчанию.
        // Если хотя бы для одной детали она выбрана и прошла валидацию.
        // При изменении кромки по умолчанию:
        //  - ширина кромки не изменилась
        //  - ширина кромки увеличилась
        // - выводим попап:
        // Внимание! Кромка по умолчанию изменена для всех деталей.
        // Удалить       Заменить
        //  - ширина кромки уменьшилась
        // - выводим попап:
        // Внимание! выбранная кромка не соответствует параметрам валидации.
        // Удалить       Отменить

        const height = Number(this.defaultKromka.height)
        const newHeight = Number(kromka.height)
        const isKromkaOk = newHeight >= height
        this.$root.$emit('openDialog', 'AskDialog', {
          acceptAction: () =>
            this.changeDefaultKromka({
              kromka,
              detail_edge_action: 'delete',
            }),
          otherAction: () =>
            isKromkaOk
              ? this.changeDefaultKromka({
                  kromka,
                  detail_edge_action: 'update',
                })
              : null,
          cancelAction: () => {},
          title: 'Змінити кромку за змовчанням?',
          subtitle: isKromkaOk
            ? this.$i18n.t('alert.note.attention_default_kromka_changed')
            : this.$i18n.t('alert.note.attention_default_kromka_invalid'),
          acceptText: this.$i18n.t('button.delete'),
          otherText: this.$i18n.t('button.change'),
          denyText: this.$i18n.t('button.cancel'),
          showOther: isKromkaOk,
          showDeny: !isKromkaOk,
        })
      } else {
        this.changeDefaultKromka({
          kromka,
        })
      }
    },
    deleteProduct(item) {
      const acceptAction = () => {
        // this.$Progress.start();
        this.$root.$emit('playPreloader')
        this.$store
          .dispatch('order/removeOrderProduct', {
            orderId: this.order.id,
            product: item,
          })
          .then(() =>
            this.$store.dispatch('order/getOrderProducts', {
              orderId: this.order.id,
            })
          )
          .then(() => {
            // this.$Progress.finish();
            this.$root.$emit('stopPreloader')
          })
          .catch(() => {
            // this.$Progress.fail();
            this.$root.$emit('stopPreloader')
          })
      }
      this.$root.$emit('openDialog', 'AskDialog', {
        acceptAction,
        title: this.$i18n.t('order.remove_material_query'),
        acceptText: this.$i18n.t('yes'),
        denyText: this.$i18n.t('no'),
      })
    },
    changeProduct(productId, data) {
      return this.$store
        .dispatch('order/changeOrderProduct', {
          orderId: this.orderId,
          productId,
          data,
        })
        .then(material => {
          // this.materialId = productId
          // this.$Progress.finish()
          // this.$root.$emit("stopPreloader")
        })
        .catch(() => {
          // this.$Progress.fail()
          // this.$root.$emit("stopPreloader")
        })
    },
    addDetail(val) {
      if (this.material.id === '') {
        this.productNotSelected = true
        setTimeout(() => {
          this.productNotSelected = false
        }, 6000)
        return
      }

      if (this.order.is_cut_map == true) {
        const confirmed = confirm(
          'В случае добавления или изменения деталей, существующая карта кроя будет сброшена'
        )

        if (confirmed) {
          // this.$Progress.start()
          // this.$root.$emit("playPreloader")
          this.$store
            .dispatch('order/addMaterialDetail', {
              order_id: this.order.id,
              material_id: val.id,
            })
            .then(() => {
              // this.$Progress.finish()
              // this.$root.$emit("stopPreloader")
              this.initSortable()
            })
            .catch(() => {
              // this.$Progress.fail()
              // this.$root.$emit("stopPreloader")
            })
        }
      } else {
        // this.$Progress.start()
        // this.$root.$emit("playPreloader")
        this.$store
          .dispatch('order/addMaterialDetail', {
            order_id: this.order.id,
            material_id: val.id,
          })
          .then(() => {
            // this.$Progress.finish()
            // this.$root.$emit("stopPreloader")
          })
          .catch(() => {
            // this.$Progress.fail()
            // this.$root.$emit("stopPreloader")
          })
      }
    },

    onPickFile() {
      if (this.material.id === '') {
        this.productNotSelected = true
        setTimeout(() => {
          this.productNotSelected = false
        }, 6000)
        return
      }
      this.$refs.importExcelFileInput.click()
    },
    onFilePicked(event) {
      const files = event.target.files
      const fileReader = new FileReader()
      fileReader.addEventListener('load', () => {
        this.importFileUrl = fileReader.result
      })
      fileReader.readAsDataURL(files[0])
      this.importFile = files[0]

      const config = {
        headers: {
          'content-type': 'multipart/form-data',
          'X-CSRF-TOKEN': document.querySelector('meta[name="csrf-token"]'),
        },
      }
      let formData = new FormData()
      formData.append('importFile', this.importFile)
      formData.append('order_id', this.order.id)
      formData.append('material_id', this.material.id)
      // this.$Progress.start()
      this.$root.$emit('playPreloader')
      return new Promise((resolve, reject) => {
        api
          .post('order/import', formData, config)
          .then(() => {
            this.$Progress.finish()
            this.$root.$emit('stopPreloader')
            this.loadData()
            //  this.branches = result.data.branch_translations
            // resolve(result)
          })
          .catch(error => {
            this.$Progress.fail()
            this.$root.$emit('stopPreloader')
            reject(error)
          })
      })
    },
    loadDetails() {
      this.$store.commit('order/clearDetails')
      this.$nextTick(() => {
        // set material once details is cleared
        // to prevent details edges request
        this.$store
          .dispatch('order/getMaterialDetails', {
            material_id: this.materialId,
          })
          .then(() => {
            this.$nextTick(() => {
              this.initPopover()
              this.initSortable()
            })
          })
          .catch(() => {})
      })
    },
    loadData() {
      return this.$store
        .dispatch('order/getOrder', {
          id: this.orderId,
        })
        .then(() =>
          this.$store.dispatch('order/getOrderProducts', {
            orderId: this.orderId,
          })
        )
        .then(res => {
          this.loadDetails()
        })
        .catch(() => {})
    },
    clearFocus() {
      this.$refs.name.blur()
      this.$refs.ends.blur()
    },
    initPopover() {
      /* global $ */
      $(this.$refs.pic)
        .popover({
          html: true,
          trigger: 'hover',
          placement: 'right',
          content: function() {
            return '<img src="' + $(this).data('img') + '" />'
          },
        })
        .hover(function() {
          $('.popover').addClass($(this).data('cls'))
        })
        .click(function() {
          $('.popover').addClass($(this).data('cls'))
        })
    },
    initSortable() {
      this.destroySortable()
      this.$nextTick(function() {
        $(this.$refs.tbody).sortable({
          distance: 10,
          update: this.swapDetails.bind(this),
          start: this.collapseRows.bind(this),
        })
      })
    },
    destroySortable() {
      if ($(this.$refs.tbody).sortable('instance')) {
        $(this.$refs.tbody).sortable('destroy')
      }
    },
    swapDetails(evt, ui) {
      const detail_id = ui.item.data('id')
      const newIndex = 1 + ui.item.index()
      console.log(detail_id, newIndex)
      this.$store
        .dispatch('order/updateDetail', {
          detail_id,
          data: { order_no: newIndex },
        })
        .then(() => {
          this.$store.commit('order/clearDetails')
          this.$store.dispatch('order/getMaterialDetails', {
            order_id: this.order.id,
            material_id: this.material.id,
          })
        })
    },
    collapseRows(evt, ui) {
      this.$refs.detailRows.forEach(c => c.hide())
    },
  },

  mounted() {},

  created() {
    this.$root.$emit('playPreloader')

    this.loadData()
      .then(() => {
        this.$root.$emit('stopPreloader')
      })
      .catch(() => {
        this.$root.$emit('stopPreloader')
      })
  },
}
</script>

<style lang="scss">
.details-list__left {
  @media (min-width: 768px) and (max-width: 1679px) {
    padding-bottom: 2rem;
    margin-bottom: 2rem;
    border-bottom: 10px solid #f7f8fb;
  }
}
.details-list__right .input-file {
  @media (max-width: 1679px) {
    margin-left: 1rem;
  }
}

table.footable > tbody > tr > td,
table.footable > tbody > tr > th {
  display: table-cell;
}

table tr td .text-validate {
  position: relative;

  .text-error {
    padding: 1rem;
    display: none;
    align-items: center;
    justify-content: center;
    left: 50%;
    transform: translateX(-50%);
    position: absolute;
    width: 197px;
    height: 42px;
    background: #464646;
    border-radius: 5px;
    font-weight: normal;
    font-size: 12px;
    line-height: 136.7%;
    color: #ffffff;
    z-index: 1;
  }

  &.error {
    input {
      border: 1px solid #ff0000;
    }

    input:focus {
      border-color: #ff0000;
    }

    .input-width:focus {
      border-color: #ff0000;
    }

    .input-width {
      border: 1px solid #ff0000;
    }

    .text-error {
      display: flex;
    }
  }
}
</style>
