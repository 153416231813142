<template>
  <div class="constructor__body">
    <div class="constructor__panel">
      <slot name="info"></slot>
      <slot name="tabs"></slot>
    </div>
    <div class="constructor-box">
      <div class="constructor-box__sidebar">
        <div class="constructor-box__sidebar-form">
          <div class="constructor__menu-title">Шаблони</div>
          <slot name="inner-tabs"></slot>
          <button
            class="constructor-form__more"
            type="button"
            data-bs-toggle="tooltip"
            v-tooltip
            data-bs-trigger="hover"
            data-bs-placement="right"
            :data-bs-original-title="$t('dateylor.load_template')"
            :aria-label="$t('dateylor.load_template')"
            data-bs-custom-class="ms-1"
            @click="$emit('addTemplate')"
          >
            <span class="ico_plus"></span>
          </button>
        </div>
        <div class="constructor-box__sidebar-content">
          <div class="constructor-options">
            <div class="sidebar-title">{{ $t('fields.options') }}</div>
            <div class="constructor-options-box">
              <slot name="form"></slot>
            </div>
            <div class="constructor-options-more">
              <button
                type="button"
                data-bs-toggle="tooltip"
                v-tooltip
                data-bs-trigger="hover"
                data-bs-placement="right"
                data-bs-custom-class="ms-1"
                :data-bs-original-title="$t('dateylor.add_item')"
                :aria-label="$t('dateylor.add_item')"
                @click="$emit('addItem')"
                :disabled="!template"
              >
                <span class="ico_plus"></span>
              </button>
            </div>
          </div>
          <div class="constructor-operation">
            <div class="sidebar-title">{{ $t('dateylor.active_op') }}</div>
            <slot name="activeOperations"></slot>
          </div>
        </div>
      </div>
      <div
        class="constructor-box__content position-relative me-0"
        :class="{ fullscreen: isFullscreen }"
        ref="fullscreen"
      >
        <slot></slot>
        <button
          class="full-size"
          type="button"
          data-bs-toggle="tooltip"
          v-tooltip
          data-bs-trigger="hover"
          data-bs-placement="top"
          :data-bs-original-title="$t('dateylor.fullscreen')"
          :aria-label="$t('dateylor.fullscreen')"
          @click="toggleFullscreen()"
        ></button>
        <button
          class="change-texture"
          type="button"
          v-tooltip
          data-bs-toggle="tooltip"
          data-bs-trigger="hover"
          data-bs-placement="top"
          title="Цвет материала"
          @click="$emit('toggleBackground')"
        ></button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Layout',
  props: { template: String },
  data() {
    return {
      isFullscreen: false,
    }
  },
  methods: {
    toggleFullscreen() {
      if (this.isFullscreen) {
        this.closeFullscreen()
      } else {
        this.openFullscreen(this.$refs.fullscreen)
      }
      this.isFullscreen = !this.isFullscreen
    },
    openFullscreen(elem) {
      if (elem.requestFullscreen) {
        elem.requestFullscreen()
      } else if (elem.webkitRequestFullscreen) {
        /* Safari */
        elem.webkitRequestFullscreen()
      } else if (elem.msRequestFullscreen) {
        /* IE11 */
        elem.msRequestFullscreen()
      }
    },
    closeFullscreen() {
      if (document.exitFullscreen) {
        document.exitFullscreen()
      } else if (document.webkitExitFullscreen) {
        /* Safari */
        document.webkitExitFullscreen()
      } else if (document.msExitFullscreen) {
        /* IE11 */
        document.msExitFullscreen()
      }
    },
  },
}
</script>

<style scoped lang="scss">
.constructor-box__content {
  background: #fff;

  // Ограничение высоты надо, чтобы избежать ненужной перерисовки при выделении разных
  // рисунков канваса. Бывает, что при выделении в форме появляется/исчезает скролл
  // и это меняет размеры канваса на пару пикселей, провоцируя перерисовку.
  .canvas-wrapper {
    max-height: 760px;
  }
  &.fullscreen > .canvas-wrapper {
    max-height: inherit;
  }

  > button {
    transform: translateY(-25px);
  }
}
</style>
